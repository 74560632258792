import { DataSource } from '@angular/cdk/table';
import { handleErrorFromRest, OkayDialogService } from '@starfish-access/core';
import { AccessRequest } from '@starfish-access/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { RoleIdAndName, Users, UsersDatasourceResponse, UsersRequestConfig } from './usermanagement.model';
import { UserManagementService } from './usermanagement.service';

export class UserManagementDataSource implements DataSource<Users> {
  private usersSubject = new BehaviorSubject<Users[]>([]);
  private loadingSubject$ = new BehaviorSubject<boolean>(false);
  private totalUsersCount$ = new BehaviorSubject<number>(0);

  constructor(private dialogService: OkayDialogService, private service: UserManagementService) {}

  // DataSource interface
  connect(): Observable<Users[]> {
    return this.usersSubject;
  }

  // DataSource interface
  disconnect(): void {
    this.usersSubject.complete();
    this.loadingSubject$.complete();
  }

  loadingSubject(): Observable<boolean> {
    return this.loadingSubject$.asObservable();
  }

  totalUsersCount(): Observable<number> {
    return this.totalUsersCount$.asObservable();
  }

  loadUsers(config: UsersRequestConfig): void {
    this.loadingSubject$.next(true);
    this.service
      .getPaginatedUsers(config)
      .pipe(
        take(1),
        catchError(() => {
          const emptyResponse: UsersDatasourceResponse = {
            users: [],
            totalUsersCount: 0,
          };
          return of(emptyResponse);
        }),
        finalize(() => {
          this.loadingSubject$.next(false);
        })
      )
      .subscribe((res) => {
        this.usersSubject.next(this.sortRolesWithinUsers(res.users));
        this.totalUsersCount$.next(res.totalUsersCount);
      });
  }

  deleteUser(userId: string, config: UsersRequestConfig): void {
    this.service
      .deleteUser(userId)
      .pipe(
        take(1),
        tap(() => this.loadUsers(config))
      )
      .subscribe();
  }

  deleteUserRole(userId: string, roleId: number, config: UsersRequestConfig): void {
    this.service
      .deleteUserRole(userId, roleId)
      .pipe(
        take(1),
        tap(() => this.loadUsers(config)),
        catchError(handleErrorFromRest)
      )
      .subscribe();
  }

  adminUserAddition(ar: AccessRequest, config: UsersRequestConfig) {
    this.service
      .addUserByAdmin(ar)
      .pipe(
        take(1),
        tap(() => {
          this.service.notifySuccess();
          this.loadUsers(config);
        }),
        catchError((e) => {
          this.dialogService.okay(
            'Role Addition Failure',
            'We could not add this role to the user.  Has the user already requested the role? A role with a submission in progress can not be manually added from this screen.'
          );
          return of(null);
        })
      )
      .subscribe();
  }

  sortRolesWithinUsers(users: Users[]): Users[] {
    const usersToDisplay: Users[] = [];

    Array.from(users).forEach((user) => {
      const roles = this.sortRoles(user.roles);
      const displayUser = {
        lName: '',
        fName: '',
        displayName: user.displayName,
        userId: user.userId,
        roles,
      };
      usersToDisplay.push(displayUser);
    });

    return usersToDisplay;
  }

  private sortRoles(roles: RoleIdAndName[]): RoleIdAndName[] {
    // build the roles list...
    const rolesList: RoleIdAndName[] = [];
    for (const role of roles) {
      // console.log('ROLE: ' + role);
      rolesList.push(role);
    }
    // console.log('ROLES LIST: ' + rolesList);
    // then sort it...
    rolesList.sort((a: RoleIdAndName, b: RoleIdAndName) => {
      if (a.roleName.toLowerCase() < b.roleName.toLowerCase()) {
        return -1;
      } else if (a.roleName.toLowerCase() > b.roleName.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
    // console.log('returning rolesList: ' + rolesList);
    return rolesList;
  }
}
