import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfiguredDelegation } from '../model/delegation.model';
import { DelegateService } from '../services/delegate.service';

@Pipe({ name: 'configuredDelegationById' })
export class ConfiguredDelegationByIdPipe implements PipeTransform {
  constructor(private service: DelegateService) {}
  transform(delId: string | null): Observable<ConfiguredDelegation | null> {
    if (!delId) {
      return of(null); // without an id we can't actually do anything
    }
    return this.service.getConfiguredDelegationById(delId);
  }
}
