import { Injectable } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ClientUserPrefs } from '../../model/user-preferences.model';
import { UserPreferencesHttpService } from './user-preferences-http.service';

@Injectable()
export class UserPreferencesService {
  // Use this readonly member for anything that needs user preferences
  readonly getPreferences$: Observable<ClientUserPrefs>;

  private defaultPreferences: ClientUserPrefs = {
    keepMenuOpen: false,
  };
  private isLoading = new BehaviorSubject<boolean>(false);
  private isUpdating = new BehaviorSubject<boolean>(false);
  private loadPrefs = new BehaviorSubject<void>(undefined);
  private loadedPrefs = this.loadPrefs.pipe(
    tap(() => this.isLoading.next(true)),
    mergeMap(() =>
      this.service.getPreferences().pipe(
        catchError(() =>
          // if we can't reach the preferences service, we should provide a default
          of(this.defaultPreferences)
        ),
        finalize(() => this.isLoading.next(false))
      )
    )
  );
  private updatePrefs = new Subject<ClientUserPrefs>();
  private updatedPrefs = this.updatePrefs.pipe(
    tap(() => this.isUpdating.next(true)),
    switchMap((prefs) => this.service.updatePreferences(prefs).pipe(finalize(() => this.isUpdating.next(false))))
  );

  constructor(private service: UserPreferencesHttpService) {
    this.getPreferences$ = merge(this.loadedPrefs, this.updatedPrefs).pipe(shareReplay(1));
  }

  updatePreferences(account: ClientUserPrefs): Observable<ClientUserPrefs> {
    this.updatePrefs.next(account);
    return this.getPreferences$;
  }
}
