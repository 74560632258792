import { Pipe, PipeTransform } from '@angular/core';
import { AccessRequest, ProcessDetails, RequestItem } from '../model/details.model';

@Pipe({ name: 'kioskName' })
export class KioskNamePipe implements PipeTransform {
  transform(pd: ProcessDetails | null): string[] {
    if (!pd) {
      return ['None'];
    }

    const request: AccessRequest = pd.requestDetails;
    const requestItemDetails: RequestItem[] = request.requestItems;

    try {
      const kioskList: string[] = [];
      for (const kiosks of requestItemDetails) {
        if (kiosks?.kiosks) {
          for (const kiosk of kiosks.kiosks) {
            kioskList.push(kiosk.name);
          }
        }
      }
      if (kioskList.length === 0) {
        return ['--'];
      }
      return kioskList;
    } catch (e) {}
    return ['None'];
  }
}
