import { Pipe, PipeTransform } from '@angular/core';
import { ClientCourseMappingList } from '@starfish-access/models';

@Pipe({ name: 'filterSynchedCourses' })
export class FilterSyncedCoursesPipe implements PipeTransform {
  transform(course: ClientCourseMappingList[] | null): ClientCourseMappingList[] {
    if (!course) {
      return [];
    }

    const unsynchedCourses: ClientCourseMappingList[] = [];

    course.map((res) => {
      if (!res.synchronized) {
        unsynchedCourses.push(res);
      }
    });

    return unsynchedCourses;
  }
}
