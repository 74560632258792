import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CourseMenuItem, MenuItem } from '@starfish-access/models';

export interface SelectionChange {
  field: 'SUBJECT' | 'COURSE' | 'SECTION';
  currentValue: string;
}

@Component({
  selector: 'sf-class-config',
  templateUrl: './class-config.component.html',
  styleUrls: ['./class-config.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClassConfigComponent),
      multi: true,
    },
  ],
})
export class ClassConfigComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() possibleSubjects: MenuItem[] = [];
  @Input() possibleCoursesAndSections: CourseMenuItem[] = [];
  @Input() classIndex = 0;
  @Output() selectionChange = new EventEmitter<SelectionChange>();

  formGroup: UntypedFormGroup;
  possibleCourses: MenuItem[] = [];
  possibleSections: MenuItem[] = [];

  constructor(fb: UntypedFormBuilder) {
    this.formGroup = fb.group({
      subject: ['ALL'],
      course: [{ value: 'ALL', disabled: true }],
      section: [{ value: 'ALL', disabled: true }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['possibleCoursesAndSections']) {
      const possibleCourses: CourseMenuItem[] = changes['possibleCoursesAndSections'].currentValue;
      if (possibleCourses?.length > 0) {
        this.possibleCourses = possibleCourses.map((item) => item.course);
      } else {
        this.formGroup.get('course')?.patchValue('ALL');
        this.formGroup.get('course')?.disable();
      }
    }
  }

  ngOnInit(): void {
    this.formGroup.get('subject')?.valueChanges.subscribe((value: string) => {
      const change: SelectionChange = {
        field: 'SUBJECT',
        currentValue: value,
      };
      this.selectionChange.emit(change);
      this.propagateChange({
        ...this.formGroup.value,
        subject: value,
      });

      if (value !== 'ALL') {
        this.formGroup.get('course')?.enable();
      }

      if (value === 'ALL') {
        this.formGroup.get('course')?.setValue('ALL');
        this.formGroup.get('course')?.disable();
      }
    });

    this.formGroup.get('course')?.valueChanges.subscribe((value: string) => {
      const change: SelectionChange = {
        field: 'COURSE',
        currentValue: value,
      };
      this.selectionChange.emit(change);

      // and this.possibleCoursesAndSections is possibly null here, this is to correct that
      if (
        !this.possibleCoursesAndSections &&
        this.formGroup.get('section')?.value &&
        this.formGroup.get('section')?.value !== 'ALL'
      ) {
        const section: MenuItem = {
          id: this.formGroup.get('section')?.value,
          displayName: this.formGroup.get('section')?.value,
        };
        this.possibleCoursesAndSections = [{ course: { id: value, displayName: value }, sections: [section] }];
      }

      if (value !== 'ALL') {
        const selectedCourseMenuItem = this.possibleCoursesAndSections?.find((c) => c.course.id === value);
        if (selectedCourseMenuItem) {
          this.possibleSections = selectedCourseMenuItem.sections;
          this.formGroup.get('section')?.enable();
          if (!this.formGroup.get('section')?.value) {
            this.formGroup.get('section')?.setValue('ALL');
          }
        }
      }
      if (value === 'ALL') {
        this.possibleSections = [];
        this.formGroup.get('section')?.setValue('ALL');
        this.formGroup.get('section')?.disable();
      }

      this.propagateChange({
        ...this.formGroup.value,
        course: value,
      });
    });

    this.formGroup.get('section')?.valueChanges.subscribe((value: string) => {
      const change: SelectionChange = {
        field: 'SECTION',
        currentValue: value,
      };
      this.selectionChange.emit(change);
      this.propagateChange({
        ...this.formGroup.value,
        section: value,
      });
    });
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formGroup.get('subject')?.disable();
      this.formGroup.get('course')?.disable();
      this.formGroup.get('section')?.disable();
    } else {
      this.formGroup.get('subject')?.enable();
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.formGroup.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
