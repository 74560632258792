import { Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DelegationMetaData } from '../../core/model/delegation.model';
import { FilterDelegationPipe } from '../../core/pipe/filter-delegation.pipe';
import { AssignmentFormComponent } from './assignment-form/assignment-form.component';
import { DirectorsFacade } from './directors.facade';

@Component({
  selector: 'sf-directors',
  templateUrl: './directors.component.html',
  styleUrls: ['./directors.component.scss'],
})
export class DirectorsComponent implements OnDestroy {
  @ViewChildren('assignmentFormComp')
  formGroupComponents: QueryList<AssignmentFormComponent>;
  filterSubject: Subject<string> = new BehaviorSubject('');
  filteredDelegations$: Observable<DelegationMetaData[]>;
  private delPipe: FilterDelegationPipe = new FilterDelegationPipe();
  private destroy$ = new Subject<void>();

  constructor(public facade: DirectorsFacade) {
    this.filteredDelegations$ = combineLatest([this.filterSubject, this.facade.getDelegationsMetaData()]).pipe(
      map(([filter, delegations]) => this.delPipe.transform(delegations, filter))
    );
  }

  hasUnsavedWork(delegationId: number): boolean {
    if (this.formGroupComponents === undefined || this.formGroupComponents.length === 0) {
      return false;
    }

    const found = this.formGroupComponents.find((afc) => delegationId === afc.configuredDelegation?.delegation?.id);
    if (found) {
      return found.formGroup?.pristine !== null && found?.formGroup?.pristine === false;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
