import { Component, Input } from '@angular/core';
import { OrgSelectItem } from '@starfish-access/shared';

@Component({
  selector: 'sf-calendar-mgr-kiosks',
  templateUrl: './calendar-mgr-kiosks.html',
  styleUrls: ['./calendar-mgr-kiosks.scss'],
})
export class CalMgrKiosksComponent {
  @Input() kiosksList: OrgSelectItem[] = [];
}
