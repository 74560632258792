import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { ApproveComponent } from './approve.component';

export const routes: Routes = [
  {
    title: 'approve',
    path: 'approve/:taskId',
    canActivate: [AuthGuard],
    component: ApproveComponent,
  },
];
