import { Pipe, PipeTransform } from '@angular/core';
import { InnerTaskComment } from '../model/details.model';

@Pipe({ name: 'formatTaskComment' })
export class FormatTaskCommentPipe implements PipeTransform {
  transform(comment: InnerTaskComment[]): string {
    if (!comment) {
      return '';
    }

    for (const c of comment) {
      return c.taskComment;
    }

    return '';
  }
}
