import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { CourseMenuItem, MenuItem } from '../../model/menu-item.model';
import { Campus, Course, EducationalTrack } from '../../model/server/course-info.model';
import { handleErrorFromRest } from '../../utils/utils.model';
import { headers } from '../service.utils';
import {
  campusesToMenuItemsPipe,
  coursesToMenuItemsPipe,
  createCacheKey,
  departmentsToMenuItemsPipe,
  educationalTracksToMenuItemsPipe,
  semestersToMenuItemsPipe,
} from './course-info.service.utils';

@Injectable({ providedIn: 'root' })
export class CourseInfoService {
  private static ALL_KEY = 'ALL';
  private baseUrl = `${this.starfishConfig.starfishInterface}accessUi`;

  private campusCache: MenuItem[] = [];
  private trackCache: MenuItem[] = [];
  private semesterCache: { [trackId: string]: MenuItem[] } = {};
  private departmentCache: { [cacheKey: string]: MenuItem[] } = {};
  private courseCache: { [cacheKey: string]: CourseMenuItem[] } = {};

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {
    if (!this.starfishConfig.starfishInterface) {
      console.warn('Expected to find property courseInfoService, but did not');
    }
  }

  getAllCampuses(): Observable<MenuItem[]> {
    if (this.campusCache.length === 0) {
      const url = `${this.baseUrl}/campuses`;
      return this.http.get<Campus[]>(url, { headers }).pipe(
        catchError(handleErrorFromRest),
        campusesToMenuItemsPipe,
        tap((menuItems) => {
          this.campusCache = menuItems;
        })
      );
    } else {
      return of(this.campusCache);
    }
  }

  getAllEducationalTracks(): Observable<MenuItem[]> {
    if (this.trackCache.length === 0) {
      const url = `${this.baseUrl}/track`;
      return this.http.get<EducationalTrack[]>(url, { headers }).pipe(
        catchError(handleErrorFromRest),
        educationalTracksToMenuItemsPipe,
        tap((MenuItems) => (this.trackCache = MenuItems))
      );
    } else {
      return of(this.trackCache);
    }
  }

  getAllSemesters(): Observable<MenuItem[]> {
    if (!this.semesterCache[CourseInfoService.ALL_KEY]) {
      const url = `${this.baseUrl}/termNames`;
      return this.http.get<string[]>(url, { headers, params: { hasSections: 'true' } }).pipe(
        catchError(handleErrorFromRest),
        semestersToMenuItemsPipe,
        tap((menuItems) => (this.semesterCache[CourseInfoService.ALL_KEY] = menuItems))
      );
    } else {
      return of(this.semesterCache[CourseInfoService.ALL_KEY]);
    }
  }

  // a department is a subject
  getAllDepartments(): Observable<MenuItem[]> {
    const cacheKey = createCacheKey(CourseInfoService.ALL_KEY, CourseInfoService.ALL_KEY, CourseInfoService.ALL_KEY);
    if (!this.departmentCache[cacheKey]) {
      const url = `${this.baseUrl}/departments`;
      return this.http
        .get<string[]>(url, {
          headers,
        })
        .pipe(
          catchError(handleErrorFromRest),
          departmentsToMenuItemsPipe,
          tap((MenuItems) => (this.departmentCache[cacheKey] = MenuItems))
        );
    } else {
      return of(this.departmentCache[cacheKey]);
    }
  }

  getSemestersByTrackId(track: string): Observable<MenuItem[]> {
    if (!this.semesterCache[track]) {
      const url = `${this.baseUrl}/termNames`;
      return this.http.get<string[]>(url, { headers, params: { track, hasSections: 'true' } }).pipe(
        catchError(handleErrorFromRest),
        semestersToMenuItemsPipe,
        tap((menuItems) => (this.semesterCache[track] = menuItems))
      );
    } else {
      return of(this.semesterCache[track]);
    }
  }

  getDepartments(campus?: string, track?: string, termName?: string): Observable<MenuItem[]> {
    const cacheKey = createCacheKey(campus, track, termName);
    if (!this.departmentCache[cacheKey]) {
      const url = `${this.baseUrl}/departments`;
      const params: { [key: string]: string } = {};
      if (campus) {
        params.campus = campus;
      }
      if (track) {
        params.track = track;
      }
      if (termName) {
        params.termName = termName;
      }
      return this.http
        .get<string[]>(url, {
          headers,
          params,
        })
        .pipe(
          catchError(handleErrorFromRest),
          departmentsToMenuItemsPipe,
          tap((MenuItems) => (this.departmentCache[cacheKey] = MenuItems))
        );
    } else {
      return of(this.departmentCache[cacheKey]);
    }
  }

  getCourses(campus?: string, track?: string, termName?: string, department?: string[]): Observable<CourseMenuItem[]> {
    const cacheKey = createCacheKey(campus, track, termName, department?.toString());
    if (!this.courseCache[cacheKey]) {
      const url = `${this.baseUrl}/courses`;
      const params: { [key: string]: string } = {};
      if (campus) {
        params.campus = campus;
      }
      if (track) {
        params.track = track;
      }
      if (termName) {
        params.termName = termName;
      }
      if (department) {
        params.department = department.toString();
      }
      return this.http
        .get<Course[]>(url, {
          headers,
          params,
        })
        .pipe(
          catchError(handleErrorFromRest),
          coursesToMenuItemsPipe,
          tap((MenuItems) => (this.courseCache[cacheKey] = MenuItems))
        );
    } else {
      return of(this.courseCache[cacheKey]);
    }
  }
}
