export interface CourseMappingDetails {
  taskId: string;
  taskName: string;
  taskStatus: string;
}

interface ClientCourseMappingDetailsDto {
  code: string;
  name: string;
}

export interface ClientCourseMappingDto {
  campus: string;
  termName: string;
  track: string;
  department: string;
  courseNumber: string;
  sectionNumber: string;
  sectionId: string;
}

export interface ClientCourseMappingList {
  id: number;
  courseDto: ClientCourseMappingDto;
  synchronized: boolean;
}

export interface ClientCourseChannelResponse {
  channelServiceId: number;
  serviceDto: ClientCourseMappingDetailsDto;
  assignedDepartments: string[];
  courses: ClientCourseMappingList[];
}

export interface SubjectAndSection {
  subject: string;
  section: ClientCourseChannelResponse | undefined;
}
