import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard } from '@starfish-access/core';
import { DelegationPermissions } from '../../core/permissions/starfish-permissions';
import { DelegationCreateComponent } from './delegation-create/delegation-create.component';
import { DelegationCreateGuard } from './delegation-create/delegation-create.guard';
import { DelegationEditGuard } from './delegation-edit/delegation-edit.guard';
import { DelegationComponent } from './delegation.component';

export const routes: Routes = [
  {
    title: 'delegations',
    path: 'delegations',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    data: {
      permissions: DelegationPermissions.VIEW,
    },
    children: [
      {
        path: '',
        component: DelegationComponent,
        canDeactivate: [DelegationEditGuard],
      },
      {
        path: 'create',
        component: DelegationCreateComponent,
        canDeactivate: [DelegationCreateGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class DelegationRoutingModule {}
