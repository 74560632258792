import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilterModule } from '@starfish-access/core';
import {
  WorkflowDetailsModule,
  WorkflowHeaderModule,
  WorkflowHistoryModule,
  WorkflowIconModule,
} from '@starfish-access/shared';
import { ApproveComponent } from './approve.component';
import { ApproveEffects } from './approve.effects';
import { taskDetailsReducer } from './approve.reducer';
import { routes } from './approve.routing';
import { ApproveService } from './approve.service';
import { TextAreaDialogComponent } from './enter-comment/textarea-dialog.component';
import { UserSelectDialogComponent } from './user-select/user-select-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    FlexLayoutModule,
    FilterModule,
    MatTableModule,
    MatSelectModule,
    WorkflowDetailsModule,
    WorkflowHeaderModule,
    WorkflowIconModule,
    WorkflowHistoryModule,
    StoreModule.forFeature('taskDetails', taskDetailsReducer),
    EffectsModule.forFeature([ApproveEffects]),
    MatDialogModule,
  ],
  declarations: [ApproveComponent, UserSelectDialogComponent, TextAreaDialogComponent],
  providers: [ApproveService],
})
export class ApproveModule {}
