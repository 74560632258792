import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrgSelectItem } from '@starfish-access/shared';
import { RoleConfig } from '../../../core/model/delegation.model';
import { orgModelToOrgSelectItem } from '../../dynamic-role/orgs/orgs.component';

export interface Organizations {
  orgs: OrgSelectItem[];
}

@Component({
  selector: 'sf-add-org',
  templateUrl: './add-org.component.html',
  styleUrls: ['./add-org.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddOrgComponent),
      multi: true,
    },
  ],
})
export class AddOrgComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() selectedRole: RoleConfig;
  @Input() hasAssignments = false;
  orgFormGroup: UntypedFormGroup;
  orgList: OrgSelectItem[];

  constructor(fb: UntypedFormBuilder) {
    this.orgFormGroup = fb.group({
      orgs: [[]],
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRole) {
      this.orgList = this.convertModel();
    }
  }

  writeValue(obj: Organizations): void {
    if (!obj || obj.orgs?.length === 0) {
      return;
    }
    if (!this.hasAssignments) {
      if (this.selectedRole?.orgsFromDelegation?.selectionType === 'ADMIN_AREA_CHOOSE') {
        obj.orgs = [];
      }
    }

    this.orgFormGroup.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.orgFormGroup.valueChanges.subscribe((value: Organizations) => {
      this.propagateChange(value);
    });
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
  private convertModel(): OrgSelectItem[] {
    if (!this.selectedRole) {
      return [];
    }
    const orgs = this.selectedRole.orgsFromDelegation.selectedItems;

    const orgList: OrgSelectItem[] = orgs ? orgs.map(orgModelToOrgSelectItem) : [];

    return orgList;
  }
}
