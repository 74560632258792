import { Injectable } from '@angular/core';
import { ConfirmDialogService, OkayDialogService, RolesService } from '@starfish-access/core';
import { ClientRoleGroup } from '@starfish-access/models';
import { Observable, of } from 'rxjs';
import { catchError, mapTo, mergeMap, take } from 'rxjs/operators';
import { UserManagementDataSource } from './usermanagement.datasource';
import { Users, UsersRequestConfig } from './usermanagement.model';
import { UserManagementService } from './usermanagement.service';

@Injectable()
export class UserManagementFacade {
  readonly roleGroupsWithAdminGroups$: Observable<ClientRoleGroup[]> =
    this.rolesService.getRequestableRoleGroupsWithAdminRoles(true);

  constructor(
    private rolesService: RolesService,
    private okayDialogService: OkayDialogService,
    private userService: UserManagementService,
    private confirmDialogService: ConfirmDialogService
  ) {}

  getSpecificUserRoles(userId: string): Observable<Users> {
    return this.userService.getSpecificUserRoles(userId);
  }

  getUsersTableDataSource(): UserManagementDataSource {
    return new UserManagementDataSource(this.okayDialogService, this.userService);
  }

  removeUserRole(userId: string, roleId: number, config: UsersRequestConfig, ds: UserManagementDataSource): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Remove Role for User?',
        message: 'Are you sure? Removing this role cannot be undone.',
      })
      .pipe(
        take(1),
        mergeMap((res) => {
          if (res) {
            return this.userService.deleteUserRole(userId, roleId).pipe(mapTo(true));
          }
          return of(false);
        }),
        catchError((e) => {
          if (e.status === 403) {
            this.okayDialogService.okay(
              'Delegated Role Error',
              'You cannot remove delegated roles from a user on this page. You can do so from the Role Management section.'
            );
          } else {
            this.okayDialogService.okay('Role Deletion Error', 'We had an unexpected error. Please try again later.');
          }

          return of(false);
        })
      )
      .subscribe((shouldReload) => {
        if (shouldReload) {
          ds.loadUsers(config);
        }
      });
  }
}
