import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationFacade } from '@psu/components/notification-ngrx';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { httpRetryBackoff } from '@psu/utils/rx';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { AccessRequest } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { Users, UsersDatasourceResponse, UsersRequestConfig } from './usermanagement.model';

@Injectable()
export class UserManagementService {
  private static userRolesEndPoint = 'starfishUserRoles';

  private static URL = '';
  userId: string;
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(
    private notifications: NotificationFacade,
    private http: HttpClient,
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig
  ) {}

  addUserByAdmin(user: AccessRequest): Observable<Response> {
    UserManagementService.URL = this.starfishConfig.starfishServices + 'processes/adhocProcess';
    return this.http
      .post<Response>(UserManagementService.URL, user, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  getSpecificUserRoles(userId: string): Observable<Users> {
    UserManagementService.URL =
      this.starfishConfig.starfishServices + UserManagementService.userRolesEndPoint + '?userid=' + userId;
    return this.http
      .get<Users[]>(UserManagementService.URL, {
        headers: this.headers,
      })
      .pipe(
        map((response: Users[]) => response[0]),
        catchError(handleErrorFromRest)
      );
  }

  getPaginatedUsers(config: UsersRequestConfig): Observable<UsersDatasourceResponse> {
    const customFilters: string = this.parameterizeConfig(config);

    const baseUrl = this.starfishConfig.starfishServices + UserManagementService.userRolesEndPoint + customFilters;

    return this.http
      .get<Users[]>(baseUrl, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        httpRetryBackoff(250),
        map((res) => {
          let usersList: Users[] = [];
          if (res.body) {
            usersList = res.body;
          }

          let act = 0;
          const instanceOfHeaderRes = res.headers.get('count');

          if (instanceOfHeaderRes != null) {
            act = +instanceOfHeaderRes;
          }

          const usersResponse: UsersDatasourceResponse = {
            users: usersList,
            totalUsersCount: act,
          };
          return usersResponse;
        }),
        catchError(handleErrorFromRest)
      );
  }

  deleteUser(userId: string): Observable<void> {
    // build the URL
    UserManagementService.URL =
      this.starfishConfig.starfishServices + UserManagementService.userRolesEndPoint + '/' + userId;
    return this.http
      .delete<Response>(UserManagementService.URL, {
        headers: this.headers,
      })
      .pipe(
        map((response: Response) => this.logResponse(response)),
        catchError(handleErrorFromRest)
      );
  }

  deleteUserRole(userId: string, roleId: number): Observable<Response> {
    // build the URL
    UserManagementService.URL =
      this.starfishConfig.starfishServices +
      UserManagementService.userRolesEndPoint +
      '/' +
      userId +
      '?roleId=' +
      roleId;

    return this.http.delete<Response>(UserManagementService.URL, {
      headers: this.headers,
    });
  }

  notifySuccess(): void {
    this.notifications.success({
      message:
        'The request to add the role has been succesfully received. We are processing and it will appear here very shortly.',
      closeText: 'OK',
    });
  }

  parameterizeConfig(config: UsersRequestConfig): string {
    if (!config) {
      return '';
    }

    if (config.pageStart && config.pageTotal) {
      // so page three, if 10 items per page, should start at 31
      const startingPage = (config.pageStart - 1) * config.pageTotal + 1;
      const numberOfItems = config.pageTotal; // items per page

      let customParams = '?firstResult=' + startingPage + '&maxResult=' + numberOfItems;

      if (config.filterString && config.filterString.length > 0) {
        customParams = customParams + '&userid=' + config.filterString.toLowerCase();
      }

      if (config.useridSortOrder && config.useridSortOrder.length > 0) {
        customParams = customParams + '&useridSortOrder=' + config.useridSortOrder.toUpperCase();
      }

      return customParams;
    } else {
      return '';
    }
  }

  private logResponse(response: any): void {
    // eslint-disable-next-line no-console
    console.log('RESPONSE: ' + JSON.stringify(response));
  }
}
