<span
  class="mat-typography confirm-dialog"
  [ngClass]="{ 'dialog-progressbar': includeProgressbar }"
  data-cy="confirm-dialog">
  <div class="display-flex confirm-dialog-title-container">
    <mat-icon
      class="confirm-dialog-alert-icon"
      svgIcon="alert-outline"></mat-icon>
    <h3
      *ngIf="title"
      class="confirm-dialog-title"
      data-cy="title">
      {{ title }}
    </h3>
  </div>

  <mat-dialog-content
    *ngIf="selections"
    [ngClass]="{ 'confirm-dialog-scrollbar-fix': scrollbarFix }">
    <div
      id="selections"
      class="selections"
      data-cy="selections"
      *ngFor="let item of selections">
      <span class="valign">
        <mat-icon class="blue1 item-icon">subject</mat-icon>
      </span>
      <span class="valign">{{ item.subject }} = {{ item.option }}</span>
      <br />
    </div>
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-content [ngClass]="{ 'confirm-dialog-scrollbar-fix': scrollbarFix }">
    <div
      id="message"
      *ngIf="message"
      data-cy="message">
      {{ message }}
    </div>
    <ng-content></ng-content>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      type="button"
      mat-button
      [color]="cancelColor ? cancelColor : 'primary'"
      (click)="dialogRef.close(false)"
      data-cy="cancel">
      {{ cancelLabel ? cancelLabel : 'Cancel' }}
    </button>
    <button
      type="button"
      mat-flat-button
      [color]="okColor ? okColor : 'primary'"
      (click)="dialogRef.close(true)"
      data-cy="ok">
      <mat-icon
        class="confirm-dialog-ok-icon"
        svgIcon="arrow-right"></mat-icon
      >{{ okLabel ? okLabel : 'Okay' }}
    </button>
  </mat-dialog-actions>
</span>
