import { Action } from '@ngrx/store';
import { CompleteResourceApproval } from './resources-complete.model';
import { ResourceTaskDetails } from './resources-task.model';

export const POST_COMPLETED = '[ResourcesApprovalActions] Post Resource completed - resetting';
export const POST_TASK_DETAILS = '[ResourcesApprovalActions] POST Resource Details';
export const POST_SUCCESS_TASK_DETAILS = '[ResourcesApprovalActions] POST Success Resource Details';
export const REQUEST_RESOURCE = '[ResourcesApprovalActions] Request Resources Approvals';
export const RECEIVE_RESOURCE = '[ResourcesApprovalActions] Receive Resources Approvals';
export const ERROR_JOBS = '[ResourcesApprovalActions] Error From Resources Approvals';

export class PostTaskDetails implements Action {
  readonly type = POST_TASK_DETAILS;
  constructor(public payload: CompleteResourceApproval) {}
}

export class PostTaskDetailsSuccess implements Action {
  readonly type = POST_SUCCESS_TASK_DETAILS;
}

export class CompleteAddProcess implements Action {
  readonly type = POST_COMPLETED;
}

export class RequestFailedJobs implements Action {
  readonly type = REQUEST_RESOURCE;
  constructor(public payload: string) {}
}

export class ReceiveFailedJobs implements Action {
  readonly type = RECEIVE_RESOURCE;
  constructor(public payload: ResourceTaskDetails) {}
}

export class ErrorFailedJobs implements Action {
  readonly type = ERROR_JOBS;
  constructor(public payload: string) {}
}

export type ResourceActions =
  | PostTaskDetails
  | PostTaskDetailsSuccess
  | CompleteAddProcess
  | RequestFailedJobs
  | ReceiveFailedJobs
  | ErrorFailedJobs;
