import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { CompleteResourceApproval } from './resources-complete.model';
import { ResourceTaskDetails } from './resources-task.model';

@Injectable()
export class ResourcesApprovalService {
  private static endPoint = 'tasks';
  private static detailsEndPoint = '/details';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getDetails(taskId: string): Observable<ResourceTaskDetails> {
    // build the URL
    ResourcesApprovalService.URL =
      this.starfishConfig.starfishServices +
      ResourcesApprovalService.endPoint +
      '/' +
      taskId +
      ResourcesApprovalService.detailsEndPoint;

    return this.http
      .get<ResourceTaskDetails>(ResourcesApprovalService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  updatedTaskbyId(completeApproval: CompleteResourceApproval): Observable<void> {
    // build the URL
    ResourcesApprovalService.URL =
      this.starfishConfig.starfishServices + ResourcesApprovalService.endPoint + '/' + completeApproval.taskId;

    const updateTask = {
      taskAction: completeApproval.taskAction,
      taskComment: completeApproval.taskComment,
      additionalInfoUser: completeApproval.additionalInfoUser,
      dusUpdates: completeApproval.dusUpdates,
    };

    return this.http
      .post<Response>(ResourcesApprovalService.URL, updateTask, {
        headers: this.headers,
      })
      .pipe(
        // eslint-disable-next-line no-console
        map((response: Response) => console.log('RESPONSE: ' + JSON.stringify(response))),
        // https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/739
        catchError(handleErrorFromRest)
      );
  }
}
