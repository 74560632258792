import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { searchForTerm, searchForTermComplete, searchForTermError } from './typeahead.actions';
import { TypeaheadService } from './typeahead.service';

@Injectable()
export class TypeaheadEffects {
  searchForTerm$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(searchForTerm),
      // TODO using switchMap here means we cannot have multiple instances of a typeahead searching at the same time.
      // Add a layer of indirection so we can pass componentId and observe just on those results.
      switchMap((action) =>
        this.service.searchUser(action.term, action.config).pipe(
          map((results) => searchForTermComplete({ componentId: action.componentId, results })),
          takeUntil(this.actions$.pipe()),
          catchError((error) => of(searchForTermError({ componentId: action.componentId, error })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private service: TypeaheadService) {}
}
