import { Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard, KioskPermissions } from '@starfish-access/core';
import { KiosksComponent } from './kiosks.component';

export const routes: Routes = [
  {
    title: 'kiosks',
    path: 'kiosks',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    component: KiosksComponent,
    data: {
      permissions: KioskPermissions.VIEW,
    },
  },
];
