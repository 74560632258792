import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserInfo } from '@starfish-access/models';
import { differenceWith, union } from 'ramda';
import { Subject } from 'rxjs';
import { AssignUserComponentData } from '../../directors.model';
import { ConnectedStudentForm } from '../../multi-student/multi-student.component';

const cmp = (x: UserInfo, y: UserInfo) => x.id === y.id;

@Component({
  selector: 'sf-compare-users',
  templateUrl: './compare-users.component.html',
  styleUrls: ['./compare-users.component.scss'],
})
export class CompareUsersComponent implements OnInit, OnDestroy {
  @Input() originalDataSet: AssignUserComponentData;
  @Output() deleteUserEmitter: EventEmitter<UserInfo[]> = new EventEmitter();
  @Output() addUserEmitter: EventEmitter<UserInfo[]> = new EventEmitter();

  usersAdded: UserInfo[] = [];
  usersRemoved: UserInfo[] = [];

  private originalUserList: UserInfo[] = [];
  private destroy$ = new Subject<void>();

  constructor() {}
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.originalUserList = this.originalDataSet.preloadedAssignment?.options.connectedStudents
      ? this.originalDataSet.preloadedAssignment?.options.connectedStudents
      : [];
  }

  updateComparisons(updatedUserList: ConnectedStudentForm) {
    if (!updatedUserList.users && !updatedUserList.bulkusers?.connectedUsers) {
      return;
    }
    const bulkOnly: UserInfo[] = this.buildBulkList(updatedUserList.bulkusers?.connectedUsers);
    const mergedOrReplacesUsersList = this.buildUpdatedList(
      updatedUserList.users,
      bulkOnly,
      updatedUserList.bulkusers?.studentUpdateType
    );
    this.setNewUsers(mergedOrReplacesUsersList);
    this.setDeletedUsers(mergedOrReplacesUsersList);
  }

  private buildBulkList(bulkList: string): UserInfo[] {
    return bulkList
      ?.split('\n')
      .filter((res) => res.length !== 0)
      .map((res) => ({
        id: res,
        displayName: res,
      }));
  }

  private buildUpdatedList(
    singleUsers: UserInfo[],
    bulkOnly: UserInfo[],
    type: 'MERGE' | 'REPLACE' | undefined
  ): UserInfo[] {
    bulkOnly = bulkOnly ? bulkOnly : [];

    let mergedOrReplacesUsersList: UserInfo[] = bulkOnly;

    try {
      if (!type || type !== 'REPLACE') {
        mergedOrReplacesUsersList = union(singleUsers, bulkOnly);
      }
    } catch (e) {
      console.error(e);
    }
    return mergedOrReplacesUsersList;
  }

  private setNewUsers(addedToList: UserInfo[]) {
    try {
      this.usersAdded = differenceWith(cmp, addedToList, this.originalUserList);
    } catch (e) {
      console.error(e);
    }
  }

  private setDeletedUsers(addedToList: UserInfo[]) {
    try {
      this.usersRemoved = differenceWith(cmp, this.originalUserList, addedToList);
    } catch (e) {
      console.error(e);
    }
  }
}
