import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard, StarfishGuard, UserListPermissions } from '@starfish-access/core';
import { UserManagementComponent } from './usermanagement.component';

export const routes: Routes = [
  {
    title: 'users',
    path: 'users',
    canActivate: [AuthGuard, FerpaGuard, StarfishGuard],
    component: UserManagementComponent,
    data: {
      permissions: [UserListPermissions.VIEW],
    },
  },
];
