import { Pipe, PipeTransform } from '@angular/core';
import { RoleDetails } from '@starfish-access/models';

@Pipe({ name: 'hasRolePipe' })
export class HasRolePipe implements PipeTransform {
  transform(usersRoles: RoleDetails[] | null, singleRole: string | undefined): boolean {
    if (!usersRoles || !singleRole) {
      return false;
    }

    if (!this.isIterable(usersRoles)) {
      return false;
    }
    for (const role of usersRoles) {
      if (role.requestedRole === singleRole && !this.wasCancelled(role)) {
        return true;
      }
    }
    return false;
  }

  private wasCancelled(role: RoleDetails): boolean {
    if (role.requestStatus === 'REJECTED') {
      return true;
    } else if (role.requestStatus === 'CANCELED') {
      return true;
    } else if (role.requestStatus === 'CANCELLED') {
      return true;
    }
    return false;
  }

  private isIterable(obj: any): boolean {
    // checks for null and undefined
    if (obj == null) {
      return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
  }
}
