<button
  mat-flat-button
  class="sticky save-button"
  data-cy="director-save-button"
  [disabled]="directorsFacade.addingAssignment$ | async"
  (click)="saveAssignment()"
  [ngClass]="{ 'sticky-all-saved': formGroup.pristine }"
  color="accent">
  <!--  <div class="save-button-layout">-->
  <mat-icon *ngIf="!(directorsFacade.addingAssignment$ | async)">save</mat-icon>
  <mat-spinner
    *ngIf="directorsFacade.addingAssignment$ | async"
    [diameter]="25"></mat-spinner>

  <span>Save</span>
  <!--  </div>-->
</button>
<div
  class="sf-assignment-form"
  [formGroup]="formGroup">
  <mat-progress-bar
    *ngIf="loadingDelegation$ | async"
    class="assignment-loading-bar"
    data-cy="assignment-form-progress-bar"
    mode="indeterminate"></mat-progress-bar>
  <sf-directors-user-table
    #userTableComp
    formControlName="assignedRoles"
    [configuredDelegation]="configuredDelegation"></sf-directors-user-table>
  <div class="cal-list"></div>
  <sf-directors-calendar-mgr
    class="regular-mgrs"
    formControlName="calendarManagers"
    additionalDetail="Use for staff members who can see all student information. Regular Calendar Managers must also have an additional Starfish role (e.g. Advising Records and Support, Staff Tutor, etc) appropriate for their job duties. All users in the “Manage Role Assignments” section above with calendar management enabled will have calendars managed by these users."
    *ngIf="
      configuredDelegation?.delegation?.calendarManagement === 'REGULAR' ||
      configuredDelegation?.delegation?.calendarManagement === 'BOTH'
    "
    [configuredDelegation]="configuredDelegation">
  </sf-directors-calendar-mgr>
  <sf-directors-calendar-mgr
    class="student-mgrs"
    calendarTitle="Student Calendar Managers"
    formControlName="studentCalendarManagers"
    additionalDetail="Use for current student workers who should not see other student’s information. All users in the “Manage Role Assignments” section above with calendar management enabled will have calendars managed by these users."
    *ngIf="
      configuredDelegation?.delegation?.calendarManagement === 'STUDENT' ||
      configuredDelegation?.delegation?.calendarManagement === 'BOTH'
    "
    [configuredDelegation]="configuredDelegation">
  </sf-directors-calendar-mgr>

  <div class="margin-bottom">
    <sf-calendar-mgr-kiosks [kiosksList]="configuredDelegation?.delegation?.calendarKiosks"></sf-calendar-mgr-kiosks>
  </div>

  <div class="margin-bottom">
    <sf-director-list [directorList]="configuredDelegation?.delegation?.admins"></sf-director-list>
  </div>

  <mat-progress-bar
    *ngIf="directorsFacade.addingAssignment$ | async"
    data-cy="save-role-progress-bar"
    mode="indeterminate"></mat-progress-bar>
</div>
