import { Component, forwardRef, Inject, Input } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TypeaheadModel, TypeaheadSearchConfig } from '@psu/components/typeahead';
import { UserInfo } from '@starfish-access/models';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { handleError, Searcher, SEARCH_IMPL, toTypeaheadModel, TypeaheadSelectConfig } from './typeahead-select.utils';

@Component({
  selector: 'sf-typeahead-select',
  templateUrl: './typeahead-select.component.html',
  styleUrls: ['./typeahead-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TypeaheadSelectComponent),
      multi: true,
    },
  ],
})
export class TypeaheadSelectComponent implements ControlValueAccessor {
  @Input() config: TypeaheadSelectConfig;

  readonly searchTermChange$ = new Subject<string>();
  readonly searchInProgress$ = new Subject<boolean>();
  readonly searchError$ = new ReplaySubject<boolean>();
  readonly searchResults$: Observable<TypeaheadModel<UserInfo>[]>;

  formGroup: UntypedFormGroup;

  constructor(@Inject(SEARCH_IMPL) private searcher: Searcher, private fb: UntypedFormBuilder) {
    this.searchResults$ = this.searchTermChange$.pipe(
      tap((_a) => this.searchInProgress$.next(true)),
      mergeMap((searchTerm: string) =>
        this.searcher.getResults(searchTerm).pipe(
          catchError(handleError(this.searchError$, this.searchInProgress$)),
          toTypeaheadModel(),
          // TODO(mat21) markDisabled(this.selectedItems?.value),
          tap((_a) => this.searchInProgress$.next(false))
        )
      )
    );
    this.formGroup = this.fb.group({
      typeaheadInput: [''],
      selectedItems: [[]],
    });
  }

  get selectedItems(): AbstractControl | null {
    return this.formGroup.get('selectedItems');
  }

  get typeaheadInput(): AbstractControl | null {
    return this.formGroup.get('typeaheadInput');
  }

  get typeaheadConfig(): TypeaheadSearchConfig {
    return {
      appearance: this.config.inputAppearance,
      placeholder: this.config.inputPlaceholder,
      resetOnSelection: true,
      minTermLength: 3,
    };
  }

  searchResultSelected(model: TypeaheadModel<UserInfo>): void {
    const currentItems: UserInfo[] = this.selectedItems?.value;
    if (currentItems.includes(model.object)) {
      return;
    }
    currentItems.push(model.object);
    this.selectedItems?.patchValue(currentItems);
    this.propagateChange(currentItems);
  }

  removeSelectedItem(userInfo: UserInfo): void {
    const currentItems: UserInfo[] = this.selectedItems?.value;
    const index = currentItems.indexOf(userInfo);
    if (index > -1) {
      currentItems.splice(index, 1);
      this.selectedItems?.patchValue(currentItems);
      this.propagateChange(currentItems);
    }
  }

  writeValue(obj: UserInfo[]): void {
    if (obj && obj.length > 0) {
      this.formGroup.get('selectedItems')?.patchValue(obj);
      this.searchTermChange$.next('');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
