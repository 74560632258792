<div class="mat-typography">
  <h3 mat-dialog-title>{{statusMessageMapping?.get(action)?.dialogTitle}}</h3>
  <mat-dialog-content fxLayout="column">
    <p>{{statusMessageMapping?.get(action)?.dialogMsg}}</p>
    <mat-form-field
      fxFlex
      appearance="outline">
      <mat-label>Comment (optional)</mat-label>
      <textarea
        matInput
        data-cy="text-area-input"
        cdkTextareaAutosize
        [(ngModel)]="comment"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end center">
    <button
      type="button"
      data-cy="text-area-cancel"
      mat-button
      color="warn"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      type="button"
      data-cy="text-area-confirm"
      mat-button
      color="primary"
      (click)="dialogRef.close({comment: comment})">
      Confirm
    </button>
  </mat-dialog-actions>
</div>
