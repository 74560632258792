<div class="role-auth-element">
  <div fxLayout="column">
    <h2>Role Authorization</h2>
    <mat-slide-toggle
      color="primary"
      [checked]="authSelectionSubj | async"
      (change)="authChecked($event)"
      class="auth-checkbox mat-body-1"
      [attr.data-cy]="roleName + '-auth-flow-selected-checkbox'"
      >Do you want to change the role authorization?</mat-slide-toggle
    >
    <p
      *ngIf="!(authSelectionSubj | async)"
      data-cy="default-auth-flow-desc"
      class="role-auth-flow-details mat-body-1">
      Current authorization approval: Manager > ASR > Data Steward > DUS
    </p>
  </div>

  <div
    *ngIf="authSelectionSubj | async"
    class="drop-list-container">
    <div
      cdkDropList
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div
        class="example-box"
        *ngFor="let approval of approvalOptions"
        cdkDrag
        [attr.data-cy]="approval + '-auth-item'"
        [cdkDragDisabled]="canMove.indexOf(approval) < 0"
        [ngClass]="wasDeleted.indexOf(approval) < 0 ? 'fade-auth-in' : 'fade-auth-out'">
        <span *ngIf="approval !== 'Add Group Name'">{{ approval }}</span>

        <form [formGroup]="umgFormGroup">
          <mat-form-field
            appearance="outline"
            class="umg-group-input"
            fxFlex
            *ngIf="approval === 'Add Group Name'">
            <input
              matInput
              placeholder="Add a Group Name"
              formControlName="name" />
          </mat-form-field>
        </form>

        <div class="actions-wrapper">
          <mat-icon
            *ngIf="canMove.indexOf(approval) > -1"
            svgIcon="drag"
            cdkDragHandle
            class="example-handle"></mat-icon>

          <a
            *ngIf="canDelete.indexOf(approval) > -1"
            class="auth-delete-button"
            (click)="deleteAuthRole(approval)"
            ><button
              aria-label="Delete"
              mat-icon-button
              color="warn"
              [attr.data-cy]="'auth-flow-delete-btn-' + approval">
              <mat-icon>delete</mat-icon>
            </button></a
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="authSelectionSubj | async"
      [ngClass]="wasDeleted.length > 0 ? 'fade-auth-in' : 'fade-auth-out'">
      <button
        mat-flat-button
        class="reset-auth-btn"
        [attr.data-cy]="roleName + '-reset-auth-flow'"
        color="primary"
        (click)="resetAuthList(true)">
        Reset Removed Authorizer(s)
      </button>
    </div>
  </div>
</div>
