/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { APP_INITIALIZER } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FORTRESS_CONFIG,
  FortressConfig,
  PermissionCheckService,
  UserPermissionCheckService,
} from '@psu/apis/fortress';
import { ANALYTICS_CONFIG, AnalyticsConfig } from '@psu/utils/analytics';
import { LoadingEvents, REQUEST_TRACING_CONFIG } from '@psu/utils/browser';
import { CDN_CONFIG, CdnConfig } from '@psu/utils/cdn';
import {
  AUTH_CONFIG_DD,
  AuthConfig,
  AuthConfigWithDiscoveryDoc,
  AuthService,
  OAuthErrorEvent,
} from '@psu/utils/security';
import { filter, takeWhile } from 'rxjs';
import { APP_PROPERTIES, AppProperties } from './app.properties';
import { FEATURES_CONFIG, FeaturesConfig } from './core/model/features.model';
import { STARFISH_CONFIG, StarfishConfig } from './starfish.config';

export const initializeAppFactory =
  (
    authConfig: AuthConfigWithDiscoveryDoc,
    authService: AuthService,
    loadingEvents: LoadingEvents
  ): (() => Promise<void>) =>
  async () => {
    authService.configure(authConfig);

    if (authConfig.showDebugInformation) {
      authService.enableDebug();
    }

    // Listen for error events until a token_received event is emitted
    authService.events
      .pipe(
        takeWhile((event) => event.type !== 'token_received'),
        filter((event): event is OAuthErrorEvent => 'reason' in event)
      )
      .subscribe((event) => {
        document.dispatchEvent(
          new CustomEvent('apperror', {
            bubbles: true,
            detail: event,
          })
        );
      });

    await authService.loadDiscoveryDocument(authConfig.discoveryDocumentUrl);
    await authService.tryLogin();

    if (authService.isLoggedIn()) {
      authService.setupAutomaticSilentRefresh();
    }

    loadingEvents.triggerAppReady();
  };

export function oauthFactory(props: AppProperties): AuthConfig {
  return props.oauth;
}

export function fortressFactory(props: AppProperties): FortressConfig {
  return props.fortress;
}

export function cdnFactory(props: AppProperties): CdnConfig {
  return props.cdn;
}

export function analyticsConfig(props: AppProperties): AnalyticsConfig {
  return props.analytics;
}

export function starfishFactory(props: AppProperties): StarfishConfig {
  return props.endpoints;
}

export function featuresFactory(props: AppProperties): FeaturesConfig {
  return props.features;
}

export const INIT_PROVIDERS = [
  { provide: AUTH_CONFIG_DD, useFactory: oauthFactory, deps: [APP_PROPERTIES] },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    multi: true,
    deps: [AUTH_CONFIG_DD, AuthService, LoadingEvents, Store],
  },
  {
    provide: FORTRESS_CONFIG,
    useFactory: fortressFactory,
    deps: [APP_PROPERTIES],
  },
  { provide: CDN_CONFIG, useFactory: cdnFactory, deps: [APP_PROPERTIES] },
  {
    provide: ANALYTICS_CONFIG,
    useFactory: analyticsConfig,
    deps: [APP_PROPERTIES],
  },
  {
    provide: STARFISH_CONFIG,
    useFactory: starfishFactory,
    deps: [APP_PROPERTIES],
  },
  {
    provide: FEATURES_CONFIG,
    useFactory: featuresFactory,
    deps: [APP_PROPERTIES],
  },
  {
    provide: REQUEST_TRACING_CONFIG,
    useValue: { applicationId: 'starfishaccess' },
  },
  { provide: PermissionCheckService, useClass: UserPermissionCheckService },
];
