import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitCommentSize' })
export class CommentSizePipe implements PipeTransform {
  transform(comment: string, limit: number): string {
    if (!comment) {
      return '';
    }

    if (!limit || limit === 0) {
      limit = 80;
    }

    if (comment.length > limit) {
      const formattedComment: string = comment.substring(0, limit) + '...';
      return formattedComment;
    }

    return comment;
  }
}
