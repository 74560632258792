import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { Manager, Role, RoleDetails } from '@starfish-access/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { STARFISH_CONFIG, StarfishConfig } from '../../../starfish.config';

@Injectable()
export class UserAccountService {
  private static endPoint = 'manager?userId=';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getManager(userId: string): Observable<Manager> {
    // build the URL
    UserAccountService.URL = this.starfishConfig.starfishServices + 'users/' + userId + '/managers';
    return this.http
      .get<Manager>(UserAccountService.URL, {
        headers: this.headers,
      })
      .pipe(
        catchError(() => {
          const emptyManager: Manager = {
            user: {
              userId,
              displayName: '',
            },
            managers: [],
          };
          return of(emptyManager);
        })
      );
  }

  // Returns roles that an individual user has requested
  getUserRoles(userId: string): Observable<RoleDetails[]> {
    const url = this.starfishConfig.starfishServices + 'users/' + userId + '/activeRoles';
    return this.http
      .get<RoleDetails[]>(url, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  // Returns a single role
  getRole(groupId: number, roleId: number): Observable<Role> {
    // eslint-disable-next-line max-len
    const url = `${this.starfishConfig.starfishServices}roleGroups/${groupId}/roles/${roleId}?includeAdminRoles=true&includeDelegationRoles=true&includeReadOnlyRoles=true`;
    return this.http
      .get<Role>(url, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  // Checks if a user exists in search service. 404's indicate they are not found.
  doesUserExist(userId: string): Observable<boolean> {
    const searchServiceLink = this.starfishConfig.searchService;
    const searchUrl = searchServiceLink + '/userid/' + userId;

    return this.http
      .get<any>(searchUrl, {
        headers: this.headers,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
