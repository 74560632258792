import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoleGroupPriority } from '@starfish-access/models';
import * as R from 'ramda';
import { Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { NEW_GROUP_ID, NEW_GROUP_PRIORITY } from '../dynamic-role.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-group-priority',
  templateUrl: './group-priority.component.html',
  styleUrls: ['./group-priority.component.scss'],
})
export class GroupPriorityComponent implements OnInit, OnDestroy {
  @Input() newGroupName: Observable<string>; // This is the name of the new group
  @Input() newGroupColor = ''; // this is the color of the new org group
  @Input() roleGroupPriorities: Observable<RoleGroupPriority[]>; // This is the existing list from the facade
  @Input() priorityObs: Subject<RoleGroupPriority[]>; // This is the new list with the new group and updated priorities
  @Input() isEditing: boolean;
  subscribedPriorities: RoleGroupPriority[];
  originalPriorities: RoleGroupPriority[];
  mouseIsDown = 'grab';
  private destroy$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    const sortByGroupPriority = R.sortBy(R.prop('priority'));

    this.roleGroupPriorities
      .pipe(
        filter((priorities) => !!priorities && priorities.length > 0),
        take(1)
      )
      .subscribe((res) => {
        this.subscribedPriorities = sortByGroupPriority(res);
        this.originalPriorities = [...this.subscribedPriorities];
        this.priorityObs.next(this.subscribedPriorities);
      });

    this.newGroupName
      .pipe(
        filter((list) => !!list),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        const newPriorityItem: RoleGroupPriority = {
          groupColor: this.newGroupColor,
          groupId: NEW_GROUP_ID,
          groupName: res,
          priority: NEW_GROUP_PRIORITY,
        };
        const isNewDynamicGroup = (n: RoleGroupPriority) => n.groupId === NEW_GROUP_ID;

        if (!this.subscribedPriorities) {
          return;
        }

        const trimmedGroup = R.reject(isNewDynamicGroup, this.subscribedPriorities);
        this.subscribedPriorities = sortByGroupPriority(trimmedGroup);
        if (res !== '' && !this.isEditing) {
          this.subscribedPriorities.push(newPriorityItem);
        }
        this.priorityObs.next(this.subscribedPriorities);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  drop(event: CdkDragDrop<RoleGroupPriority[]>): void {
    moveItemInArray(this.subscribedPriorities, event.previousIndex, event.currentIndex);
    this.mouseIsDown = 'grab';
    let index = 1;
    for (const p of this.subscribedPriorities) {
      p.priority = index++;
    }
    this.priorityObs.next(this.subscribedPriorities);
  }

  reset(): void {
    this.subscribedPriorities = [...this.originalPriorities];
    this.priorityObs.next(this.subscribedPriorities);
  }
}
