import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TypeaheadSearchConfig } from '@psu/components/typeahead';
import { SearchService } from '@starfish-access/core';
import {
  CalendarManagementType,
  CalendarManagers,
  RoleConfig,
  SelectableRole,
  UserInfo,
} from '@starfish-access/models';

import { OrgSelectItem } from '../../org-select/org-select.component';
import { SEARCH_IMPL, TypeaheadSelectConfig } from '../../typeahead-select/typeahead-select.utils';

export interface DelegationFormGroupModel {
  name: string;
  roleConfigs: RoleConfig[];
  calendarManagement: CalendarManagementType;
  calendarManagers: CalendarManagers;
  admins: UserInfo[];
  calendarKiosks: OrgSelectItem[];
}

@Component({
  selector: 'sf-delegation-form',
  templateUrl: './delegation-form.component.html',
  styleUrls: ['./delegation-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DelegationFormComponent),
      multi: true,
    },
    { provide: SEARCH_IMPL, useExisting: SearchService },
  ],
})
export class DelegationFormComponent implements ControlValueAccessor {
  @Input() roles: SelectableRole[];
  @Input() kiosksAvailable: OrgSelectItem[];
  @Output() deleteRole: EventEmitter<RoleConfig> = new EventEmitter();
  formGroup: UntypedFormGroup;
  adminSearchConfig: TypeaheadSearchConfig;

  delegationAdminConfig: TypeaheadSelectConfig = {
    inputAppearance: 'outline',
    inputPlaceholder: 'Search by User ID',
    removableItems: true,
  };

  constructor(fb: UntypedFormBuilder) {
    this.formGroup = fb.group({
      name: [],
      roleConfigs: [[]],
      calendarManagement: ['REGULAR'],
      calendarManagers: [],
      admins: [[]],
      calendarKiosks: [[]],
    });

    this.adminSearchConfig = {
      appearance: 'outline',
      placeholder: 'Search by User ID',
      resetOnSelection: true,
    };

    this.formGroup.valueChanges.subscribe((value) => {
      this.propagateChange(value);
    });
  }

  writeValue(obj: DelegationFormGroupModel): void {
    if (obj) {
      this.formGroup.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
