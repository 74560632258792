import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { SplitButtonAction } from '@psu/components/button';
import { TaskDetails } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { STARFISH_CONFIG, StarfishConfig } from '../../starfish.config';

@Component({
  templateUrl: './workflow-header.component.html',
  styleUrls: ['./workflow-header.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-workflow-header',
})
export class WorkflowHeaderComponent {
  @Input() taskDetails: TaskDetails;
  @Input() processDetailsStatus: string | null = null;
  @Input() submitInProgress: Observable<boolean>;
  @Input() isRequestView: boolean;
  @Output() submitEmitter: EventEmitter<string> = new EventEmitter();
  psuDirectoryLink = 'https://directory.psu.edu/?userId=';
  splitButtonCancel: SplitButtonAction[] = [];
  constructor(@Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {
    this.psuDirectoryLink = this.starfishConfig.psuDirectoryEntry || 'https://directory.psu.edu/?userId=';

    this.splitButtonCancel.push({
      action: 'REJECT',
      label: 'Reject',
    });
    this.splitButtonCancel.push({
      action: 'RETURN_TO_REQUESTER',
      label: 'Return',
    });
  }

  isDataStewardTask(): boolean {
    return this.taskDetails.taskName === 'DS Approval';
  }

  isReturned(): boolean {
    return this.taskDetails.taskStatus === 'AWAITING_ADDITIONAL_INFO';
  }

  completeRequest(value: string): void {
    this.submitEmitter.emit(value);
  }
}
