import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserInfo } from '@starfish-access/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AccountAutoCompleteComponent } from '../../../shared/autocomplete/autocomplete.component';

@Component({
  selector: 'sf-connected-student-delete-readonly',
  templateUrl: './connected-student-delete-readonly.component.html',
  styleUrls: ['./connected-student-delete-readonly.component.scss'],
})
export class ConnectedStudentDeleteComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: AccountAutoCompleteComponent;
  @Input() userList: UserInfo[];
  filterSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredChips$: Observable<UserInfo[]>;
  deletionCount = 0;
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.filteredChips$ = this.filterSubject$.pipe(
      takeUntil(this.destroy$),
      map((filter) => {
        if (!filter) {
          return this.userList;
        }

        return this.userList.filter((u) => {
          if (u?.id) {
            return u.id.toLowerCase().includes(filter.toLowerCase());
          }
          return '';
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
