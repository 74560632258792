import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { CoreModule, FilterModule } from '@starfish-access/core';
import { ErrorDetailsPipe } from './error-details.pipe';
import { SvcErrorDialogComponent } from './svc-error-dialog.component';
import { SvcErrorDialogService } from './svc-error-dialog.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    CoreModule,
    FilterModule,
    MatInputModule,
    MatDialogModule,
    ClipboardModule,
    MatDividerModule,
  ],
  providers: [SvcErrorDialogService],
  declarations: [SvcErrorDialogComponent, ErrorDetailsPipe],
})
export class SvcErrorDialogModule {}
