<form
  [formGroup]="formGroup"
  class="example-form">
  <mat-radio-group
    color="primary"
    formControlName="studentUpdateType"
    aria-label="Should this ids merge with the existing ones"
    class="example-radio-group">
    <mat-radio-button
      class="example-radio-button"
      aria-label="Merge with Existing IDs"
      value="MERGE">
      Merge with existing IDs
    </mat-radio-button>
    <mat-radio-button
      class="example-radio-button"
      value="REPLACE"
      aria-label="Replace all Existing IDs">
      Replace all IDs with below
    </mat-radio-button>
  </mat-radio-group>
  <mat-form-field
    class="example-full-width"
    appearance="outline">
    <textarea
      matInput
      data-cy="bulk-user-text-area"
      formControlName="connectedUsers"
      placeholder="abc123"></textarea>
  </mat-form-field>
</form>
