import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientRoleGroup } from '@starfish-access/models';
import { DynamicRolesFacade } from '../dynamic-role.facade.service';
import { EditedRoleGroupModel, NewGroupOrgModel } from '../dynamic-role.model';
import { NewRoleGroupComponent } from '../new-role-group/new-role-group.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-edit-role-group',
  templateUrl: './edit-role-group.component.html',
  styleUrls: ['./edit-role-group.component.scss'],
})
export class EditRoleGroupComponent implements OnInit {
  @ViewChildren('roleGroupComp')
  roleGroupComp: QueryList<NewRoleGroupComponent>;
  roleGroupForm: UntypedFormGroup;
  groupMap: Map<string, UntypedFormGroup> = new Map();

  constructor(private fb: UntypedFormBuilder, public facade: DynamicRolesFacade) {}

  ngOnInit(): void {
    this.facade.requestRoleGroups();
    this.facade.requestRoleGroupPriorities();
  }

  clearEntries(componentIndex: number, originalRoleGroup: ClientRoleGroup) {
    const roleGroupComp: NewRoleGroupComponent = this.roleGroupComp.toArray()[componentIndex];
    roleGroupComp.writeValue(originalRoleGroup);
  }

  createFormGroup(rolegroup: ClientRoleGroup): UntypedFormGroup {
    const frmGrp = this.groupMap.get(rolegroup.name);
    if (this.groupMap.has(rolegroup.name) && frmGrp) {
      return frmGrp;
    }
    const formGroup: UntypedFormGroup = this.fb.group({
      existingGroup: ['', [Validators.required]],
    });
    const grpCtrl = formGroup.get('existingGroup');
    if (grpCtrl) {
      grpCtrl.patchValue(rolegroup);
    }

    this.groupMap.set(rolegroup.name, formGroup);
    return formGroup;
  }

  updateRoleGroup(
    roleGroupForm: UntypedFormGroup | undefined,
    roleGroup: ClientRoleGroup,
    componentIndex: number
  ): void {
    if (!roleGroupForm) {
      return;
    }
    const grpCtrl = roleGroupForm.get('existingGroup');

    const orgModel: NewGroupOrgModel = grpCtrl ? grpCtrl.value : {};
    const updatedRoleGroup: EditedRoleGroupModel = {
      updatedGroupDetails: orgModel,
      originalRoleGroup: roleGroup,
    };
    this.facade.updateExistingRoleGroup(updatedRoleGroup);
  }
}
