<div
  fxLayout="column"
  class="mat-typography">
  <h3 matDialogTitle>Add New User</h3>
  <mat-dialog-content>
    <div class="content-wrapper">
      <ta-typeahead
        data-cy="add-new-user-to-sf-users-ta"
        [config]="config"
        [placeholder]="'Start typing to search. Results will appear after three characters.'"
        [resultTemplate]="resultTemplate"
        (selected)="addNewUser($event)"
        [noResultsTemplate]="noResultsTemplate"
        [autoActiveFirstOption]="false"
        [clearButtonIconTemplate]="clearButtonIconTemplate"
        [showSearchButton]="true"
        [showMatPrefix]="false"
        #ta1>
      </ta-typeahead>

      <sf-add-role
        #sfAddRoleComp
        data-cy="new-user-role"
        [existingUserRoles]="existingRoles | async"
        [disableSaveAction]="selectedUser === undefined"
        (closeAction)="dialogRef.close()"
        (saveAction)="saveNewUser(dialogRef)"
        [allRoles]="data?.adminRoleGroups | async"
        fxFlex></sf-add-role>

      <div
        *ngIf="existingRoles && (existingRoles | async)?.roles?.length > 0"
        class="assigned-roles-list">
        <p class="body-bold">Assigned Roles:</p>
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let roleDetails of (existingRoles | async)?.roles">{{
            roleDetails.roleName
          }}</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
    <!-- This template is for the display of results in autocomplete overlay -->
    <ng-template
      #resultTemplate
      let-result>
      <span
        fxLayout="row"
        fxLayoutAlign="start center">
        <span
          fxLayout="row"
          class="result-content"
          fxFlex>
          <span>{{ result.displayName }} ({{ result.userid }})</span>
        </span>
      </span>
    </ng-template>
    <ng-template #noResultsTemplate> No Results Found </ng-template>
    <ng-template #clearButtonIconTemplate>
      <mat-icon (click)="clearUser()">clear</mat-icon>
    </ng-template>
  </mat-dialog-content>
</div>
