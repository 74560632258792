import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { ResourcesComponent } from './resources.component';

export const routes: Routes = [
  {
    title: 'resources',
    path: 'resourcesapproval/:taskId',
    canActivate: [AuthGuard],
    component: ResourcesComponent,
  },
];
