import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { DelegationMetaData, Role } from '@starfish-access/models';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterDelegationPipe } from '../../../core/pipe/filter-delegation.pipe';
import { DelegationCreateFacade } from '../delegation-create/delegation-create.facade';
import { DelegationEditComponent } from '../delegation-edit/delegation-edit.component';
import { DelegationFacade } from '../delegation.facade';

@Component({
  selector: 'sf-delegation-list',
  templateUrl: './delegation-list.component.html',
  styleUrls: ['./delegation-list.component.scss'],
})
export class DelegationListComponent {
  @Input() roles: Role[];
  @ViewChildren('delFormComp')
  formGroupComponents: QueryList<DelegationEditComponent>; // This is used only by the guard
  filterSubject: Subject<string> = new BehaviorSubject('');
  filteredDelegations$: Observable<DelegationMetaData[]>;
  private delPipe: FilterDelegationPipe = new FilterDelegationPipe();

  constructor(public createFacade: DelegationCreateFacade, public facade: DelegationFacade) {
    this.filteredDelegations$ = combineLatest([this.filterSubject, this.facade.getDelegationsMetaData()]).pipe(
      map(([filter, delegations]) => this.delPipe.transform(delegations, filter))
    );
  }

  hasUnsavedWork(delegationId: number): boolean {
    if (this.formGroupComponents === undefined || this.formGroupComponents.length === 0) {
      return false;
    }

    const found = this.formGroupComponents.find((afc) => delegationId === afc?.delegation?.id);
    if (found) {
      return found.delegationFormGroup?.pristine !== null && found?.delegationFormGroup?.pristine === false;
    }
    return false;
  }
}
