import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Event, Router, RouterEvent, Scroll } from '@angular/router';
import { AuthService } from '@psu/utils/security';
import { OrientationTrainingService, StarfishFerpaService } from '@starfish-access/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { AssignedRoleDialogComponent } from '../core/dialog/sf-assigned-role-dialog/sf-assigned-role-dialog.component';
import { StarfishOrientationDialog } from '../core/dialog/sf-orientation-dialog';
import { ClientRoleGroup, RoleDetailsExtended } from '../core/model/roles.model';
import { DashboardFacade } from './dashboard.facade';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChildren('roleGroupList')
  roleGroupList: QueryList<ElementRef>;
  hasFerpaTraining: boolean;
  disabled = false;
  isLoggedIn = false;
  allRoleGroups$: Observable<ClientRoleGroup[]>;
  filteredRoleGroups$: Observable<ClientRoleGroup[]>;
  filterRoleGroupsSubject: Subject<string> = new BehaviorSubject('');
  showScrollButton = false;
  destroy$ = new Subject<void>();
  canManageDelegations: boolean;
  private jumpLinkName = '';

  constructor(
    public ferpaService: StarfishFerpaService,
    public authService: AuthService,
    public trainingService: OrientationTrainingService,
    public dashboardFacade: DashboardFacade,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.isLoggedIn = this.authService.getCurrentUser() !== null;
    this.ferpaService
      .hasFerpa()
      .pipe(take(1))
      .subscribe((res) => (this.hasFerpaTraining = res));

    this.dashboardFacade.getDelegationGroupsMetaData().subscribe((res) => (this.canManageDelegations = res.length > 0));

    /**
     * Jump links in angular are supported, but do not work within a mat-sidenav-container, which is
     * where the <routeroutlet> for this application lives. This may be fixable & simplified in the future:
     *
     * https://www.geekstrick.com/fragment-url-in-angular-8/
     * https://stackblitz.com/edit/fragment-url-in-angular
     * https://stackoverflow.com/questions/52725394/router-anchorscrolling-does-not-work-with-materials-mat-sidenav-containerfulls
     */
    this.router.events.subscribe((e: Event | RouterEvent) => {
      if (e instanceof Scroll) {
        if (e.anchor && e.anchor.includes('roleGroupList')) {
          this.roleGroupList
            ?.find((groupPanel) => this.jumpLinkName === groupPanel.nativeElement.id)
            ?.nativeElement.scrollIntoView()
            ?.window.scrollBy(0, -24);
        }
      }
    });

    document.getElementsByClassName('content-area')[0]?.addEventListener('scroll', () => {
      this.onScroll();
    });
  }

  ngOnInit(): void {
    if (this.isLoggedIn) {
      this.trainingService.getEula$
        .pipe(
          take(1),
          mergeMap((hasAgreed) => {
            if (hasAgreed || hasAgreed === null) {
              return of(hasAgreed);
            }
            return this.checkDialog();
          })
        )
        .subscribe((res) => {
          if (res) {
            //noop, helps for debugging here: console.log('Agreement Recorded');
          }
        });

      this.allRoleGroups$ = this.dashboardFacade.getAllRoleGroups();
      this.filteredRoleGroups$ = combineLatest([this.filterRoleGroupsSubject, this.allRoleGroups$]).pipe(
        map(([filter, allRoleGroups]) => {
          // No filter applied, return all role groups
          if (filter.length === 0) {
            return allRoleGroups;
          } else {
            // Filter applied matches role name
            const filteredRoleGroups: ClientRoleGroup[] = [];
            allRoleGroups.forEach((roleGroup) => {
              const filteredRoles = roleGroup.roles.filter((role) =>
                role.roleName.toLowerCase().includes(filter.toLowerCase())
              );
              if (filteredRoles.length > 0) {
                filteredRoleGroups.push({
                  ...roleGroup,
                  roles: filteredRoles,
                });
              }
            });
            return filteredRoleGroups;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showAssignedRoleDialog(roleDetails: RoleDetailsExtended): void {
    // Obtain the Role descriptions (in the Role object) and then show the dialog
    this.dashboardFacade.getRole(roleDetails.roleGroupId, roleDetails.requestedRoleId).subscribe((role) => {
      this.dialog.open(AssignedRoleDialogComponent, {
        width: '640px',
        data: { roleDetails, role },
      });
    });
  }

  onAssignedRoleDialogClose(): void {
    this.dialog.closeAll();
  }

  scrollToRoleGroup(groupName: string): void {
    this.jumpLinkName = groupName;
    this.router.navigate([''], { fragment: `roleGroupList=${groupName}` });
  }

  onScroll() {
    const scrollTop = document.getElementsByClassName('content-area')[0]?.scrollTop;
    if (scrollTop > 1400) {
      this.showScrollButton = true;
    } else {
      this.showScrollButton = false;
    }
  }

  private checkDialog(): Observable<boolean> {
    return this.dialog
      .open(StarfishOrientationDialog, {
        width: '640px',
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        map((result) => {
          if (result) {
            return this.trainingService.updateEulaAgreement();
          }
          return result;
        })
      );
  }
}
