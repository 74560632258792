import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';

@Injectable()
export class OrientationTrainingHttpService {
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getEula(): Observable<boolean> {
    const baseUrl = this.starfishConfig.starfishServices + 'self/eula';
    return this.http
      .get<boolean>(baseUrl, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  updateEula(): Observable<boolean> {
    const baseUrl = this.starfishConfig.starfishServices + 'self/eula';
    return this.http
      .post<boolean>(baseUrl, true, {
        headers: this.headers,
      })
      .pipe(
        mapTo(true), // a succesful post response returns void. Instead we'll return the value the server now has.
        catchError(handleErrorFromRest)
      );
  }
}
