import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService, UrlBuilder } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Observable, throwError } from 'rxjs';
import { catchError, flatMap, retry, timeout } from 'rxjs/operators';
import { TypeaheadConfig } from './typeahead.config';
import { User } from './user.model';

@Injectable()
export class TypeaheadService {
  private defaultMessage = 'Error searching for user, please try again later.';

  constructor(private http: HttpClient, private errorService: HttpErrorService) {}

  searchUser(searchTerm: string, config: TypeaheadConfig): Observable<User[]> {
    let params = new HttpParams();
    params = params.append(config.searchQueryParam || 'text', searchTerm);
    params = config.maxResults ? params.append('size', '' + config.maxResults) : params;
    if (config.activeUsersOnly !== undefined) {
      params = params.append('Active', `${config.activeUsersOnly}`);
    }
    let headers = new HttpHeaders();
    if (config.sendAuth) {
      headers = headers.append(REQUIRE_AUTH_HEADER, 'true');
    }
    return new UrlBuilder(config.url, 'TypeaheadService').build('').pipe(
      flatMap((url) =>
        this.http.get<User[]>(url, {
          params,
          headers,
        })
      ),
      timeout(config.searchTimeout || 5000),
      retry(5),
      catchError((err) => this.errorService.handleError(err, this.defaultMessage)),
      catchError((err) => {
        if (typeof err === 'string') {
          return throwError(err);
        } else {
          return throwError(this.defaultMessage);
        }
      })
    );
  }
}
