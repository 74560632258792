import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reverseList' })
export class ReverseListPipe implements PipeTransform {
  transform(value: any, reverse: boolean): any {
    if (!value) {
      return '';
    }

    if (!reverse) {
      return value;
    }

    return value.slice().reverse();
  }
}
