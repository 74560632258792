import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TypeaheadModule as ComponentTypeaheadModule, TypeaheadSearchModule } from '@psu/components/typeahead';
import { ResponsiveModule } from '@psu/utils/responsive';
import { FilterModule } from '@starfish-access/core';
import { AccountAutoCompleteComponent } from './autocomplete/autocomplete.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { DelegationFormComponent } from './delegation/delegation-form/delegation-form.component';
import { ClassConfigComponent } from './delegation/role-config-form/class-config/class-config.component';
import { CourseConfigComponent } from './delegation/role-config-form/course-config/course-config.component';
import { RoleConfigFormComponent } from './delegation/role-config-form/role-config-form.component';
import { WarningDialogComponent } from './delegation/role-config-form/warning-dialog/warning-dialog.component';
import { RoleConfigListComponent } from './delegation/role-config-list/role-config-list.component';
import { NavListComponent } from './nav/nav-list.component';
import { NavListService } from './nav/nav-list.service';
import { OrgSelectComponent } from './org-select/org-select.component';
import { RoleIconComponent } from './roleicon/role-icon.component';
import { SimpleServiceSelectorComponent } from './simple-service-selector/simple-service-selector.component';
import { TogglerequestsComponent } from './togglerequests/togglerequests.component';
import { TypeaheadSelectComponent } from './typeahead-select/typeahead-select.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    FlexLayoutModule,
    FilterModule,
    MatExpansionModule,
    MatDividerModule,
    MatTabsModule,
    MatChipsModule,
    ComponentTypeaheadModule,
    TypeaheadSearchModule,
    MatDatepickerModule,
    ResponsiveModule,
    RouterModule,
  ],
  declarations: [
    RoleIconComponent,
    AccountAutoCompleteComponent,
    OrgSelectComponent,
    TogglerequestsComponent,
    DelegationFormComponent,
    RoleConfigListComponent,
    RoleConfigFormComponent,
    SimpleServiceSelectorComponent,
    TypeaheadSelectComponent,
    CourseConfigComponent,
    ClassConfigComponent,
    DatePickerComponent,
    WarningDialogComponent,
    NavListComponent,
  ],
  exports: [
    RoleIconComponent,
    AccountAutoCompleteComponent,
    OrgSelectComponent,
    TogglerequestsComponent,
    DelegationFormComponent,
    TypeaheadSelectComponent,
    CourseConfigComponent,
    DatePickerComponent,
    NavListComponent,
  ],
  providers: [NavListService],
})
export class StarfishCommonModule {}
