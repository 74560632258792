import { ClientRoleGroup } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { UserManagementDataSource } from './usermanagement.datasource';

export class RoleIdAndName {
  roleId: number;
  roleName: string;
}

export class Users {
  userId: string;
  displayName: string;
  roles: RoleIdAndName[];
}

export interface UsersRequestConfig {
  pageStart?: number;
  pageTotal?: number;
  filterString?: string;
  useridSortOrder?: string;
}

export interface UsersDatasourceResponse {
  users: Users[];
  totalUsersCount: number;
}

export interface NewUserDialogData {
  adminRoleGroups: Observable<ClientRoleGroup[]>;
  dataSource: UserManagementDataSource;
}
