import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConfirmDialogService, ConfirmMessageConfig, TOOLTIP_DELAY } from '@starfish-access/core';
import { TaskDetails } from '@starfish-access/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DisplayUser } from '../roles/roles.model';
import { KioskUpdate, ResourcesApproval, ServiceUpdate, UserRoles } from './resouces.model';
import { CompleteResourceApproval } from './resources-complete.model';
import { ResourceTaskDetails } from './resources-task.model';
import { CompleteAddProcess, PostTaskDetails, RequestFailedJobs } from './resources.actions';
import {
  selectResourcesApproval,
  selectResourcesError,
  selectResourcesHasFailed,
  selectResourcesIsFetching,
  selectResourcesIsSuccessfullySubmitted,
} from './resources.reducer';

@Component({
  styleUrls: ['./resources.component.scss'],
  templateUrl: './resources.component.html',
})
export class ResourcesComponent implements OnInit, OnDestroy {
  errorMessage: Observable<string>;
  hasFailed = false;

  isFetching: boolean;
  submitInProgress = false;
  resourcesApproval: ResourcesApproval = {
    userRoles: [],
    kioskUpdates: [],
    serviceUpdates: [],
  };

  taskDetails: TaskDetails;
  taskId = '';
  tooltipDelay: string = TOOLTIP_DELAY;

  // Records user selections to submit
  selectedKiosks: KioskUpdate[] = [];
  selectedServices: ServiceUpdate[] = [];
  selectedUsers: UserRoles[] = [];
  newUsers: UserRoles[] = [];
  userData: DisplayUser[] = [];

  accent: any;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    public router: Router
  ) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.taskId = params.taskId;
    });

    this.store
      .select<boolean>(selectResourcesIsFetching)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.isFetching = res));
    this.store
      .select<ResourceTaskDetails>(selectResourcesApproval)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.loadDataFromStore(res));
    this.store
      .select<boolean>(selectResourcesIsSuccessfullySubmitted)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.forwardOnSuccessfulSubmission(res));
    this.errorMessage = this.store.select<string>(selectResourcesError);
    this.store
      .select<boolean>(selectResourcesHasFailed)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.hasFailed = res));
  }

  getErrors(err: any) {
    const errors: string[] = [];
    err.forEach((item: any) => {
      if (item !== '') {
        errors.push(item);
      }
    });
    return errors;
  }

  completeRequest() {
    const resourceUpdates: ResourcesApproval = {
      userRoles: this.selectedUsers,
      kioskUpdates: this.selectedKiosks,
      serviceUpdates: this.selectedServices,
    };

    const completeApproval: CompleteResourceApproval = {
      taskId: this.taskId,
      taskAction: 'APPROVE',
      taskComment: 'DUS update with kiosk-service object',
      additionalInfoUser: '',
      dusUpdates: resourceUpdates,
    };

    let allUsersSelected = false;
    let allKiosksSelected = false;
    let allServicesSelected = false;

    const userRoles = this.resourcesApproval.userRoles;
    // console.log('userRoles: ' + userRoles);
    if (this.hasUsers() && userRoles) {
      // console.log(userRoles.length + ' user role requests');
      if (this.selectedUsers.length < userRoles.length) {
        // console.warn('not all user\'s roles are selected');
      } else {
        allUsersSelected = true;
      }
    }

    const kioskUpdates = this.resourcesApproval.kioskUpdates;
    if (this.hasKiosks() && kioskUpdates) {
      if (this.selectedKiosks.length < kioskUpdates.length) {
        // why?
      } else {
        allKiosksSelected = true;
      }
    }

    const serviceUpdates = this.resourcesApproval.serviceUpdates;
    if (this.hasServices() && serviceUpdates) {
      if (this.selectedServices.length < serviceUpdates.length) {
      } else {
        allServicesSelected = true;
      }
    }

    let confirmationMessage =
      'By continuing, you are confirming that your user, kiosk, and service selections have been added to Starfish.\n';
    confirmationMessage +=
      '\nIf not all users, kiosks, or services have been added for a request, you may need to re-select some or all of them again next time.';

    const confirmMsg: ConfirmMessageConfig = {
      title: 'Confirm Selections',
      message: confirmationMessage,
    };

    this.submitInProgress = true;
    this.confirmDialogService
      .confirmMessage(confirmMsg)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const isUndefined: boolean = res === undefined;
        if (!isUndefined) {
          // user hit submit
          this.confirmSubmission(completeApproval, res);
        } else {
          // user hit cancel
          this.submitInProgress = false;
        }
      });
  }

  forwardOnSuccessfulSubmission(result: boolean) {
    if (result) {
      this.store.dispatch(new CompleteAddProcess());
      this.router.navigate(['/tasklist']);
    }
  }

  hasKiosks(): boolean {
    if (!this.resourcesApproval.kioskUpdates) {
      return false;
    }
    return this.resourcesApproval.kioskUpdates.length > 0;
  }

  hasServices(): boolean {
    if (!this.resourcesApproval.serviceUpdates) {
      return false;
    } else {
      return this.resourcesApproval.serviceUpdates.length > 0;
    }
  }

  hasUsers(): boolean {
    if (!this.resourcesApproval.userRoles) {
      this.selectedUsers = [];
      return false;
    }
    return this.resourcesApproval.userRoles.length > 0;
  }

  hasData() {
    if (this.hasKiosks() || this.hasServices() || this.hasUsers()) {
      return true;
    }
    return false;
  }

  loadDataFromStore(res: ResourceTaskDetails) {
    this.taskDetails = res.taskDetails;
    this.resourcesApproval = res.ksUpdates;
    const userRoles = this.resourcesApproval.userRoles;
    if (userRoles) {
      this.newUsers = userRoles;
      for (const users of userRoles) {
        if (users) {
          this.addUserToData(users.user);
        }
      }
    }
  }

  ngOnInit() {
    this.store.dispatch(new RequestFailedJobs(this.taskId));
    this.submitInProgress = false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggledKiosk(kiosk: KioskUpdate) {
    const index = this.selectedKiosks.indexOf(kiosk);
    if (index < 0) {
      this.selectedKiosks.push(kiosk);
    } else {
      this.selectedKiosks.splice(index, 1);
    }
  }

  toggledService(service: ServiceUpdate) {
    const index = this.selectedServices.indexOf(service);
    if (index < 0) {
      this.selectedServices.push(service);
    } else {
      this.selectedServices.splice(index, 1);
    }
  }

  toggledUser(userId: string, event: any) {
    const userList = this.resourcesApproval.userRoles;
    if (event.checked && userList) {
      for (const roles of userList) {
        if (userId === roles.user) {
          this.selectedUsers.push(roles);
        }
      }
    } else {
      for (const user of this.selectedUsers) {
        if (userId === user.user) {
          const removeUser = this.selectedUsers.indexOf(user);
          this.selectedUsers.splice(removeUser, 1);
        }
      }
    }
  }

  getDisplayName(userId: string) {
    for (const user of this.userData) {
      if (user.displayName.length > 0 && userId === user.userId) {
        return user.displayName;
      }
    }
    return '';
  }

  addUserToData(userId: string) {
    const displayUser: DisplayUser = {
      displayName: '',
      userId,
    };
    this.userData.push(displayUser);
  }

  private confirmSubmission(completeApproval: CompleteResourceApproval, confirm: boolean) {
    if (confirm) {
      this.submitInProgress = true;
      this.store.dispatch(new PostTaskDetails(completeApproval));
    } else {
      this.submitInProgress = false;
    }
  }
}
