<h1>Add Dynamic Roles</h1>
<mat-card
  appearance="outlined"
  class="dynamic-role-card"
  *ngIf="!(isLoading$ | async)"
  fxLayout="column">
  <mat-card-content>
    <sf-role-details-form
      #roleDetailsFormComponent
      [newRoleForm]="newRoleForm"
      [roleGroups$]="facade.roleGroupsWithAdminGroups$ | async">
    </sf-role-details-form>

    <div
      class="role-buttons"
      fxLayoutAlign="end center"
      fxLayoutGap="16px">
      <button
        *ngIf="!(facade.isCreating$ | async)"
        mat-stroked-button
        color="primary"
        (click)="cancel()">
        Cancel
      </button>
      <button
        *ngIf="!(facade.isCreating$ | async)"
        mat-flat-button
        color="primary"
        (click)="addNewRole()"
        data-cy="new-role-save-btn">
        Save
      </button>
      <mat-progress-bar
        *ngIf="facade.isCreating$ | async"
        data-cy="save-role-progress-bar"
        mode="indeterminate"></mat-progress-bar>
    </div>
  </mat-card-content>
</mat-card>
