import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-multi-row',
  templateUrl: './edit-row.component.html',
  styleUrls: ['./edit-row.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditRowComponent {
  @Input()
  canAdd: boolean;
  @Input()
  canDelete: boolean;
  @Input()
  rowCount: number;
  @Input()
  rowTitle = '';
  @Input()
  captionStyleLabels = false;
  @Output()
  add: EventEmitter<any> = new EventEmitter();
  @Output()
  delete: EventEmitter<any> = new EventEmitter();

  onAdd(): void {
    this.add.emit();
  }

  onDelete(): void {
    this.delete.emit();
  }
}
