import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@psu/utils/security';
import { ConfirmDialogService, OkayDialogService, StarfishFerpaService } from '@starfish-access/core';
import { CompleteApproval, ProcessDetails, TaskDetails } from '@starfish-access/models';
import { BehaviorSubject, interval, merge, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  selectDetailsListDetailsList,
  selectDetailsListError,
  selectDetailsListIsFetching,
} from '../details/details.reducer';
import { PostTaskDetails, RequestTaskDetails } from './approve.actions';
import {
  selectTaskDetailsError,
  selectTaskDetailsHasFailed,
  selectTaskDetailsIsFetching,
  selectTaskDetailsTaskDetails,
} from './approve.reducer';
import { TextAreaDialogComponent } from './enter-comment/textarea-dialog.component';
import { UserSelectDialogComponent } from './user-select/user-select-dialog.component';

@Component({
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss'],
})
export class ApproveComponent implements OnInit, OnDestroy {
  isFetchingWorkflowHistory: Observable<boolean>;
  errorMessage: Observable<any>;
  hasFerpa: Observable<boolean>;
  hasFailed: Observable<boolean>;
  isFetchingTaskDetails: Observable<boolean>;
  commentBlock = ''; // ng model for collecting user comments
  requestItemDetailsObs: Observable<ProcessDetails>;
  taskDetails: TaskDetails;
  taskId = ''; // passed in via url param
  submitInProgressSubj: Subject<boolean> = new BehaviorSubject<boolean>(false); // for when the user clicks 'yes' on the submit confirmation
  pendingUser = false;
  userId: string;
  private errorRedirect = false;
  private destroy$ = new Subject<void>();

  constructor(
    public okayDialogService: OkayDialogService,
    public store: Store<any>,
    public router: Router,
    private route: ActivatedRoute,
    private confirmDialogService: ConfirmDialogService,
    private ferpaService: StarfishFerpaService,
    private authService: AuthService
  ) {
    this.hasFailed = this.store.select<boolean>(selectTaskDetailsHasFailed);
    this.isFetchingTaskDetails = this.store.select<boolean>(selectTaskDetailsIsFetching);
    this.isFetchingWorkflowHistory = this.store.select<boolean>(selectDetailsListIsFetching);

    this.requestItemDetailsObs = this.store.select<ProcessDetails>(selectDetailsListDetailsList);

    this.store
      .select<TaskDetails>(selectTaskDetailsTaskDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.taskStatus === undefined) {
          this.errorRedirect = true;
          this.router.navigate(['/404']);
        }
        this.taskDetails = res;
      });

    const userFromToken = this.authService.getCurrentUser();
    if (userFromToken !== null) {
      this.userId = userFromToken.userName;
    }

    // this.currentUserFacade.userId$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((res) => (this.userId = res));

    this.errorMessage = merge(
      this.store.select<string>(selectTaskDetailsError),
      this.store.select<string>(selectDetailsListError)
    );
  }

  getErrors(err: any): string[] {
    const errors: string[] = [];
    err.forEach((item: any) => {
      if (item !== '') {
        errors.push(item);
      }
    });
    return errors;
  }

  completeRequest(action: string): void {
    const commentBlockArray: string[] = [];
    commentBlockArray.push(this.commentBlock);

    const updatedTask: TaskDetails = {
      taskId: this.taskDetails.taskId,
      processInstanceId: this.taskDetails.processInstanceId,
      taskName: this.taskDetails.taskName,
      taskStatus: this.taskDetails.taskStatus,
      requester: this.taskDetails.requester,
      assignedTo: this.taskDetails.assignedTo,
      approvedBy: this.taskDetails.approvedBy,
      claimTime: this.taskDetails.claimTime,
      endTime: this.taskDetails.endTime,
      comment: commentBlockArray,
    };

    const completeApproval: CompleteApproval = {
      taskDetails: updatedTask,
      taskAction: action,
      taskReassignedTo: '',
      newestComment: this.commentBlock,
    };

    if (this.taskDetails.taskStatus === 'AWAITING_ADDITIONAL_INFO') {
      const confirmDialogRef = this.confirmDialogService.confirm(TextAreaDialogComponent);
      confirmDialogRef.componentInstance.action = this.taskDetails.taskStatus;

      confirmDialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          completeApproval.newestComment = res.comment;
          this.confirmSubmission(completeApproval, true);
        });
    } else if (action === 'RETURN_TO_REQUESTER') {
      const userSelectDialogRef = this.confirmDialogService.confirm(UserSelectDialogComponent);
      userSelectDialogRef.componentInstance.users = this.loadReturnUsers();
      userSelectDialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          completeApproval.newestComment = res.comment;
          completeApproval.taskReassignedTo = res.user;
          this.confirmSubmission(completeApproval, true);
        });
    } else {
      const confirmDialogRef = this.confirmDialogService.confirm(TextAreaDialogComponent);
      confirmDialogRef.componentInstance.action = action;

      confirmDialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          completeApproval.newestComment = res.comment;
          this.confirmSubmission(completeApproval, true);
        });
    }
  }

  loadReturnUsers(): string[] {
    const returnUsers: string[] = [];
    // can always be returned to requester
    const requester = this.taskDetails.requester;
    returnUsers.push(requester);

    for (const userId of this.taskDetails.assignedTo) {
      // can't return task to yourself
      if (userId !== this.userId && userId !== requester) {
        returnUsers.push(userId);
      }
    }

    return returnUsers;
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.taskId = params.taskId;
    });
    if (this.taskId) {
      this.store.dispatch(new RequestTaskDetails(this.taskId));
    }
    // preload returnUsers
    interval(1000)
      .pipe(take(2))
      .subscribe(() => {
        if (!this.errorRedirect) {
          this.loadReturnUsers();
        }
      });

    this.hasFerpa = this.ferpaService.hasFerpa();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  confirmSubmission(completeApproval: CompleteApproval, confirm: boolean): void {
    if (this.pendingUser === true) {
      completeApproval.taskDetails.taskStatus = 'Awaiting User Addition To Starfish';
    }
    if (confirm) {
      this.submitInProgressSubj.next(true);
      this.store.dispatch(new PostTaskDetails(completeApproval));
    }
  }
}
