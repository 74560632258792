import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { OkayDialogService } from '@starfish-access/core';
import { TaskDetails } from '@starfish-access/models';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RequestDetails } from '../details/details.actions';
import * as ApproveActions from './approve.actions';
import { ApproveService } from './approve.service';

@Injectable()
export class ApproveEffects {
  request$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveActions.RequestTaskDetails>(ApproveActions.REQUEST_TASK_DETAILS),
      switchMap((taskId) =>
        this.approveService.getTaskbyId(taskId.payload).pipe(
          map((taskDetails: TaskDetails) => {
            this.store.dispatch(new RequestDetails(taskDetails.processInstanceId));
            return new ApproveActions.ReceiveTaskDetails(taskDetails);
          }),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ApproveActions.ErrorTaskDetails(errorMsg));
          })
        )
      )
    )
  );

  post$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ApproveActions.PostTaskDetails>(ApproveActions.POST_TASK_DETAILS),
      switchMap((taskDetails) =>
        this.approveService.updatedTaskbyId(taskDetails.payload).pipe(
          map(() => {
            this.store.dispatch(new ApproveActions.CompleteAddProcess());
            this.router.navigate(['/tasklist']);
            return new ApproveActions.PostTaskDetailsSuccess();
          }),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ApproveActions.ErrorTaskDetails(errorMsg));
          })
        )
      )
    )
  );

  constructor(
    private store: Store<any>,
    public router: Router,
    private actions$: Actions,
    private approveService: ApproveService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      const message = 'Error retrieving information from a required Starfish service.';
      this.dialogService.okay('Starfish Approval Error', message);
    }
  }

  logPayload(payload: any) {
    return payload;
  }
}
