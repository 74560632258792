import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-icon-preview',
  templateUrl: './icon-preview.component.html',
  styleUrls: ['./icon-preview.component.scss'],
})
export class IconPreviewComponent {
  @Input() colorSelection: Observable<string>;
  @Input() iconSelection: Observable<string>;
}
