import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard } from '@starfish-access/core';
import { DetailsComponent } from './details.component';

export const routes: Routes = [
  {
    title: 'details',
    path: 'details/:processId',
    canActivate: [AuthGuard, FerpaGuard],
    component: DetailsComponent,
  },
];
