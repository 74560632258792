<div class="mat-typography">
  <h3>{{ title }}</h3>
  <div [innerHTML]="message"></div>

  <div>
    <ul>
      <li>
        If you are a current Penn State faculty or staff member, please go to WorkLion.psu.edu and<br />
        select Submit an Inquiry, found under the Contact Us menu, asking for access to Cornerstone.
      </li>
      <br />
      <li>
        If you are a current student, you do not need to complete the Starfish Orientation. Please<br />
        work with your supervisor if you were instructed to seek access to a specific Starfish role.
      </li>
    </ul>
  </div>

  <div
    fxLayoutAlign="end center"
    mat-dialog-actions>
    <button
      type="button"
      mat-button
      color="primary"
      (click)="dialogRef.close(true);">
      OK
    </button>
  </div>
</div>
