import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchService } from '../services/search.service';

@Pipe({ name: 'searchServiceGetNamePipe' })
export class DisplayNamePipe implements PipeTransform {
  constructor(private service: SearchService) {}
  transform(userId: string | null): Observable<string> {
    if (!userId) {
      return of('');
    }

    return this.service.getResults(userId).pipe(
      map((res) => {
        if (res && res[0]) {
          return res[0].displayName;
        }
        return userId;
      })
    );
  }
}
