import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericServiceDeletionStatus } from '@starfish-access/models';

@Component({
  templateUrl: './svc-error-dialog.component.html',
  styleUrls: ['./svc-error-dialog.component.scss'],
})
export class SvcErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: GenericServiceDeletionStatus) {}
}
