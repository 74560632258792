import { Action } from '@ngrx/store';
import { User } from './roles.model';

export const POST_SUCCESS_DATA_STEWARD = '[Roles] Data Steward Posted and Created';
export const POST_DATA_STEWARDS = '[Roles] Post Data Steward';
export const REQUEST_DATA_STEWARDS = '[Roles] Request Data Stewards';
export const RECEIVE_DATA_STEWARDS = '[Roles] Receive Data Stewards';
export const DELETE_SUCCESS_DATA_STEWARD = '[Roles] Data Steward Deleted Succesfully';
export const DELETE_DATA_STEWARDS = '[Roles] Delete Data Steward';
export const ERROR_DATA_STEWARDS = '[Roles] Error From Data Stewards';
export const POST_DUS = '[Roles] Post DUS';
export const POST_SUCCESS_DUS = '[Roles] DUS Posted and Created';
export const REQUEST_DUS = '[Roles] Request DUS';
export const RECEIVE_DUS = '[Roles] Receive DUS';
export const DELETE_SUCCESS_DUS = '[Roles] DUS Deleted Succesfully';
export const DELETE_DUS = '[Roles] Delete DUS';
export const ERROR_DUS = '[Roles] Error From DUS';

// Data Steward Actions
export class AddSteward implements Action {
  readonly type = POST_DATA_STEWARDS;
  constructor(public payload: User) {}
}

export class AddStewardSuccess implements Action {
  readonly type = POST_SUCCESS_DATA_STEWARD;
  constructor(public payload: User) {}
}

export class DeleteSteward implements Action {
  readonly type = DELETE_DATA_STEWARDS;
  constructor(public payload: string) {}
}

export class DeleteStewardSuccess implements Action {
  readonly type = DELETE_SUCCESS_DATA_STEWARD;
  constructor(public payload: string) {}
}

export class RequestStewards implements Action {
  readonly type = REQUEST_DATA_STEWARDS;
}

export class ReceiveStewards implements Action {
  readonly type = RECEIVE_DATA_STEWARDS;
  constructor(public payload: User[]) {}
}

export class ErrorStewards implements Action {
  readonly type = ERROR_DATA_STEWARDS;
  constructor(public payload: string) {}
}

// DUS Actions
export class AddDUS implements Action {
  readonly type = POST_DUS;
  constructor(public payload: User) {}
}

export class AddDusSuccess implements Action {
  readonly type = POST_SUCCESS_DUS;
  constructor(public payload: User) {}
}

export class DeleteDus implements Action {
  readonly type = DELETE_DUS;
  constructor(public payload: string) {}
}

export class DeleteDusSuccess implements Action {
  readonly type = DELETE_SUCCESS_DUS;
  constructor(public payload: string) {}
}

export class RequestDUS implements Action {
  readonly type = REQUEST_DUS;
}

export class ReceiveDUS implements Action {
  readonly type = RECEIVE_DUS;
  constructor(public payload: User[]) {}
}

export class ErrorDUS implements Action {
  readonly type = ERROR_DUS;
  constructor(public payload: string) {}
}

export type RoleActions =
  | AddSteward
  | AddStewardSuccess
  | DeleteSteward
  | DeleteStewardSuccess
  | RequestStewards
  | ReceiveStewards
  | ErrorStewards
  | AddDUS
  | AddDusSuccess
  | DeleteDus
  | DeleteDusSuccess
  | RequestDUS
  | ReceiveDUS
  | ErrorDUS;
