import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgLetModule } from '@psu/utils/angular';
import { TypeaheadComponent } from './typeahead.component';
import { TypeaheadEffects } from './typeahead.effects';
import { TypeaheadFacade } from './typeahead.facade';
import { reducer, TYPEAHEAD_FEATURE_KEY } from './typeahead.reducer';
import { TypeaheadService } from './typeahead.service';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgLetModule,
    StoreModule.forFeature(TYPEAHEAD_FEATURE_KEY, reducer),
    EffectsModule.forFeature([TypeaheadEffects]),
  ],
  declarations: [TypeaheadComponent],
  exports: [TypeaheadComponent],
  providers: [TypeaheadEffects, TypeaheadFacade, TypeaheadService],
})
export class TypeaheadModule {}
