import { Pipe, PipeTransform } from '@angular/core';
import { AccessRequest, ProcessDetails, RequestItem } from '../model/details.model';

@Pipe({ name: 'roleName' })
export class RoleNamePipe implements PipeTransform {
  transform(pd: ProcessDetails | null): string {
    if (!pd) {
      return '';
    }

    const request: AccessRequest = pd.requestDetails;
    const requestItemDetails: RequestItem[] = request.requestItems;

    // even though its an array, currently this will only have 1 item in it.
    // We don't (yet) support multiple roles in a single request
    try {
      for (const roles of requestItemDetails) {
        if (roles?.roles) {
          for (const role of roles.roles) {
            if (role.name) {
              return role.name;
            }
          }
        }
      }
    } catch (e) {
      return '';
    }
    return '';
  }
}
