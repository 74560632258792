<h3>Role Group Priority</h3>
<p>Drag and drop the following role groups for a specific display order.</p>
<div
  cdkDropList
  class="example-list"
  (cdkDropListDropped)="drop($event)">
  <div
    class="example-box"
    fxLayout="row"
    *ngFor="let rolegroup of subscribedPriorities; let i = index"
    cdkDrag
    (mouseup)="mouseIsDown = 'grab'"
    (mousedown)="mouseIsDown = 'move'"
    [style.cursor]="mouseIsDown">
    <div
      class="circle-display"
      [ngClass]="rolegroup.groupColor"
      fxLayoutAlign="center center">
      <span class="role-icon">{{ i + 1 }}</span>
    </div>
    <span class="mat-subtitle-1 role-name">{{ rolegroup.groupName }}</span>
    <mat-icon svgIcon="drag-vertical"></mat-icon>
  </div>
</div>
