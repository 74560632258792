import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserInfo } from '../model/delegation.model';
import { SearchResult } from '../model/server/search.model';

export const searchResultToUserInfo = (searchResult: SearchResult): UserInfo => ({
  id: searchResult.userid,
  displayName: searchResult.displayName,
});

export const searchResultsToUserInfos = (searchResults: SearchResult[]): UserInfo[] =>
  searchResults.map(searchResultToUserInfo);

export const searchResultsToUserInfosPipe = () => pipe(map(searchResultsToUserInfos));
