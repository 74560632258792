import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ResponsiveModule } from '@psu/utils/responsive';
import { DialogService } from './dialog.service';
import { ErrorDialog } from './error-dialog.component';
import { ServiceDialogComponent } from './service-dialog.component';
import { StarfishOrientationDialog } from './sf-orientation-dialog';
import { TextAreaDialog } from './textarea-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatListModule,
    MatSelectModule,
    MatSnackBarModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ResponsiveModule,
  ],
  exports: [ServiceDialogComponent, TextAreaDialog, ErrorDialog, StarfishOrientationDialog],
  declarations: [ServiceDialogComponent, TextAreaDialog, ErrorDialog, StarfishOrientationDialog],
  providers: [DialogService],
})
export class DialogModule {}
