import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationRef, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { FooterModule } from '@psu/components/layout';
import { VersionBannerModule } from '@psu/components/version-banner';
import { ErrorStatusInterceptor } from '@psu/utils/analytics';
import { CacheInterceptor, RequestCacheInterceptor } from '@psu/utils/browser';
import { ResponsiveModule } from '@psu/utils/responsive';
import { AuthInterceptor, AuthModule, UserIdRequestTracingInterceptor } from '@psu/utils/security';
import { ConfirmDialogModule, OkayDialogModule } from '@starfish-access/core';
import { StarfishCommonModule } from '@starfish-access/shared';
import { AppComponent } from './app.component';
import { INIT_PROVIDERS } from './app.initializer';
import { ROUTES } from './app.routing';
import { VersionInterceptor } from './core/services/version.interceptor';
import { ApproveModule } from './features/approve/approve.module';
import { AuditModule } from './features/audit/audit.module';
import { CourseMappingModule } from './features/course-mapping/course-mapping.module';
import { DashboardComponent } from './features/dashboard.component';
import { DelegationModule } from './features/delegation/delegation.module';
import { DetailsModule } from './features/details/details.module';
import { DirectorsModule } from './features/directors/directors.module';
import { DynamicRoleModule } from './features/dynamic-role/dynamic-role.module';
import { FailedJobsModule } from './features/failedjobs/failedjobs.module';
import { KiosksModule } from './features/kiosks/kiosks.module';
import { KioskSvcModule } from './features/kiosksvcs/kiosk-services.module';
import { ResourcesModule } from './features/resources/resources.module';
import { RolesModule } from './features/roles/roles.module';
import { TaskListModule } from './features/tasklist/tasklist.module';
import { UserDetailsModule } from './features/user-details/user-details.module';
import { UserManagementModule } from './features/usermanagement/usermanagement.module';
import { NotFound404Component } from './not-found404.component';
import { reducers } from './reducers';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';

export const getInitialState = () => ({});
@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, DashboardComponent, NotFound404Component],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    StarfishCommonModule,
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
    }),
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatToolbarModule,
    FlexLayoutModule,
    OkayDialogModule,
    ConfirmDialogModule,
    FooterModule,
    ResourcesModule,
    FailedJobsModule,
    KiosksModule,
    KioskSvcModule,
    RolesModule,
    ApproveModule,
    DetailsModule,
    CourseMappingModule,
    AuditModule,
    TaskListModule,
    ResponsiveModule,
    StoreModule.forRoot(reducers, {
      initialState: getInitialState,
      runtimeChecks: {
        strictActionImmutability: true,
        strictActionSerializability: false,
        strictStateImmutability: true,
        strictStateSerializability: false,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, name: 'Starfish Access' }),
    AuthModule,
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    UserManagementModule,
    DynamicRoleModule,
    VersionBannerModule,
    DelegationModule,
    DirectorsModule,
    UserDetailsModule,
    ScrollToTopComponent,
    FooterModule,
    FooterModule,
    FooterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestCacheInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    ...INIT_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserIdRequestTracingInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorStatusInterceptor,
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' } as MatFormFieldDefaultOptions,
    },
  ],
})
export class AppModule {
  constructor(public appRef: ApplicationRef, matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./images/mdi.svg')); // Or whatever path you placed mdi.svg at
  }
}
