import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'initialCaps' })
export class InitialCapsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value
      .split('_')
      .map((wrd) => {
        if (wrd !== 'ASR' && wrd !== 'DS' && wrd !== 'DUS') {
          return wrd.charAt(0).toUpperCase() + wrd.substring(1).toLowerCase();
        } else {
          return wrd;
        }
      })
      .join(' ');
  }
}
