<div class="header-display-wrapper">
  <span class="display-label">{{ displayTitle }}</span>
  <div *ngIf="!(isExpanded | async)">
    <div *ngFor="let item of listToDisplay; let i = index">
      <div
        *ngIf="i < 2"
        class="display-item">
        {{ item }}
      </div>
      <div
        *ngIf="i < 2 && ((i !== 1 && listToDisplay?.length >= 2) || (i === 1 && listToDisplay?.length < 2))"
        class="display-divider"></div>
    </div>
    <div
      *ngIf="isExpandable && !(isExpanded | async) && listToDisplay && listToDisplay.length > 2"
      class="display-item">
      <span class="display-label"> + {{ listToDisplay.length - 2 }} more </span>
    </div>
  </div>
  <div *ngIf="isExpanded | async">
    <div *ngFor="let item of listToDisplay; let i = index">
      <div class="display-item">{{ item }}</div>
      <div
        *ngIf="isExpandable && i !== listToDisplay.length - 1"
        class="display-divider"></div>
    </div>
  </div>
</div>
