import { Injectable } from '@angular/core';
import { CourseInfoService, createCacheKey } from '@starfish-access/core';
import { CourseMenuItem, MenuItem } from '@starfish-access/models';
import { assoc, has } from 'ramda';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class CourseConfigFacade {
  readonly possibleCampuses$: Observable<MenuItem[]> = this.service.getAllCampuses();

  readonly possibleEducationalTracks$ = this.service.getAllEducationalTracks().pipe(shareReplay(1));

  readonly possibleSemesters$ = this.service.getAllSemesters().pipe(shareReplay(1));

  readonly possibleSubjects$ = this.service.getAllDepartments().pipe(shareReplay(1));

  private courseObservables: {
    [key: string]: Observable<CourseMenuItem[]>;
  } = {};

  private deptObservables: {
    [key: string]: Observable<MenuItem[]>;
  } = {};

  constructor(private service: CourseInfoService) {}

  getSemestersByTrackId(track: string): Observable<MenuItem[]> {
    return this.service.getSemestersByTrackId(track);
  }

  getDepartments(campus: string, track: string, termName: string): Observable<MenuItem[]> {
    const cacheKey = createCacheKey(campus, track, termName);
    const hasKey = has(cacheKey);
    if (!hasKey(this.deptObservables)) {
      const depts$ = this.service.getDepartments(campus, track, termName).pipe(shareReplay(1));
      this.deptObservables = assoc(cacheKey, depts$, this.deptObservables);
    }
    return this.deptObservables[cacheKey];
  }

  getCourses(campus: string, track: string, termName: string, department: string[]): Observable<CourseMenuItem[]> {
    const cacheKey = createCacheKey(campus, track, termName, department?.toString());
    const hasKey = has(cacheKey);
    if (!hasKey(this.courseObservables)) {
      const courses$ = this.service.getCourses(campus, track, termName, department).pipe(shareReplay(1));
      this.courseObservables = assoc(cacheKey, courses$, this.courseObservables);
    }
    return this.courseObservables[cacheKey];
  }
}
