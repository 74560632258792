import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrgModel, RoleConfig } from '@starfish-access/models';
import { OrgSelectItem } from '@starfish-access/shared';
import { orgModelToOrgSelectItem } from '../../dynamic-role/orgs/orgs.component';

export interface KiosksAndServices {
  services: OrgSelectItem[];
  kiosks: OrgSelectItem[];
}
@Component({
  selector: 'sf-add-svc-kiosk',
  templateUrl: './add-svc-kiosk.component.html',
  styleUrls: ['./add-svc-kiosk.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddSvcKioskComponent),
      multi: true,
    },
  ],
})
export class AddSvcKioskComponent implements OnInit, ControlValueAccessor {
  @Input() selectedRole: RoleConfig;
  @Input() hasAssignments = false;
  svcAndKioskFormGroup: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.svcAndKioskFormGroup = fb.group({
      services: [[]],
      kiosks: [[]],
    });
  }

  // In the directors area, we should only be evaluation items from Delegation (servicesFromDelegation, orgsFromDeletation, etc)
  convertModel(step2Orgs: OrgModel[]): OrgSelectItem[] {
    const orgList: OrgSelectItem[] = step2Orgs ? step2Orgs.map(orgModelToOrgSelectItem) : [];
    return orgList;
  }

  writeValue(obj: KiosksAndServices): void {
    if (!obj) {
      return;
    }

    if (!this.hasAssignments) {
      // if we've deferred selection and don't have an assignment, don't patch in values;
      if (this.selectedRole.servicesFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE') {
        obj.services = [];
      }
      if (this.selectedRole.kiosksFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE') {
        obj.kiosks = [];
      }
    }
    // else patch value is coming from an assignment or from previously selected items - apply

    this.svcAndKioskFormGroup.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.svcAndKioskFormGroup.valueChanges.subscribe((value: KiosksAndServices) => {
      this.propagateChange(value);
    });
  }
  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
