import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '@starfish-access/models';

@Pipe({ name: 'sortCourseNumber' })
export class SortCoursesPipe implements PipeTransform {
  transform(arr: MenuItem[]): MenuItem[] {
    if (!arr) {
      return [];
    }

    return arr.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
  }
}
