import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { KioskSvc } from '../../model/kiosk-services.model';
import { ServerService } from '../orgs.models';

export const serverToService = (serverService: ServerService): KioskSvc => ({
  id: serverService.organizationId,
  name: serverService.organizationName,
  description: '',
  parentId: undefined,
});

export const serviceToServer = (service: KioskSvc): ServerService => ({
  organizationId: service.id,
  organizationName: service.name,
});

export const serversToServices = (serverServices: ServerService[]): KioskSvc[] => serverServices.map(serverToService);

export const servicesToServers = (services: KioskSvc[]): ServerService[] => services.map(serviceToServer);

export const serverModelToServices = () => pipe(map(serversToServices));

export const serverModelToService = () => pipe(map(serverToService));

export const serviceToServerPipe = () => pipe(map(serviceToServer));
