import { Component, Input } from '@angular/core';
import { TaskIconDisplay } from '@starfish-access/models';

const quoteBox =
  'M22,12A10,10,0,1,1,12,2,10,10,0,0,1,22,12ZM12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4ZM10.8,16H11a.4076.4076,0,0,0,.28-.116L12.76,14.4H15.2a.8.8,0,0,0,.8-.8V8.8a.8.8,0,0,0-.8-.8H8.8a.8.8,0,0,0-.8.8v4.8a.8.8,0,0,0,.8.8h1.6v1.2a.4.4,0,0,0,.4.4m1.6-6.4v1.2h1.2v.8H12.4v1.2h-.8V11.6H10.4v-.8h1.2V9.6Z';
const grayCheck =
  'M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.24,7.76C15.07,6.58 13.53,6 12,6V12L7.76,16.24C10.1,18.58 13.9,18.58 16.24,16.24C18.59,13.9 18.59,10.1 16.24,7.76Z';
const backArray =
  'M7,12L12,7V10H16V14H12V17L7,12M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12Z';
const circleCheck =
  'M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4M11,16.5L6.5,12L7.91,10.59L11,13.67L16.59,8.09L18,9.5L11,16.5Z';
@Component({
  templateUrl: './workflow-icon.component.html',
  styleUrls: ['./workflow-icon.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-workflow-icon',
})
export class WorkflowIconComponent {
  @Input() taskStatus: string;
  statusIconMapping = new Map<string, TaskIconDisplay>();
  constructor() {
    this.statusIconMapping.set('APPROVED', {
      iconName: circleCheck,
      iconColorClass: 'green',
    });
    this.statusIconMapping.set('COMPLETED', {
      iconName: circleCheck,
      iconColorClass: 'green',
    });
    this.statusIconMapping.set('AUDIT_COMPLETED', {
      iconName: circleCheck,
      iconColorClass: 'green',
    });
    this.statusIconMapping.set('REJECTED', {
      iconName: backArray,
      iconColorClass: 'red',
    });
    this.statusIconMapping.set('CANCELLED', {
      iconName: backArray,
      iconColorClass: 'red',
    });
    this.statusIconMapping.set('CANCELED', {
      iconName: backArray,
      iconColorClass: 'red',
    });
    this.statusIconMapping.set('RETURNED_TO_REQUESTER', {
      iconName: backArray,
      iconColorClass: 'red',
    });
    this.statusIconMapping.set('New Comment', {
      iconName: quoteBox,
      iconColorClass: 'blue',
    });
    this.statusIconMapping.set('AWAITING_USER_ADDITION_IN_STARFISH', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('loading...', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_APPROVAL', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_ADDITIONAL_INFORMATION', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_ADDITIONAL_INFO', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_ASR_APPROVAL', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_DS_APPROVAL', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_MANAGER_OVERRIDE', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_MANAGER_APPROVAL', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_SPECIAL_GRP_APPROVAL', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('AWAITING_DUS_CONFIRMATION', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
    this.statusIconMapping.set('IN_PROGRESS', {
      iconName: grayCheck,
      iconColorClass: 'gray',
    });
  }
}
