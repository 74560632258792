import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseMappingDetails } from '@starfish-access/models';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CourseMappingFacade } from '../course-mapping.facade';

@Component({
  selector: 'sf-task-approval',
  templateUrl: './task-approval.component.html',
  styleUrls: ['./task-approval.component.scss'],
})
export class TaskApprovalComponent implements OnInit, OnDestroy {
  courseMappingTaskDetails$: Observable<CourseMappingDetails>;
  taskId: string;

  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, public facade: CourseMappingFacade) {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.taskId = params.taskId;
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.courseMappingTaskDetails$ = this.facade.getTaskDetails(this.taskId);
  }
}
