import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  clearSearchInput,
  destroyTypeaheadComponent,
  initTypeaheadComponent,
  searchForTerm,
} from './typeahead.actions';
import { TypeaheadConfig } from './typeahead.config';
import { LOAD_ALL_OPTION, TYPEAHEAD_DELAY } from './typeahead.model';
import { TypeaheadComponentState } from './typeahead.reducer';
import { selectTypeaheadComponents } from './typeahead.selectors';

@Injectable()
export class TypeaheadFacade {
  private clear: { [key: string]: boolean } = {};

  constructor(private store: Store<any>) {}

  observeComponent(
    componentId: string,
    searchTerm$: Observable<string>,
    config: TypeaheadConfig
  ): Observable<TypeaheadComponentState> {
    // register the component
    this.store.dispatch(initTypeaheadComponent({ componentId }));

    // pipe any emissions of the search term (valueChanges on the form),
    // apply business rules,
    // then call the backend by dispatching an action.
    return searchTerm$.pipe(
      debounceTime(TYPEAHEAD_DELAY),
      filter((term) => !!term && typeof term === 'string' && term !== LOAD_ALL_OPTION),
      map((term) => (config.lowerCaseSearchQueryParam ? term.trim().toLocaleLowerCase() : term.trim())),
      filter((term) => !!term && term.length > 2),
      distinctUntilChanged((a, b) => {
        // handles the case where you type "test", then hit clear button, then type "test" again.
        if (this.clear[componentId]) {
          this.clear[componentId] = false;
          return false;
        }
        return a === b;
      }),
      tap((term) => {
        this.store.dispatch(searchForTerm({ componentId, term, config }));
      }),
      switchMap(() =>
        this.store.pipe(
          select(selectTypeaheadComponents),
          map((components) => (components !== undefined ? components[componentId] : (undefined as any)))
        )
      ),
      finalize(() => this.store.dispatch(destroyTypeaheadComponent({ componentId })))
    );
  }

  clearSearchInput(componentId: string): void {
    this.clear[componentId] = true;
    this.store.dispatch(clearSearchInput({ componentId }));
  }
}
