import { Kiosk } from './kiosks.model';
import { ServerRole } from './server/server-roles.model';

// TODO jrm review this to see what can be deleted / removed / combined
export type ServiceSelectionType = 'SELECTED' | 'ADMIN_AREA_CHOOSE' | 'NONE';

export interface ServiceSelection {
  selectionType: ServiceSelectionType;
  services: Services[];
}

export interface OrgModel {
  id?: number;
  code: string;
  name: string;
}

export class ApprovalTask {
  taskName: string;
  taskId: string;
  taskStatus: string;
  taskAssignedTo: string[];
  taskApprovedBy: string;
  taskApprovalTime: string;
  taskComment: ApprovalTaskComments[];
}

export interface ApprovalTaskComments {
  taskComment: string;
  userCommented: string;
  timeCommented: string;
}

export class Services {
  id: string;
  name: string;
  description: string;
}

export class ProcessDetails {
  requestDetails: AccessRequest;
  approvalTasks: ApprovalTask[];
  processComments: InnerComment[];
  organizations?: OrgModel[];
}

export class WorkFlowDisplay extends ApprovalTask {
  approverFullName: string;
}

export class UpdateProcess {
  requestToUpdate: AccessRequest;
  processInstanceId: string;
}

export class AccessUser {
  userId: string;
}

export class NewComment {
  processInstanceId: string;
  comment: InnerComment;
}

export class Semester {
  semester: string;
}

export class InnerComment {
  'processComment': string;
  'userCommented': string;
  'timeCommented': string;
}

export class RequestItem {
  type: string;
  requestId?: number;
  services?: Services[];
  kiosks?: Kiosk[];
  roles?: ServerRole[];
  calendarRoleUsers?: AccessUser[];
  calendarRoleSemesters?: Semester[];
  organizations?: OrgModel[];
}

export class AccessRequest {
  accessUser: AccessUser;
  college: string;
  department: string;
  jobTitle: string;
  starfishTrainingFlag: boolean;
  requestStatus?: string | null;
  requestItems: RequestItem[];
  'request-date': string;
  'last-status-date': string;
  organizations?: OrgModel[];
  requestedOrganizations?: OrgModel[]; /// TODO can this be removed? JRM
  approvingManager?: string;
}

export class InnerTaskComment {
  'taskComment': string;
  'userCommented': string;
  'timeCommented': string;
}

export class GenericService {
  id: string;
  name: string;
  description: string;
  parentId: string | undefined;
}

export interface UserRoleService {
  user: string;
  role: string;
}

export interface RoleConfigService {
  roleName: string;
  delegationNames: string[] | null;
}

export interface GenericServiceDeletionError {
  delegationRolesWithOrg: RoleConfigService[];
  delegatedUserRolesWithOrg: UserRoleService[];
  nondelegatedUserRolesWithOrg: UserRoleService[];
  delegationsWithCalendarmanagerKiosk: string[];
  isServiceMappedToCourseChannel: string | null;
}

export interface GenericServiceDeletionStatus {
  successfulDeletion: boolean;
  errorCode?: string; // unique to some starfish error messages
  serverResponseCode?: number; // 200 / 409 / etc
  conflictError?: GenericServiceDeletionError;
  type: 'KIOSK' | 'SERVICE';
}
