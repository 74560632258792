import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientRoleGroup, RoleGroupPriority } from '@starfish-access/models';
import {
  CREATE_GROUPS,
  CREATE_GROUPS_SUCCESS,
  CREATE_NEW_GROUP_AND_PRIORITY,
  DynamicRoleActions,
  ERROR,
  RECEIVE_GROUPS,
  RECEIVE_GROUP_PRIORITIES,
  REQUEST_GROUPS,
  UPDATE_EXISTING_ROLE_GROUPS,
  UPDATE_EXISTING_ROLE_GROUPS_SUCCESS,
  UPDATE_GROUPS,
  UPDATE_GROUPS_SUCCESS,
} from './dynamic-role.actions';

export interface DynamicRolesState {
  hasFailed: boolean;
  isFetching: boolean;
  isCreating: boolean;
  errorMsg: string;
  roleGroups: ClientRoleGroup[];
  roleGroupPriorities: RoleGroupPriority[];
}

export const initialState: DynamicRolesState = {
  hasFailed: false,
  isFetching: false,
  isCreating: false,
  errorMsg: '',
  roleGroups: [],
  roleGroupPriorities: [],
};

export const dynamicRoles = createFeatureSelector<DynamicRolesState>('dynamicRoles');
export const selectReadingRolesHasFailed = createSelector(dynamicRoles, (t) => t.hasFailed);
export const selectRolesIsFetching = createSelector(dynamicRoles, (t) => t.isFetching);
export const selectRolesIsUpdating = createSelector(dynamicRoles, (t) => t.isCreating);

export const selectRolesError = createSelector(dynamicRoles, (t) => t.errorMsg);
export const selectRolesAndGroups = createSelector(dynamicRoles, (t) => t.roleGroups);

export const selectRoleGroupPriorities = createSelector(dynamicRoles, (t) => t.roleGroupPriorities);

export const dynamicRolesReducer = (state = initialState, action: DynamicRoleActions): DynamicRolesState => {
  switch (action.type) {
    case REQUEST_GROUPS: {
      return Object.assign({}, state, {
        isFetching: true,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case RECEIVE_GROUPS: {
      // console.log('reducer payload: '  + JSON.stringify(action.payload));
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: false,
        errorMsg: '',
        roleGroups: action.payload,
      });
    }
    case RECEIVE_GROUP_PRIORITIES: {
      return Object.assign({}, state, {
        roleGroupPriorities: action.payload,
      });
    }
    case CREATE_NEW_GROUP_AND_PRIORITY: {
      return Object.assign({}, state, {
        isCreating: true,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case UPDATE_EXISTING_ROLE_GROUPS: {
      return Object.assign({}, state, {
        isCreating: true,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case UPDATE_EXISTING_ROLE_GROUPS_SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case UPDATE_GROUPS: {
      return Object.assign({}, state, {
        isCreating: true,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case UPDATE_GROUPS_SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case CREATE_GROUPS: {
      return Object.assign({}, state, {
        isCreating: true,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case CREATE_GROUPS_SUCCESS: {
      return Object.assign({}, state, {
        isCreating: false,
        hasFailed: false,
        errorMsg: '',
      });
    }
    case ERROR: {
      let err = action.payload;
      if (err === undefined) {
        err = 'An unknown error has occurred';
      }
      // console.error('' + err);
      return Object.assign({}, state, {
        isFetching: false,
        isCreating: false,
        hasFailed: true,
        errorMsg: err,
        userAccount: 'not found',
      });
    }
    default: {
      return state;
    }
  }
};
