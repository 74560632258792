export class StarfishAdminPermissions {
  static ADMIN = 'starfish-access.admin';
}

export class DelegationPermissions {
  static VIEW = 'starfish-workflow.kiosk.view';
  static ADD = 'starfish-workflow.kiosk.add';
  static EDIT = 'starfish-workflow.kiosk.update';
  static DELETE = 'starfish-workflow.kiosk.delete';
}

export class DelegationManagerPermissions {
  static VIEW = 'starfish-workflow.delegationassignment.view';
  static ADD = 'starfish-workflow.delegationassignment.add';
  static EDIT = 'starfish-workflow.delegationassignment.update';
  static DELETE = 'starfish-workflow.delegationassignment.delete';
}

export class KioskPermissions {
  static VIEW = 'starfish-workflow.kiosk.view';
  static ADD = 'starfish-workflow.kiosk.add';
  static EDIT = 'starfish-workflow.kiosk.update';
  static DELETE = 'starfish-workflow.kiosk.delete';
}

export class KioskServicePermissions {
  static VIEW = 'starfish-workflow.service.view';
  static ADD = 'starfish-workflow.service.add';
  static EDIT = 'starfish-workflow.service.update';
  static DELETE = 'starfish-workflow.service.delete';
}

export class FailedJobPermissions {
  static VIEW = 'starfish-workflow.job.view';
  static DELETE = 'starfish-workflow.job.delete';
  static RETRY = 'starfish-workflow.job.retry';
}

export class DataStewardRolePermissions {
  static VIEW = 'starfish-workflow.dataSteward.view';
  static ADD = 'starfish-workflow.dataSteward.add';
  static DELETE = 'starfish-workflow.dataSteward.delete';
}

export class DusRolePermissions {
  static VIEW = 'starfish-workflow.dus.view';
  static ADD = 'starfish-workflow.dus.add';
  static DELETE = 'starfish-workflow.dus.delete';
}

export class UserListPermissions {
  static VIEW = 'starfish-workflow.user.role.view';
  static DELETE = 'starfish-workflow.user.role.delete';
}
