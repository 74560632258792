import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'hasCartItems' })
export class HasCartItemsPipe implements PipeTransform {
  transform(cartItems: number[] | null): boolean {
    if (!cartItems) {
      return false;
    }
    if (cartItems.length === 0) {
      return false;
    }
    return true;
  }
}
