import { Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { DataStewardRolePermissions, DusRolePermissions, FerpaGuard } from '@starfish-access/core';
import { RolesComponent } from './roles.component';

export const routes: Routes = [
  {
    title: 'roles',
    path: 'roles',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    component: RolesComponent,
    data: {
      permissions: [DataStewardRolePermissions.VIEW, DusRolePermissions.VIEW],
    },
  },
];
