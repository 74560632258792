<h2 class="role-desc">Role Description</h2>
<p class="mat-body-1">
  Each box creates a new bullet point for the role description. Click the + sign to add a new bullet point
</p>

<div [formGroup]="formGroup">
  <div
    formArrayName="descriptions"
    class="descriptions-wrapper">
    <div *ngFor="let desc of descriptions.controls; index as i">
      <sf-multi-row
        #multiRow
        [rowCount]="i"
        rowTitle="Row Title"
        (add)="addItem()"
        (delete)="deleteItem(i)"
        [canAdd]="canAdd"
        [formGroupName]="i"
        [canDelete]="canAdd"
        [attr.data-cy]="'multi-description-' + i">
        <mat-form-field
          class="role-desc-entry"
          appearance="outline"
          fxFlex>
          <mat-label>Enter Role Description</mat-label>
          <input
            [formControl]="desc"
            data-cy="input-role-description"
            matInput
            placeholder="A bullet point item about this role" />
          <mat-hint>When text is added click the + sign to add another bullet point, if needed.</mat-hint>
          <mat-error>You must enter a description</mat-error>
        </mat-form-field>
      </sf-multi-row>
    </div>
  </div>
</div>
