import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { ApprovalTaskComponent } from './approval-task/approval-task.component';

export const routes: Routes = [
  {
    title: 'audit',
    path: 'auditingapproval/:taskId',
    canActivate: [AuthGuard],
    component: ApprovalTaskComponent,
  },
];
