import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RolesService } from '@starfish-access/core';
import {
  ClientDelegationMetaData,
  ClientRoleGroup,
  ClientRoleGroupAndPriority,
  RoleGroupPriority,
} from '@starfish-access/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { RequestGroupPriorities, RequestGroups, UpdateExistingRoleGroup, UpdateGroups } from './dynamic-role.actions';
import { EditedRoleGroupModel } from './dynamic-role.model';
import {
  selectRoleGroupPriorities,
  selectRolesAndGroups,
  selectRolesIsFetching,
  selectRolesIsUpdating,
} from './dynamic-role.reducer';

@Injectable()
export class DynamicRolesFacade {
  isCreating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private roleGroups: Observable<ClientRoleGroup[]>;
  private roleGroupPriorities: Observable<RoleGroupPriority[]>;
  private isFetching: Observable<boolean>;
  private roleGroupsWithAdminGroups: Observable<ClientRoleGroup[]>;
  private isUpdating: Observable<boolean>;

  constructor(private rolesService: RolesService, private store: Store<any>) {
    this.roleGroups = this.store.select(selectRolesAndGroups);
    this.isUpdating = this.store.select(selectRolesIsUpdating);
    this.isFetching = this.store.select(selectRolesIsFetching);
    this.roleGroupPriorities = this.store.select(selectRoleGroupPriorities);
    this.roleGroupsWithAdminGroups = this.rolesService.getRequestableRoleGroupsWithAdminRoles(true);
  }

  get roleGroups$(): Observable<ClientRoleGroup[]> {
    return this.roleGroups;
  }

  get roleGroupPriorities$(): Observable<RoleGroupPriority[]> {
    return this.roleGroupPriorities;
  }

  get roleGroupsWithAdminGroups$(): Observable<ClientRoleGroup[]> {
    return this.roleGroupsWithAdminGroups;
  }

  get isUpdating$(): Observable<boolean> {
    return this.isUpdating;
  }

  get isFetching$(): Observable<boolean> {
    return this.isFetching;
  }

  requestRoleGroups(): void {
    this.store.dispatch(new RequestGroups());
  }

  requestRoleGroupPriorities(): void {
    this.store.dispatch(new RequestGroupPriorities());
  }

  updateRoleGroup(gap: ClientRoleGroupAndPriority): void {
    this.store.dispatch(new UpdateGroups(gap));
  }

  createRoleInExistingRoleGroup(gap: ClientRoleGroupAndPriority): Observable<Response> {
    this.isCreating$.next(true);
    return this.rolesService.createRoleAndValidateUmg(gap).pipe(finalize(() => this.isCreating$.next(false)));
  }

  createNewRoleInNewRoleGroup(rgAndPriority: ClientRoleGroupAndPriority): Observable<any> {
    this.isCreating$.next(true);
    return this.rolesService
      .createNewGroupAndSetPriority(rgAndPriority)
      .pipe(finalize(() => this.isCreating$.next(false)));
  }

  updateExistingRoleGroup(rg: EditedRoleGroupModel) {
    this.store.dispatch(new UpdateExistingRoleGroup(rg));
  }

  getDelegationNameAndId(roleId: string): Observable<string> {
    if (!roleId) {
      return of(''); // without an id we can't actually do anything
    }
    return this.rolesService.getAssociatedDelegationGroups(roleId).pipe(
      take(1),
      map((res) => {
        const delList = res.map(this.getDelName).toString();
        return delList.length > 0
          ? 'Included in these Delegation Groups: ' + delList
          : 'Not part of any Delegation Groups';
      })
    );
  }

  private getDelName(del: ClientDelegationMetaData): string {
    return del.delegationName;
  }
}
