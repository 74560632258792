import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard, StarfishGuard, UserListPermissions } from '@starfish-access/core';
import { UserDetailsComponent } from './user-details.component';

export const routes: Routes = [
  {
    title: 'userdetails',
    path: 'userdetails',
    canActivate: [AuthGuard, FerpaGuard, StarfishGuard],
    component: UserDetailsComponent,
    data: {
      permissions: [UserListPermissions.VIEW],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserDetailsRoutingModule {}
