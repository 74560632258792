<div
  id="add-user-role-comp"
  [formGroup]="roleAndUserFormGroup"
  fxLayout="column">
  <div *ngIf="isReadOnly">
    <h3 class="mat-h3">Users</h3>
    <mat-chip-set
      role="list"
      aria-label="User Selection">
      <mat-chip
        role="listitem"
        *ngFor="let user of roleAndUserFormGroup?.get('users')?.value"
        [attr.data-cy]="'chip-' + user.displayName"
        >{{ user.displayName | searchServiceGetNamePipe | async }} [{{ user.id }}]</mat-chip
      >
    </mat-chip-set>

    <h3 class="mat-h3">Role</h3>
    <mat-chip-set
      role="list"
      aria-label="Role Selection">
      <mat-chip
        role="listitem"
        *ngFor="let role of roleAndUserFormGroup?.get('roles')?.value"
        [attr.data-cy]="'chip-' + role.displayName"
        >{{ role.displayName }} [{{ role.roleId }}]</mat-chip
      >
    </mat-chip-set>
  </div>

  <div *ngIf="!isReadOnly">
    <h2 class="mat-h2">Add User(s)</h2>
    <sf-typeahead-select
      [config]="delegationAdminConfig"
      formControlName="users">
    </sf-typeahead-select>
    <mat-error *ngIf="roleAndUserFormGroup?.get('users')?.errors?.required && formControl.touched">
      <span data-cy="user-list-error">You must choose at least one user</span>
    </mat-error>

    <h2 class="mat-h2">Assign Role</h2>
    <sf-org-select-input
      #serviceSelector
      class="roles-selector"
      formControlName="roles"
      [possibleItems]="roleConfigs | roleToSelect | sortOrgs"
      [config]="{ placeholder: 'Search for roles to assign', showSearchIcon: false, removable: true }">
    </sf-org-select-input>
    <mat-error
      data-cy="user-and-role-list-error"
      *ngIf="roleAndUserFormGroup?.get('roles')?.errors?.maxlength && formControl.touched">
      <span data-cy="role-list-error">You must choose only one role</span>
    </mat-error>
    <mat-error
      data-cy="user-and-role-list-error"
      *ngIf="roleAndUserFormGroup?.get('roles')?.errors?.required && formControl.touched">
      <span data-cy="role-list-error">You must choose at least one role</span>
    </mat-error>
    <mat-error
      data-cy="assignment-exists-error"
      *ngIf="roleAndUserFormGroup?.errors?.invalidAssignment">
      <span data-cy="assignment-exists-error-text">{{ roleAndUserFormGroup?.errors?.invalidAssignment }}</span>
    </mat-error>
  </div>
</div>
