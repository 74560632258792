import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-account-search',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AccountAutoCompleteComponent implements AfterViewInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSearch = new EventEmitter<string>();
  @Input() placeHolderText = 'Search by Role';
  @ViewChild('searchByRole') input: ElementRef;

  private subscription: Subscription;

  ngAfterViewInit(): void {
    const terms$ = fromEvent<any>(this.input.nativeElement, 'keyup').pipe(
      map((event) => event.target.value),
      startWith('')
    );
    this.subscription = terms$.subscribe((criterion) => {
      this.onSearch.emit(criterion);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
