import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProcessDetails } from '@starfish-access/models';
import * as DetailsActions from './details.actions';

export interface DetailsState {
  isFetching: boolean;
  isSubmitting: boolean;
  isAddingComment: boolean;
  errorMsg: string;
  detailsList: ProcessDetails;
  isSuccessfullySubmitted: boolean;
  processRequestsSubmitted: number[];
}

export const initialState: DetailsState = {
  isFetching: false,
  isSubmitting: false,
  isAddingComment: false,
  errorMsg: '',
  detailsList: {
    requestDetails: {
      accessUser: {
        userId: '',
      },
      college: '',
      department: '',
      jobTitle: '',
      starfishTrainingFlag: false,
      requestStatus: '',
      requestItems: [],
      'request-date': '',
      'last-status-date': '',
    },
    approvalTasks: [],
    processComments: [],
  },
  isSuccessfullySubmitted: false,
  processRequestsSubmitted: [],
};

export const selectDetailsList = createFeatureSelector<DetailsState>('detailsList');
export const selectDetailsListIsFetching = createSelector(selectDetailsList, (d) => d.isFetching);
export const selectDetailsListIsSubmitting = createSelector(selectDetailsList, (d) => d.isSubmitting);
export const selectDetailsListIsAddingComment = createSelector(selectDetailsList, (d) => d.isAddingComment);
export const selectDetailsListError = createSelector(selectDetailsList, (d) => d.errorMsg);
export const selectDetailsListDetailsList = createSelector(selectDetailsList, (d) => d.detailsList);
export const selectDetailsListIsSuccessfullySubmitted = createSelector(
  selectDetailsList,
  (d) => d.isSuccessfullySubmitted
);
export const selectProcessRequestsSubmitted = createSelector(selectDetailsList, (d) => d.processRequestsSubmitted);

export const detailsListReducer = (state = initialState, action: DetailsActions.DetailsActions): DetailsState => {
  switch (action.type) {
    case DetailsActions.REQUEST_DETAILS: {
      return Object.assign({}, state, {
        isFetching: true,
      });
    }
    case DetailsActions.RECEIVE_DETAILS: {
      return Object.assign({}, state, {
        detailsList: action.payload,
        isFetching: false,
      });
    }
    case DetailsActions.POST_DETAILS: {
      const listToUpdate: number[] = Object.assign([], state.processRequestsSubmitted);
      if (
        action.payload &&
        action.payload.requestItems[2] &&
        action.payload.requestItems[2] &&
        action.payload.requestItems[2].roles &&
        action.payload.requestItems[2].roles[0].id
      ) {
        listToUpdate.push(action.payload.requestItems[2].roles[0].id);
      }
      return Object.assign({}, state, {
        processRequestsSubmitted: listToUpdate,
        isSubmitting: true,
        isSuccessfullySubmitted: false,
      });
    }

    case DetailsActions.CLEAR_REQUEST_CART: {
      return Object.assign({}, state, {
        processRequestsSubmitted: [],
      });
    }

    case DetailsActions.POST_SUCCESS_DETAILS: {
      return Object.assign({}, state, {
        isSubmitting: false,
        isSuccessfullySubmitted: true,
      });
    }
    case DetailsActions.POST_COMPLETED: {
      return Object.assign({}, state, {
        isSuccessfullySubmitted: false,
      });
    }
    case DetailsActions.PUT_DETAILS: {
      return Object.assign({}, state, {
        isSubmitting: true,
        isSuccessfullySubmitted: false,
      });
    }
    case DetailsActions.PUT_SUCCESS_DETAILS: {
      return Object.assign({}, state, {
        isSubmitting: false,
        isSuccessfullySubmitted: true,
      });
    }
    // TODO this had a constant defined, but no corresponding action
    // case DetailsActions.PUT_COMPLETED: {
    //   return Object.assign({}, state, {
    //     isSuccessfullySubmitted: false
    //   });
    // }
    case DetailsActions.PUT_COMMENT: {
      return Object.assign({}, state, {
        isAddingComment: true,
      });
    }
    case DetailsActions.PUT_SUCCESS_COMMENT: {
      const updatedProcess: ProcessDetails = new ProcessDetails();
      updatedProcess.approvalTasks = state.detailsList.approvalTasks.slice();
      updatedProcess.requestDetails = state.detailsList.requestDetails;
      updatedProcess.processComments = state.detailsList.processComments.slice();
      updatedProcess.processComments.push(action.payload.comment);

      return Object.assign({}, state, {
        detailsList: updatedProcess,
        isAddingComment: false,
      });
    }

    case DetailsActions.PUT_COMMENT_COMPLETED: {
      return Object.assign({}, state, {
        // noop
      });
    }
    case DetailsActions.ERROR_DETAILS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      // console.error('Details Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
        isSubmitting: false,
      });
    }

    default: {
      return state;
    }
  }
};
