import { Pipe, PipeTransform } from '@angular/core';
import { OrgModel } from '@starfish-access/models';

@Pipe({ name: 'sortOrgModels' })
export class SortArrayOfOrgModelsPipe implements PipeTransform {
  transform(arr: Array<OrgModel>): OrgModel[] {
    if (!arr) {
      return [];
    }
    return arr.sort((a, b) => a.name.localeCompare(b.name));
  }
}
