import { InnerTaskComment } from './details.model';

export interface DialogMessage {
  dialogTitle: string;
  dialogMsg: string;
}

export interface TaskDetails {
  taskId: string;
  processInstanceId: string;
  taskName: string;
  taskStatus: string;
  requester: string;
  assignedTo: string[];
  approvedBy: string;
  claimTime: string;
  endTime: string;
  comment: string[];
}

export interface UpdateApproval {
  taskAction: string;
  taskComment: string;
  additionalInfoUser: string;
}

export interface CompleteApproval {
  taskDetails: TaskDetails;
  taskAction: string;
  taskReassignedTo: string;
  newestComment: string;
}

export interface ApprovalWorkFlowDisplay {
  name: string;
  status: string;
  timeCompleted: string;
  completedBy: string;
  comment: string;
}

export class DisplayComment {
  author: string;
  comment: string;
  commentDate: string;
}

export class DisplayTask {
  taskName: string;
  taskStatus: string;
  taskComment: InnerTaskComment;
  taskCommentBy: string;
  taskCommentOn: string;
  taskApprover: string;
  taskCompleted: string;
}

export class TaskIconDisplay {
  iconName: string;
  iconColorClass: string;
}
