import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { ClientAuditTask, Tasks } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { handleErrorFromRest } from '../utils/utils.model';

interface ServerTasks {
  taskId: string;
  taskName: string;
  taskStatus: string;
  auditedUser: {
    userId: string;
    displayName: string;
  };
  auditedRole: string;
  auditedRequestId: string;
  auditAssignedTo: string;
  taskCreatedTime: string;
}

interface ServerAuditTask {
  auditTaskId: string;
  auditProcessInstanceId: string;
  taskName: string;
  taskStatus: string;
  auditedUser: {
    userId: string;
    displayName: string;
  };
  auditedRole: string;
  auditedRequestId: string;
  auditAssignedTo: string;
  auditCompletedBy: string;
  claimTime: number;
  endTime: number;
  taskComments: [
    {
      taskComment: string;
      userCommented: string;
      timeCommented: number;
    }
  ];
}

export type AuditTaskActionType = 'KEEP' | 'REMOVE' | 'CHANGE';

@Injectable()
export class TaskService {
  private static endPoint = 'tasks';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  postAuditTask(auditType: AuditTaskActionType, taskId: string): Observable<Response> {
    // build the URL
    const url = this.starfishConfig.starfishServices + 'audit/tasks/' + taskId + '?action=' + auditType;
    return this.http
      .post<Response>(
        url,
        {},
        {
          headers: this.headers,
        }
      )
      .pipe(catchError(handleErrorFromRest));
  }

  getTasks(): Observable<Tasks[]> {
    // build the URL
    TaskService.URL = this.starfishConfig.starfishServices + TaskService.endPoint;
    return this.http
      .get<Tasks[]>(TaskService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  getAuditTasks(): Observable<Tasks[]> {
    // get ALL audit tasks
    TaskService.URL = this.starfishConfig.starfishServices + 'audit/tasks';

    return this.http
      .get<ServerTasks[]>(TaskService.URL, {
        headers: this.headers,
      })
      .pipe(
        map((res) => res.map(this.toTask)),
        catchError(handleErrorFromRest)
      );
  }

  getAuditTask(taskId: string): Observable<ClientAuditTask> {
    // get one audit task by ID audit/tasks/2342221
    TaskService.URL = this.starfishConfig.starfishServices + 'audit/tasks/' + taskId;

    return this.http
      .get<ServerAuditTask>(TaskService.URL, {
        headers: this.headers,
      })
      .pipe(map(this.toClientTask), catchError(handleErrorFromRest));
  }

  isUserDataSteward(userid: string): Observable<boolean> {
    // build the URL
    TaskService.URL = this.starfishConfig.starfishServices + 'users/' + userid + '/isUserDataSteward';
    return this.http
      .get<boolean>(TaskService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  private toClientTask(server: ServerAuditTask): ClientAuditTask {
    return {
      roleName: server?.auditedRole ? server.auditedRole : '',
      userDisplayName: server?.auditedUser?.displayName ? server.auditedUser.displayName : '',
      userId: server?.auditedUser?.userId ? server.auditedUser.userId : '',
      taskName: server.taskName ? server.taskName : '',
    };
  }

  private toTask(server: ServerTasks): Tasks {
    return {
      taskId: server.taskId,
      taskName: server.taskName,
      requester: server.auditedUser.userId,
      processInstanceId: server.auditedRole,
      assignee: server.auditAssignedTo,
      taskCreatedTime: server.taskCreatedTime,
      taskStatus: server.taskStatus,
    };
  }
}
