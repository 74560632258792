import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OkayDialogService } from '@starfish-access/core';
import { clone, reject } from 'ramda';
import { of, Subject } from 'rxjs';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { Delegation, RoleConfig, SelectableRole } from '../../../core/model/delegation.model';
import { DelegationFacade } from '../delegation.facade';

@Component({
  selector: 'sf-delegation-edit',
  templateUrl: './delegation-edit.component.html',
  styleUrls: ['./delegation-edit.component.scss'],
})
export class DelegationEditComponent implements OnDestroy, OnChanges {
  @Input() delegation: Delegation;
  @Input() roles: SelectableRole[];
  delegationFormGroup: UntypedFormGroup;

  private destroy$ = new Subject<void>();

  constructor(
    public facade: DelegationFacade,
    private dialogService: OkayDialogService,
    private fb: UntypedFormBuilder
  ) {
    this.delegationFormGroup = this.fb.group({ delegation: [[]] });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.delegation) {
      this.delegationFormGroup.get('delegation')?.patchValue(this.delegation);
      this.delegationFormGroup.markAsPristine();
    }
  }

  // To delete a role, we need the delegation id (unique to the delegation group) and the role
  // delegation id ( roleDelegationConfig.roleDelegationId), which is unique to a specific role inside of a delegation group, but different from a role's unique id.
  markRoleAsDeleted(roleDelegationConfig: RoleConfig): void {
    if (!this.delegation.id) {
      return;
    }

    this.facade
      .removeDelegationRole(this.delegation.id.toString(), roleDelegationConfig.roleDelegationId?.toString(), false)
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((numOfAssignments: number | boolean) => {
          if (typeof numOfAssignments === 'boolean') {
            // if we are here, a non-409 network error has occurred
            return of(false);
          }

          return this.facade.confirmRoleDeletion(roleDelegationConfig.displayName, numOfAssignments);
        }),
        catchError((err) => this.dialogService.okay('Group Not Updated', 'We could not update your group at this time'))
      )
      .subscribe((res) => {
        if (res && roleDelegationConfig) {
          this.deletionConfirmation(roleDelegationConfig);
        }
      });
  }

  saveUpdates(id: number | undefined): void {
    const del: Delegation = this.delegationFormGroup.get('delegation')?.value;

    del.id = id;
    if (del) {
      this.facade
        .updateDelegation(del)
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) =>
            this.dialogService.okay('Group Not Updated', 'We could not update your group at this time')
          )
        )
        .subscribe((res) => {
          const created = res === null;
          if (created || (res && (res as any).id > 0)) {
            const updatedDelegation: any = res; // Delegation
            this.ngOnChanges(updatedDelegation); // update the form with the response of the post
            this.delegationFormGroup.markAsPristine();
            this.dialogService.okay('Delegation Group Updated', 'Successfully updated the delegation group.');
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private deletionConfirmation(roleConfigToDelete: RoleConfig) {
    const formDelModel: Delegation = this.delegationFormGroup.get('delegation')?.value;

    const isMarkedForDeletion = (rc: RoleConfig) => {
      if (rc.roleDelegationId && roleConfigToDelete.roleDelegationId) {
        return rc.roleDelegationId === +roleConfigToDelete.roleDelegationId;
      }
      return rc.displayName === roleConfigToDelete.displayName;
    };

    const rcList = clone(formDelModel.roleConfigs);
    const newRcList = reject(isMarkedForDeletion, rcList);

    formDelModel.roleConfigs = newRcList;

    // update the form with the new list, having deleted the previous entries
    this.delegationFormGroup.get('delegation')?.patchValue(formDelModel);
  }
}
