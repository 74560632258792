import { Injectable } from '@angular/core';
import { AuditTaskActionType, handleErrorFromRest, TaskService } from '@starfish-access/core';
import { ClientAuditTask } from '@starfish-access/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

@Injectable()
export class AuditFacade {
  isFetching$ = new BehaviorSubject<boolean>(false);
  fetchingDetails$ = new BehaviorSubject<boolean>(false);

  constructor(private service: TaskService) {}

  updateRoleAudit(auditType: AuditTaskActionType, taskId: string): Observable<boolean> {
    this.isFetching$.next(true);
    return this.service.postAuditTask(auditType, taskId).pipe(
      catchError(handleErrorFromRest),
      map(() => true),
      finalize(() => {
        this.isFetching$.next(false);
      })
    );
  }

  getAuditTaskDetails(taskId: string): Observable<ClientAuditTask> {
    this.fetchingDetails$.next(true);
    return this.service.getAuditTask(taskId).pipe(
      catchError(handleErrorFromRest),
      finalize(() => {
        this.fetchingDetails$.next(false);
      })
    );
  }
}
