<h2 mat-dialog-title>Are you sure?</h2>
<mat-dialog-content class="mat-typography">
  <p>Editing any relationships in this Delegation Group will update all existing assignments.</p>
  <p>No changes are final until you save this delegation group.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    data-cy="ok"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    OK
  </button>
</mat-dialog-actions>
