import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { UserInfo } from '@starfish-access/models';

export interface ConnectedStudentForm {
  users: UserInfo[];
  bulkusers: MultiStudentUserForm;
}
export interface MultiStudentUserForm {
  connectedUsers: string; // a /n delimited list of user ids
  studentUpdateType: 'MERGE' | 'REPLACE';
}

@Component({
  selector: 'sf-multi-student',
  templateUrl: './multi-student.component.html',
  styleUrls: ['./multi-student.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiStudentComponent),
      multi: true,
    },
  ],
})
export class MultiStudentComponent implements ControlValueAccessor, OnInit {
  @Input() formControl: UntypedFormControl;
  formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      connectedUsers: [],
      studentUpdateType: ['MERGE'],
    });
  }

  writeValue(obj: MultiStudentUserForm): void {
    if (obj != null) {
      this.formGroup.patchValue(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.subscribe((value: string[]) => {
      this.propagateChange(value);
    });
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
