import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OkayDialogService } from '@starfish-access/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ResourcesApprovalActions from './resources.actions';
import { ResourcesApprovalService } from './resources.service';

@Injectable()
export class ResourcesApprovalEffects {
  request$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ResourcesApprovalActions.RequestFailedJobs>(ResourcesApprovalActions.REQUEST_RESOURCE),
      switchMap((taskId) =>
        this.resourcesService.getDetails(taskId.payload).pipe(
          map((resourcesApproval) => new ResourcesApprovalActions.ReceiveFailedJobs(resourcesApproval)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ResourcesApprovalActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  post$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<ResourcesApprovalActions.PostTaskDetails>(ResourcesApprovalActions.POST_TASK_DETAILS),
      switchMap((taskDetails) =>
        this.resourcesService.updatedTaskbyId(taskDetails.payload).pipe(
          map(() => new ResourcesApprovalActions.PostTaskDetailsSuccess()),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ResourcesApprovalActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private resourcesService: ResourcesApprovalService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      const message = 'Error retrieving information from a required Starfish service.';
      this.dialogService.okay('Starfish DUS Error', message);
    }
  }
}
