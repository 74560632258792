import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../../features/roles/roles.model';

@Pipe({ name: 'isSteward' })
export class IsStewardPipe implements PipeTransform {
  transform(usersRole: User[], userId: string): boolean {
    if (!usersRole || !userId) {
      return false;
    }

    for (const user of usersRole) {
      if (user.userId === userId) {
        return true;
      }
    }
    return false;
  }
}
