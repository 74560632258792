import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ClientCourseChannelResponse, SubjectAndSection } from '@starfish-access/models';
import { clone, difference } from 'ramda';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { CourseListFacade } from '../course-list/course-list.facade';

interface SectionDisplay {
  value: ClientCourseChannelResponse;
  viewValue: string;
}

export interface Selections {
  subject: string;
  option: string;
}

@Component({
  selector: 'sf-unmapped-courses-display',
  templateUrl: './unmapped-courses-display.component.html',
  styleUrls: ['./unmapped-courses-display.component.scss'],
})
export class UnmappedCoursesDisplayComponent implements OnInit, OnDestroy {
  dataSource: MatTableDataSource<SubjectAndSection> = new MatTableDataSource();
  displayedColumns: string[] = ['subject', 'sections'];
  sections: SectionDisplay[] = [];
  selections: Selections[] = [];

  private destroy$ = new Subject<void>();

  constructor(private facade: CourseListFacade) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.facade
      .getAllMappedCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.sections = res.map(this.toPossibleSection);
      });

    this.facade
      .getUnmappedCourses()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => (this.dataSource = new MatTableDataSource<SubjectAndSection>(res.map(this.toSubjectAndSection)))
      );
  }

  saveMappings(): void {
    const selections = this.selections;
    this.facade
      .mapCoursesDialog(selections)
      .pipe(
        mergeMap((res) => {
          if (res) {
            const courseUpdate = this.facade.updateMappedCourses(this.dataSource.data);
            return courseUpdate;
          }
          return [];
        })
      )
      .subscribe((updatedList) => {
        const originalSubjectList: string[] = clone(this.dataSource.data).map((s) => s.subject);
        const leftOvers = difference(originalSubjectList, updatedList);
        const newList: SubjectAndSection[] = leftOvers.map(this.toSubjectAndSection);
        this.dataSource = new MatTableDataSource(newList);
      });
  }

  launchDialog(res: ClientCourseChannelResponse): void {
    this.facade.subjectInfoDialog(res);
  }

  onServiceSelection(subject: string, option: string): void {
    const selection = {
      subject,
      option,
    };

    // allow changing an existing selection
    const itemToRemove = this.selections.findIndex((item) => item.subject === subject);
    // if changed, remove original selection from the selections array
    if (itemToRemove !== -1) {
      this.selections.splice(itemToRemove, 1);
    }

    // add the original or updated selection to the array
    this.selections.push(selection);
  }

  private toSubjectAndSection(subject: string): SubjectAndSection {
    return {
      subject,
      section: undefined,
    };
  }

  private toPossibleSection(client: ClientCourseChannelResponse): SectionDisplay {
    return {
      viewValue: client.serviceDto.code,
      value: client,
    };
  }
}
