import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { SearchService } from '@starfish-access/core';
import {
  ConfiguredAssignments,
  ConfiguredOptions,
  CourseConfig,
  OrgModel,
  RoleConfig,
  UserInfo,
} from '@starfish-access/models';
import { OrgSelectItem, SEARCH_IMPL, TypeaheadSelectConfig } from '@starfish-access/shared';
import { clone } from 'ramda';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { orgModelToOrgSelectItem, orgSelectItemToOrgModel } from '../../../dynamic-role/orgs/orgs.component';
import { buildStudentList } from '../../directors.facade';
import { AssignUserComponentData, StudentSummaryReport } from '../../directors.model';
import { ConnectedStudentForm } from '../../multi-student/multi-student.component';
import { CompareUsersComponent } from '../compare-users/compare-users.component';
import { MissingUsersDialogComponent } from '../missing-users-dialog/missing-users-dialog.component';
import {
  AssignUserFacade,
  buildCourseConfig,
  compareNewAssignmentSemester,
  createValidator,
  isRejectedIdOnForm,
} from './assign-user.facade';

const emptyRc: RoleConfig = {
  courseConfig: undefined,
  displayName: '',
  roleSelectionType: 'ALL_STUDENTS',
  servicesFromRole: { selectedItems: [], selectionType: 'NONE' },
  kiosksFromRole: { selectedItems: [], selectionType: 'NONE' },
  orgsFromRole: { selectedItems: [], selectionType: 'NONE' },
  kiosksFromDelegation: { selectedItems: [], selectionType: 'NONE' },
  servicesFromDelegation: { selectedItems: [], selectionType: 'NONE' },
  orgsFromDelegation: { selectedItems: [], selectionType: 'NONE' },
  studentSupporters: [],
  connectedStudents: [],
  roleDelegationId: undefined,
  roleId: '-1',
};

@Component({
  selector: 'sf-assign-user',
  templateUrl: './assign-user.component.html',
  styleUrls: ['./assign-user.component.scss'],
  providers: [{ provide: SEARCH_IMPL, useExisting: SearchService }],
})
export class AssignUserComponent implements OnInit, OnDestroy {
  @ViewChild('compare') compareUsersComp: CompareUsersComponent;
  usersAndRoleGroup: UntypedFormGroup;
  allStudentsGroup: UntypedFormGroup;
  connectedStudentsGroup: UntypedFormGroup;
  coursesGroup: UntypedFormGroup;
  organizationsGroup: UntypedFormGroup;
  kioskAndServiceGroup: UntypedFormGroup;
  connectedUserConfig: TypeaheadSelectConfig = {
    inputAppearance: 'outline',
    inputPlaceholder: 'Search by User ID or Name',
    removableItems: true,
  };
  viewConnectedStudents$: BehaviorSubject<StudentSummaryReport> = new BehaviorSubject<StudentSummaryReport>('HIDE');
  invalidUsersRemoved$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  studentsToView$: BehaviorSubject<UserInfo[]> = new BehaviorSubject([]);
  hasAssignments$: Subject<boolean> = new BehaviorSubject(false);
  roleSelection$ = new BehaviorSubject<RoleConfig>(emptyRc);
  clonedOriginalDataSet: AssignUserComponentData;
  private preloadedId: number | undefined = undefined;
  private destroy$ = new Subject<void>();
  private transitionToSemester = '';

  constructor(
    private dialogRef: MatDialogRef<AssignUserComponent>,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: AssignUserComponentData,
    public facade: AssignUserFacade
  ) {
    this.usersAndRoleGroup = this.fb.group({
      userAndRoleCtrl: [],
    });
    this.coursesGroup = this.fb.group({
      courses: [], // ?
    });
    this.allStudentsGroup = this.fb.group({
      supporters: [], // UserInfo[]
    });
    this.connectedStudentsGroup = this.fb.group({
      connectedUsers: [], // UserInfo[]
    });
    this.connectedStudentsGroup.setAsyncValidators(createValidator(this.facade, true));

    this.organizationsGroup = this.fb.group({
      orgsCtrl: [
        {
          orgs: [],
        },
      ],
    });
    this.kioskAndServiceGroup = this.fb.group({
      servicesAndKiosksCtrl: [
        {
          kiosks: [],
          services: [],
        },
      ],
    });

    this.clonedOriginalDataSet = clone(this.data);
  }

  ngOnInit(): void {
    this.facade.transitionSemester$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.transitionToSemester = res));

    this.usersAndRoleGroup
      .get('userAndRoleCtrl')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const rc: RoleConfig = res.roles[0];
        this.roleSelection$.next(rc);
        this.loadServicesAndKiosks(rc);
        this.loadCourseConfig(rc);
        this.loadOrgs(rc);
      });

    this.connectedStudentsGroup.get('connectedUsers')?.valueChanges.subscribe((val) => {
      this.compareUsersComp?.updateComparisons(val);
    });

    if (this.data.preloadedAssignment) {
      this.hasAssignments$.next(true);
      // This is a previously created assignment
      const ca: ConfiguredAssignments = this.data.preloadedAssignment;
      this.preloadedId = ca.assignmentId;
      // {users: Array, roles: Array}
      this.usersAndRoleGroup.get('userAndRoleCtrl')?.patchValue({
        users: [ca.user],
        roles: [ca.role],
      });

      const roles: RoleConfig[] = this.data?.delegation?.delegation?.roleConfigs
        ? this.data?.delegation?.delegation?.roleConfigs
        : [];

      roles.forEach((element) => {
        if (element.roleId === ca.role.roleId) {
          const combinedCourseConfig = buildCourseConfig(element.courseConfig, ca.options.courseConfig);
          if (combinedCourseConfig) {
            this.coursesGroup.get('courses')?.patchValue(combinedCourseConfig);
          }
        }
      });
      // {services: Array, kiosks: Array}
      this.kioskAndServiceGroup.get('servicesAndKiosksCtrl')?.patchValue({
        services: ca?.options?.servicesConfig?.selectedItems
          ? ca?.options?.servicesConfig?.selectedItems.map(orgModelToOrgSelectItem)
          : [],
        kiosks: ca?.options?.kiosksConfig?.selectedItems
          ? ca?.options?.kiosksConfig?.selectedItems.map(orgModelToOrgSelectItem)
          : [],
      });

      this.allStudentsGroup.get('supporters')?.patchValue(ca?.options?.studentSupporters);

      this.connectedStudentsGroup.get('connectedUsers')?.patchValue({ users: ca?.options?.connectedStudents });

      const orgs: OrgSelectItem[] = ca?.options?.orgsConfig?.selectedItems.map(orgModelToOrgSelectItem);
      this.organizationsGroup.get('orgsCtrl')?.patchValue({
        orgs,
      });

      if (ca.role) {
        this.roleSelection$.next(ca.role);
      }
    }
  }

  goForward(stepper: MatStepper) {
    this.usersAndRoleGroup.get('userAndRoleCtrl')?.markAllAsTouched();
    stepper.next();
  }

  viewConnectedStudents(stepper: MatStepper, type: StudentSummaryReport, students?: UserInfo[]) {
    this.connectedStudentsGroup.clearAsyncValidators();
    this.viewConnectedStudents$.next(type);
    if (students) {
      this.studentsToView$.next(students);
    }

    // To allow the step to be added to the dom, before we move to it.
    setTimeout(() => {
      stepper.next();
      this.connectedStudentsGroup.setAsyncValidators(createValidator(this.facade, true));
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  cancel(): void {
    this.dialogRef.close(undefined);
  }

  openDialog(missingUsers: UserInfo[]): void {
    this.invalidUsersRemoved$.next(false);
    const dialogRef = this.dialog.open(MissingUsersDialogComponent, {
      width: '800px',
      minHeight: '300px',
      maxHeight: '800px',
      disableClose: true,
      data: { users: missingUsers },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // remove the invalid users
        const studentForm: ConnectedStudentForm = {
          users: this.connectedStudentsGroup.get('connectedUsers')?.value?.users,
          bulkusers: this.connectedStudentsGroup?.get('connectedUsers')?.value?.bulkusers,
        };

        // patch single section
        const singleIdList: UserInfo[] = studentForm.users;
        const trimmedSingleIds: UserInfo[] = isRejectedIdOnForm(singleIdList, missingUsers);
        studentForm.users = trimmedSingleIds;

        // patch bulk section
        let ids = studentForm.bulkusers?.connectedUsers ? studentForm.bulkusers?.connectedUsers : '';

        // we have a list of users that aren't in search service (missingUsers)
        // from that list, we should remove them from the text area that contains all of the
        // bulk user ids
        ids = ids
          .split('\n') // split the textarea into a list delimited by newlines
          .filter((i) => {
            // filter out any ids found in missingUsers
            const id = i.trim();
            return !missingUsers.some((userInfo) => {
              // id might contain a trailing '@psu.edu'
              const missingUserId = userInfo.id.trim();
              return id === missingUserId || id === `${missingUserId}@psu.edu`;
            });
          })
          .join('\n'); // join the resulting array's elements with newlines

        if (studentForm.bulkusers?.connectedUsers) {
          studentForm.bulkusers.connectedUsers = ids;
        }

        // we've completed removing the invalid users we are done
        this.invalidUsersRemoved$.next(true);

        // patch in the updated form with removed invalid users
        this.connectedStudentsGroup.get('connectedUsers')?.patchValue(studentForm);
      }
    });
  }

  complete(): void {
    const caList: ConfiguredAssignments[] = [];
    const selectedUsers: UserInfo[] = this.usersAndRoleGroup?.get('userAndRoleCtrl')?.value?.users;

    selectedUsers.forEach((user) => {
      // we should already be in this format - this might not be necessary

      // TODO https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/508
      let usersList = this.connectedStudentsGroup?.get('connectedUsers')?.value?.users;
      if (!usersList) {
        usersList = this.connectedStudentsGroup?.get('connectedUsers')?.value;
      }

      const studentForm: ConnectedStudentForm = {
        users: usersList,
        bulkusers: this.connectedStudentsGroup?.get('connectedUsers')?.value?.bulkusers,
      };

      const connStudents: UserInfo[] = buildStudentList(studentForm);
      const userKiosks: OrgModel[] = this.kioskAndServiceGroup
        ?.get('servicesAndKiosksCtrl')
        ?.value?.kiosks.map(orgSelectItemToOrgModel);
      const userServices: OrgModel[] = this.kioskAndServiceGroup
        ?.get('servicesAndKiosksCtrl')
        ?.value?.services.map(orgSelectItemToOrgModel);
      const userOrgs: OrgModel[] = this.organizationsGroup?.get('orgsCtrl')?.value?.orgs.map(orgSelectItemToOrgModel);

      const courseInfo: CourseConfig = this.coursesGroup.get('courses')?.value;
      const suppStudents: UserInfo[] = this.allStudentsGroup?.get('supporters')?.value;

      const rc: RoleConfig = this.usersAndRoleGroup?.get('userAndRoleCtrl')?.value?.roles[0];
      const options: ConfiguredOptions = {
        courseConfig: courseInfo,
        kiosksConfig: {
          selectionType: rc.kiosksFromDelegation.selectionType,
          selectedItems: userKiosks,
        },
        servicesConfig: {
          selectionType: rc.servicesFromDelegation.selectionType,
          selectedItems: userServices,
        },
        orgsConfig: {
          selectionType: rc.orgsFromDelegation.selectionType,
          selectedItems: userOrgs,
        },
        studentSupporters: suppStudents ? suppStudents : [],
        connectedStudents: connStudents ? connStudents : [],
      };

      const ca: ConfiguredAssignments = {
        assignmentId: this.preloadedId,
        calendarManagementEnabled: true, // this is unused on the outgoing model
        options,
        role: rc,
        user,
        hasBeenModified: true,
        transitionState: compareNewAssignmentSemester(options?.courseConfig?.semester, this.transitionToSemester),
      };
      caList.push(ca);
    });

    this.dialogRef.close(caList);
  }

  private loadCourseConfig(rc: RoleConfig): void {
    if (!rc) {
      return;
    }
    this.coursesGroup.get('courses')?.patchValue(rc.courseConfig);
  }

  private loadServicesAndKiosks(rc: RoleConfig) {
    this.kioskAndServiceGroup.get('servicesAndKiosksCtrl')?.patchValue({
      services: rc?.servicesFromDelegation?.selectedItems
        ? rc.servicesFromDelegation?.selectedItems.map(orgModelToOrgSelectItem)
        : [],
      kiosks: rc?.kiosksFromDelegation?.selectedItems
        ? rc.kiosksFromDelegation?.selectedItems.map(orgModelToOrgSelectItem)
        : [],
    });
  }

  private loadOrgs(rc: RoleConfig) {
    this.organizationsGroup.get('orgsCtrl')?.patchValue({
      orgs: rc?.orgsFromDelegation?.selectedItems
        ? rc?.orgsFromDelegation?.selectedItems.map(orgModelToOrgSelectItem)
        : [],
    });
  }
}
