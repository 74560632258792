import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { KioskSvc } from '../model/kiosk-services.model';
import { Kiosk } from '../model/kiosks.model';
import { OrganizationFile, RelationshipOrgModel } from '../model/starfish-organizations.model';
import { ServerService } from './orgs.models';

export const orgFileToKiosk = (orgFile: OrganizationFile): Kiosk => ({
  id: orgFile.organizationId,
  name: orgFile.organizationName,
  description: 'not implemented yet',
  parentId: orgFile.parentOrgId ? orgFile.parentOrgId : undefined,
});

export const orgFilesToKiosks = (orgFiles: OrganizationFile[]): Kiosk[] => orgFiles.map(orgFileToKiosk);

export const serverToService = (serverService: ServerService): KioskSvc => ({
  id: serverService.organizationId,
  name: serverService.organizationName,
  description: '',
  parentId: undefined,
});

export const serversToServices = (serverServices: ServerService[]) => serverServices.map(serverToService);

export const orgFileToService = (orgFile: OrganizationFile): KioskSvc => ({
  id: orgFile.organizationId,
  name: orgFile.organizationName,
  description: 'not implemented yet',
  parentId: orgFile.parentOrgId ? orgFile.parentOrgId : undefined,
});

export const orgFilesToServices = (orgFiles: OrganizationFile[]): KioskSvc[] => orgFiles.map(orgFileToService);

export const orgFileToRelationshipOrg = (orgFiles: OrganizationFile[]): RelationshipOrgModel[] =>
  orgFiles.map((orgFile) => ({
    displayName: orgFile.organizationName,
    orgId: orgFile.organizationId,
  }));

export const kioskToOrgFile = (kiosk: Kiosk): OrganizationFile => ({
  organizationId: kiosk.id,
  organizationName: kiosk.name,
  organizationIntegrationId: kiosk.id,
  parentOrgId: kiosk.parentId ? kiosk.parentId : undefined,
});

export const serviceToOrgFile = (service: KioskSvc): OrganizationFile => ({
  organizationId: service.id,
  organizationName: service.name,
  organizationIntegrationId: service.id,
  parentOrgId: service.parentId ? service.parentId : undefined,
});

export const convertToKiosk = () => pipe(map(orgFileToKiosk));

export const convertToKiosks = () => pipe(map(orgFilesToKiosks));

export const convertToService = () => pipe(map(orgFileToService));

export const convertToServices = () => pipe(map(orgFilesToServices));

export const convertToRelationshipOrg = () => pipe(map(orgFileToRelationshipOrg));

export const serverModelToServices = () => pipe(map(serversToServices));
