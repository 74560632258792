import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-togglerequests',
  templateUrl: './togglerequests.component.html',
  styleUrls: ['./togglerequests.component.scss'],
})
export class TogglerequestsComponent {
  // TODO use config for all these inputs
  @Input() isViewingAllSubmissions: boolean;
  @Input() canViewAll: boolean;
  @Input() isUserSteward: boolean;
  @Input() primaryTitle = 'My Submitted Requests';
  @Input() secondaryTitle = 'All Submitted Requests';
  @Input() primaryButton = 'My Requests';
  @Input() secondaryButton = 'All Requests';
  @Output() viewEmitter: EventEmitter<void> = new EventEmitter();
  @ViewChild('toggleGroup')
  toggleGroup: MatButtonToggleGroup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly VIEW_MY = 'View My Requests';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly VIEW_ALL = 'View All Requests';
  selectedValue = this.VIEW_MY;
  constructor() {}

  setAdmin(): void {
    this.selectedValue = this.VIEW_ALL;
  }
}
