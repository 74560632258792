import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OkayDialogService, RolesService } from '@starfish-access/core';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  ErrorRoles,
  REQUEST_GROUPS,
  REQUEST_GROUP_PRIORITIES,
  ReceiveGroupPriorities,
  ReceiveGroups,
  RequestGroupPriorities,
  RequestGroups,
  UPDATE_EXISTING_ROLE_GROUPS,
  UPDATE_EXISTING_ROLE_GROUPS_SUCCESS,
  UPDATE_GROUPS,
  UPDATE_GROUPS_SUCCESS,
  UpdateExistingRoleGroup,
  UpdateExistingRoleGroupSuccess,
  UpdateGroups,
  UpdateGroupsSuccess,
} from './dynamic-role.actions';

@Injectable()
export class DynamicRolesEffects {
  requestGroupRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestGroups>(REQUEST_GROUPS),
      switchMap((res) =>
        this.rolesService.getRequestableGroups().pipe(
          map((response) => new ReceiveGroups(response)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ErrorRoles(errorMsg));
          })
        )
      )
    )
  );

  requestGroupRolePriorities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestGroupPriorities>(REQUEST_GROUP_PRIORITIES),
      switchMap((res) =>
        this.rolesService.getRoleGroupPriorities().pipe(
          map((response) => new ReceiveGroupPriorities(response)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ErrorRoles(errorMsg));
          })
        )
      )
    )
  );

  updateGroupRoles$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateGroups>(UPDATE_GROUPS),
      switchMap((res) =>
        this.roleService.updateRoleAndValidateUmg(res.payload).pipe(
          map(() => new UpdateGroupsSuccess()),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ErrorRoles(errorMsg));
          })
        )
      )
    )
  );

  updateExistingRoleGroup$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateExistingRoleGroup>(UPDATE_EXISTING_ROLE_GROUPS),
      switchMap((res) =>
        this.roleService.updateExistingRoleGroup(res.payload).pipe(
          map(() => new UpdateExistingRoleGroupSuccess()),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new ErrorRoles(errorMsg));
          })
        )
      )
    )
  );

  promptExistingRoleGroupUpdateSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateExistingRoleGroupSuccess>(UPDATE_EXISTING_ROLE_GROUPS_SUCCESS),
      mergeMap((action) =>
        this.dialogService
          .okay('Role Group Updated', 'Successfully updated this role group.')
          .pipe(map((res) => new RequestGroups()))
      )
    )
  );

  promptUpdateProgress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateGroupsSuccess>(UPDATE_GROUPS_SUCCESS),
      mergeMap((action) =>
        this.dialogService
          .okay('Role Updated', 'Successfully updated this role.')
          .pipe(map((res) => new RequestGroups()))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private rolesService: RolesService,
    private roleService: RolesService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      let message = 'Error retrieving information from a required Starfish service.';
      if (err.message && err.message.length > 0) {
        message = err.message;
      }
      this.dialogService.okay('Starfish Role Error', message);
    }
  }
}
