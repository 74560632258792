import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule, FilterModule } from '@starfish-access/core';
import { StarfishCommonModule } from '@starfish-access/shared';
import { AddOrgComponent } from './add-org/add-org.component';
import { AddSvcKioskComponent } from './add-svc-kiosk/add-svc-kiosk.component';
import { AddUserAndRoleComponent } from './add-user-and-role/add-user-and-role.component';
import { AssignUserComponent } from './assignment-form/assign-user/assign-user.component';
import { AssignUserFacade } from './assignment-form/assign-user/assign-user.facade';
import { AssignmentFormComponent } from './assignment-form/assignment-form.component';
import { CompareUsersComponent } from './assignment-form/compare-users/compare-users.component';
import { MissingUsersDialogComponent } from './assignment-form/missing-users-dialog/missing-users-dialog.component';
import { AssignmentReportComponent } from './assignment-report/assignment-report.component';
import { CalMgrKiosksComponent } from './calendar-mgr-kiosks/calendar-mgr-kiosks.component';
import { CalendarMgrComponent } from './calendar-mgr/calendar-mgr.component';
import { FindCalendarMgrComponent } from './calendar-mgr/find-calendar-mgr/find-calendar-mgr.component';
import { ConnectedStudentAddComponent } from './connected-student-add-readonly/connected-student-add-readonly.component';
import { ConnectedStudentDeleteComponent } from './connected-student-delete-readonly/connected-student-delete-readonly.component';
import { ConnectedStudentSummaryComponent } from './connected-student-summary/connected-student-summary.component';
import { ConnectedStudentComponent } from './connected-student/connected-student.component';
import { DeleteDialogComponent } from './delete-dialog.component';
import { DirectorListComponent } from './director-list/director-list.component';
import { DirectorsRoutingModule } from './directors-routing.module';
import { DirectorsComponent } from './directors.component';
import { DirectorsFacade } from './directors.facade';
import { GenerateCsvComponent } from './generate-csv/generate-csv.component';
import { MultiStudentComponent } from './multi-student/multi-student.component';
import { UserTableComponent } from './user-table/user-table.component';
@NgModule({
  declarations: [
    DirectorsComponent,
    AddUserAndRoleComponent,
    AddSvcKioskComponent,
    AddOrgComponent,
    UserTableComponent,
    CalendarMgrComponent,
    AssignmentFormComponent,
    AssignUserComponent,
    CompareUsersComponent,
    FindCalendarMgrComponent,
    ConnectedStudentComponent,
    ConnectedStudentSummaryComponent,
    ConnectedStudentAddComponent,
    ConnectedStudentDeleteComponent,
    DeleteDialogComponent,
    AssignmentReportComponent,
    DirectorListComponent,
    CalMgrKiosksComponent,
    MultiStudentComponent,
    MissingUsersDialogComponent,
    GenerateCsvComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    StarfishCommonModule,
    DirectorsRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatRadioModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FilterModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatTooltipModule,
    BrowserAnimationsModule,
  ],
  providers: [DirectorsFacade, AssignUserFacade],
})
export class DirectorsModule {}
