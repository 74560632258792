<div class="org-options">
  <h2>Relationships</h2>

  <mat-chip-listbox
    [ngClass]="{ hidden: !editMode }"
    selectable="false">
    <mat-chip-option class="edit-relationship-group">{{ relationshipType?.value | roleRelationship }}</mat-chip-option>
  </mat-chip-listbox>
  <mat-radio-group
    aria-label="Select an option"
    [ngClass]="{ hidden: editMode }"
    id="main-radio-group"
    color="primary"
    fxLayout="row"
    [formControl]="relationshipType">
    <mat-radio-button
      checked
      data-cy="ALL_STUDENTS_BTN"
      value="ALL_STUDENTS"
      >All Students</mat-radio-button
    >
    <mat-radio-button
      data-cy="ORG_RADIO_BTN"
      value="ORGANIZATION"
      >Organizations</mat-radio-button
    >
    <mat-radio-button
      [ngClass]="userRequestedSelected ? 'hide' : 'show'"
      data-cy="ONE_TO_ONE_BTN"
      value="ONE_TO_ONE"
      >1:1</mat-radio-button
    >
    <mat-radio-button
      [ngClass]="userRequestedSelected ? 'hide' : 'show'"
      value="COURSE"
      >Courses</mat-radio-button
    >
  </mat-radio-group>

  <div class="relationship-options">
    <div
      id="org-section-option"
      [ngClass]="relationshipType?.value === 'ORGANIZATION' ? 'show' : 'hide'">
      <mat-radio-group
        color="primary"
        #orgRadioDeferGroup
        class="org-radio-group"
        [formControl]="deferOrgControl"
        aria-label="Select an option for deferment"
        fxLayout="row">
        <mat-radio-button
          [value]="false"
          class="mat-body-1"
          >Assign the orgs to this role now</mat-radio-button
        >
        <mat-radio-button
          #orgMultiRadioBtn
          data-cy="multiple-selection-menu-radio"
          [value]="true"
          class="mat-body-1"
          >Let the user choose from this list</mat-radio-button
        >
      </mat-radio-group>

      <sf-org-select-input
        #orgSelect
        [possibleItems]="orgFacade.typeAheadList$ | async"
        [typeaheadErrorMatcher]="orgListStateMatcher"
        (blur)="blurHandler($event)"
        [possibleItemsLoading]="orgFacade.isLoadingData() | async"
        [config]="{
          placeholder: 'Search for an organization',
          showSearchIcon: true,
          removable: true,
          keepPanelOpen: true
        }"
        [formControl]="orgList">
        <mat-error
          data-cy="org-list-error"
          *ngIf="!orgList?.valid"
          >You have not selected any organizations</mat-error
        >
      </sf-org-select-input>

      <mat-radio-group
        color="primary"
        #orgRadioGroup
        [formControl]="orgSelectionType"
        class="org-radio-group"
        aria-label="Select an option for how many orgs can be selected"
        fxLayout="row">
        <mat-radio-button
          checked="true"
          value="SINGLE"
          class="mat-body-2"
          >Only one org can be chosen</mat-radio-button
        >
        <mat-radio-button
          #orgMultiRadioBtn
          data-cy="multiple-selection-menu-radio"
          value="MULTIPLE"
          class="mat-body-1"
          >Multiple orgs can be chosen</mat-radio-button
        >
      </mat-radio-group>
    </div>
  </div>
</div>
