import { Pipe, PipeTransform } from '@angular/core';
import { ConfiguredDelegation } from '../model/delegation.model';

@Pipe({ name: 'filterConfiguredDelegation' })
export class FilterConfiguredDelegationPipe implements PipeTransform {
  transform(delegationList: ConfiguredDelegation[], filter: string): ConfiguredDelegation[] {
    if (!delegationList || !filter) {
      return delegationList;
    }

    return delegationList.filter((d) => {
      if (d && d.delegation.name) {
        return d.delegation.name.toLowerCase().includes(filter.toLowerCase());
      }
      return '';
    });
  }
}
