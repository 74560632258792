<div class="delegation-main">
  <sf-account-search
    (onSearch)="filterSubject.next($event)"
    placeHolderText="Search by delegation name"></sf-account-search>
  <div>
    <h2 class="groups-h2">Delegation Assignment Management</h2>
    <mat-progress-bar
      *ngIf="facade.loadingDelegations$ | async"
      data-cy="save-role-progress-bar"
      mode="indeterminate"></mat-progress-bar>
    <mat-accordion>
      <mat-expansion-panel
        hideToggle
        #matRolePanel
        *ngFor="let delegation of filteredDelegations$ | async"
        [attr.data-cy]="delegation?.name + 'expansion panel'"
        class="mat-elevation-z0 exp-panel-class">
        <mat-expansion-panel-header class="exp-panel-header">
          <mat-panel-title
            fxLayoutAlign="start center"
            fxLayoutGap="8px">
            <sf-role-icon
              iconName="waves"
              [isOpen]="matRolePanel.expanded"
              [roleName]="delegation.name"></sf-role-icon>

            <div
              fxLayout="column"
              class="role-name-div">
              <span
                class="mat-subtitle-1 title-text"
                [ngClass]="{ 'accordian-selected': matRolePanel.expanded }"
                >{{ delegation.name }}</span
              >
            </div>
          </mat-panel-title>
          <mat-panel-description>
            <ng-container *ngIf="hasUnsavedWork(delegation.id)">
              <mat-icon
                svgIcon="alert-outline"
                data-cy="you-have-unsaved-work-icon"
                class="unsaved-warning"></mat-icon>
              <span
                data-cy="you-have-unsaved-work-text"
                class="unsaved-warning aligned-with-icon title-bold">
                You have unsaved work!
              </span>
            </ng-container>
            <span fxFlex></span>
            <mat-icon svgIcon="square-edit-outline"></mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <ng-template matExpansionPanelContent>
          <sf-delegation-edit
            #delFormComp
            [delegation]="delegation.id | delegationById | async"
            [roles]="createFacade.delegatableRoles$ | async">
          </sf-delegation-edit>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
