import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'courseCountPipe' })
export class UnsyncedCourseCountPipe implements PipeTransform {
  transform(count: number | null): string {
    if (!count) {
      return '0';
    }

    if (count > 9) {
      return '9+';
    }

    return count.toString();
  }
}
