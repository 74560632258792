import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '@psu/utils/security';
import { DialogService, StarfishFerpaService } from '@starfish-access/core';
import { ApprovalTask, NewComment, ProcessDetails, TaskDetails } from '@starfish-access/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AddComment, RequestDetails } from './details.actions';
import { selectDetailsListDetailsList, selectDetailsListIsFetching } from './details.reducer';

@Component({
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit, OnDestroy {
  processId = '0';
  taskDetails: Subject<TaskDetails> = new BehaviorSubject<TaskDetails>({
    approvedBy: 'loading...',
    assignedTo: ['loading...'],
    comment: ['loading...'],
    processInstanceId: 'loading...',
    taskId: 'loading...',
    taskName: 'loading...',
    requester: 'loading...',
    claimTime: 'loading...',
    endTime: 'loading...',
    taskStatus: 'loading...',
  });
  isFetchingObs: Observable<boolean>;
  hasFerpa: Observable<boolean>;
  requestItemDetailsObs: Observable<ProcessDetails>;
  userId: string;
  private destroy$ = new Subject<void>();

  constructor(
    private dialogService: DialogService,
    private store: Store<any>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private ferpaService: StarfishFerpaService
  ) {
    this.store
      .select<ProcessDetails>(selectDetailsListDetailsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.saveLoadedDetails(res));

    this.requestItemDetailsObs = this.store.select<ProcessDetails>(selectDetailsListDetailsList);
    this.isFetchingObs = this.store.select<boolean>(selectDetailsListIsFetching);

    this.hasFerpa = this.ferpaService.hasFerpa();
    this.route.params.subscribe((params) => {
      this.processId = params.processId;
    });

    const userFromToken = this.authService.getCurrentUser();
    if (userFromToken !== null) {
      this.userId = userFromToken.userName;
    }

    // this.currentUserFacade.userId$
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((res) => (this.userId = res));
  }

  // as "timeCommented":"2019-07-16T13:50:31.277Z"
  currentDateTime(): string {
    const dateTime: Date = new Date();
    const datePipe = new DatePipe('en-US');
    const commentTime: string | null = datePipe.transform(
      dateTime,
      // eslint-disable-next-line @typescript-eslint/quotes
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    );
    return commentTime || '';
  }

  ngOnInit(): void {
    this.store.dispatch(new RequestDetails(this.processId));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  newCommentDialog(): void {
    this.dialogService
      .textAreaDialog('Enter a New Comment')
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        const isUndefined: boolean = res === undefined;
        if (!isUndefined) {
          if (res.length > 0) {
            const newComment: NewComment = {
              processInstanceId: this.processId,
              comment: {
                processComment: res,
                userCommented: this.userId,
                timeCommented: this.currentDateTime(),
              },
            };
            // console.warn(this.formatDateTime(this.currentDateTime()));
            this.store.dispatch(new AddComment(newComment));
          }
        }
      });
  }

  // TODO - how else can this be done?
  saveLoadedDetails(res: ProcessDetails): void {
    const currentTask = res.approvalTasks[res.approvalTasks.length - 1];
    if (currentTask) {
      this.taskDetails.next({
        approvedBy: currentTask.taskApprovedBy,
        assignedTo: currentTask.taskAssignedTo,
        comment: this.gatherTaskComments(currentTask),
        processInstanceId: currentTask.taskId,
        taskId: currentTask.taskId,
        taskName: currentTask.taskName,
        requester: res.requestDetails.accessUser.userId,
        claimTime: currentTask.taskApprovalTime,
        endTime: currentTask.taskApprovalTime,
        taskStatus: currentTask.taskStatus,
      });
    }
  }

  private gatherTaskComments(at: ApprovalTask): string[] {
    const commentsArray: string[] = [];
    at.taskComment.forEach((commentBlock) => {
      commentsArray.push(commentBlock.taskComment);
    });
    return commentsArray;
  }
}
