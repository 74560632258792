import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { UsersRequestConfig } from '../usermanagement.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-usermgt-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
  @Output() setFilter = new EventEmitter<UsersRequestConfig>();
  @Input() userManagementFilter: UntypedFormControl;

  // used for subscriptions
  private destroy$ = new Subject<void>();

  constructor() {
    this.userManagementFilter = new UntypedFormControl('');
  }

  menuSelection(): void {
    const config: UsersRequestConfig = {
      filterString: this.userManagementFilter.value,
    };
    this.setFilter.emit(config);
  }

  clearFilter() {
    this.userManagementFilter.reset();
    this.menuSelection();
  }

  ngOnInit(): void {
    this.userManagementFilter.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((res) => {
        this.menuSelection();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
