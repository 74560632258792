import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Searcher } from '@starfish-access/shared';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { UserInfo } from '../model/delegation.model';
import { SearchResult } from '../model/server/search.model';
import { handleErrorFromRest } from '../utils/utils.model';
import { searchResultsToUserInfosPipe } from './search.service.utils';
import { headers } from './service.utils';

@Injectable({
  providedIn: 'root',
})
export class SearchService implements Searcher {
  private baseUrl = this.starfishConfig.searchService;

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getResults(searchTerm: string): Observable<UserInfo[]> {
    const url = `${this.baseUrl}/?text=${searchTerm}`;
    return this.http
      .get<SearchResult[]>(url, { headers })
      .pipe(catchError(handleErrorFromRest), searchResultsToUserInfosPipe());
  }
}
