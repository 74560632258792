import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionCheckService } from '@psu/apis/fortress';
import { ConfirmDialogService, TOOLTIP_DELAY, UserListPermissions } from '@starfish-access/core';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { STARFISH_CONFIG, StarfishConfig } from 'src/app/starfish.config';
import { NewUserComponent } from './new-user/new-user.component';
import { UserDetailsDataSource } from './user-details.datasource';
import { UserDetailsFacade } from './user-details.facade';
import {
  DelegationGroupAssignments,
  ManagerDelegations,
  RoleIdAndName,
  SearchServiceConfig,
  StarfishServiceConfig,
  Users,
  UsersRequestConfig,
} from './user-details.model';

@Component({
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  canView: Observable<boolean>;
  canDelete: Observable<boolean>;
  dataSource: UserDetailsDataSource;
  searchServiceConfig: SearchServiceConfig; // Used for adding users TO starfish
  starfishServiceConfig: StarfishServiceConfig; // Used for finding users IN starfish
  selectedStarfishUser: Users | undefined;
  canUserBeDeletedFromStarfish: boolean;
  tooltipDelay: string = TOOLTIP_DELAY;
  userExistsInStarfish = false;
  deleteUserTooltip = 'Delete User';
  existingRoles: RoleIdAndName[];
  delegationGroupAssignments$: Observable<DelegationGroupAssignments>;
  calendarManagerDelegations$: Observable<ManagerDelegations[]>;
  roleManagerDelegations$: Observable<ManagerDelegations[]>;
  private permissions: any = {};
  private destroy$ = new Subject<void>();
  private config: UsersRequestConfig = {
    pageStart: 1,
    pageTotal: 5,
  };

  constructor(
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig,
    public dialog: MatDialog,
    private permissionCheckService: PermissionCheckService,
    public facade: UserDetailsFacade,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.canView = this.permissionCheckService.hasPermission(UserListPermissions.VIEW);
    this.canDelete = this.permissionCheckService.hasPermission(UserListPermissions.DELETE);
    const searchServiceLink = this.starfishConfig.searchService;
    this.searchServiceConfig = {
      url: searchServiceLink,
      ssnSearchUrl: '',
      maxResults: 100,
      resultHeight: '48',
      sendAuth: true,
    };
    const starfishServiceUrl = this.starfishConfig.starfishServices + 'starfishUserRoles';
    this.starfishServiceConfig = {
      url: starfishServiceUrl,
      searchQueryParam: 'userid',
      resultHeight: '48',
      sendAuth: true,
      lowerCaseSearchQueryParam: true,
    };
  }

  ngOnInit(): void {
    this.canView.pipe(take(1)).forEach((next) => {
      this.permissions.canView = next;
    });
    this.canDelete.pipe(take(1)).forEach((next) => {
      this.permissions.canDelete = next;
    });

    this.dataSource = this.facade.getUsersTableDataSource();
    this.dataSource.loadUsers(this.config);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openNewUserDialog(): void {
    this.dialog.open(NewUserComponent, {
      width: '60vw',
      data: {
        adminRoleGroups: this.facade.roleGroupsWithAdminGroups$,
        dataSource: this.dataSource,
      },
      disableClose: true,
    });
  }

  openDeleteUserDialog(userId: string): void {
    this.confirmDialogService
      .confirmMessage({
        title: `You are about to delete ${userId}!`,
        message: `You are about to delete user ${userId} from the Starfish Users. This cannot be undone. Are you sure?`,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.confirmDelete(userId, res));
  }

  selectUser(user: Users) {
    this.selectedStarfishUser = user;
    this.userExistsInStarfish = true;
    this.existingRoles = user.roles;

    // The user must exist in starfish AND have no roles to allow being deleted
    if (this.existingRoles.length > 0) {
      this.canUserBeDeletedFromStarfish = false;
    } else {
      this.canUserBeDeletedFromStarfish = true;
    }

    this.delegationGroupAssignments$ = this.facade.getDelegatedRoleAssignments(this.selectedStarfishUser.userId);
    this.calendarManagerDelegations$ = this.facade.getManagerDelegations(
      this.selectedStarfishUser.userId,
      'CALENDAR_MANAGER'
    );
    this.roleManagerDelegations$ = this.facade.getManagerDelegations(this.selectedStarfishUser.userId, 'ROLE_MANAGER');
  }

  clearUser(): void {
    this.selectedStarfishUser = undefined;
    this.userExistsInStarfish = false;
    this.existingRoles = [];
  }

  private confirmDelete(userId: string, confirm: boolean) {
    if (confirm && (this.selectedStarfishUser as Users)) {
      this.dataSource.deleteUser(userId, this.config);
    }
  }
}
