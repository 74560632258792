import { Component } from '@angular/core';

@Component({
  selector: 'sf-orientation-dialog',
  templateUrl: 'sf-orientation-dialog.html',
  styleUrls: ['./sf-orientation-dialog.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class StarfishOrientationDialog {
  checked = false;
}
