import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PermissionCheckService } from '@psu/apis/fortress';
import { ConfirmDialogService, FailedJobPermissions, limitCommentSize, TOOLTIP_DELAY } from '@starfish-access/core';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OkayDialogService } from '../../core/okay-dialog/okay-dialog.service';
import { CancelJob, DeleteJob, RequestFailedJobs, RetryJob } from './failedjobs.actions';
import { FailedJob } from './failedjobs.model';
import {
  selectFailedJobsHasFailed,
  selectFailedJobsHasSucceeded,
  selectFailedJobsIsFetching,
  selectFailedJobsList,
} from './failedjobs.reducer';

@Component({
  templateUrl: './failedjobs.component.html',
  styleUrls: ['./failedjobs.component.scss'],
})
export class FailedJobsComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  canView: Observable<boolean>;
  canRetry: Observable<boolean>;
  canDelete: Observable<boolean>;
  permissions: any = {};
  displayedColumns: string[] = ['jobid', 'processid', 'exception', 'failedtime', 'retry', 'delete'];
  dataSource: MatTableDataSource<FailedJob>;
  hasFailed = false;
  isFetching: boolean;
  isSelectedToRetry: FailedJob[] = [];
  isSelectedToDelete: FailedJob[] = [];
  isSelectedToCancel: FailedJob[] = [];
  failedJobsList: Observable<FailedJob[]>;
  search = '';
  tooltipDelay: string = TOOLTIP_DELAY;
  md2: any;
  async: any;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private store: Store<any>,
    private permissionCheckService: PermissionCheckService,
    private confirmDialogService: ConfirmDialogService,
    private okayDialogService: OkayDialogService,
    public snackBar: MatSnackBar
  ) {
    this.canView = this.permissionCheckService.hasPermission(FailedJobPermissions.VIEW);
    this.canRetry = this.permissionCheckService.hasPermission(FailedJobPermissions.RETRY);
    this.canDelete = this.permissionCheckService.hasPermission(FailedJobPermissions.DELETE);

    this.failedJobsList = this.store.select<any>(selectFailedJobsList);

    this.store
      .select<FailedJob[]>(selectFailedJobsList)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dataSource = new MatTableDataSource(res);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        });
      });

    this.store
      .select<boolean>(selectFailedJobsHasFailed)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.showSuccessMessage(res, this.snackBar);
        this.hasFailed = res;
      });
    this.store
      .select<boolean>(selectFailedJobsHasSucceeded)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.showSuccessMessage(res, this.snackBar));
    this.store
      .select<boolean>(selectFailedJobsIsFetching)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.isFetching = res));
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  confirmRetry(failedJob: FailedJob, confirm: boolean) {
    if (confirm) {
      this.isSelectedToRetry.push(failedJob);
      this.store.dispatch(new RetryJob(failedJob));
    }
  }

  confirmDelete(failedJob: FailedJob, confirm: boolean) {
    if (confirm) {
      this.isSelectedToDelete.push(failedJob);
      this.store.dispatch(new DeleteJob(failedJob));
    }
  }

  confirmCancel(failedJob: FailedJob, confirm: boolean) {
    if (confirm) {
      this.isSelectedToCancel.push(failedJob);
      this.store.dispatch(new CancelJob(failedJob));
    }
  }

  isSendingRetry(jobId: string): boolean {
    for (const job of this.isSelectedToRetry) {
      if (job.jobId === jobId) {
        return true;
      }
    }
    return false;
  }

  limitExceptiontSize(msg: string, limit: number) {
    return limitCommentSize(msg, limit);
  }

  ngOnInit() {
    this.store.dispatch(new RequestFailedJobs());

    // store all permissions
    this.canDelete.pipe(take(1)).forEach((next) => {
      this.permissions.canDelete = next;
    });
    this.canRetry.pipe(take(1)).forEach((next) => {
      this.permissions.canRetry = next;
    });
    this.canView.pipe(take(1)).forEach((next) => {
      this.permissions.canView = next;
    });
    // this.getPerms();
    // test getPerm()
    // if (this.getPerm('canEdit')) {
    // console.log('TEST getPerm(\'canEdit\'): ' + this.getPerm('canEdit'));
    // }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  retryProcess(failedJob: FailedJob): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Retry Failed Job',
        message: 'Are you sure? This will resubmit the process.',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.confirmRetry(failedJob, res));
  }

  deleteJob(failedJob: FailedJob): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Delete Failed Job',
        message: 'Are you sure? This cannot be undone.',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.confirmDelete(failedJob, res));
  }

  cancelProcess(failedJob: FailedJob): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Cancel this Process?',
        message: 'Are you sure? This cannot be undone.',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => this.confirmCancel(failedJob, res));
  }

  showExceptionDetails(title: string, details: string): void {
    this.okayDialogService.okay(title, details);
  }

  showSuccessMessage(res: boolean, snackBar: MatSnackBar) {
    if (res) {
      // console.log('Operation successful!');
    } else {
      // console.warn('Operation failed, please try again.');
    }
  }
}
