import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dlg-okay-dialog',
  templateUrl: './okay-dialog.component.html',
})
export class OkayDialogComponent {
  title: string;
  message: string;
  okLabel = 'OK';

  constructor(public dialogRef: MatDialogRef<OkayDialogComponent>) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(evt: KeyboardEvent) {
    this.dialogRef.close(false);
  }
}
