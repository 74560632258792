import { Injectable, QueryList } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfirmDialogService } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { AssignmentFormComponent } from './assignment-form/assignment-form.component';
import { DirectorsComponent } from './directors.component';

@Injectable({ providedIn: 'root' })
export class DirectorsGuard {
  constructor(private dialogService: ConfirmDialogService) {}

  canDeactivate(
    component: DirectorsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const list: QueryList<AssignmentFormComponent> = component?.formGroupComponents;
    let allPassing = true;
    list.forEach((element) => {
      if (!element.formGroup.pristine) {
        allPassing = false;
      }
    });

    return allPassing
      ? true
      : this.dialogService.confirmDiscard('If you have unsaved changes, they will be lost if you leave.');
  }
}
