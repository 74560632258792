import { Inject, Injectable } from '@angular/core';
import { FortressService, PermissionCheckService } from '@psu/apis/fortress';
import { AuthService } from '@psu/utils/security';
import { StarfishFerpaService } from '@starfish-access/core';
import { clone, pickBy } from 'ramda';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FEATURES_CONFIG, FeaturesConfig } from '../../core/model/features.model';
import { NavSection } from './nav-section.model';

export const removeDisabledFeatures = (sections: NavSection[], featureConfig: FeaturesConfig): NavSection[] => {
  const enabledFeatureNames = Object.keys(pickBy((val: boolean, _key: string) => val, featureConfig));

  const filteredSections = clone(sections);

  filteredSections.forEach((section: NavSection) => {
    section.views = section.views.filter((view) =>
      view.requiredFeatureToggle
        ? enabledFeatureNames.includes(view.requiredFeatureToggle) ||
          !Object.keys(featureConfig).includes(view.requiredFeatureToggle)
        : true
    );
  });

  return filteredSections;
};

@Injectable()
export class NavListService {
  constructor(
    private authService: AuthService,
    @Inject(FEATURES_CONFIG) private featureConfig: FeaturesConfig,
    private ferpaService: StarfishFerpaService,
    private permissionCheckService: PermissionCheckService,
    private fortressService: FortressService
  ) {}

  filter(raw: NavSection[]): Observable<NavSection[]> {
    const isLoggedIn = this.authService.getCurrentUser() !== null;
    // Turn the raw sections array into an observable,
    // The resulting projection function is used to filter down the nav list.

    // return this.store.select<any>(selectMyFortressPermissions).pipe(

    const fortressPermissions$ = isLoggedIn
      ? this.fortressService
          .getMyUserPermissions(undefined, undefined, {
            cacheOptions: { cacheRequestMinutes: '60' },
          })
          .pipe(filter((p) => p !== undefined))
      : of([]);

    return fortressPermissions$.pipe(
      switchMap(() =>
        combineLatest([
          of(raw),
          of(isLoggedIn),
          // this.store
          //   .select(selectIsLoggedIn)
          //   .pipe(map((loggedIn) => !!loggedIn)),
          this.ferpaService.hasFerpa().pipe(map((hasFerpa) => !!hasFerpa)),
        ]).pipe(
          map(([sections, loggedIn, hasFerpa]) => {
            // Work on copies of the original NavSection items, so we don't lose them
            // forever if they are filtered out once.
            let result: NavSection[] = [];

            // Filter out individual views based on whether they require authentication.
            // TODO could we make this more general?  i.e. the NavView provides an
            // Observable<boolean> that we subscribe to here?  That would allow usage
            // for items where you need a specific permission.
            for (const section of sections) {
              result.push({
                heading: section.heading,
                views: (section.views || []).filter((v) => {
                  let hasPermission = true;
                  if (loggedIn) {
                    if (v.permissions) {
                      this.permissionCheckService
                        .hasPermission(v.permissions)
                        .pipe(take(1))
                        .subscribe((r) => (hasPermission = r));
                      // TODO: .subscribe doesn't block and might not run before L94
                    }
                  } else {
                    hasPermission = false;
                  }
                  return (!v.requiresAuth || loggedIn) && (!v.requiresFerpa || hasFerpa) && hasPermission;
                }),
              });
            }

            result = removeDisabledFeatures(result, this.featureConfig);

            // Finally, filter out any sections that are now empty.
            return result.filter((s) => s.views.length > 0);
          })
        )
      )
    );
  }
}
