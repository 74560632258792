import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskDetails } from '@starfish-access/models';
import * as ApproveActions from './approve.actions';

export interface ApproveState {
  hasFailed: boolean;
  isFetching: boolean;
  isUpdating: boolean;
  errorMsg: string;
  isSuccessfullySubmitted: boolean;
  taskDetails: TaskDetails;
}

const initialState: ApproveState = {
  hasFailed: false,
  isFetching: false,
  isUpdating: false,
  errorMsg: '',
  isSuccessfullySubmitted: false,
  taskDetails: {
    taskId: '',
    processInstanceId: '',
    taskName: '',
    taskStatus: '',
    requester: '',
    assignedTo: [],
    approvedBy: '',
    claimTime: '',
    endTime: '',
    comment: [],
  },
};

export const selectTaskDetails = createFeatureSelector<ApproveState>('taskDetails');
export const selectTaskDetailsHasFailed = createSelector(selectTaskDetails, (d) => d.hasFailed);
export const selectTaskDetailsIsFetching = createSelector(selectTaskDetails, (d) => d.isFetching);
export const selectTaskDetailsIsUpdating = createSelector(selectTaskDetails, (d) => d.isUpdating);
export const selectTaskDetailsError = createSelector(selectTaskDetails, (d) => d.errorMsg);
export const selectTaskDetailsIsSuccessfullySubmitted = createSelector(
  selectTaskDetails,
  (d) => d.isSuccessfullySubmitted
);
export const selectTaskDetailsTaskDetails = createSelector(selectTaskDetails, (d) => d.taskDetails);

export const taskDetailsReducer = (state = initialState, action: ApproveActions.ApproveActions): ApproveState => {
  switch (action.type) {
    case ApproveActions.REQUEST_TASK_DETAILS: {
      return Object.assign({}, state, {
        isFetching: true,
        hasFailed: false,
      });
    }
    case ApproveActions.RECEIVE_TASK_DETAILS: {
      return Object.assign({}, state, {
        taskDetails: action.payload,
        isFetching: false,
      });
    }
    case ApproveActions.POST_TASK_DETAILS: {
      return Object.assign({}, state, {
        isUpdating: true,
        isSuccessfullySubmitted: false,
      });
    }
    case ApproveActions.POST_SUCCESS_TASK_DETAILS: {
      return Object.assign({}, state, {
        isUpdating: false,
        isSuccessfullySubmitted: true,
      });
    }
    case ApproveActions.POST_COMPLETED: {
      return Object.assign({}, state, {
        isSuccessfullySubmitted: false,
      });
    }
    case ApproveActions.ERROR_TASK_DETAILS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      // console.error('Approve Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
      });
    }
    default: {
      return state;
    }
  }
};
