import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'canCancelProcess' })
export class CancelProcessPipe implements PipeTransform {
  transform(statusMessage: string): boolean {
    if (statusMessage === 'AWAITING_MANUAL_UPLOAD') {
      return false;
    }
    // Blaim Webster
    if (statusMessage === 'CANCELED') {
      return false;
    }
    if (statusMessage === 'CANCELLED') {
      return false;
    }
    if (statusMessage === 'COMPLETED') {
      return false;
    }
    if (statusMessage === 'REJECTED') {
      return false;
    }
    if (statusMessage === 'DEACTIVATED') {
      return false;
    }
    return true;
  }
}
