import { Routes } from '@angular/router';
import { DashboardComponent } from './features/dashboard.component';
import { NotFound404Component } from './not-found404.component';

export const ROUTES: Routes = [
  {
    title: 'Dashboard',
    path: '',
    component: DashboardComponent,
    canActivate: [],
    pathMatch: 'full',
  },
  { path: '**', component: NotFound404Component },
];
