import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { ClientUserPrefs } from '../../model/user-preferences.model';
import { handleErrorFromRest } from '../../utils/utils.model';

interface ServerUserPrefs {
  keepMenuOpen: boolean;
}

const headers = new HttpHeaders()
  .append('Content-Type', 'text/plain')
  .append(NO_CACHE_HEADER, 'true')
  .append(REQUIRE_AUTH_HEADER, 'true');

@Injectable()
export class UserPreferencesHttpService {
  private baseUrl = this.starfishConfig.starfishServices + 'self/uiPreferences';

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getPreferences(): Observable<ClientUserPrefs> {
    return this.http.get<ServerUserPrefs>(this.baseUrl, { headers }).pipe(
      map((res) => this.serverToClient(res)),
      catchError(handleErrorFromRest)
    );
  }

  updatePreferences(prefs: ClientUserPrefs): Observable<ClientUserPrefs> {
    return this.http
      .post<ServerUserPrefs>(this.baseUrl, this.clientToServer(prefs), {
        headers,
      })
      .pipe(
        map((res) => this.serverToClient(res)),
        catchError(handleErrorFromRest)
      );
  }

  private clientToServer(client: ClientUserPrefs): ServerUserPrefs {
    return {
      keepMenuOpen: client.keepMenuOpen,
    };
  }

  private serverToClient(server: ServerUserPrefs): ClientUserPrefs {
    return {
      keepMenuOpen: server.keepMenuOpen,
    };
  }
}
