import { InjectionToken } from '@angular/core';
import { FortressConfig } from '@psu/apis/fortress';
import { AnalyticsConfig } from '@psu/utils/analytics';
import { CdnConfig } from '@psu/utils/cdn';
import { FeaturesConfig } from './core/model/features.model';
import { StarfishConfig } from './starfish.config';
import { AuthConfigWithDiscoveryDoc } from '@psu/utils/security';

export interface AppProperties {
  oauth: AuthConfigWithDiscoveryDoc;
  fortress: FortressConfig;
  analytics: AnalyticsConfig;
  cdn: CdnConfig;
  endpoints: StarfishConfig;
  features: FeaturesConfig;
  app: {
    environment: string;
  };
}

export const APP_PROPERTIES = new InjectionToken<AppProperties>('app.properties');
