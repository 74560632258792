import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { CourseMappingDetails } from '@starfish-access/models';
import { pick } from 'ramda';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';

interface ServerCourseMappingResponse {
  taskId: string;
  processInstanceId: string; // populated but not used for this task,
  taskName: string; // always Course Mapping Service Synchronization Task,
  taskStatus: string;
  requester: string; // always null
  assignedTo: string[];
  approvedBy: string; // always null
  claimTime: string; // always null
  endTime: string; // always null
  comment: any[]; // always empty
}

interface ApproveCourseMappingTask {
  taskAction: string; // can only be 'APPROVE',
  taskComment: string;
}

@Injectable()
export class CourseMappingService {
  private static endPoint = 'tasks';
  private static detailsEndPoint = '/details';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getDetails(taskId: string): Observable<CourseMappingDetails> {
    // build the URL
    CourseMappingService.URL =
      this.starfishConfig.starfishServices +
      CourseMappingService.endPoint +
      '/' +
      taskId +
      CourseMappingService.detailsEndPoint;

    return this.http
      .get<ServerCourseMappingResponse>(CourseMappingService.URL, {
        headers: this.headers,
      })
      .pipe(
        map((res) => this.toClient(res)),
        catchError(handleErrorFromRest)
      );
  }

  approveTask(taskId: string): Observable<Response> {
    // build the URL
    const postUrl = this.starfishConfig.starfishServices + CourseMappingService.endPoint + '/' + taskId;
    const approveTask: ApproveCourseMappingTask = {
      taskAction: 'APPROVE',
      taskComment: 'Approved Course Mapping Sync',
    };

    return this.http
      .post<Response>(postUrl, approveTask, {
        headers: this.headers,
      })
      .pipe(
        map((response: Response) => response),
        catchError(handleErrorFromRest)
      );
  }

  private toClient(server: ServerCourseMappingResponse): CourseMappingDetails {
    return pick(['taskId', 'taskName', 'taskStatus'], server);
  }
}
