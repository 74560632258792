import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ClientCourseSelectionType, CourseConfig, CourseMenuItem, MenuItem } from '@starfish-access/models';
import { isEmpty } from 'ramda';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ClassConfigComponent, SelectionChange } from '../class-config/class-config.component';
import { CourseConfigFacade } from './course-config.facade';

@Component({
  selector: 'sf-course-config',
  templateUrl: './course-config.component.html',
  styleUrls: ['./course-config.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CourseConfigComponent,
      multi: true,
    },
    { provide: NG_VALIDATORS, useExisting: CourseConfigComponent, multi: true },
    CourseConfigFacade,
  ],
})
export class CourseConfigComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() courseSelectionEnabled = true; // if the course type is set to 'DEFER'
  @Input() courseDefermentOptionsEnabled = true; // if the sliders should be displayed
  @Input() courseConfigType: ClientCourseSelectionType = 'DEFER_COURSES'; // is either defer or all-courses-at-campus.
  @ViewChild('campusSelect') campusSelect: MatSelect;
  @ViewChild('trackSelect') trackSelect: MatSelect;
  @ViewChild('semesterSelect') semesterSelect: MatSelect;
  @ViewChild('classConfig') classConfigComponent: ClassConfigComponent;

  possibleSemesters$: Observable<MenuItem[]>;
  possibleSubjects$: Observable<MenuItem[]>;
  possibleCoursesMap: Map<string, Observable<CourseMenuItem[]>> = new Map(); // the old thing

  courseConfig: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, public facade: CourseConfigFacade) {
    this.possibleCoursesMap = new Map();

    this.courseConfig = this.fb.group({
      type: [],
      campus: ['ALL'],
      assignCampus: [true],
      educationalTrack: ['ALL'],
      assignEducationalTrack: [false],
      semester: ['ALL', [Validators.required]],
      assignSemester: [false],

      classConfigArray: this.fb.array([
        {
          value: { subject: 'ALL', course: 'ALL', section: 'ALL' },
        },
      ]),
      assignClasses: [false],
    });
  }

  get classArrayCtrl(): UntypedFormArray {
    return this.courseConfig.get('classConfigArray') as UntypedFormArray;
  }

  classConfigSelectionChanged(selectionChange: SelectionChange, index: number): void {
    if (selectionChange.field === 'SUBJECT' && selectionChange.currentValue !== 'ALL') {
      const selectedCampus = this.courseConfig.get('campus')?.value.id;
      const selectedTrack = this.courseConfig.get('educationalTrack')?.value.id;
      const selectedTermName = this.courseConfig.get('semester')?.value.id;

      const possibleObs: Observable<CourseMenuItem[]> = this.facade.getCourses(
        selectedCampus,
        selectedTrack,
        selectedTermName,
        [selectionChange.currentValue]
      );
      this.possibleCoursesMap.set(selectionChange.currentValue, possibleObs);
    }
  }

  ngOnInit(): void {
    this.courseConfig.get('educationalTrack')?.valueChanges.subscribe((value: string) => {
      this.possibleSemesters$ = this.facade.getSemestersByTrackId(value);
      this.courseConfig.get('semester')?.enable();
    });

    this.courseConfig.get('semester')?.valueChanges.subscribe((value: string) => {
      if (value && value !== 'ALL') {
        const selectedCampus = this.courseConfig.get('campus')?.value;
        const selectedTrack = this.courseConfig.get('educationalTrack')?.value;
        this.possibleSubjects$ = this.facade.getDepartments(selectedCampus, selectedTrack, value);
      }

      // https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/536
      if (this.classArrayCtrl.controls.length === 0) {
        this.defaultClassControlValue();
      }

      // if we are on step 3, and classes were already assigned, disable these options
      if (!this.courseDefermentOptionsEnabled && this.courseConfig.get('assignClasses')?.value) {
        this.classArrayCtrl.controls.forEach((element) => {
          element.disable();
        });
      }
    });

    this.courseConfig.get('assignClasses')?.valueChanges.subscribe((value: boolean) => {
      // if the value is false, and the sliders are enabled (meaning we are on step 2)
      if (!value && this.courseDefermentOptionsEnabled) {
        this.classArrayCtrl.clear();
        this.addItem();
      }
    });
    setTimeout(() => {
      this.courseConfig.valueChanges
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
        .subscribe(() => {
          this.propagateChange(this.courseConfig.getRawValue());
        });
    });

    // we only need this validator if we're on step 3
    if (!this.courseDefermentOptionsEnabled) {
      this.courseConfig.get('semester')?.setValidators([Validators.required, this.noAllValueValidator]);
    }
  }

  writeValue(obj: CourseConfig): void {
    // Add a form control to the array for each possible course being added
    const classesArrayControl: UntypedFormArray = this.courseConfig.get('classConfigArray') as UntypedFormArray;

    classesArrayControl.clear();

    if (obj?.classConfigArray) {
      obj.classConfigArray.forEach((element) => {
        classesArrayControl.push(new UntypedFormControl());
        this.possibleCoursesMap = new Map();
      });
    }

    // make sure we have at least one class control option
    if (classesArrayControl.controls.length === 0) {
      classesArrayControl.push(new UntypedFormControl());
      this.possibleCoursesMap = new Map();
    }

    if (obj) {
      this.courseConfig.patchValue(obj);
    }

    this.checkDeferment(this.courseConfig.get('assignCampus')?.value, this.courseConfig.get('campus'));
    this.checkDeferment(
      this.courseConfig.get('assignEducationalTrack')?.value,
      this.courseConfig.get('educationalTrack')
    );
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  addItem(): void {
    const fc = new UntypedFormControl();
    fc.setValue({ subject: 'ALL', course: 'ALL', section: 'ALL' });
    this.classArrayCtrl.push(fc);
  }

  deleteItem(index: number): void {
    this.classArrayCtrl.removeAt(index);
    if (this.classArrayCtrl.length === 0) {
      this.addItem();
    }
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    const errors = {
      ...Object.values(this.courseConfig.controls).reduce((acc, obj) => {
        acc = { ...acc, ...obj.errors };
        return acc;
      }, {} as ValidationErrors),
    };
    return isEmpty(errors) ? null : errors;
  }

  private noAllValueValidator(control: UntypedFormControl) {
    const containsAllKeyword = control.value === 'ALL'; // We want them to select something
    const isValid = !containsAllKeyword;
    return isValid ? null : { containsAll: true };
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});

  // check deferment options to see if we can enable this
  private checkDeferment(assignValue: boolean, control: AbstractControl | null): void {
    if (assignValue && !this.courseDefermentOptionsEnabled) {
      control?.disable();
    }
  }

  private defaultClassControlValue(): void {
    this.classArrayCtrl.controls.forEach((element) => {
      element.enable();
      element.setValue(
        {
          subject: 'ALL',
          course: 'ALL',
          section: 'ALL',
        },
        { emitEvent: false }
      );
    });
  }
}
