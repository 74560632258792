import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { GenericService } from '../model/details.model';

export class ServiceConfig {
  title?: string;
  service?: GenericService;
  saveBtnText?: string;
  disableNameInput?: boolean;
  requiredName?: string;
  forbiddenName?: string;
}

/** Alphanumerics spaces dashes and underscores should be the only thing allowed in an ID */
export const idCharacterValidator =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    const regex = /^[ a-zA-Z0-9_-]*$/g;
    const matchesAll = regex.test(control.value);
    return matchesAll
      ? null
      : {
          invalidCharacter: 'IDs can only contain letters, numbers, spaces, dashes and underscores.',
        };
  };

/** input should be soething like /kiosk/i */
export const forbiddenNameValidator =
  (nameRe: RegExp | undefined): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (!nameRe) {
      return null;
    }
    const forbidden = nameRe.test(control.value);
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };

/** input should be something like /kiosk/i */
export const requiredNameValidator =
  (nameRe: RegExp | undefined): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (!nameRe) {
      return null;
    }
    const required = nameRe.test(control.value);
    return required ? null : { requiredName: { value: control.value } };
  };

@Component({
  templateUrl: './service-dialog.component.html',
  styleUrls: ['./service-dialog.component.scss'],
})
export class ServiceDialogComponent implements OnDestroy, OnInit {
  serviceConfig: ServiceConfig = {};
  isNameValid = true;
  serviceFormGroup: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<ServiceDialogComponent>, public snackBar: MatSnackBar) {}

  get name() {
    return this.serviceFormGroup.get('name');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    const requiredRegEx: RegExp | undefined = this.serviceConfig.requiredName
      ? new RegExp(this.serviceConfig.requiredName, 'i')
      : undefined;
    const forbiddenRegEx: RegExp | undefined = this.serviceConfig.forbiddenName
      ? new RegExp(this.serviceConfig.forbiddenName, 'i')
      : undefined;

    this.serviceFormGroup = new UntypedFormGroup({
      description: new UntypedFormControl({ value: '', disabled: this.serviceConfig.disableNameInput }, [
        Validators.required,
        idCharacterValidator(),
      ]),
      name: new UntypedFormControl('', [requiredNameValidator(requiredRegEx), forbiddenNameValidator(forbiddenRegEx)]),
    });

    // if we're editing a service
    if (this.serviceConfig.service) {
      this.serviceFormGroup.patchValue({
        name: this.serviceConfig.service.name,
        description: this.serviceConfig.service.id,
      });
    }
  }

  saveChanges() {
    if (!this.serviceFormGroup.valid) {
      this.isNameValid = false;
    } else {
      this.isNameValid = true;
      if (!this.serviceConfig.service) {
        const serviceToAdd = this.serviceFormGroup.value.name;
        const testService = serviceToAdd.toLowerCase();
        // TODO(mat21) check for existing service by name
        // for (const service of this.services) {
        //   for (const name in service) {
        //     if (
        //       name === 'name' &&
        //       service[name].toLowerCase() === testService
        //     ) {
        //       this.isNameValid = false;
        //       break;
        //     }
        //   }
        // }
      }
      if (this.isNameValid) {
        const formModel = this.serviceFormGroup.value;
        const saveService: GenericService = {
          id: this.serviceConfig.service ? this.serviceConfig.service.id : (formModel.description as string),
          name: formModel.name as string,
          description: formModel.description as string,
          parentId: undefined,
        };
        this.dialogRef.close(saveService);
      } else {
        this.snackBar.open('A service named "' + this.serviceFormGroup.value.name + '" already exists', '', {
          duration: 3000,
        });
      }
    }
  }
}
