<div class="mat-typography">
  <h3 mat-dialog-title>Return request to a user</h3>
  <mat-dialog-content fxLayout="column">
    <p>Select the user below to return this request to. This action cannot be undone.</p>
    <mat-form-field appearance="outline">
      <mat-label>Select user</mat-label>
      <mat-select
        class="mat-autocomplete-width"
        [(ngModel)]="selectedValue"
        placeholder="Choose an option">
        <mat-option
          *ngFor="let user of users"
          [value]="user"
          >{{ user }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field
      fxFlex
      appearance="outline">
      <mat-label>Add in any relevant justifications for the requested roles here</mat-label>
      <textarea
        data-cy="return-to-user-dialog-input"
        matInput
        cdkTextareaAutosize
        [(ngModel)]="comment"
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        placeholder="Comment"></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions fxLayoutAlign="end center">
    <button
      type="button"
      mat-button
      color="warn"
      (click)="dialogRef.close()">
      CANCEL
    </button>
    <button
      type="button"
      data-cy="confirm-user-select"
      disabled
      [attr.disabled]="selectedValue ? null : undefined "
      mat-button
      color="primary"
      (click)="dialogRef.close({user: selectedValue, comment: comment})">
      CONFIRM
    </button>
  </mat-dialog-actions>
</div>
