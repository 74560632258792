import { createAction, props } from '@ngrx/store';
import { TypeaheadConfig } from './typeahead.config';
import { User } from './user.model';

const PREFIX = '[Typeahead]';

export const initTypeaheadComponent = createAction(`${PREFIX} Register Component`, props<{ componentId: string }>());
export const destroyTypeaheadComponent = createAction(`${PREFIX} Destroy Component`, props<{ componentId: string }>());
export const searchForTerm = createAction(
  `${PREFIX} Search For Term`,
  props<{ componentId: string; term: string; config: TypeaheadConfig }>()
);
export const searchForTermComplete = createAction(
  `${PREFIX} Search For Term Complete`,
  props<{ componentId: string; results: User[] }>()
);
export const searchForTermError = createAction(
  `${PREFIX} Search For Term Error`,
  props<{ componentId: string; error: string }>()
);
export const clearSearchInput = createAction(`${PREFIX} Clear Search Input`, props<{ componentId: string }>());
