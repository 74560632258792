import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { AuthService, REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';

const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append(NO_CACHE_HEADER, 'true')
  .append(REQUIRE_AUTH_HEADER, 'true');

@Injectable()
export class StarfishFerpaService {
  private personExistsCache: boolean | undefined = undefined;
  private ferpaResponseCache: boolean | undefined = undefined;

  constructor(
    private http: HttpClient,
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig,
    private authService: AuthService
  ) {}

  personExists(): Observable<boolean> {
    if (!this.personExistsCache) {
      const loggedInUser = this.authService.getCurrentUser();
      if (loggedInUser) {
        const searchUrl = this.starfishConfig.directoryServices + 'people/' + loggedInUser.userName;

        return this.http.get<any>(searchUrl, { headers }).pipe(
          map((res) => {
            if (res && res['user-id']) {
              this.personExistsCache = true;
              return true;
            }
            this.personExistsCache = false;
            return false;
          }),
          catchError((e) => {
            this.personExistsCache = false;
            return of(false);
          })
        );
      } else {
        return of(false); // user is not logged in
      }

      // return this.currentUserFacade.userId$.pipe(
      //   mergeMap((userId) => {
      //     const searchUrl =
      //       this.starfishConfig.directoryServices + 'people/' + userId;
      //     return this.http
      //       .get<any>(searchUrl, { headers })
      //       .pipe(
      //         map((res) => {
      //           if (res && res['user-id']) {
      //             this.personExistsCache = true;
      //             return true;
      //           }
      //           this.personExistsCache = false;
      //           return false;
      //         }),
      //         catchError((e) => {
      //           this.personExistsCache = false;
      //           return of(false);
      //         })
      //       );
      //   })
      // );
    } else {
      return of(this.personExistsCache);
    }
  }

  hasFerpa(): Observable<boolean> {
    if (this.ferpaResponseCache === undefined) {
      const loggedInUser = this.authService.getCurrentUser();

      if (loggedInUser) {
        const searchUrl = this.starfishConfig.directoryServices + 'people/' + loggedInUser.userName;

        return this.http.get<any>(searchUrl, { headers }).pipe(
          map((res) => {
            if (res && res['ps-ferpa-exam']) {
              this.ferpaResponseCache = res['ps-ferpa-exam'];
              return res['ps-ferpa-exam'];
            }
            return false;
          }),
          catchError(() => {
            this.ferpaResponseCache = false;
            return of(false);
          })
        );
      } else {
        return of(false); // user is not logged in
      }
      //   const searchUrl =
      //     this.starfishConfig.directoryServices + 'people/' + userId;
      //   return this.http
      //     .get<any>(searchUrl, { headers })
      //     .pipe(
      //       map((res) => {
      //         if (res && res['ps-ferpa-exam']) {
      //           this.ferpaResponseCache = res['ps-ferpa-exam'];
      //           return res['ps-ferpa-exam'];
      //         }
      //         return false;
      //       }),
      //       catchError(() => {
      //         this.ferpaResponseCache = false;
      //         return of(false);
      //       })
      //     );
      // })
      // );
    } else {
      return of(this.ferpaResponseCache);
    }
  }
}
