import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
  transform(array: any[], query: string, field: string, caseInsensitive = true): any {
    // console.log(array, query, field, caseInsensitive);
    if (field) {
      if (query) {
        query = caseInsensitive ? query.toLocaleLowerCase() : query;
        return array.filter((value: any, index: number, arr: any) =>
          this.matches(value[field], query, caseInsensitive)
        );
      }
    }
    return array;
  }

  private matches(value: any, query: string, caseInsensitive: boolean): boolean {
    value = caseInsensitive ? value.toString().toLocaleLowerCase() : value.toString();
    return value.indexOf(query) > -1;
  }
}
