import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OkayDialogService } from '@starfish-access/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as FailedJobsActions from './failedjobs.actions';
import { FailedJobsService } from './failedjobs.service';

@Injectable()
export class FailedJobsEffects {
  request$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FailedJobsActions.RequestFailedJobs>(FailedJobsActions.REQUEST_JOBS),
      switchMap(() =>
        this.failedJobsService.getAllFailedJobs().pipe(
          map((failedJobsList) => new FailedJobsActions.ReceiveFailedJobs(failedJobsList)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new FailedJobsActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  retry$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FailedJobsActions.RetryJob>(FailedJobsActions.RETRY_JOB),
      switchMap((failedJob) =>
        this.failedJobsService.retryFailedJob(failedJob.payload).pipe(
          map(() => new FailedJobsActions.RetryJobSuccess(failedJob.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new FailedJobsActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  cancel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FailedJobsActions.CancelJob>(FailedJobsActions.CANCEL_JOB),
      switchMap((failedJob) =>
        this.failedJobsService.cancelProcess(failedJob.payload).pipe(
          map(() => new FailedJobsActions.CancelJobSuccess(failedJob.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new FailedJobsActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  delete$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<FailedJobsActions.DeleteJob>(FailedJobsActions.DELETE_JOB),
      switchMap((failedJob) =>
        this.failedJobsService.deleteFailedJob(failedJob.payload).pipe(
          map(() => new FailedJobsActions.DeleteJobSuccess(failedJob.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new FailedJobsActions.ErrorFailedJobs(errorMsg));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private failedJobsService: FailedJobsService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      // console.error(err);
      const message = 'Error retrieving information from a required Starfish service.';
      this.dialogService.okay('Starfish Failed Jobs Error', message);
    }
  }
}
