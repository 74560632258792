<span class="sf-delegation">
  <span class="header">
    <h1>Delegations</h1>
    <button
      class="create-role-btn"
      mat-flat-button
      color="primary"
      data-cy="create-new-delegation-btn"
      routerLink="create">
      Create New Delegation
    </button>
  </span>
  <sf-delegation-list #delListContainer></sf-delegation-list>
</span>
