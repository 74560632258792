<div class="mat-typography">
  <h3 matDialogTitle>Add New User</h3>
  <mat-dialog-content>
    <ta-typeahead
      data-cy="add-new-user-to-sf-users-ta"
      [config]="config"
      [placeholder]="'Start typing to search. Results will appear after three characters.'"
      [resultTemplate]="resultTemplate"
      (selected)="addNewUser($event)"
      [noResultsTemplate]="noResultsTemplate"
      [autoActiveFirstOption]="false"
      [clearButtonIconTemplate]="clearButtonIconTemplate"
      [showSearchButton]="true"
      [showMatPrefix]="false"
      #ta1>
    </ta-typeahead>

    <ng-container *ngIf="existingRoles && (existingRoles | async) as existingRolesValue">
      <div
        *ngIf="existingRoles && existingRolesValue?.roles?.length > 0"
        class="assigned-roles-list">
        <p class="body-bold">Assigned Roles:</p>
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let roleDetails of existingRolesValue?.roles">{{
            roleDetails.roleName
          }}</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </ng-container>
    <!-- This template is for the display of results in autocomplete overlay -->
    <ng-template
      #resultTemplate
      let-result>
      <span class="display-flex">
        <span class="result-content">
          <span>{{ result.displayName }} ({{ result.userid }})</span>
        </span>
      </span>
    </ng-template>
    <ng-template #noResultsTemplate> No Results Found </ng-template>
    <ng-template #clearButtonIconTemplate>
      <mat-icon (click)="clearUser()">clear</mat-icon>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-button
      data-cy="cancel-user-add-btn"
      color="primary"
      (click)="dialogRef.close()">
      Cancel
    </button>
    <button
      mat-flat-button
      data-cy="save-user-add-btn"
      color="primary"
      (click)="saveNewUser()"
      [disabled]="disableSaveAction">
      Save
    </button>
  </mat-dialog-actions>
</div>
