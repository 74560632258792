import { Injectable } from '@angular/core';
import { AuthService } from '@psu/utils/security';
import { DelegateService, RolesService, UserAccountService } from '@starfish-access/core';
import { ClientRoleGroup, DelegationMetaData, Role, RoleDetails } from '@starfish-access/models';
import { BehaviorSubject, Observable, finalize, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DashboardFacade {
  loadingMyRoles$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  loadingDelegations$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  readonly myRoles$: Observable<RoleDetails[]> = this.userAccountService
    .getUserRoles(this.authService.getCurrentUser()?.userName as any)
    .pipe(
      map((res) => res.sort((a, b) => a.requestedRole.localeCompare(b.requestedRole))),
      finalize(() => this.loadingMyRoles$.next(false))
    );

  constructor(
    private authService: AuthService,
    private delegateService: DelegateService,
    private rolesService: RolesService,
    private userAccountService: UserAccountService
  ) {}

  getRole(groupId: number, roleId: number): Observable<Role> {
    return this.userAccountService.getRole(groupId, roleId);
  }

  getAllRoleGroups(): Observable<ClientRoleGroup[]> {
    return this.rolesService.getAllRoleGroups().pipe(
      map((res) => res.sort((a, b) => a.name.localeCompare(b.name))),
      finalize(() => this.loadingDelegations$.next(false))
    );
  }

  getDelegationGroupsMetaData(): Observable<DelegationMetaData[]> {
    return this.delegateService.getAllDelegationGroupsMetaData();
  }
}
