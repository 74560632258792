<div
  fxLayout="column"
  class="workflow-component-wrapper">
  <div fxLayout="row">
    <h2>Task History</h2>
    <span fxFlex></span>
    <div fxLayoutAlign="end center">
      <button
        data-cy="sort-workflow-by-date"
        mat-button
        (click)="toggleDate()">
        Date
        <mat-icon *ngIf="!reverse">arrow_downward</mat-icon>
        <mat-icon *ngIf="reverse">arrow_upward</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="isFetchingWorkflowHistory | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <mat-card
    appearance="outlined"
    fxLayout="column"
    *ngFor="let approvalTask of workFlowItems | async | reverseList : reverse; index as i">
    <mat-card-content>
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="8px">
        <sf-workflow-icon
          class="status-icon"
          [taskStatus]="approvalTask.status">
        </sf-workflow-icon>

        <div
          fxLayout="column"
          class="role-name-div">
          <span class="task-title">{{ approvalTask.name }}</span>
          <div
            fxLayout="row"
            class="task-description">
            <span [attr.data-cy]="i + 'task-status-history'">{{ approvalTask | formatTaskStatus }}</span>
            <span *ngIf="approvalTask.completedBy">
              |
              <a
                href="{{ psuDirectoryLink }}{{ approvalTask.completedBy }}"
                target="_blank"
                >{{ approvalTask.completedBy }}</a
              ></span
            >
          </div>
        </div>
      </div>
      <div *ngIf="approvalTask.comment?.length > 0">
        <div class="divider">
          <mat-divider></mat-divider>
        </div>
        <div fxLayout="start center">
          <mat-icon class="comment-icon">chat</mat-icon>
          <span
            [attr.data-cy]="approvalTask.comment + '-task-commented-item'"
            class="comment-text"
            >{{ approvalTask.comment }}
          </span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
