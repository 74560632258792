import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CoreModule } from '@starfish-access/core';
import { TypeaheadModule } from '../../shared/typeahead/typeahead.module';
import { UserManagementModule } from '../usermanagement/usermanagement.module';
import { DetailIconComponent } from './detail-icon/detail-icon.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserDetailsRoutingModule } from './user-details-routing.module';
import { UserDetailsComponent } from './user-details.component';
import { UserDetailsFacade } from './user-details.facade';
import { UserDetailsService } from './user-details.service';

@NgModule({
  declarations: [UserDetailsComponent, NewUserComponent, DetailIconComponent],
  exports: [],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    UserDetailsRoutingModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    TypeaheadModule,
    UserManagementModule,
  ],
  providers: [UserDetailsService, UserDetailsFacade],
})
export class UserDetailsModule {}
