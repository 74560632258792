import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { TaskListComponent } from './tasklist.component';

export const routes: Routes = [
  {
    title: 'tasklist',
    path: 'tasklist',
    canActivate: [AuthGuard],
    component: TaskListComponent,
  },
];
