import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { ClientCourseChannelResponse } from '../model/course-mapping.model';
import { handleErrorFromRest } from '../utils/utils.model';

interface ServerDto {
  code: string;
  name: string;
}

interface CourseDto {
  campus: string;
  termName: string;
  track: string;
  department: string;
  courseNumber: string;
  sectionNumber: string;
  sectionId: string;
}

interface ServerCourseList {
  id: number;
  courseDto: CourseDto;
  synchronized: boolean;
}

interface ServerCourseChannelResponse {
  channelServiceId: number;
  serviceDto: ServerDto;
  assignedDepartments: string[];
  courses: ServerCourseList[];
}

@Injectable()
export class CourseChannelService implements OnDestroy {
  private static channelEndpoint = 'courseChannelServices';
  unsyncedCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');
  private destroy$ = new Subject<void>();

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {
    this.unsyncedCourseCount();
  }

  getAllCourses(): Observable<ClientCourseChannelResponse[]> {
    // build the URL
    const url = this.starfishConfig.starfishServices + CourseChannelService.channelEndpoint;

    return this.http
      .get<ServerCourseChannelResponse[]>(url, {
        headers: this.headers,
      })
      .pipe(
        map((res) => res),
        catchError(handleErrorFromRest)
      );
  }

  unsyncedCourseCount(): void {
    this.getAllCourses()
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => [])
      )
      .subscribe((res) => {
        let counter = 0;
        res?.forEach((courseChannel) => {
          courseChannel.courses?.forEach((course) => {
            if (!course.synchronized) {
              counter++;
            }
          });
        });
        this.unsyncedCounter$.next(counter);
      });
  }

  updateBatchCourses(channelList: ClientCourseChannelResponse[]): Observable<ClientCourseChannelResponse[]> {
    const serverCalls$: Observable<ClientCourseChannelResponse>[] = channelList.map((element) =>
      this.updateCourse(element)
    );
    return forkJoin(serverCalls$).pipe(
      map((responses: ClientCourseChannelResponse[]) => responses),
      catchError(handleErrorFromRest)
    );
  }

  updateCourse(channel: ClientCourseChannelResponse): Observable<ClientCourseChannelResponse> {
    // build the URL
    const url =
      this.starfishConfig.starfishServices + CourseChannelService.channelEndpoint + '/' + channel.channelServiceId;

    // as of right now the server and client models are the same
    const server: ServerCourseChannelResponse = channel;

    return this.http
      .put<ServerCourseChannelResponse>(url, server, {
        headers: this.headers,
      })
      .pipe(
        // current client and server models are equal
        map((res) => {
          if (res?.courses?.length > 0) {
            const newUnsyncedCount = this.unsyncedCounter$.value - res.courses.length;
            this.unsyncedCounter$.next(newUnsyncedCount);
          }
          return res;
        }),
        catchError(handleErrorFromRest)
      );
  }

  getUnmappedCourses(): Observable<string[]> {
    // courseChannelServices/unmappedSubject/{track}
    const url =
      this.starfishConfig.starfishServices +
      CourseChannelService.channelEndpoint +
      '/unmappedSubject?track=UNDERGRADUATE&track=GRADUATE';

    return this.http
      .get<string[]>(url, {
        headers: this.headers,
      })
      .pipe(
        map((res) => res),
        catchError(handleErrorFromRest)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
