<span
  [formGroup]="courseConfig"
  class="sf-course-config mat-typography">
  <mat-slide-toggle
    color="primary"
    data-cy="campus-slider"
    *ngIf="courseDefermentOptionsEnabled"
    formControlName="assignCampus"
    >Assign Campus Now</mat-slide-toggle
  >
  <mat-form-field
    appearance="outline"
    [ngClass]="{ hidden: courseDefermentOptionsEnabled && !courseConfig.get('assignCampus')?.value }"
    class="form-field">
    <mat-label>Campus</mat-label>
    <mat-select
      [attr.data-cy]="'select-campus'"
      #campusSelect
      formControlName="campus">
      <mat-option
        data-cy="no-campus-needed-option"
        value="ALL"
        >-- All Campuses --</mat-option
      >
      <mat-option
        *ngFor="let item of facade.possibleCampuses$ | async | sortCampusName"
        [attr.data-cy]="'campus-' + item.id + '-option'"
        [value]="item.id"
        >{{ item.displayName }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-slide-toggle
    color="primary"
    data-cy="track-slider"
    *ngIf="courseDefermentOptionsEnabled"
    formControlName="assignEducationalTrack"
    >Assign Track Now</mat-slide-toggle
  >
  <mat-form-field
    appearance="outline"
    [ngClass]="{ hidden: courseDefermentOptionsEnabled && !courseConfig.get('assignEducationalTrack')?.value }"
    class="form-field">
    <mat-label>Educational Track</mat-label>
    <mat-select
      data-cy="select-educational-track"
      #trackSelect
      formControlName="educationalTrack">
      <mat-option
        data-cy="no-educational-track-needed-option"
        value="ALL"
        >-- All Educational Tracks --</mat-option
      >
      <mat-option
        *ngFor="let item of facade.possibleEducationalTracks$ | async"
        [attr.data-cy]="'track-' + item.id + '-option'"
        [value]="item.id"
        >{{ item.displayName }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    [ngClass]="{ hidden: courseDefermentOptionsEnabled && !courseConfig.get('assignSemester')?.value }"
    class="form-field">
    <mat-label>Semester</mat-label>
    <mat-select
      data-cy="select-semester"
      #semesterSelect
      formControlName="semester">
      <mat-option
        data-cy="no-semester-needed-option"
        value="ALL"
        >-- Choose a Semester --</mat-option
      >
      <mat-option
        *ngFor="let item of facade.possibleSemesters$ | async"
        [attr.data-cy]="'semester-' + item.id + '-option'"
        [value]="item.id"
        >{{ item.displayName }}</mat-option
      >
    </mat-select>
    <mat-error
      data-cy="missing-semester-error"
      *ngIf="courseConfig.get('semester')?.errors?.containsAll">
      You must make a semester selection
    </mat-error>
  </mat-form-field>

  <mat-slide-toggle
    color="primary"
    data-cy="classes-slider"
    *ngIf="courseDefermentOptionsEnabled && courseSelectionEnabled"
    formControlName="assignClasses"
    >Assign Classes Now</mat-slide-toggle
  >

  <div
    formArrayName="classConfigArray"
    [ngClass]="{ hidden: courseDefermentOptionsEnabled && !courseConfig.get('assignClasses')?.value }">
    <div
      class="config-row"
      *ngFor="let class of classArrayCtrl?.controls; index as i">
      <sf-class-config
        [formControl]="class"
        class="class-display"
        #classConfig
        [classIndex]="i"
        [possibleSubjects]="facade.possibleSubjects$ | async"
        [possibleCoursesAndSections]="possibleCoursesMap.get(class.value?.subject) | async"
        (selectionChange)="classConfigSelectionChanged($event, i)">
      </sf-class-config>

      <div
        class="add-remove-container"
        fxLayout="row"
        [ngClass]="{ hidden: !courseDefermentOptionsEnabled && courseConfig.get('assignClasses')?.value }">
        <button
          mat-icon-button
          color="warn"
          class="btn-class-option"
          (click)="deleteItem(i)"
          aria-label="Remove this class">
          <mat-icon>remove_circle</mat-icon>
        </button>

        <button
          mat-icon-button
          *ngIf="class.value?.subject !== 'ALL' && classArrayCtrl?.controls?.length - 1 === i"
          color="primary"
          class="btn-class-option"
          data-cy="add-another-course-btn"
          (click)="addItem()"
          aria-label="Add another class">
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
    </div>
  </div>
</span>
