import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_PROPERTIES, AppProperties } from './app/app.properties';

(async () => {
  try {
    const [clientIdResponse, propertiesResponse] = await Promise.all([
      fetch('/ui-properties/resources/oauth/oauth_client_id', { cache: 'no-cache' }),
      fetch(`/ui-properties/resources/starfish-access-ui.json`, { cache: 'no-cache' }),
    ]);

    if (!clientIdResponse.ok && !propertiesResponse.ok) {
      throw new Error('OAuth Client ID and app properties not found.');
    } else if (!clientIdResponse.ok) {
      throw new Error('OAuth Client ID not found.');
    } else if (!propertiesResponse.ok) {
      throw new Error('App properties not found.');
    }

    const [clientId, properties] = await Promise.all([
      clientIdResponse.text(),
      propertiesResponse.json() as Promise<AppProperties>,
    ]);

    // If the client ID file is not found, an HTML page may be returned
    if (!clientId || clientId.includes('<!DOCTYPE html')) {
      throw new Error('OAuth Client ID is invalid.');
    }

    properties.oauth.clientId = clientId.trim();

    await platformBrowserDynamic([{ provide: APP_PROPERTIES, useValue: properties }]).bootstrapModule(AppModule);
  } catch (err) {
    document.dispatchEvent(new CustomEvent('apperror', { bubbles: true, detail: err }));
  }
})();
