import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Selections } from '../../features/course-mapping/unmapped-courses-display/unmapped-courses-display.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';

export interface ConfirmMessageConfig {
  message: string;
  title?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  confirmColor?: string;
  cancelColor?: string;
  includeProgressbar?: boolean;
  selections?: Selections[];
}

@Injectable()
export class ConfirmDialogService {
  private dialogRefs: MatDialogRef<any>[] = [];

  constructor(private dialog: MatDialog) {}

  confirm<T>(
    type: ComponentType<T>,
    dialogConfig?: MatDialogConfig,
    serverActionProgressBar?: boolean
  ): MatDialogRef<T> {
    if (!dialogConfig) {
      dialogConfig = {};
    }
    const dialogRef = this.dialog.open(type, {
      ...dialogConfig,
      panelClass: this.getPanelClasses(
        dialogConfig.panelClass as any,
        serverActionProgressBar ? serverActionProgressBar : false
      ),
    });

    this.dialogRefs.push(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.dialogRefs.splice(this.dialogRefs.indexOf(dialogRef), 1))
      )
      .subscribe();
    return dialogRef;
  }

  confirmMessage(config: ConfirmMessageConfig, dialogConfig?: MatDialogConfig): Observable<boolean> {
    const dialog = this.confirm(ConfirmDialogComponent, dialogConfig, config.includeProgressbar);
    dialog.componentInstance.selections = config.selections;
    dialog.componentInstance.message = config.message;
    dialog.componentInstance.title = config?.title ? config.title : 'Confirm';
    if (config.cancelLabel) {
      dialog.componentInstance.cancelLabel = config.cancelLabel;
    }
    if (config.cancelColor !== undefined) {
      dialog.componentInstance.cancelColor = config.cancelColor;
    }
    if (config.confirmLabel) {
      dialog.componentInstance.okLabel = config.confirmLabel;
    }
    if (config.confirmColor !== undefined) {
      dialog.componentInstance.okColor = config.confirmColor;
    }
    dialog.componentInstance.includeProgressbar = config.includeProgressbar ? config.includeProgressbar : false;

    return dialog.afterClosed();
  }

  confirmDelete(
    message: string,
    dialogConfig?: MatDialogConfig,
    serverActionProgressBar?: boolean
  ): Observable<boolean> {
    return this.confirmMessage(
      {
        message,
        cancelLabel: 'Cancel',
        cancelColor: 'warn',
        confirmLabel: 'Delete',
        confirmColor: 'primary',
        includeProgressbar: serverActionProgressBar,
      },
      dialogConfig
    );
  }

  confirmDiscard(
    message: string,
    dialogConfig?: MatDialogConfig,
    serverActionProgressBar?: boolean
  ): Observable<boolean> {
    return this.confirmMessage(
      {
        message,
        cancelLabel: 'Cancel',
        cancelColor: 'primary',
        confirmLabel: 'Discard',
        confirmColor: 'primary',
        title: 'Discard Changes',
        includeProgressbar: serverActionProgressBar,
      },
      dialogConfig
    );
  }

  close(): void {
    this.dialogRefs.forEach((ref) => ref.close());
    this.dialogRefs = [];
  }

  // This will allow retaining panelClass values defined inside MatDialogContent as well as applying
  // the optional progress bar class when necessary
  private getPanelClasses(existingPanelClass: string | string[], progressBar: boolean): string[] {
    let classes: string[] = [];
    if (existingPanelClass) {
      classes = typeof existingPanelClass === 'string' ? [existingPanelClass] : [...existingPanelClass];
    }
    if (progressBar) {
      classes.push('progressbar-standard-dialog');
    }

    return classes;
  }
}
