import { Pipe, PipeTransform } from '@angular/core';
import { OrgSelectItem } from '@starfish-access/shared';

@Pipe({ name: 'sortOrgs' })
export class SortArrayOfOrgSelectItemsPipe implements PipeTransform {
  transform(arr: Array<OrgSelectItem>): OrgSelectItem[] {
    if (!arr) {
      return [];
    }
    return arr.sort((a, b) => a.orgName.localeCompare(b.orgName));
  }
}
