import { Injectable } from '@angular/core';
import { ClientCourseMappingList, CourseMappingDetails } from '@starfish-access/models';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CourseMappingService } from './course-mapping.service';

export const toCourseString = (courses: ClientCourseMappingList[]): string => {
  const sectionIdList: string[] = courses?.map((res: any) => res.courseDto.sectionId);

  let clipboardString = '';
  sectionIdList?.forEach((element) => {
    clipboardString += element + '\r\n';
  });

  return clipboardString;
};

@Injectable()
export class CourseMappingFacade {
  isFetching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasApproved$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private service: CourseMappingService) {}

  getTaskDetails(taskId: string): Observable<CourseMappingDetails> {
    this.isFetching$.next(true);
    return this.service.getDetails(taskId).pipe(
      catchError((err) => {
        this.isFetching$.next(false);
        return throwError(err);
      }),
      tap((res) => {
        if (res?.taskStatus !== 'AWAITING_APPROVAL') {
          this.hasApproved$.next(true);
        }
        this.isFetching$.next(false);
      })
    );
  }

  approveTask(taskId: string): void {
    this.isFetching$.next(true);
    this.hasApproved$.next(false);
    this.service
      .approveTask(taskId)
      .pipe(
        catchError((err) => {
          this.isFetching$.next(false);
          return throwError(err);
        })
      )
      .subscribe((res) => {
        this.isFetching$.next(false);
        this.hasApproved$.next(true);
      });
  }
}
