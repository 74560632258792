import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roleRelationship' })
export class RoleRelationshipsPipe implements PipeTransform {
  transform(relationship: string): string {
    if (relationship === 'ONE_TO_ONE') {
      return '1:1';
    }
    if (relationship === 'COURSE') {
      return 'Courses';
    }
    if (relationship === 'ORGANIZATION') {
      return 'Organizations';
    }
    if (relationship === 'ALL_STUDENTS') {
      return 'All Students';
    }
    return '';
  }
}
