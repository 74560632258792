import { ClientRoleGroup, CourseConfig, SelectableRole } from '@starfish-access/models';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClassConfig } from '../../../core/model/delegation.model';
import { ClientCourseConfiguration } from '../../../core/model/roles.model';

export const convertToSelectableRoles = pipe(
  map((roleGroups: ClientRoleGroup[]): SelectableRole[] =>
    roleGroups.reduce((acc, roleGroup) => {
      const selectableRoles: SelectableRole[] = roleGroup.roles.map((role) => {
        const data = {
          id: role.id ? role.id : 0,
          displayName: role.roleName,
          groupName: roleGroup.name,
          iconColor: roleGroup.iconColor,
          iconName: roleGroup.iconImage,
          roleType: role.relationships.relationshipType,
          orgSelectionEnabled: role.relationships.organizations
            ? role.relationships.organizations.length > 0 && role.relationships.deferOrganization
            : false,
          possibleOrgs: role.relationships.organizations || [],
          kioskSelectionEnabled: role.servicesAndKiosks.kioskDelegation,
          possibleKiosks: role.servicesAndKiosks.kioskList || [],
          serviceSelectionEnabled: role.servicesAndKiosks.serviceDelegation,
          possibleServices: role.servicesAndKiosks.serviceList || [],
          courseConfig: toCourseConfig(role.courseConfig),
          courseSelectionEnabled: role.courseConfig?.courseType
            ? role.courseConfig.courseType === 'DEFER_COURSES'
            : true,
        };
        return data;
      });
      acc.push(...selectableRoles);

      // sort the roles pulled from role groups and added to this array (will repeatedly sort as we pull from groups)
      acc.sort((a, b) => a.displayName.toLocaleLowerCase().localeCompare(b.displayName.toLocaleLowerCase()));
      return acc;
    }, [] as SelectableRole[])
  )
);

// Removes roles that are not delegatable, and also removes any role groups
// that were left with empty role lists because of said filtering
export const filterDelegatableRoles = pipe(
  map((roleGroups: ClientRoleGroup[]) =>
    roleGroups.reduce((acc, current) => {
      const newRoleGroup: ClientRoleGroup = {
        ...current,
        roles: current.roles.filter((role) => role.roleControl === 'DELEGATION_ONLY'),
      };
      if (newRoleGroup.roles.length > 0) {
        acc.push(newRoleGroup);
      }
      return acc;
    }, [] as ClientRoleGroup[])
  )
);

export const toCourseConfig = (input: ClientCourseConfiguration): CourseConfig | undefined => {
  if (!input) {
    return undefined;
  }

  const courses: ClassConfig[] = [];
  input?.courses?.forEach((element) => {
    const c: ClassConfig = {
      course: element.courseNumber,
      section: element.courseNumber,
      subject: element.track,
    };
    courses.push(c);
  });

  return {
    assignCampus: input?.deferOptions ? input.deferOptions.deferCampusSelection : false,
    assignEducationalTrack: input?.deferOptions ? input.deferOptions.deferTrackSelection : false,
    assignSemester: false,
    assignClasses: input?.deferOptions ? input.deferOptions.deferCourseSelection : false,
    campus: input?.courses?.length ? input.courses[0].campus : 'ALL',
    educationalTrack: input?.courses?.length ? input.courses[0].track : 'ALL',
    semester: input?.courses?.length ? input.courses[0].termName : 'ALL',
    classConfigArray: courses,
    type: input?.courseType ? input.courseType : 'DEFER_COURSES',
  };
};
