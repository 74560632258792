import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResourceTaskDetails } from './resources-task.model';
import * as ResourcesApprovalActions from './resources.actions';

export interface ResourcesApprovalState {
  hasFailed: boolean;
  isFetching: boolean;
  isUpdating: boolean;
  isSuccessfullySubmitted: boolean;
  resourcesApproval: ResourceTaskDetails;
  errorMsg: string;
}

export const initialState: ResourcesApprovalState = {
  hasFailed: false,
  isFetching: false,
  isSuccessfullySubmitted: false,
  isUpdating: false,
  errorMsg: '',
  resourcesApproval: {
    taskDetails: {
      taskId: '',
      processInstanceId: '',
      taskName: '',
      taskStatus: '',
      requester: '',
      assignedTo: [],
      approvedBy: '',
      claimTime: '',
      endTime: '',
      comment: [],
    },
    ksUpdates: {
      userRoles: [],
      kioskUpdates: [],
      serviceUpdates: [],
    },
  },
};

export const selectResources = createFeatureSelector<ResourcesApprovalState>('resourcesApproval');
export const selectResourcesHasFailed = createSelector(selectResources, (r) => r.hasFailed);
export const selectResourcesIsFetching = createSelector(selectResources, (r) => r.isFetching);
export const selectResourcesIsUpdating = createSelector(selectResources, (r) => r.isUpdating);
export const selectResourcesIsSuccessfullySubmitted = createSelector(selectResources, (r) => r.isSuccessfullySubmitted);
export const selectResourcesApproval = createSelector(selectResources, (r) => r.resourcesApproval);
export const selectResourcesError = createSelector(selectResources, (r) => r.errorMsg);

export const resourcesApprovalReducer = (
  state = initialState,
  action: ResourcesApprovalActions.ResourceActions
): ResourcesApprovalState => {
  switch (action.type) {
    case ResourcesApprovalActions.REQUEST_RESOURCE: {
      return Object.assign({}, state, {
        isFetching: true,
        hasFailed: false,
      });
    }
    case ResourcesApprovalActions.RECEIVE_RESOURCE: {
      return Object.assign({}, state, {
        resourcesApproval: action.payload,
        isFetching: false,
      });
    }
    case ResourcesApprovalActions.POST_TASK_DETAILS: {
      return Object.assign({}, state, {
        isUpdating: true,
        isSuccessfullySubmitted: false,
      });
    }
    case ResourcesApprovalActions.POST_SUCCESS_TASK_DETAILS: {
      return Object.assign({}, state, {
        isUpdating: false,
        isSuccessfullySubmitted: true,
      });
    }
    case ResourcesApprovalActions.POST_COMPLETED: {
      return Object.assign({}, state, {
        isSuccessfullySubmitted: false,
      });
    }
    case ResourcesApprovalActions.ERROR_JOBS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      console.error('Resources (DUS) Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
      });
    }
    default: {
      return state;
    }
  }
};
