import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { KioskSvc } from '../../model/kiosk-services.model';
import { handleErrorFromRest } from '../../utils/utils.model';
import { ServerService } from '../orgs.models';
import { serverModelToServices, serviceToServer } from './service.service.utils';

const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append(NO_CACHE_HEADER, 'true')
  .append(REQUIRE_AUTH_HEADER, 'true');

@Injectable()
export class ServiceService {
  private baseUrl = this.starfishConfig.starfishServices;
  private SERVICE_PATH = 'services';
  private serviceBaseUrl = `${this.baseUrl}${this.SERVICE_PATH}`;

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getServices(legacy: boolean = false): Observable<KioskSvc[]> {
    return this.http
      .get<ServerService[]>(this.serviceBaseUrl, { headers })
      .pipe(catchError(handleErrorFromRest), serverModelToServices());
  }

  createService(service: KioskSvc): Observable<KioskSvc> {
    return this.http
      .post<Response>(this.serviceBaseUrl, serviceToServer(service), {
        headers,
      })
      .pipe(
        catchError(handleErrorFromRest),
        map(() => ({
          id: service.id.toUpperCase(),
          name: service.name,
          description: service.description,
          parentId: service.parentId,
        }))
      );
  }

  updateService(updatedService: KioskSvc): Observable<KioskSvc> {
    const url = `${this.serviceBaseUrl}/${updatedService.id}`;
    return this.http.put<Response>(url, serviceToServer(updatedService), { headers }).pipe(
      catchError(handleErrorFromRest),
      map((x) => updatedService)
    );
  }

  deleteService(serviceId: string): Observable<Response> {
    const url = `${this.serviceBaseUrl}/${serviceId}`;
    return this.http.delete<Response>(url, { headers });
  }
}
