export const SELECT_YOUR_MANAGER = 'Search for my manager';
export class Manager {
  user: SingleUser;
  managers: SingleUser[];
}

export class SingleUser {
  userId: string;
  displayName: string;
}

export class DialogUser {
  title: string;
  msg: string;
}

export const ACADEMIC_SUPPORT_MSG: string =
  '<ul><li>Appropriate for users who do not provide academic advising, ' +
  'but who do update student academic records or assist in monitoring ' +
  'student degree progress</li>' +
  '<li>Creates a connection to <strong>all students</strong> at Penn State</li>' +
  '<li>Allows user to see and update records created by Instructors, ' +
  'TAs, Assigned Advisers, and General Advisers</li>' +
  '<li>Allows user to be another Starfish user’s calendar manager</li></ul>';

export const ADVISOR_MSG: string =
  '<ul><li>Appropriate for users who are expected to advise students ' +
  'who are NOT on their assigned roster, including students attending NSO</li>' +
  '<li>Creates a connection to <strong>all students</strong> at Penn State</li>' +
  '<li>Allows user to see and update academic advising-related records ' +
  'and early progress reports made by instructors</li></ul>';

export const TUTOR_MSG: string =
  '<ul><li>Appropriate for staff who provide learning assistance to students</li>' +
  '<li>Creates a connection to <strong>all students</strong> at Penn State</li>' +
  '<li>Allows user to schedule and document tutoring appointments with students</li></ul>';

export const STUDY_MSG: string =
  '<ul><li>Appropriate for users who serve as study abroad advisers in academic ' +
  'colleges and at campuses who have permission and need to see student grades ' +
  'and courses</li>' +
  '<li>Creates a connection to <strong>all students</strong> at Penn State</li>' +
  '<li>Allows user to share information with Assigned Advisers and General Advisers</li></ul>';

export const CAREER_MSG: string =
  '<ul><li>Appropriate for users who provide career counseling to students</li>' +
  '<li>Creates a connection to <strong>all students</strong> at Penn State</li></ul>';

export const CALENDAR_MSG: string =
  '<ul><li>Only assigned by request of the student’s supervisor</li>' +
  '<li>Allows a student worker to manage specific Starfish users’ calendars ' +
  'without seeing other students’ information</li></ul>';

export const SERVICE_MSG: string =
  'A Starfish Service makes an office and its members visible to students ' +
  'and other users. Starfish users are only searchable by students in Starfish ' +
  'if they are associated with a public-facing service. In some cases, this service selection has already been made for you and this is for information purposes only.';

export const KIOSK_MSG: string =
  'A Starfish kiosk waiting room allows students to check in to see an individual. ' +
  'The waiting room is visible only to users who have been associated with the ' +
  'particular unit’s Starfish kiosk. Users associated with the kiosk can view the ' +
  'waiting room, pick students up from the waiting room, indicate that students have ' +
  'left, and edit meeting details. In some cases, this kiosk selection has already been made for you and this is for information purposes only.';

export const ORG_MSG =
  'An Organization is a group of students or staff that the user of this role will coordinate with. In some cases, this organizational selection has already been made for you and this is for information purposes only.';
