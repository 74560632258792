import { Component, Input, OnInit } from '@angular/core';
import { ClientRoleGroup } from '@starfish-access/models';
import * as R from 'ramda';
import { Subject } from 'rxjs';
import { IconGroup } from './color-selector.model';

@Component({
  selector: 'sf-icon-selector',
  templateUrl: './icon-selector.component.html',
  styleUrls: ['./icon-selector.component.scss'],
})
export class IconSelectorComponent implements OnInit {
  @Input() iconSelection: Subject<string>;
  @Input() roleGroups: ClientRoleGroup[];
  @Input() loadedRoleGroup: ClientRoleGroup;

  ig: IconGroup;
  iconArray: string[] = [
    'alpha-a-circle',
    'alpha-d-circle',
    'alpha-p-circle',
    'car-sports',
    'bell-outline',
    'star',
    'google-circles-communities',
    'timer-sand-empty',
    'opacity',
    'voice',
    'polymer',
    'radar',
    'domain',
    'emoticon-outline',
    'swap-vertical-variant',
    'gesture',
    'brightness-7',
    'currency-usd',
    'gamepad',
    'google-chrome',
    'image-filter-vintage',
    'image-filter-hdr',
    'coffee',
    'flower',
    'all-inclusive',
    'umbrella',
    'dumbbell',
    'spa',
    'lightbulb',
    'fire',
    'tennis',
    'star-half',
  ];

  ngOnInit(): void {
    this.ig = this.randomIcon();
    if (this.loadedRoleGroup) {
      this.iconSelection.next(this.loadedRoleGroup.iconImage);
    } else {
      this.iconSelection.next(this.ig.icon1);
    }
  }

  resetIcons(): void {
    this.ig = this.randomIcon();
  }

  randomIcon(): IconGroup {
    const usedIcons: string[] = [];
    for (const rg of this.roleGroups) {
      usedIcons.push(rg.iconImage);
    }

    // R.difference will remove all previously used icons, so we don't allow the user to repeat a color choice
    const reducedArray: string[] = R.difference([...this.iconArray], usedIcons);
    const icon1: string = this.random(reducedArray);
    const icon1index: number = reducedArray.indexOf(icon1);
    const reducedArray2: string[] = R.remove(icon1index, 1, reducedArray);
    const icon2: string = this.random(reducedArray2);
    const icon2index = reducedArray2.indexOf(icon2);
    const reducedArray3: string[] = R.remove(icon2index, 1, reducedArray2);
    const icon3: string = this.random(reducedArray3);

    const ig: IconGroup = {
      icon1,
      icon2,
      icon3,
    };

    return ig;
  }

  random(array: string[]): string {
    const randomNum = Math.round(Math.random() * (array.length - 1));
    const icon: string = array[randomNum];
    return icon;
  }
}
