import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OkayDialogService } from '@starfish-access/core';
import { ClientCourseChannelResponse, ClientCourseMappingList } from '@starfish-access/models';
import { catchError, mergeMap } from 'rxjs/operators';
import { CourseListFacade } from '../course-list/course-list.facade';
import { toCourseString } from '../course-mapping.facade';

@Component({
  selector: 'sf-sync-courses-display',
  templateUrl: './sync-courses-display.component.html',
  styleUrls: ['./sync-courses-display.component.scss'],
})
export class SyncCoursesDisplayComponent implements OnInit, AfterViewInit {
  @Input() courseChannel: ClientCourseChannelResponse;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<ClientCourseMappingList> = new MatTableDataSource();
  displayedColumns: string[] = ['synchronized', 'department', 'courseNumber', 'sectionNumber', 'termName', 'campus'];

  constructor(public facade: CourseListFacade, private okayDialog: OkayDialogService) {}

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    if (this.courseChannel) {
      this.dataSource = new MatTableDataSource<ClientCourseMappingList>(this.courseChannel.courses);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setupFilter();
      this.dataSource.sort = this.sort;
      this.dataSource.sort.sort({
        id: 'synchronized',
        start: 'asc',
      } as MatSortable);
    });
  }

  courseCopyString(): string {
    return toCourseString(this.courseChannel.courses);
  }

  syncCourse(): void {
    this.facade
      .syncCoursesConfirmDialog(this.courseChannel)
      .pipe(
        mergeMap((res) => {
          if (res) {
            return this.facade.syncCourses(this.courseChannel);
          }
          return [];
        }),
        catchError(() => {
          this.okayDialog.okay('Synchronization Error', 'We could not sync all of the courses at this time.');
          return [];
        })
      )
      .subscribe(() => {
        this.courseChannel.courses.forEach((element) => (element.synchronized = true));
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (data === null || !sort.active || sort.direction === '') {
      return;
    }
    data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'synchronized':
          return this.compare(a.synchronized.toString(), b.synchronized.toString(), isAsc);
        case 'department':
          return this.compare(a.courseDto.department, b.courseDto.department, isAsc);
        case 'courseNumber':
          return this.compare(a.courseDto.courseNumber, b.courseDto.courseNumber, isAsc);
        case 'sectionNumber':
          return this.compare(a.courseDto.sectionNumber, b.courseDto.sectionNumber, isAsc);
        case 'termName':
          return this.compare(a.courseDto.termName, b.courseDto.termName, isAsc);
        case 'campus':
          return this.compare(a.courseDto.campus, b.courseDto.campus, isAsc);
        default:
          return 0;
      }
    });
  }

  private compare(a: string, b: string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  private setupFilter() {
    this.dataSource.filterPredicate = (d: ClientCourseMappingList, filter: string) => {
      const tableColumns = [];
      tableColumns.push(d.synchronized.toString().toLowerCase().includes(filter));
      tableColumns.push(d.courseDto.department.toLowerCase().includes(filter));
      tableColumns.push(d.courseDto.courseNumber.toLowerCase().includes(filter));
      tableColumns.push(d.courseDto.sectionNumber.toLowerCase().includes(filter));
      tableColumns.push(d.courseDto.termName.toLowerCase().includes(filter));
      tableColumns.push(d.courseDto.campus.toLowerCase().includes(filter));

      return tableColumns.some(Boolean);
    };
  }
}
