import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { CourseListComponent } from './course-list/course-list.component';
import { TaskApprovalComponent } from './task-approval/task-approval.component';

export const routes: Routes = [
  {
    title: 'coursemapping',
    path: 'coursemappingapproval/:taskId',
    canActivate: [AuthGuard],
    component: TaskApprovalComponent,
  },
  {
    path: 'coursemappinglist',
    canActivate: [AuthGuard],
    component: CourseListComponent,
  },
];
