<h2 mat-dialog-title>Are you sure?</h2>
<mat-dialog-content class="mat-typography">
  <p *ngIf="data.numberOfAssignments > 0">
    There are {{ data.numberOfAssignments }} assignments associated with {{ data.roleNameToDelete }}. Continuing will
    remove this role from this delegation group and the users from the role.
  </p>
  <p *ngIf="data.numberOfAssignments === 0">
    There are no assignments associated with {{ data.roleNameToDelete }}. Continuing will remove this role from this
    delegation group.
  </p>
  <p>No changes are final until you save this delegation group.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    data-cy="cancel"
    mat-stroked-button
    color="primary"
    [mat-dialog-close]="false"
    mat-dialog-close>
    Cancel
  </button>
  <button
    data-cy="ok"
    mat-flat-button
    color="primary"
    [mat-dialog-close]="true"
    cdkFocusInitial>
    OK
  </button>
</mat-dialog-actions>
