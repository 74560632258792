import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard } from '@starfish-access/core';
import { DirectorsComponent } from './directors.component';
import { DirectorsGuard } from './directors.guard';

export const routes: Routes = [
  {
    title: 'area-directors',
    path: 'area-directors',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    children: [
      {
        path: '',
        component: DirectorsComponent,
        canDeactivate: [DirectorsGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class DirectorsRoutingModule {}
