import { Injectable, OnDestroy } from '@angular/core';
import { OrganizationService } from '@starfish-access/core';
import { OrgSelectItem } from '@starfish-access/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class OrgsFacade implements OnDestroy {
  typeAheadList$: Observable<OrgSelectItem[]>;
  private loadingSubject: Subject<boolean> = new BehaviorSubject(true);

  constructor(private orgService: OrganizationService) {
    this.typeAheadList$ = this.typeAheadList();
  }

  // TODO(mat21) this is called from a template and gets called hundreds of times, creating a new observable each time.
  isLoadingData(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }
  ngOnDestroy(): void {
    this.loadingSubject.complete();
  }
  private typeAheadList(): Observable<OrgSelectItem[]> {
    return this.orgService.getRelationshipOrgs().pipe(
      map((orgList) =>
        orgList.map((singleOrg) => ({
          id: singleOrg.orgId,
          displayName: `${singleOrg.displayName} [${singleOrg.orgId}]`,
          orgName: singleOrg.displayName,
        }))
      ),
      tap((_a) => this.loadingSubject.next(false))
    );
  }
}
