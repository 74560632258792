<section
  class="spaced-cards"
  *ngIf="!(errorMessage | async) && !(hasFailed | async); else error_message">
  <h1>Request #{{ taskDetails.processInstanceId }}</h1>
  <sf-workflow-details
    [processDetails]="requestItemDetailsObs"
    [fetchingDetails]="isFetchingWorkflowHistory"
    [hasFerpa]="hasFerpa"
    [taskDetails]="taskDetails"
    [submitInProgress]="submitInProgressSubj"
    (submitEmitter)="completeRequest($event)">
  </sf-workflow-details>

  <sf-workflow-history
    [isFetchingWorkflowHistory]="isFetchingWorkflowHistory"
    [processDetails]="requestItemDetailsObs">
  </sf-workflow-history>
</section>

<ng-template #error_message>
  <div class="error_message">
    <span> <strong>ERROR: </strong>{{ getErrors(errorMessage) }} </span>
  </div>
</ng-template>
