import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OkayDialogService, generateRoleForm } from '@starfish-access/core';
import { ClientRoleGroupAndPriority, Role } from '@starfish-access/models';
import { of } from 'ramda';
import { BehaviorSubject, Subject } from 'rxjs';
import { catchError, filter, mapTo, take, takeUntil } from 'rxjs/operators';
import { DynamicRolesFacade } from './dynamic-role.facade.service';
import { RoleDetailsFormComponent } from './role-details-form/role-details-form.component';

@Component({
  selector: 'sf-dynamic-role',
  templateUrl: './dynamic-role.component.html',
  styleUrls: ['./dynamic-role.component.scss'],
})
export class DynamicRoleComponent implements OnInit, OnDestroy {
  @ViewChild('roleDetailsFormComponent')
  roleDetailsFormComp: RoleDetailsFormComponent;
  newRoleForm: UntypedFormGroup;
  isLoading$ = new BehaviorSubject<boolean>(true);
  private destroy$ = new Subject<void>();
  constructor(
    public facade: DynamicRolesFacade,
    public router: Router,
    private fb: UntypedFormBuilder,
    private dialogService: OkayDialogService
  ) {
    this.facade.roleGroupsWithAdminGroups$
      .pipe(
        takeUntil(this.destroy$),
        filter((res) => !!res)
      )
      .subscribe((res) => {
        this.newRoleForm = generateRoleForm(this.fb, res);
        this.isLoading$.next(false);
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.isLoading$.complete();
  }

  ngOnInit(): void {
    this.facade.requestRoleGroups();
    this.facade.requestRoleGroupPriorities();
  }

  addNewRole(): void {
    this.newRoleForm.markAllAsTouched();
    this.newRoleForm.markAsTouched();

    if (!this.newRoleForm.valid) {
      console.warn('invalid form', this.newRoleForm);
      return;
    }

    if (this.newRoleForm) {
      const clientSideRole: Role = this.newRoleForm.value;

      // TODO(mat21) work towards being able to just do const newRoleToCreate: SingelRole = formGroup.value
      if (this.roleDetailsFormComp.getGroupSelection() === 'existinggroup') {
        const clientGap: ClientRoleGroupAndPriority = {
          clientSideRole,
          roleGroupType: 'EXISTING',
        };
        this.facade
          .createRoleInExistingRoleGroup(clientGap)
          .pipe(
            take(1),
            mapTo(true),
            catchError(() => of(false))
          )
          .subscribe((res) => this.showDialogAndReRoute(res));
      } else {
        const clientGap: ClientRoleGroupAndPriority = {
          clientSideRole,
          roleGroupType: 'NEW',
        };
        this.facade
          .createNewRoleInNewRoleGroup(clientGap)
          .pipe(take(1))
          .subscribe((res) => this.showDialogAndReRoute(res));
      }
    }
  }

  cancel() {
    this.router.navigate(['/editrole']);
  }

  private showDialogAndReRoute(result: boolean) {
    if (result) {
      this.dialogService.okay('Role Created', 'Successfully created a new role.');
      this.router.navigate(['/editrole']);
    } else {
      this.dialogService.okay('Starfish Role Error', 'Error retrieving information from a required Starfish service.');
    }
  }
}
