import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonModule } from '@psu/components/button';
import { NotificationModule } from '@psu/components/notification-ngrx';
import { FilterModule } from '@starfish-access/core';
import { WorkflowIconModule } from '../workflowicon/workflow-icon.module';
import { WorkflowHeaderComponent } from './workflow-header.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    MatTableModule,
    FlexLayoutModule,
    FilterModule,
    MatProgressSpinnerModule,
    WorkflowIconModule,
    ButtonModule,
  ],
  declarations: [WorkflowHeaderComponent],
  exports: [WorkflowHeaderComponent],
  providers: [],
})
export class WorkflowHeaderModule {}
