import { Component, ViewChild } from '@angular/core';
import { DelegationListComponent } from './delegation-list/delegation-list.component';

@Component({
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss'],
})
export class DelegationComponent {
  @ViewChild('delListContainer') delList: DelegationListComponent;
}
