import { Injectable } from '@angular/core';
import { KioskService, ServiceService } from '@starfish-access/core';

@Injectable()
export class RoleDetailsFormFacade {
  readonly kiosks$ = this.kioskService.getKiosks();
  readonly services$ = this.serviceService.getServices();

  constructor(private kioskService: KioskService, private serviceService: ServiceService) {}
}
