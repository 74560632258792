import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TypeaheadComponent, TypeaheadConfig, TypeaheadModel } from '@psu/components/typeahead';
import { equals, includes } from 'ramda';

export interface OrgSelectItem {
  id: string;
  displayName: string;
  orgName: string;
  selected?: boolean;
}

export interface OrgSelectConfig {
  placeholder: string;
  showSearchIcon: boolean;
  removable?: boolean;
  keepPanelOpen?: boolean;
}

@Component({
  selector: 'sf-org-select-input',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './org-select.component.html',
  styleUrls: ['./org-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgSelectComponent),
      multi: true,
    },
  ],
})
export class OrgSelectComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() possibleItems: OrgSelectItem[];
  @Input() possibleItemsLoading: boolean;
  @Input() config: OrgSelectConfig;
  @Input() formControl: UntypedFormControl;
  // Only here to pass on to the typeahead control
  @Input() typeaheadErrorMatcher: ErrorStateMatcher;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<any>();
  @ViewChild('typeahead', { static: true })
  private typeahead: TypeaheadComponent<OrgSelectItem>;
  taConfig: TypeaheadConfig<OrgSelectItem>;

  private formGroup: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.formGroup = fb.group({
      selectedOrgs: new UntypedFormControl([]),
      typeaheadInput: new UntypedFormControl(''),
    });
  }

  get orgsFc(): AbstractControl | null {
    return this.formGroup.get('selectedOrgs');
  }

  focus(): void {
    if (this.typeahead.focus) {
      this.typeahead.focus();
    }
  }

  inputBlurred(event: any): void {
    this.propagateTouch(event);
    this.blur.emit(event);
  }

  itemSelected(item: TypeaheadModel<OrgSelectItem>): void {
    const orgList: OrgSelectItem[] = this.orgsFc ? this.orgsFc.value : [];
    item.disabled = true;
    // don't allow repeats
    if (includes(item.object, orgList)) {
      return;
    }
    orgList.push(item.object);
    if (this.orgsFc) {
      this.orgsFc.patchValue(orgList);
    }
    this.propagateChange(orgList);
  }

  removeSelectedItem(item: OrgSelectItem): void {
    const orgList: OrgSelectItem[] = this.orgsFc ? this.orgsFc.value : [];
    const index = orgList.indexOf(item);
    // if we delete the chip, the element in the ta config should no longer be disabled
    this.taConfig?.dataSource.forEach((element) => {
      if (element.object.id === item.id) {
        element.disabled = false;
      }
    });
    if (index > -1) {
      orgList.splice(index, 1);
      if (this.orgsFc) {
        this.orgsFc.patchValue(orgList);
      }
      this.propagateChange(orgList);
    }
  }

  writeValue(obj: OrgSelectItem[]): void {
    if (obj) {
      if (this.orgsFc) {
        this.orgsFc.patchValue(obj);
        this.propagateChange(obj);
      }
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.taConfig = this.updateTaConfig(this.possibleItems);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      this.taConfig = {
        ...this.taConfig,
        placeholder: this.config.placeholder,
        showSearchIcon: this.config.showSearchIcon,
        keepPanelOpen: false,
        clearOnSelect: true,
      };
    }
    if (changes['possibleItems']) {
      const prev: OrgSelectItem[] = changes['possibleItems'].previousValue;
      const curr: OrgSelectItem[] = changes['possibleItems'].currentValue;
      const functionallyEqual = equals(prev, curr);
      // lets only update the taconfig if we need to
      if (!functionallyEqual) {
        this.taConfig = this.updateTaConfig(curr);
      }
    }
  }

  propagateTouch: (_: any) => any = (_: any) => ({});
  private propagateChange: (_: any) => any = (_: any) => ({});

  private updateTaConfig(items: OrgSelectItem[]): TypeaheadConfig<OrgSelectItem> {
    return {
      dataSource: items
        ? items.map((item) => ({
            displayValue: (item.orgName || item.displayName) + ' [' + item.id + ']',
            object: item,
          }))
        : [],
      isLoadingDataSource: this.possibleItemsLoading,
      placeholder: this.config.placeholder,
      showSearchIcon: this.config.showSearchIcon,
      keepPanelOpen: false,
      clearOnSelect: true,
    };
  }
}
