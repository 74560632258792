<span class="sf-role-config-list mat-typography">
  <uic-typeahead
    #typeahead
    [typeaheadConfig]="roleSearchConfig$ | async"
    (selectionMade)="addRole($event)">
    <mat-error>
      <ng-content select="mat-error"></ng-content>
    </mat-error>
  </uic-typeahead>

  <mat-progress-bar
    *ngIf="createFacade.isLoading$ | async"
    mode="indeterminate"
    data-cy="roles-loading-progress-bar"></mat-progress-bar>

  <table
    #table
    class="role-table"
    mat-table
    [dataSource]="roleTableDatasource">
    <ng-container matColumnDef="displayName">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Role Name
      </th>
      <td
        mat-cell
        [attr.data-cy]="'role-name-cell-' + roleConfig.roleId"
        *matCellDef="let roleConfig">
        {{ roleConfig.displayName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="deleterole">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Delete Role
      </th>
      <td
        mat-cell
        [attr.data-cy]="'role-button-cell-' + roleConfig.roleId"
        class="button-cell"
        *matCellDef="let roleConfig; let i = index">
        <button
          mat-flat-button
          [attr.data-cy]="'deloete-role-button-' + roleConfig.roleId"
          type="button"
          color="warn"
          (click)="deleteRole.emit(roleConfig)"
          [disabled]="createFacade.isLoading$ | async">
          <mat-icon
            svgIcon="window-close"
            class="button-icon"></mat-icon
          ><span
            class="button-icon-text"
            utResponsive
            >Delete</span
          >
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="button">
      <th
        mat-header-cell
        *matHeaderCellDef>
        Edit Role
      </th>
      <td
        mat-cell
        [attr.data-cy]="'role-button-cell-' + roleConfig.roleId"
        class="button-cell"
        *matCellDef="let roleConfig; let i = index">
        <button
          mat-flat-button
          [attr.data-cy]="'role-button-' + roleConfig.roleId"
          type="button"
          color="primary"
          (click)="editRole(i)"
          [disabled]="createFacade.isLoading$ | async">
          <mat-icon
            svgIcon="pencil"
            class="button-icon"></mat-icon
          ><span
            class="button-icon-text"
            utResponsive
            >Edit</span
          >
        </button>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</span>
