import { ClientRoleGroup, RoleGroupPriority } from '@starfish-access/models';

export const NEW_GROUP_ID = 1234;
export const NEW_GROUP_PRIORITY = 100;

export interface NewGroupOrgModel {
  color: string;
  icon: string;
  name: string;
  priorityList: RoleGroupPriority[];
  description?: string;
}

export interface EditedRoleGroupModel {
  updatedGroupDetails: NewGroupOrgModel;
  originalRoleGroup: ClientRoleGroup;
}
