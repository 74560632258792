import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NotificationFacade } from '@psu/components/notification-ngrx';
import { OkayDialogService } from '@starfish-access/core';
import { ProcessDetails } from '@starfish-access/models';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as DetailsActions from './details.actions';
import { DetailsService } from './details.service';

@Injectable()
export class DetailsEffects {
  post$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DetailsActions.AddProcess>(DetailsActions.POST_DETAILS),
      switchMap((newRequest) =>
        this.detailsService.addNewProcess(newRequest.payload).pipe(
          map(() => new DetailsActions.AddProcessSuccess()),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new DetailsActions.ErrorDetails(errorMsg));
          })
        )
      )
    )
  );

  processSucess$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType<DetailsActions.AddProcessSuccess>(DetailsActions.POST_SUCCESS_DETAILS),
        map(() =>
          this.notifications.success({
            message: 'Account request sucessfully submitted.',
            closeText: 'OK',
          })
        )
      ),
    { dispatch: false }
  );

  request$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DetailsActions.RequestDetails>(DetailsActions.REQUEST_DETAILS),
      switchMap((id) =>
        this.detailsService.getDetails(id.payload).pipe(
          map((detailsList: ProcessDetails) => new DetailsActions.ReceiveDetails(detailsList)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new DetailsActions.ErrorDetails(errorMsg));
          })
        )
      )
    )
  );

  put$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DetailsActions.PutProcess>(DetailsActions.PUT_DETAILS),
      switchMap((processToUpdate) =>
        this.detailsService.updateProcess(processToUpdate.payload).pipe(
          map(() => new DetailsActions.PutProcessSuccess()),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new DetailsActions.ErrorDetails(errorMsg));
          })
        )
      )
    )
  );

  putComment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<DetailsActions.AddComment>(DetailsActions.PUT_COMMENT),
      switchMap((newComment) =>
        this.detailsService.addNewComment(newComment.payload).pipe(
          map(() => new DetailsActions.AddCommentSuccess(newComment.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new DetailsActions.ErrorDetails(errorMsg));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private notifications: NotificationFacade,
    private detailsService: DetailsService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      const message = 'Error retrieving information from a required Starfish service.';
      this.dialogService.okay('Starfish Details Error', message);
    }
  }
}
