import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { UserInfo } from '@starfish-access/models';
import { OrgSelectItem, SEARCH_IMPL, TypeaheadSelectConfig } from '@starfish-access/shared';
import { isEmpty } from 'ramda';
import { ConfiguredAssignments, RoleConfig } from '../../../core/model/delegation.model';
import { SearchService } from '../../../core/services/search.service';
import { validAssignmentValidator } from '../../../core/validators/assignment.validator';

export interface UserAndRole {
  users: UserInfo[];
  roles: RoleConfig[];
}

export interface FromSelection {
  users: UserInfo[];
  roles: OrgSelectItem[];
}

@Component({
  selector: 'sf-add-user-and-role',
  templateUrl: './add-user-and-role.component.html',
  styleUrls: ['./add-user-and-role.component.scss'],
  providers: [
    { provide: SEARCH_IMPL, useExisting: SearchService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddUserAndRoleComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddUserAndRoleComponent),
      multi: true,
    },
  ],
})
export class AddUserAndRoleComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() roleConfigs: RoleConfig[];
  @Input() existingAssignments: ConfiguredAssignments[];
  @Input() isReadOnly: false;
  @Input() formControl: UntypedFormControl;

  delegationAdminConfig: TypeaheadSelectConfig = {
    inputAppearance: 'outline',
    inputPlaceholder: 'Search by User ID or Name',
    removableItems: true,
  };

  roleAndUserFormGroup: UntypedFormGroup;

  constructor(public fb: UntypedFormBuilder) {
    // this.roleAndUserFormGroup = fb.group({
    //   users: [[], Validators.required],
    //   roles: [[], [Validators.required, Validators.maxLength(1)]],
    // }, {validator: validAssignmentValidator({rolesKey: 'roles', usersKey: 'users', currentAssignments: this.existingAssignments})})
  }

  ngOnInit(): void {
    this.roleAndUserFormGroup = this.fb.group(
      {
        users: [[], Validators.required],
        roles: [[], [Validators.required, Validators.maxLength(1)]],
      },
      {
        validator: validAssignmentValidator({
          rolesKey: 'roles',
          usersKey: 'users',
          currentAssignments: this.existingAssignments,
        }),
      }
    );

    this.roleAndUserFormGroup.valueChanges.subscribe((value: FromSelection) => {
      const rc: RoleConfig[] = [];
      value.roles.forEach((element) => {
        this.roleConfigs.forEach((config) => {
          if (config.roleId === element.id) {
            rc.push(config);
          }
        });
      });

      const valueToPatch: UserAndRole = {
        users: value.users,
        roles: rc,
      };
      this.propagateChange(valueToPatch);
    });
  }

  // writeValue will come in as 'any' UserAndRole is to know what to expect here
  writeValue(obj: UserAndRole): void {
    if (!obj) {
      return;
    }
    this.roleAndUserFormGroup.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  validate(_control: AbstractControl): ValidationErrors | null {
    const validationErrors: ValidationErrors = {};

    if (!this.roleAndUserFormGroup.get('users')?.valid) {
      validationErrors.usersRequired = 'You must assign to at least user';
    }
    if (!this.roleAndUserFormGroup.get('roles')?.valid) {
      validationErrors.rolesRequired = 'You must choose one and only one role';
    }

    if (this.roleAndUserFormGroup?.errors?.invalidAssignment) {
      return (validationErrors.invalidAssignment = this.roleAndUserFormGroup?.errors?.invalidAssignment);
    }

    return !isEmpty(validationErrors) ? validationErrors : null;
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
