import { Observable, throwError as observableThrowError } from 'rxjs';

export const TOOLTIP_DELAY = '200';

export const handleErrorFromRest = (error: Response | any): Observable<never> => {
  // console.warn('ERROR (verbatim): ' + error.toString());
  let errMsg = 'No Message Provided';
  const errURL = error.url;
  const errStatus = error.status;
  const errText = error.errorText;
  const errStatusText = error.errorStatusText;

  switch (errStatus) {
    case 503:
      // cornerstone trap
      errMsg = 'Starfish Error 503: Service Unavailable';
      console.error(errMsg);
      break;
    case 504:
      // cornerstone trap
      errMsg = 'Starfish Error 504: Gateway Timeout';
      console.error(errMsg);
      break;
    case 502:
      // cornerstone trap
      errMsg = 'Starfish Error 502: Bad Gateway';
      console.error(errMsg);
      break;
    case 500:
      // probably did something stupid
      errMsg = 'Starfish Error 500: Internal Server Error';
      console.error(errMsg);
      break;
    case 415:
      // thou shalt not enter
      errMsg = 'Starfish Error 415: Unsupported Media Type';
      console.error(errMsg);
      break;
    case 404:
      // user not found in cornerstone (or something far, far worse...)
      errMsg = 'Starfish Error 404: Not Found';
      console.error(errMsg);
      break;
    case 403:
      // thou shalt not enter
      errMsg = 'Starfish Error 403: Forbidden';
      console.error(errMsg);
      break;
    case 402:
      // thou shalt not enter
      errMsg = 'Starfish Error 401: Unauthorized';
      console.error(errMsg);
      break;
    case 401:
      // thou shalt not enter
      errMsg = 'Starfish Error 400: Bad Request';
      console.error(errMsg);
      break;
    case 0:
      // CORS issue likely, or the web services didn't get a response from the server
      errMsg = 'Error retrieving information from a required Starfish service.';
      console.error('Starfish Error 0: ' + errMsg);
      break;
    default:
      console.error('Starfish Error Message: ' + errMsg);
      console.error('Starfish Error URL: ' + errURL);
      console.error('Starfish Error Status: ' + errStatus);
      console.error('Starfish Error Text: ' + errText);
      console.error('Starfish Error Status Text: ' + errStatusText);
  }

  return observableThrowError(errStatus.toString());
};

export const limitCommentSize = (comment: string, limit: number): string => {
  const isUndefined: boolean = comment === undefined;
  if (!isUndefined) {
    if (comment.length > limit) {
      const formattedComment: string = comment.substring(0, limit) + '...';
      return formattedComment;
    }
  }
  return comment;
};
