import { Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { FailedJobPermissions, FerpaGuard } from '@starfish-access/core';
import { FailedJobsComponent } from './failedjobs.component';

export const routes: Routes = [
  {
    title: 'failedjobs',
    path: 'failedjobs',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    component: FailedJobsComponent,
    data: {
      permissions: FailedJobPermissions.VIEW,
    },
  },
];
