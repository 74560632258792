import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgLetModule } from '@psu/utils/angular';
import { CoreModule, FilterModule, OkayDialogModule } from '@starfish-access/core';
import { TypeaheadModule } from '@starfish-access/shared';
import { AddRoleComponent } from './add-role/add-role.component';
import { FilterComponent } from './filter/filter.component';
import { NewUserComponent } from './new-user/new-user.component';
import { UserManagementComponent } from './usermanagement.component';
import { UserManagementFacade } from './usermanagement.facade';
import { routes } from './usermanagement.routing';
import { UserManagementService } from './usermanagement.service';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    RouterModule.forChild(routes),
    CoreModule,
    FlexLayoutModule,
    FilterModule,
    MatChipsModule,
    MatPaginatorModule,
    MatSelectModule,
    TypeaheadModule,
    NgLetModule,
    OkayDialogModule,
    MatDialogModule,
  ],
  declarations: [UserManagementComponent, AddRoleComponent, NewUserComponent, FilterComponent],
  exports: [RouterModule],
  providers: [UserManagementService, UserManagementFacade],
})
export class UserManagementModule {}
