import { Action } from '@ngrx/store';
import { ClientRoleGroup, ClientRoleGroupAndPriority, RoleGroupPriority } from '@starfish-access/models';
import { EditedRoleGroupModel } from './dynamic-role.model';

export const SET_GROUP_PRIORITIES = '[DynamicRoles] Set Group Role Priorities';
export const GROUP_PRIORITIES_SUCCESS = '[DynamicRoles] Get Group Role Priorities Success';
export const REQUEST_GROUPS = '[DynamicRoles] Request Group Roles';
export const RECEIVE_GROUPS = '[DynamicRoles] Receive Group Roles';
export const REQUEST_GROUP_PRIORITIES = '[DynamicRoles] Request Group Priorities';
export const RECEIVE_GROUP_PRIORITIES = '[DynamicRoles] Receive Group Priorities';
export const CREATE_GROUPS = '[DynamicRoles] Create Group Roles';
export const UPDATE_GROUPS = '[DynamicRoles] Update Group Roles';
export const UPDATE_GROUPS_SUCCESS = '[DynamicRoles] Update Group Roles Success Response';
export const CREATE_NEW_GROUP_AND_PRIORITY = '[DynamicRoles] Create New Group Role and Set Priorities';
export const CREATE_GROUPS_SUCCESS = '[DynamicRoles] Create Group Roles Success';
export const ERROR = '[DynamicRoles] Error From DynamicRoles';
export const UPDATE_EXISTING_ROLE_GROUPS = '[DynamicRoles] Update Existing Role Group Role';
export const UPDATE_EXISTING_ROLE_GROUPS_SUCCESS = '[DynamicRoles] Update Existing Role Group';

export class SetGroupPriorities implements Action {
  readonly type = SET_GROUP_PRIORITIES;
  constructor(public payload: RoleGroupPriority[]) {}
}

export class GroupPrioritiesSuccess implements Action {
  readonly type = GROUP_PRIORITIES_SUCCESS;
  constructor() {}
}

export class RequestGroupPriorities implements Action {
  readonly type = REQUEST_GROUP_PRIORITIES;
  constructor() {}
}

export class ReceiveGroupPriorities implements Action {
  readonly type = RECEIVE_GROUP_PRIORITIES;
  constructor(public payload: RoleGroupPriority[]) {}
}

export class RequestGroups implements Action {
  readonly type = REQUEST_GROUPS;
  constructor() {}
}

export class ReceiveGroups implements Action {
  readonly type = RECEIVE_GROUPS;
  constructor(public payload: ClientRoleGroup[]) {}
}

export class UpdateGroups implements Action {
  readonly type = UPDATE_GROUPS;
  constructor(public payload: ClientRoleGroupAndPriority) {}
}

export class UpdateGroupsSuccess implements Action {
  readonly type = UPDATE_GROUPS_SUCCESS;
  constructor() {}
}

export class CreateGroups implements Action {
  readonly type = CREATE_GROUPS;
  constructor(public payload: ClientRoleGroupAndPriority) {}
}
export class CreateNewGroupAndPriority implements Action {
  readonly type = CREATE_NEW_GROUP_AND_PRIORITY;
  constructor(public payload: ClientRoleGroupAndPriority) {}
}

export class CreateGroupsSuccess implements Action {
  readonly type = CREATE_GROUPS_SUCCESS;
  constructor() {}
}

export class UpdateExistingRoleGroup implements Action {
  readonly type = UPDATE_EXISTING_ROLE_GROUPS;
  constructor(public payload: EditedRoleGroupModel) {}
}

export class UpdateExistingRoleGroupSuccess implements Action {
  readonly type = UPDATE_EXISTING_ROLE_GROUPS_SUCCESS;
  constructor() {}
}

export class ErrorRoles implements Action {
  readonly type = ERROR;
  constructor(public payload: string) {}
}

export type DynamicRoleActions =
  | UpdateExistingRoleGroup
  | UpdateExistingRoleGroupSuccess
  | SetGroupPriorities
  | GroupPrioritiesSuccess
  | RequestGroupPriorities
  | ReceiveGroupPriorities
  | CreateNewGroupAndPriority
  | RequestGroups
  | ReceiveGroups
  | UpdateGroups
  | UpdateGroupsSuccess
  | CreateGroups
  | CreateGroupsSuccess
  | ErrorRoles;
