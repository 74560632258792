<h2>Role Members</h2>

<mat-card
  appearance="outlined"
  class="card-table">
  <mat-card-content>
    <mat-form-field
      class="starfish-filter"
      appearance="outline"
      utResponsive>
      <mat-label>Filter Results</mat-label>
      <input
        matInput
        [attr.data-cy]="tableType + '-search-filter'"
        placeholder="abc123"
        (keyup)="applyFilter($event.target.value)" />
      <mat-hint>Search by PSU User ID or Name</mat-hint>
    </mat-form-field>

    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z0 starfish-data-table">
      <ng-container matColumnDef="displayName">
        <th
          mat-header-cell
          *matHeaderCellDef>
          Name
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.displayName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th
          mat-header-cell
          *matHeaderCellDef>
          User ID
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [attr.data-cy]="element.userId + '-' + tableType + '-result'">
          {{ element.userId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th
          mat-header-cell
          *matHeaderCellDef>
          Remove User
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          <a
            *ngIf="canDelete | async"
            [attr.data-cy]="element.userId + '-remove-' + tableType + '-item'"
            (click)="removeUser(element.userId)">
            <button
              mat-flat-button
              color="warn">
              <mat-icon>delete</mat-icon><span>Remove</span>
            </button>
          </a>
          <span *ngIf="!(canDelete | async)">&nbsp;</span>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="userRoleColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: userRoleColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>
