import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { CompleteApproval, TaskDetails, UpdateApproval } from '@starfish-access/models';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';

@Injectable()
export class ApproveService {
  private static endPoint = 'tasks';
  private static detailsEndPoint = '/details';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getTaskbyId(taskId: string): Observable<TaskDetails> {
    // build the URL
    ApproveService.URL =
      this.starfishConfig.starfishServices + ApproveService.endPoint + '/' + taskId + ApproveService.detailsEndPoint;

    // console.log('URL = ' + ApproveService.URL);
    return this.http
      .get<TaskDetails>(ApproveService.URL, {
        headers: this.headers,
      })
      .pipe(
        // .map((response: Response) => response.json() as TaskDetails)
        catchError(handleErrorFromRest)
      );
  }

  /*
   * action is one of: RETURN_TO_REQUESTER, APPROVE, REJECT
   */
  updatedTaskbyId(completeApproval: CompleteApproval): Observable<Response> {
    // build the URL
    ApproveService.URL =
      this.starfishConfig.starfishServices + ApproveService.endPoint + '/' + completeApproval.taskDetails.taskId;

    const updateTask: UpdateApproval = {
      taskAction: completeApproval.taskAction,
      taskComment: completeApproval.newestComment,
      additionalInfoUser: completeApproval.taskReassignedTo,
    };

    if (completeApproval.taskDetails.taskId) {
      // console.log('updateTask: ' + JSON.stringify(updateTask));
      // console.log('completeApproval: ' + JSON.stringify(completeApproval));
      // console.log('URL: ' + ApproveService.URL);
      return this.http
        .post<Response>(ApproveService.URL, updateTask, {
          headers: this.headers,
        })
        .pipe(catchError(handleErrorFromRest));
    }
    return of();
  }
}
