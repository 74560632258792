import { Component, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

@Component({
  selector: 'sf-descriptions',
  templateUrl: './descriptions.component.html',
  styleUrls: ['./descriptions.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DescriptionsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DescriptionsComponent),
      multi: true,
    },
  ],
})
export class DescriptionsComponent implements ControlValueAccessor, Validator {
  canAdd = true;
  formGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.formGroup = this.fb.group({
      descriptions: this.fb.array([new UntypedFormControl()]),
    });

    this.descriptions.valueChanges.subscribe((lines: string[]) => {
      this.propagateChange(lines);
    });
  }

  get descriptions(): UntypedFormArray {
    return this.formGroup.get('descriptions') as UntypedFormArray;
  }

  writeValue(descriptions: string[]): void {
    this.clearDescs();
    if (descriptions) {
      if (descriptions.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < descriptions.length; i++) {
          this.addItem();
        }
      }

      if (descriptions) {
        this.descriptions.patchValue(descriptions);
        this.propagateChange(descriptions);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  validate(control: AbstractControl): ValidationErrors | null {
    // TODO jrm support validation where at least one descriptive line is required
    return null;
  }
  blurHandler(event: any): void {
    this.descriptions.updateValueAndValidity();
    this.propagateTouch(event);
  }

  addItem(): void {
    this.descriptions.push(new UntypedFormControl());
  }

  clearDescs() {
    let index = 0;
    for (const desc of this.descriptions.controls) {
      this.deleteItem(index++);
    }
    this.deleteItem(0);
  }

  deleteItem(index: number): void {
    this.descriptions.removeAt(index);

    if (this.descriptions.length === 0) {
      this.addItem();
    }
  }

  deleteWithoutSafetyCheck(index: number): void {
    this.descriptions.removeAt(index);
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
