import { Pipe, PipeTransform } from '@angular/core';
import { ProcessDetails } from '../model/details.model';

@Pipe({ name: 'orgName' })
export class OrgNamePipe implements PipeTransform {
  transform(pd: ProcessDetails | null): string[] {
    if (!pd) {
      return [];
    }

    if (!pd.organizations || pd.organizations.length === 0) {
      return [];
    }
    const orgList: string[] = [];

    // TODO this needs fixed https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/461
    for (const org of pd.organizations) {
      if ((org as any).organization) {
        orgList.push((org as any).organization.name);
      } else {
        orgList.push(org.name);
      }
    }

    return orgList;
  }
}
