import { Pipe, PipeTransform } from '@angular/core';
import { GenericService } from '../model/details.model';

@Pipe({ name: 'sortServices' })
export class SortArrayOfServicesItemsPipe implements PipeTransform {
  transform(arr: Array<GenericService>): GenericService[] {
    if (!arr) {
      return [];
    }
    return arr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name));
  }
}
