import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-edit-role-name',
  templateUrl: './edit-role-name.component.html',
  styleUrls: ['./edit-role-name.component.scss'],
})
export class EditRoleNameComponent {
  @Input() roleName: UntypedFormControl;

  constructor() {}
}
