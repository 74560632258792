import { Injectable } from '@angular/core';
import { DelegateService } from '@starfish-access/core';
import {
  ClientAssignmentResponse,
  ConfiguredAssignments,
  ConfiguredDelegation,
  DelegationMetaData,
  UserInfo,
} from '@starfish-access/models';
import { union } from 'ramda';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { ConnectedStudentForm } from './multi-student/multi-student.component';

export const buildStudentList = (data: ConnectedStudentForm | undefined): UserInfo[] => {
  // something went wrong and we have no data
  if (!data) {
    return [];
  }

  if (!data?.bulkusers || data?.bulkusers.connectedUsers?.length === 0) {
    // There is nothing in the bulk user section, ignore it.
    return data.users;
  }

  // The entered something in the bulk user section, we'll need the list
  const bulkOnly: UserInfo[] = data.bulkusers.connectedUsers
    ?.split('\n')
    .filter((val) => val !== '')
    .map((res) => {
      const formattedUserId = res.toLocaleLowerCase().replace('@psu.edu', '');
      return {
        id: formattedUserId,
        displayName: formattedUserId,
      };
    });

  if (data.bulkusers.studentUpdateType === 'REPLACE') {
    // They only want what they entered in the bulk section, ignore everything else
    return bulkOnly;
  }

  // else, we are merging the world
  const mergedList: UserInfo[] = union(data.users, bulkOnly);

  return mergedList;
};

@Injectable()
export class DirectorsFacade {
  transitionSemester$ = this.getTransitionSemester();

  addingAssignment$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  downloadCSV$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  deletingAssignment$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingAssignments$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingDelegations$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private service: DelegateService) {}
  getConfiguredDelegations(): Observable<ConfiguredDelegation[]> {
    this.loadingDelegations$.next(true);

    return this.service.getConfiguredDelegations().pipe(tap(() => this.loadingDelegations$.next(false)));
  }

  updateConfiguredDelegation(cd: ConfiguredDelegation): Observable<ClientAssignmentResponse[]> {
    this.addingAssignment$.next(true);
    return this.service.addNewDelegationAssignment(cd).pipe(
      catchError((err) => {
        this.addingAssignment$.next(false);
        return throwError(err);
      }),
      tap(() => this.addingAssignment$.next(false))
    );
  }

  getDelegationsMetaData(): Observable<DelegationMetaData[]> {
    this.loadingDelegations$.next(true);

    return this.service.getAllDelegationGroupsMetaData().pipe(tap(() => this.loadingDelegations$.next(false)));
  }

  getConfiguredAssignments(cd: ConfiguredDelegation): Observable<ConfiguredAssignments[]> {
    this.loadingAssignments$.next(true);
    return this.service.getDelegationAssignments(cd).pipe(
      catchError((err) => {
        this.loadingAssignments$.next(false);
        return throwError(err);
      }),
      tap(() => this.loadingAssignments$.next(false))
    );
  }

  deleteConfiguredAssignment(delegationid: number, assignmentId: number): Observable<Response> {
    this.deletingAssignment$.next(true);
    return this.service.deleteConfiguredAssignment(delegationid, assignmentId).pipe(
      catchError((err) => {
        this.deletingAssignment$.next(false);
        return throwError(err);
      }),
      tap(() => this.deletingAssignment$.next(false))
    );
  }

  downloadAssignmentReport(delegationId: string): Observable<Blob | null> {
    this.downloadCSV$.next(true);
    return this.service.downloadAssignmentReport(delegationId).pipe(
      catchError((err) => throwError(err)),
      finalize(() => this.downloadCSV$.next(false))
    );
  }

  getTransitionSemester(): Observable<string> {
    return this.service.getCurrentSemester().pipe(map((res) => res.name));
  }
}
