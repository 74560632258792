import { Kiosk } from '@starfish-access/models';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServerKiosk } from '../orgs.models';

export const serverToKiosk = (serverKiosk: ServerKiosk): Kiosk => ({
  id: serverKiosk.organizationId,
  name: serverKiosk.organizationName,
  description: '',
  parentId: undefined,
});

export const kioskToServer = (kiosk: Kiosk): ServerKiosk => ({
  organizationId: kiosk.id,
  organizationName: kiosk.name,
});

export const serversToKiosks = (serverKiosks: ServerKiosk[]): Kiosk[] => serverKiosks.map(serverToKiosk);

export const kiosksToServers = (kiosks: Kiosk[]): ServerKiosk[] => kiosks.map(kioskToServer);

export const serverModelToKiosks = () => pipe(map(serversToKiosks));

export const serverModelToKiosk = () => pipe(map(serverToKiosk));

export const kioskToServerPipe = () => pipe(map(kioskToServer));
