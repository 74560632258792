import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as FailedJobsActions from './failedjobs.actions';
import { FailedJob } from './failedjobs.model';

export interface FailedJobsState {
  hasFailed: boolean;
  hasSucceeded: boolean;
  isFetching: boolean;
  isRetrying: boolean;
  isDeleting: boolean;
  errorMsg: string;
  failedJobsList: FailedJob[];
}

export const initialState: FailedJobsState = {
  hasFailed: false,
  hasSucceeded: false,
  isFetching: false,
  isRetrying: false,
  isDeleting: false,
  errorMsg: '',
  failedJobsList: [],
};

export const selectFailedJobs = createFeatureSelector<FailedJobsState>('failedJobsList');
export const selectFailedJobsHasFailed = createSelector(selectFailedJobs, (f) => f.hasFailed);
export const selectFailedJobsHasSucceeded = createSelector(selectFailedJobs, (f) => f.hasSucceeded);
export const selectFailedJobsIsFetching = createSelector(selectFailedJobs, (f) => f.isFetching);
export const selectFailedJobsIsRetrying = createSelector(selectFailedJobs, (f) => f.isRetrying);
export const selectFailedJobsIsDeleting = createSelector(selectFailedJobs, (f) => f.isDeleting);
export const selectFailedJobsError = createSelector(selectFailedJobs, (f) => f.errorMsg);
export const selectFailedJobsList = createSelector(selectFailedJobs, (f) => f.failedJobsList);

export const failedListReducer = (
  state = initialState,
  action: FailedJobsActions.FailedJobsActions
): FailedJobsState => {
  switch (action.type) {
    case FailedJobsActions.REQUEST_JOBS: {
      return Object.assign({}, state, {
        isFetching: true,
        hasFailed: false,
        isRetrying: false,
      });
    }
    case FailedJobsActions.RECEIVE_JOBS: {
      return Object.assign({}, state, {
        failedJobsList: action.payload,
        isFetching: false,
      });
    }
    case FailedJobsActions.RETRY_JOB: {
      return Object.assign({}, state, {
        isRetrying: true,
        hasFailed: false,
        hasSucceeded: false,
      });
    }
    case FailedJobsActions.RETRY_JOB_SUCCESS: {
      // console.log('success!!!');
      const updatedList = state.failedJobsList.slice();
      removeFromList(updatedList, action.payload.jobId);
      return Object.assign({}, state, {
        failedJobsList: updatedList,
        hasSucceeded: true,
        isRetrying: false,
        isDeleting: false,
      });
    }

    case FailedJobsActions.DELETE_JOB: {
      // console.log('DELETE_JOB from REDUCER');
      return Object.assign({}, state, {
        isDeleting: true,
        hasFailed: false,
        hasSucceeded: false,
      });
    }
    case FailedJobsActions.DELETE_JOB_SUCCESS: {
      // console.log('DELETE_JOB_SUCCESS from REDUCER');
      const updatedList = state.failedJobsList.slice();
      removeFromList(updatedList, action.payload.jobId);
      return Object.assign({}, state, {
        failedJobsList: updatedList,
        hasSucceeded: true,
        isDeleting: false,
      });
    }

    case FailedJobsActions.CANCEL_JOB: {
      // console.log('CANCEL_JOB from REDUCER');
      return Object.assign({}, state, {
        isDeleting: true,
        hasFailed: false,
        hasSucceeded: false,
      });
    }
    case FailedJobsActions.CANCEL_JOB_SUCCESS: {
      // console.log('CANCEL_JOB_SUCCESS from REDUCER');
      const updatedList = state.failedJobsList.slice();
      removeFromList(updatedList, action.payload.jobId);
      return Object.assign({}, state, {
        failedJobsList: updatedList,
        hasSucceeded: true,
        isDeleting: false,
      });
    }

    case FailedJobsActions.ERROR_JOBS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      // console.error('Failed Jobs Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
      });
    }
    default: {
      return state;
    }
  }
};

export const removeFromList = (jobsList: FailedJob[], jobId: string) => {
  // console.log('removeFromList: ' + jobId);
  let itemToRemove: FailedJob | undefined;
  for (const item of jobsList) {
    if (item.jobId === jobId) {
      itemToRemove = item;
    }
  }
  if (itemToRemove) {
    const index = jobsList.indexOf(itemToRemove);
    if (index > -1) {
      jobsList.splice(index, 1);
    }
  }
};
