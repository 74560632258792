import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Delegation } from '../model/delegation.model';
import { DelegateService } from '../services/delegate.service';

@Pipe({ name: 'delegationById' })
export class DelegationByIdPipe implements PipeTransform {
  constructor(private service: DelegateService) {}
  transform(delId: string | null): Observable<Delegation | null> {
    if (!delId) {
      return of(null); // without an id we can't actually do anything
    }

    return this.service.getDelegationGroupById(delId).pipe(map((res) => res));
  }
}
