import { Action, createReducer, on } from '@ngrx/store';
import {
  clearSearchInput,
  destroyTypeaheadComponent,
  initTypeaheadComponent,
  searchForTerm,
  searchForTermComplete,
  searchForTermError,
} from './typeahead.actions';
import { User } from './user.model';

export const TYPEAHEAD_FEATURE_KEY = 'typeahead';

export interface TypeaheadComponentState {
  searchInProgress: boolean;
  hasSearched: boolean;
  error: string | undefined;
  currentTerm: string | undefined;
  results: User[] | undefined;
  isSpecialSearch: boolean | undefined;
}

export interface TypeaheadState {
  components: { [key: string]: TypeaheadComponentState };
}

export const initialTypeaheadState: TypeaheadState = {
  components: {},
};

export const initialTypeaheadComponentState: TypeaheadComponentState = {
  searchInProgress: false,
  hasSearched: false,
  error: undefined,
  currentTerm: undefined,
  results: undefined,
  isSpecialSearch: undefined,
};

let components: { [key: string]: TypeaheadComponentState };
let thisComponent: TypeaheadComponentState;

const typeaheadReducer = createReducer(
  initialTypeaheadState,
  on(initTypeaheadComponent, (state, { componentId }) => {
    components = { ...state.components };
    components[componentId] = initialTypeaheadComponentState;
    return {
      ...state,
      components,
    };
  }),
  on(destroyTypeaheadComponent, (state, { componentId }) => {
    components = { ...state.components };
    delete components[componentId];
    return {
      ...state,
      components,
    };
  }),
  on(searchForTerm, (state, { componentId, term, type }) => {
    components = { ...state.components };
    if (state.components[componentId]) {
      thisComponent = {
        ...components[componentId],
        searchInProgress: true,
        error: undefined,
        currentTerm: term,
        results: undefined,
        isSpecialSearch: type !== searchForTerm.type,
      };
      components[componentId] = thisComponent;
    }
    return {
      ...state,
      components,
    };
  }),
  on(searchForTermComplete, (state, { componentId, results, type }) => {
    components = { ...state.components };
    if (components[componentId]) {
      thisComponent = {
        ...components[componentId],
        searchInProgress: false,
        results,
        hasSearched: true,
      };
      components[componentId] = thisComponent;
    }
    return {
      ...state,
      components,
    };
  }),
  on(searchForTermError, (state, { componentId, error }) => {
    components = { ...state.components };
    if (components[componentId]) {
      thisComponent = {
        ...components[componentId],
        searchInProgress: false,
        error: error ? error.toString() : undefined,
        hasSearched: true,
      };
      components[componentId] = thisComponent;
    }
    return {
      ...state,
      components,
    };
  }),
  on(clearSearchInput, (state, { componentId }) => {
    components = { ...state.components };
    if (components[componentId]) {
      thisComponent = {
        ...components[componentId],
        error: undefined,
        results: undefined,
      };
      components[componentId] = thisComponent;
    }
    return {
      ...state,
      components,
    };
  })
);

export const reducer = (state: TypeaheadState, action: Action): TypeaheadState => typeaheadReducer(state, action);
