import { Routes } from '@angular/router';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard, StarfishGuard, UserListPermissions } from '@starfish-access/core';
import { DynamicRoleComponent } from './dynamic-role.component';
import { EditRoleGroupComponent } from './edit-role-group/edit-role-group.component';
import { EditRoleComponent } from './edit-role/edit-role.component';

export const routes: Routes = [
  {
    title: 'createrole',
    path: 'createrole',
    canActivate: [AuthGuard, FerpaGuard, StarfishGuard],
    component: DynamicRoleComponent,
    data: {
      permissions: [UserListPermissions.VIEW],
    },
  },
  {
    path: 'editrole',
    canActivate: [AuthGuard, FerpaGuard, StarfishGuard],
    component: EditRoleComponent,
    data: {
      permissions: [UserListPermissions.VIEW],
    },
  },
  {
    path: 'editrolegroup',
    canActivate: [AuthGuard, FerpaGuard, StarfishGuard],
    component: EditRoleGroupComponent,
    data: {
      permissions: [UserListPermissions.VIEW],
    },
  },
];
