<div class="assignment-report mat-typography">
  <mat-dialog-content class="mat-typography view-report-display">
    <h2>Role Report</h2>
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z0 report-table"
      matSort>
      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
      <ng-container matColumnDef="userName">
        <th
          mat-header-cell
          *matHeaderCellDef>
          User Name
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{
            element.userName !== element.userId ? element.userName : (element.userId | searchServiceGetNamePipe | async)
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="userId">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          User ID
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.userId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="roleName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Role Name
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.roleName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="target">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Target
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.target | reportRoleRelationship }}
        </td>
      </ng-container>

      <ng-container matColumnDef="service">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Service
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.service }}
        </td>
      </ng-container>

      <ng-container matColumnDef="kiosk">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header>
          Kiosk
        </th>
        <td
          mat-cell
          *matCellDef="let element">
          {{ element.kiosk }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      [mat-dialog-close]="false"
      data-cy="close-n-at"
      color="primary"
      cdkFocusInitial
      mat-dialog-close>
      Close
    </button>

    <button
      mat-flat-button
      [mat-dialog-close]="true"
      color="primary"
      data-cy="download-and-close"
      cdkFocusInitial>
      Download and Close
    </button>
  </mat-dialog-actions>
</div>
