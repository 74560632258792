import { Component, HostListener, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { OkayDialogService } from '@starfish-access/core';
import { Delegation, RoleConfig } from '@starfish-access/models';
import { clone, reject } from 'ramda';
import { Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { DelegationCreateFacade } from './delegation-create.facade';

@Component({
  templateUrl: './delegation-create.component.html',
  styleUrls: ['./delegation-create.component.scss'],
})
export class DelegationCreateComponent implements OnDestroy {
  delegationFormGroup: UntypedFormGroup;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public router: Router,
    public facade: DelegationCreateFacade,
    private dialogService: OkayDialogService
  ) {
    this.delegationFormGroup = this.fb.group({ delegation: [[]] });
  }

  @HostListener('window:beforeunload', ['$event'])
  handleBrowserUnload($event: any) {
    if (!this.delegationFormGroup?.get('delegation')?.pristine) {
      $event.returnValue = 'You have not completed creating this delegation.  Do you want to discard your changes?';
    }
  }

  markRoleAsDeleted(roleDelegationConfig: RoleConfig) {
    this.facade
      .confirmRoleDeletion(roleDelegationConfig.displayName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res && roleDelegationConfig) {
          this.deletionConfirmation(roleDelegationConfig);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  formSubmit($event: any): void {
    const delegation: Delegation = this.delegationFormGroup?.get('delegation')?.value; // So we know what we're creating

    this.facade
      .createDelegation(delegation)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => this.dialogService.okay('Group Not Created', this.generateError(err)))
      )
      .subscribe((res) => {
        const created = (res as any)?.id !== undefined;
        if (created) {
          this.delegationFormGroup.reset();
          this.dialogService.okay(
            'Delegation Group Created',
            'Successfully created a new delegation group. It is now available to the assigned directors.'
          );
          this.router.navigate(['delegations']);
        }
      });
  }

  private generateError(err: any): string {
    let description = 'We could not process your group at this time';
    // eslint-disable-next-line eqeqeq
    if (err == 400) {
      // i want to compare to 400 regardless of type
      description = 'We were not able to process this new group at this time.';
      // eslint-disable-next-line eqeqeq
    } else if (err == 500) {
      description = 'You must choose at least one role to assign to this group';
    }
    return description;
  }
  private deletionConfirmation(roleConfigToDelete: RoleConfig) {
    const formDelModel: Delegation = this.delegationFormGroup.get('delegation')?.value;

    const isMarkedForDeletion = (rc: RoleConfig) => rc.displayName === roleConfigToDelete.displayName;

    const rcList = clone(formDelModel.roleConfigs);
    const newRcList = reject(isMarkedForDeletion, rcList);

    formDelModel.roleConfigs = newRcList;

    // update the form with the new list, having deleted the previous entries
    this.delegationFormGroup.get('delegation')?.patchValue(formDelModel);
  }
}
