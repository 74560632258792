import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogService, DialogService, TOOLTIP_DELAY } from '@starfish-access/core';
import { Kiosk } from '@starfish-access/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KiosksFacade } from './kiosks.facade';

@Component({
  templateUrl: './kiosks.component.html',
  styleUrls: ['./kiosks.component.scss'],
})
export class KiosksComponent implements OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['name', 'id', 'delete'];
  dataSource: MatTableDataSource<Kiosk> = new MatTableDataSource();

  tooltipDelay: string = TOOLTIP_DELAY;

  private isDeletingKiosk: boolean;
  private deletedKiosk: string[] = [];
  private destroy$ = new Subject<void>();

  constructor(
    public confirmDialogService: ConfirmDialogService,
    public dialogService: DialogService,
    public facade: KiosksFacade
  ) {
    this.facade
      .kioskList()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.dataSource.data = res;
        // set timeout because we are in an ngIf. If the ngIf goes away this can move to ngafterviewinit
        setTimeout(() => {
          this.paginator.pageSizeOptions = [10, 25, 50, res.length];
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSize = 10;
          this.dataSource.sort = this.sort;
        });
      });
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  // TODO replace this with an observable from the facade jrm
  isActivelyDeleting(kioskId: string): boolean {
    for (const id of this.deletedKiosk) {
      if (id === kioskId) {
        return this.isDeletingKiosk;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
