import { Action } from '@ngrx/store';
import { CompleteApproval, TaskDetails } from '@starfish-access/models';

export const POST_COMPLETED = '[ApproveDetails] Post completed - resetting';
export const POST_TASK_DETAILS = '[ApproveDetails] POST Task Details';
export const POST_SUCCESS_TASK_DETAILS = '[ApproveDetails] POST Success Task Details';
export const REQUEST_TASK_DETAILS = '[ApproveDetails] Request Task Details';
export const RECEIVE_TASK_DETAILS = '[ApproveDetails] Receive Task Details';
export const ERROR_TASK_DETAILS = '[ApproveDetails] Error From Task Details';

export class PostTaskDetails implements Action {
  readonly type = POST_TASK_DETAILS;
  constructor(public payload: CompleteApproval) {}
}

export class PostTaskDetailsSuccess implements Action {
  readonly type = POST_SUCCESS_TASK_DETAILS;
}

export class CompleteAddProcess implements Action {
  readonly type = POST_COMPLETED;
}

export class RequestTaskDetails implements Action {
  readonly type = REQUEST_TASK_DETAILS;
  constructor(public payload: string) {}
}

export class ReceiveTaskDetails implements Action {
  readonly type = RECEIVE_TASK_DETAILS;
  constructor(public payload: TaskDetails) {}
}

export class ErrorTaskDetails implements Action {
  readonly type = ERROR_TASK_DETAILS;
  constructor(public payload: string) {}
}

export type ApproveActions =
  | PostTaskDetails
  | PostTaskDetailsSuccess
  | CompleteAddProcess
  | RequestTaskDetails
  | ReceiveTaskDetails
  | ErrorTaskDetails;
