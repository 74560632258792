import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as RolesActions from './roles.actions';
import { User } from './roles.model';

export interface RolesState {
  hasFailed: boolean;
  isFetchingStewards: boolean;
  isFetchingDus: boolean;
  errorMsg: string;
  stewardsList: User[];
  dusList: User[];
}

const initialState: RolesState = {
  hasFailed: false,
  isFetchingStewards: false,
  isFetchingDus: false,
  errorMsg: '',
  stewardsList: [],
  dusList: [],
};

export const selectRoles = createFeatureSelector<RolesState>('rolesList');
export const selectRolesHasFailed = createSelector(selectRoles, (r) => r.hasFailed);
export const selectRolesIsFetchingStewards = createSelector(selectRoles, (r) => r.isFetchingStewards);
export const selectRolesIsFetchingDUS = createSelector(selectRoles, (r) => r.isFetchingDus);
export const selectRolesError = createSelector(selectRoles, (r) => r.errorMsg);
export const selectRolesStewardList = createSelector(selectRoles, (r) => r.stewardsList);
export const selectRolesDUSList = createSelector(selectRoles, (r) => r.dusList);

// TODO add an 'is posting' fetching boolean and include the posts here

export const rolesListReducer = (state = initialState, action: RolesActions.RoleActions): RolesState => {
  switch (action.type) {
    case RolesActions.DELETE_SUCCESS_DATA_STEWARD: {
      const updatedList = state.stewardsList.slice();
      removeFromList(updatedList, action.payload);
      return Object.assign({}, state, {
        stewardsList: updatedList,
        isFetchingStewards: false,
      });
    }

    case RolesActions.POST_SUCCESS_DATA_STEWARD: {
      return Object.assign({}, state, {
        isFetchingStewards: false,
      });
    }
    case RolesActions.REQUEST_DATA_STEWARDS: {
      return Object.assign({}, state, {
        hasFailed: false,
        isFetchingStewards: true,
      });
    }
    case RolesActions.RECEIVE_DATA_STEWARDS: {
      return Object.assign({}, state, {
        stewardsList: action.payload,
        isFetchingStewards: false,
      });
    }

    case RolesActions.DELETE_SUCCESS_DUS: {
      const updatedList = state.dusList.slice();
      removeFromList(updatedList, action.payload);
      return Object.assign({}, state, {
        dusList: updatedList,
        isFetchingDus: false,
      });
    }

    case RolesActions.POST_SUCCESS_DUS: {
      return Object.assign({}, state, {
        isFetchingDus: false,
      });
    }

    case RolesActions.REQUEST_DUS: {
      return Object.assign({}, state, {
        hasFailed: false,
        isFetchingDus: true,
      });
    }
    case RolesActions.RECEIVE_DUS: {
      return Object.assign({}, state, {
        dusList: action.payload,
        isFetchingDus: false,
      });
    }
    case RolesActions.ERROR_DATA_STEWARDS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      // console.error('Roles (DS) Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
      });
    }
    case RolesActions.ERROR_DUS: {
      let err = action.payload;
      if (err === 'undefined') {
        err = 'An unknown error has occurred';
      }
      // console.error('Roles (DUS) Reducer: ' + err);
      return Object.assign({}, state, {
        isFetching: false,
        hasFailed: true,
        errorMsg: err,
      });
    }
    default: {
      return state;
    }
  }
};

export const removeFromList = (userList: User[], userId: string) => {
  let itemToRemove: User | undefined;
  for (const item of userList) {
    if (item.userId === userId) {
      itemToRemove = item;
    }
  }
  if (itemToRemove) {
    const index = userList.indexOf(itemToRemove);
    if (index > -1) {
      userList.splice(index, 1);
    }
  }
};
