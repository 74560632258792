import {
  DataStewardRolePermissions,
  DelegationPermissions,
  DusRolePermissions,
  FailedJobPermissions,
  KioskPermissions,
  KioskServicePermissions,
  UserListPermissions,
} from '../../core/permissions/starfish-permissions';
import { NavSection } from './nav-section.model';

export const navViews: NavSection[] = [
  {
    views: [
      {
        name: 'Home',
        icon: 'home',
        routerLink: ['/'],
      },
      {
        name: 'Tasks',
        icon: 'assignment',
        routerLink: ['tasklist'],
        requiresAuth: true,
        requiresFerpa: false,
        requiresStarfish: false,
      },
    ],
  },
  {
    heading: 'Administration',
    views: [
      {
        name: 'Kiosks',
        icon: 'devices',
        routerLink: ['kiosks'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [KioskPermissions.VIEW],
      },
      {
        name: 'Services',
        icon: 'room_service',
        routerLink: ['services'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [KioskServicePermissions.VIEW],
      },
      {
        name: 'Role Configs',
        icon: 'edit',
        routerLink: ['editrole'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [UserListPermissions.VIEW],
      },
      {
        name: 'Role Groups',
        icon: 'pie_chart',
        routerLink: ['editrolegroup'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [UserListPermissions.VIEW],
      },
      {
        name: 'Course Mapping',
        icon: 'golf_course',
        routerLink: ['coursemappinglist'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        hasCourses: true,
        permissions: [DusRolePermissions.VIEW],
      },
      {
        name: 'SRRS Admins',
        icon: 'group',
        routerLink: ['roles'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [DataStewardRolePermissions.VIEW, DusRolePermissions.VIEW],
      },
      {
        name: 'Starfish Users',
        icon: 'person_outline',
        routerLink: ['users'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: false,
        permissions: [UserListPermissions.VIEW],
      },
      {
        name: 'User Details',
        icon: 'person_outline',
        routerLink: ['userdetails'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: false,
        requiredFeatureToggle: 'enableNewStarfishUsersPage',
        permissions: [UserListPermissions.VIEW],
      },
      {
        name: 'Failed Jobs',
        icon: 'new_releases',
        routerLink: ['failedjobs'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [FailedJobPermissions.VIEW],
      },
    ],
  },
  {
    heading: 'Delegated Mgmt',
    views: [
      {
        name: 'Delegation Setup',
        icon: 'waves',
        routerLink: ['delegations'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
        permissions: [DelegationPermissions.VIEW],
      },
      {
        name: 'Role Management',
        icon: 'select_all',
        routerLink: ['area-directors'],
        requiresAuth: true,
        requiresFerpa: true,
        requiresStarfish: true,
      },
    ],
  },
];
