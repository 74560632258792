<div class="sf-org-select">
  <uic-typeahead
    #typeahead
    [typeaheadConfig]="taConfig"
    [errorMatcher]="typeaheadErrorMatcher"
    (selectionMade)="itemSelected($event)"
    (inputBlurred)="inputBlurred($event)">
    <mat-error>
      <ng-content select="mat-error"></ng-content>
    </mat-error>
  </uic-typeahead>

  <mat-chip-listbox selectable="false">
    <mat-chip-option
      *ngFor="let org of orgsFc?.value | sortOrgs"
      [removable]="config.removable"
      [attr.data-cy]="org.displayName + 'select-thing'"
      (removed)="removeSelectedItem(org)"
      >{{ org.displayName }}
      <mat-icon
        matChipRemove
        *ngIf="config.removable"
        >cancel</mat-icon
      >
    </mat-chip-option>
  </mat-chip-listbox>
</div>
