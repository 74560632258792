<h2
  class="mat-h2"
  mat-dialog-title
  data-cy="error-title-dialog">
  Error Deleting this {{ data.type | titlecase }}
</h2>
<mat-dialog-content class="mat-typography svc-error-dialog">
  <h3>You can't delete this {{ data.type | lowercase }} yet</h3>
  <p data-cy="error-title-descriptions">
    This {{ data.type | lowercase }} cannot be deleted because it is part of other roles, delegations and/or
    assignments. In the space below refer to the specific items that this {{ data.type | lowercase }} is still a member
    of. When those relationships have been removed, this {{ data.type | lowercase }} can be deleted.
  </p>

  <h3 class="mat-h3">This is part of the following associations</h3>

  <div class="border-display detail-padding">
    <div *ngIf="data.conflictError?.delegationRolesWithOrg?.length > 0">
      <div *ngFor="let delRole of data.conflictError?.delegationRolesWithOrg">
        <div class="mat-body-1">Delegation Group: {{ delRole.roleName }}</div>
        <ul
          class="chip-space-list"
          *ngIf="delRole.delegationNames?.length > 0">
          <li *ngFor="let delName of delRole.delegationNames">{{ delName }}</li>
        </ul>
      </div>
    </div>
    <div *ngIf="data.conflictError?.delegatedUserRolesWithOrg?.length > 0">
      <mat-divider></mat-divider>
      <div>
        The following users are assigned this {{ data.type | lowercase }} through a delegation
        <ul
          role="list"
          class="section-spacing">
          <li
            role="listitem"
            *ngFor="let assignment of data.conflictError?.delegatedUserRolesWithOrg">
            {{ assignment.user }} in {{ assignment.role }}
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="data.conflictError?.nondelegatedUserRolesWithOrg?.length > 0">
      <mat-divider></mat-divider>

      <div>
        The following users are assigned this {{ data.type | lowercase }} through a user requested role or admin
        assignment
        <ul
          role="list"
          class="section-spacing">
          <li
            role="listitem"
            *ngFor="let assignment of data.conflictError?.nondelegatedUserRolesWithOrg">
            {{ assignment.user }} in {{ assignment.role }}
          </li>
        </ul>
      </div>
    </div>
    <div *ngIf="data.conflictError?.delegationsWithCalendarmanagerKiosk">
      <mat-divider></mat-divider>
      <div mat-subheader>
        Part of the following delegations with Calendar Management
        <ul role="list">
          <li
            role="listitem"
            *ngFor="let mgr of data.conflictError?.delegationsWithCalendarmanagerKiosk">
            {{ mgr }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-flat-button
    [cdkCopyToClipboard]="data | svcErrorDetails"
    color="primary">
    Copy Details
  </button>

  <button
    mat-flat-button
    mat-dialog-close
    color="primary">
    OK
  </button>
</mat-dialog-actions>
