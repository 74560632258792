<div class="workflow-header-component">
  <sf-workflow-icon
    class="workflow-icon-wrapper"
    [taskStatus]="processDetailsStatus ? processDetailsStatus : taskDetails?.taskStatus">
  </sf-workflow-icon>
  <div
    class="header-inner-wrapper"
    *ngIf="taskDetails?.taskStatus !== 'loading...'">
    <div class="header-column">
      <span class="header-label">Status</span>
      <span data-cy="process-details-status">{{
        (processDetailsStatus ? processDetailsStatus : taskDetails?.taskStatus) | initialCaps
      }}</span>
    </div>
    <div class="header-column">
      <span class="header-label">Task ID</span>
      <span>#{{ taskDetails?.taskId }}</span>
    </div>
    <div class="header-column">
      <span class="header-label">Requested by</span>
      <span
        ><a
          href="{{ psuDirectoryLink }}{{ taskDetails?.requester }}"
          target="_blank"
          >{{ taskDetails?.requester }}</a
        ></span
      >
    </div>
  </div>
  <div class="spacer"></div>
  <div *ngIf="taskDetails?.taskStatus !== 'CANCELLED'">
    <span
      *ngIf="!(submitInProgress | async) && !isRequestView"
      class="request-details-buttons">
      <button
        mat-stroked-button
        data-cy="complete-submit"
        *ngIf="isReturned()"
        class="approve-buttons"
        (click)="completeRequest('APPROVE')">
        Submit
      </button>
      <button
        mat-stroked-button
        data-cy="approve-submit"
        *ngIf="!isReturned()"
        [attr.data-cy]="'btn-approve'"
        class="approve-buttons"
        (click)="completeRequest('APPROVE')">
        Approve
      </button>
      <div
        class="split-buttons-div"
        *ngIf="!isReturned()">
        <uic-split-button
          (action)="completeRequest($event)"
          [actions]="splitButtonCancel">
        </uic-split-button>
      </div>
    </span>
  </div>
  <span *ngIf="submitInProgress | async">
    <mat-spinner
      diameter="48"
      color="primary"></mat-spinner>
  </span>
</div>
