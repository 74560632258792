import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationModule } from '@psu/components/notification-ngrx';
import { FilterModule } from '@starfish-access/core';
import { WorkflowHeaderModule } from '../header/workflow-header.module';
import { WorkflowHeaderDisplayModule } from '../headerdisplay/header-display.module';
import { WorkflowDetailsComponent } from './workflow-details.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NotificationModule,
    MatTableModule,
    FlexLayoutModule,
    FilterModule,
    WorkflowHeaderDisplayModule,
    WorkflowHeaderModule,
  ],
  declarations: [WorkflowDetailsComponent],
  exports: [WorkflowDetailsComponent],
  providers: [],
})
export class WorkflowDetailsModule {}
