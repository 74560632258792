import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

// Backend will return latest version of an endpoint, unless
// overridden here
const versionedEndpoints = {
  '/resources/roles': 'application/vnd-psu.edu-v1+json',
};

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = checkVersion(req, versionedEndpoints);
    return next.handle(req);
  }
}

export const checkVersion = (req: HttpRequest<any>, versions: { [key: string]: string }): HttpRequest<any> => {
  Object.entries(versions).forEach(([key, value]) => {
    if (req.url.includes(key)) {
      req = req.clone({
        setHeaders: { Accept: value },
      });
    }
  });
  return req;
};
