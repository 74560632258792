<div class="area-director-list">
  <h3 class="mat-h3 director-list-heading">All Delegated Managers for this Role</h3>
  <span
    >NOTE: This is for review purposes only. If changes are needed, contact
    <a href="mailto:starfish@psu.edu?subject=Changes Are Needed to a Delegated Manager in a Role">starfish@psu.edu</a
    >.</span
  >
  <table
    *ngIf="directorList?.length > 0"
    mat-table
    [dataSource]="dataSource">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- User Name Column -->
    <ng-container matColumnDef="name">
      <th
        mat-header-cell
        *matHeaderCellDef>
        User Name
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.displayName | searchServiceGetNamePipe | async }}
      </td>
    </ng-container>

    <!-- User ID Column -->
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef>
        User ID
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.id }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <div
    *ngIf="!directorList || directorList.length === 0"
    class="body-bold no-entries">
    This Delegation Group has no managers associated with it.
  </div>
</div>
