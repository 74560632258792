import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
} from '@angular/forms';
import { ConfiguredAssignments, UserInfo } from '@starfish-access/models';

interface UserAssignmentDetails {
  rolesKey: string;
  usersKey: string;
  currentAssignments: ConfiguredAssignments[];
}

/*
    Validator Function and Directive that will evaluate a set of user
    ids against a specific assignment, to verify that the user is not already 
    part of the assignment. 
    
    Uses two form field inputs for verification aganst an input parameter
*/
export const validAssignmentValidator =
  (validAssignment: UserAssignmentDetails): ValidatorFn =>
  (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const roleControl = formGroup.get(validAssignment.rolesKey);
    const usersControl = formGroup.get(validAssignment.usersKey);
    const existingAssignments = validAssignment.currentAssignments ? validAssignment.currentAssignments : [];

    // let the required directive handle this case.
    if (!roleControl || !usersControl || !existingAssignments || existingAssignments.length === 0) {
      return null;
    }

    const userList: UserInfo[] = usersControl.value;
    const roleSelected: any = roleControl.value[0]; // in a valid case, we only have the 1st index

    let found = '';
    existingAssignments.forEach((previousAssignment) => {
      userList.forEach((assignedUser) => {
        if (previousAssignment.role.roleId === roleSelected.id && previousAssignment.user.id === assignedUser.id) {
          found = assignedUser.displayName + ' is already assigned to ' + roleSelected.displayName + '';
        }
      });
    });

    return found.length === 0 ? null : { invalidAssignment: found };
  };

@Directive({
  selector:
    // eslint-disable-next-line @angular-eslint/directive-selector
    '[validAssignment][formControlName],[validAssignment][formControl],[validAssignment][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidAssignmentSelectedDirective,
      multi: true,
    },
  ],
})
export class ValidAssignmentSelectedDirective implements Validator {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('validAssignment') validAssignment: UserAssignmentDetails;
  validate(control: AbstractControl): ValidationErrors | null {
    return validAssignmentValidator(this.validAssignment)(control);
  }
}
