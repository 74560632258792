import { Component } from '@angular/core';

@Component({
  selector: 'sf-delete-dialog',
  template: `
    <h1 mat-dialog-title>Deletion in Progress</h1>
    <div mat-dialog-content>
      Deleting relationships for this assignment. This will take some time.
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  `,
})
export class DeleteDialogComponent {}
