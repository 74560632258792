import { Injectable } from '@angular/core';
import { PermissionCheckService } from '@psu/apis/fortress';
import {
  ConfirmDialogService,
  DialogService,
  KioskServicePermissions,
  OkayDialogService,
  ServiceService,
  SvcErrorDialogService,
} from '@starfish-access/core';
import { GenericServiceDeletionStatus, KioskSvc } from '@starfish-access/models';
import { findIndex, of, prepend, propEq, reject, update } from 'ramda';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class KioskSvcFacade {
  readonly canView$ = this.permissionCheckService.hasPermission(KioskServicePermissions.VIEW);
  readonly canAdd$ = this.permissionCheckService.hasPermission(KioskServicePermissions.ADD);
  readonly canEdit$ = this.permissionCheckService.hasPermission(KioskServicePermissions.EDIT);
  readonly canDelete$ = this.permissionCheckService.hasPermission(KioskServicePermissions.DELETE);

  readonly hasFailed$ = new ReplaySubject<boolean>();
  readonly isLoadingData$ = new ReplaySubject<boolean>();
  private serviceData$ = new ReplaySubject<KioskSvc[]>();
  private originalDataArray: KioskSvc[];

  constructor(
    private permissionCheckService: PermissionCheckService,
    private dialogService: DialogService,
    private okayDialogService: OkayDialogService,
    private confirmDialogService: ConfirmDialogService,
    private serviceService: ServiceService,
    private errorDialog: SvcErrorDialogService
  ) {
    this.serviceService
      .getServices()
      .pipe(take(1))
      .subscribe((res) => {
        this.originalDataArray = res;
        this.serviceData$.next(res);
      });
  }

  serviceList(): Observable<KioskSvc[]> {
    return this.serviceData$.asObservable();
  }

  addNewService(): void {
    this.dialogService
      .serviceDialog({
        title: 'Create a New Service',
        saveBtnText: 'Create',
        forbiddenName: 'kiosk',
      })
      .pipe(
        take(1),
        mergeMap((res) => {
          if ((res as any) !== '') {
            return this.serviceService.createService(res);
          }
          return of(undefined);
        }),
        catchError(() => of(null))
      )
      .subscribe((res: KioskSvc | null) => {
        if (typeof res !== 'string' && res) {
          this.updateList(res);
        } else if (res === null) {
          // we have an error
          this.okayDialogService.okay(
            'Failed to Create Service',
            'We could not create the service at this time. You can try again or contact starfish@psu.edu for assistance.'
          );
        }
      });
  }

  sum(a: number, b: number): number {
    return a + b;
  }

  editExistingService(service: KioskSvc): void {
    this.dialogService
      .serviceDialog({
        title: 'Modify this Existing Service',
        service,
        disableNameInput: true,
        saveBtnText: 'Save',
        forbiddenName: 'kiosk',
      })
      .pipe(
        take(1),
        mergeMap((res) => {
          if (res) {
            return this.serviceService.updateService(res);
          }
          return of(undefined);
        }),
        catchError(() => of(null))
      )
      .subscribe((res) => {
        if (typeof res !== 'string' && res) {
          this.editList(res);
        } else if (res === null) {
          // we have an error
          this.okayDialogService.okay(
            'Failed to Edit Service',
            'We could not edit the service at this time. You can try again or contact starfish@psu.edu for assistance.'
          );
        }
      });
  }

  deleteExistingService(id: string) {
    this.confirmDialogService
      .confirmMessage({
        title: 'Delete Service',
        message: 'Are you sure? Deleting this Service cannot be undone',
      })
      .subscribe((confirm) => {
        if (confirm) {
          this.serviceService
            .deleteService(id)
            .pipe(
              take(1),
              map((res) => ({ successfulDeletion: true })),
              catchError((e) => {
                const errorResponse: GenericServiceDeletionStatus = {
                  successfulDeletion: false,
                  errorCode: e.errorMessage,
                  serverResponseCode: e.status,
                  conflictError: e.error,
                  type: 'SERVICE',
                };

                return of(errorResponse);
              })
            )
            .subscribe((res: GenericServiceDeletionStatus) => {
              if (!res.successfulDeletion && res.serverResponseCode === 409) {
                this.errorDialog.errorDisplay(res);
              } else if (!res.successfulDeletion) {
                this.okayDialogService.okay(
                  'Failed to Delete Service',
                  'We could not delete this service at this time.'
                );
              } else {
                this.deleteFromList(id);
              }
            });
        }
      });
  }

  private updateList(svc: KioskSvc) {
    this.originalDataArray = prepend(svc, this.originalDataArray);
    this.serviceData$.next(this.originalDataArray);
  }

  private deleteFromList(svcId: string) {
    const isSvcToRemove = (k: KioskSvc) => k.id === svcId;
    this.originalDataArray = reject(isSvcToRemove, this.originalDataArray);
    this.serviceData$.next(this.originalDataArray);
  }

  private editList(svc: KioskSvc) {
    const index: number = findIndex(propEq('id', svc.id))(this.originalDataArray);
    this.originalDataArray = update(index, svc, this.originalDataArray);
    this.serviceData$.next(this.originalDataArray);
  }
}
