import { Pipe, PipeTransform } from '@angular/core';
import { GenericServiceDeletionStatus } from '@starfish-access/models';

@Pipe({ name: 'svcErrorDetails' })
export class ErrorDetailsPipe implements PipeTransform {
  transform(gsds: GenericServiceDeletionStatus | null): string {
    if (!gsds) {
      return 'Could not build error details';
    }

    let result = 'Error Details: \n';
    const errorToEval = gsds?.conflictError;

    if (errorToEval?.delegationRolesWithOrg) {
      result += 'Delegation Groups: \n';
      for (const roleConfigService of errorToEval?.delegationRolesWithOrg) {
        result += roleConfigService.roleName;

        if (roleConfigService.delegationNames) {
          result += ' roles -> (';
          for (const user of roleConfigService.delegationNames) {
            result += user + ' ';
          }
          result += ') ';
        }
        result += '\n';
      }
    }

    if (errorToEval?.delegatedUserRolesWithOrg) {
      result += 'The following users are assigned this item through a delegation: \n';
      for (const roleConfigService of errorToEval?.delegatedUserRolesWithOrg) {
        result += '(' + roleConfigService.user + ' in ' + roleConfigService.role + ')\n';
      }
    }

    if (errorToEval?.nondelegatedUserRolesWithOrg) {
      result += 'The following users are assigned this item through a user requested role or admin assignment: \n';
      for (const roleConfigService of errorToEval?.nondelegatedUserRolesWithOrg) {
        result += '(' + roleConfigService.user + ' in ' + roleConfigService.role + ')\n';
      }
    }

    if (
      errorToEval?.delegationsWithCalendarmanagerKiosk &&
      errorToEval?.delegationsWithCalendarmanagerKiosk?.length > 0
    ) {
      result += 'Part of the following delegations with Calendar Management : \n';
      for (const roleConfigService of errorToEval?.delegationsWithCalendarmanagerKiosk) {
        result += '(' + roleConfigService + ')\n';
      }
    }
    return result === 'Error Details: \n' ? 'Could not build error details' : result;
  }
}
