import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { DelegateService } from '@starfish-access/core';
import { ClassConfig, ClientTransitionType, CourseConfig, UserInfo } from '@starfish-access/models';
import { differenceWith, filter, innerJoin, of, propEq } from 'ramda';
import { BehaviorSubject, forkJoin, Observable, timer } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { buildStudentList } from '../../directors.facade';
import { ConnectedStudentForm } from '../../multi-student/multi-student.component';

export const buildCourseConfig = (
  fromRole: CourseConfig | undefined,
  fromAssignment: CourseConfig | undefined
): CourseConfig | undefined => {
  if (!fromRole && !fromAssignment) {
    return undefined;
  }

  if (!fromAssignment) {
    return fromRole;
  }
  if (!fromRole) {
    return fromAssignment;
  }
  const base: CourseConfig = fromRole;
  base.campus = fromAssignment.campus ? fromAssignment.campus : fromRole.campus;
  base.semester = fromAssignment.semester ? fromAssignment.semester : fromRole.semester;
  base.educationalTrack = fromAssignment.educationalTrack ? fromAssignment.educationalTrack : fromRole.educationalTrack;
  const classArray: ClassConfig[] = fromAssignment.classConfigArray
    ? fromAssignment.classConfigArray
    : fromRole.classConfigArray;
  base.classConfigArray = classArray.map(fixClassConfigArray);

  return base;
};

export const fixClassConfigArray = (input: ClassConfig): ClassConfig => {
  const cc: ClassConfig = {
    course: input.course ? input.course : 'ALL',
    section: input.section ? input.section : 'ALL',
    subject: input.subject ? input.subject : 'ALL',
  };
  return cc;
};

export const validateUserType = (
  usersToVerify: UserInfo[],
  psuIdList: UserInfo[],
  userIdList: UserInfo[],
  returnInvalid?: boolean
): UserInfo[] => {
  const verifiedIds: UserInfo[] = [];
  psuIdList.map((res) => verifiedIds.push(res));
  userIdList.map((res) => verifiedIds.push(res));

  if (returnInvalid) {
    return differenceWith((x: UserInfo, y: UserInfo) => x.id === y.id, usersToVerify, verifiedIds);
  }

  return innerJoin((x: UserInfo, y: UserInfo) => x.id === y.id, usersToVerify, verifiedIds);
};

export const createValidator =
  (facade: AssignUserFacade, returnInvalid: boolean): AsyncValidatorFn =>
  (control: AbstractControl): Observable<ValidationErrors | null> =>
    timer(300).pipe(
      mergeMap(() => {
        const val: ConnectedStudentForm | undefined = control.get('connectedUsers')?.value;

        const usersToVerify = buildStudentList(val);

        return facade.userValidationCheck(usersToVerify, returnInvalid).pipe(
          map((result: UserInfo[]) => (result?.length > 0 ? { invalidAsyncUsers: result } : null)),
          catchError((e) => of(null))
        );
      })
    );

export const isUserInForm = (missingUsers: UserInfo[], idToKeep: string): boolean => {
  const isEqualToId = propEq('id', idToKeep);
  return filter(isEqualToId, missingUsers).length === 1;
};

export const isRejectedIdOnForm = (alreadyOnForm: UserInfo[], missingUsers: UserInfo[]): UserInfo[] => {
  const trimmedList: UserInfo[] = [];
  alreadyOnForm?.forEach((element) => {
    const b = isUserInForm(missingUsers, element.id);
    if (!b) {
      trimmedList.push(element);
    }
  });
  return trimmedList;
};

export const compareNewAssignmentSemester = (
  courseSemester: string | undefined,
  currentSemester: string
): ClientTransitionType => {
  if (!courseSemester) {
    return 'NOT_TRANSITIONABLE';
  }
  if (courseSemester !== currentSemester) {
    return 'TRANSITIONABLE';
  }
  return 'TRANSITIONED';
};

@Injectable()
export class AssignUserFacade {
  downloadingCsv$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingMissingStudents$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  transitionSemester$ = this.getTransitionSemester();

  constructor(private service: DelegateService) {}

  userValidationCheck(usersToVerify: UserInfo[], returnInvalid: boolean): Observable<UserInfo[]> {
    this.loadingMissingStudents$.next(true);
    return forkJoin([
      this.service.userValidationSearch(usersToVerify, '.bulkSearchPsuid'),
      this.service.userValidationSearch(usersToVerify, '.bulkSearchUserid'),
    ]).pipe(
      map(([foundPsuIds, foundUserIds]) => validateUserType(usersToVerify, foundPsuIds, foundUserIds, returnInvalid)),
      catchError((e) => of([])),
      finalize(() => this.loadingMissingStudents$.next(false))
    );
  }

  getTransitionSemester(): Observable<string> {
    return this.service.getCurrentSemester().pipe(map((res) => res.name));
  }
}
