<div
  class="cal-kiosk-list"
  *ngIf="kiosksList?.length > 0">
  <h3 class="mat-h3 cal-kiosk-list-heading">Student Calendar Manager Kiosks</h3>

  <mat-chip-set
    role="list"
    aria-label="Kiosks Selected For the Student Calendar Managers">
    <mat-chip
      role="listitem"
      *ngFor="let kiosk of kiosksList"
      >{{kiosk.orgName}}</mat-chip
    >
  </mat-chip-set>
</div>
