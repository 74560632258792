<span class="sf-typeahead-select mat-typography">
  <uic-typeahead-search
    [config]="typeaheadConfig"
    [searchResults]="searchResults$ | async"
    [loadingResults]="searchInProgress$ | async"
    (searchTermChange)="searchTermChange$.next($event)"
    (selectedResult)="searchResultSelected($event)">
  </uic-typeahead-search>

  <div
    *ngIf="config.showSummary"
    data-cy="associated-students-role">
    You have {{ selectedItems?.value?.length }} students in this role that you can edit.
  </div>

  <mat-chip-listbox
    selectable="false"
    *ngIf="!config.showSummary">
    <mat-chip-option
      *ngFor="let thing of selectedItems?.value | sortDisplayName"
      [removable]="config.removableItems"
      [attr.data-cy]="'chip-' + thing.displayName"
      (removed)="removeSelectedItem(thing)"
      >{{ thing.displayName }}
      <mat-icon
        matChipRemove
        [attr.data-cy]="'chip-' + thing.displayName + '-remove'"
        *ngIf="config.removableItems"
        >cancel</mat-icon
      >
    </mat-chip-option>
  </mat-chip-listbox>
</span>
