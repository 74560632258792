<div class="sf-orientation-dialog">
  <mat-dialog-content class="mat-typography">
    <h1 data-cy="eula-title">Welcome to Starfish Role Management</h1>

    <p>
      Starfish is a cloud-based software package that facilitates communication among academic advisers, instructors,
      students, and others who are here to support student learning.
    </p>

    <p>
      Access to Starfish is granted to users who need a particular Starfish role to perform their assigned job at Penn
      State. Departments are required to regularly review, verify, and update access.
    </p>

    <h2 data-cy="eula-terms">By accepting a role in Starfish, you are accepting the responsibility to:</h2>
    <ol>
      <li class="li-tag">Protect the privacy of student records by using them only to perform your job.</li>
      <li class="li-tag">Consult Starfish records made by others.</li>
      <li class="li-tag">Follow up on tracking items (flags, kudos, referrals, and to-dos).</li>
      <li class="li-tag">
        Record the recommendations and policy interpretations you shared with students in Starfish.
      </li>
    </ol>

    <div
      fxLayoutAlign="center center"
      class="eula-checkbox">
      <mat-checkbox
        color="primary"
        data-cy="eula-checkbox"
        [(ngModel)]="checked">
      </mat-checkbox>
      <div>
        <div class="checkbox-text body-bold">Clicking here denotes your acceptance to these terms.</div>
        <div class="checkbox-text body-bold">(You will only see this message once)</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="center"
    class="training-buttons">
    <a
      mat-stroked-button
      utResponsive
      class="training-button"
      data-cy="decline-eula"
      color="primary"
      href="https://sites.psu.edu/starfishinfo"
      >Nevermind</a
    >
    <button
      mat-flat-button
      utResponsive
      color="primary"
      data-cy="agree-to-eula"
      [mat-dialog-close]="true"
      [disabled]="!checked"
      cdkFocusInitial>
      Let's Go!
    </button>
  </mat-dialog-actions>
</div>
