import { Pipe, PipeTransform } from '@angular/core';
import { ClassConfig, CourseConfig } from '../model/delegation.model';

@Pipe({ name: 'targetTooltip' })
export class TargetTooltipPipe implements PipeTransform {
  transform(cc: CourseConfig | null): string {
    if (!cc) {
      return '';
    }

    if (cc.classConfigArray.length > 15) {
      const arrayOfRemovedItems = cc.classConfigArray.splice(14);
      const formattedString = cc.classConfigArray.map(this.classToString).toString();
      return formattedString.replace(',', '') + 'and ' + arrayOfRemovedItems.length + ' more...';
    }

    return cc.classConfigArray.map(this.classToString).toString().replace(',', '');
  }

  private classToString(cc: ClassConfig): string {
    return cc.subject + '.' + cc.course + '.' + cc.section + '\r\n';
  }
}
