import { Component } from '@angular/core';
import { ClientCourseChannelResponse } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { CourseListFacade } from './course-list.facade';

@Component({
  selector: 'sf-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent {
  courseMappingList$: Observable<ClientCourseChannelResponse[]>;
  unMappedCourseList$: Observable<string[]>;

  constructor(private facade: CourseListFacade) {
    this.courseMappingList$ = this.facade.getAllMappedCourses();
    this.unMappedCourseList$ = this.facade.getUnmappedCourses();
  }
}
