import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

@Component({
  templateUrl: './user-select-dialog.html',
})
export class UserSelectDialogComponent {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  users: string[];
  comment = '';
  selectedValue: string;

  constructor(public dialogRef: MatDialogRef<UserSelectDialogComponent>, private ngZone: NgZone) {}

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }
}
