<mat-form-field
  appearance="outline"
  fxFlex>
  <mat-icon
    matPrefix
    aria-disabled="true"
    class="sf-users-search-icon">
    search
  </mat-icon>
  <mat-label>Filter by User ID</mat-label>
  <input
    type="text"
    data-cy="filter-starfish-users"
    placeholder="Filter by User ID"
    aria-label="Filter by User ID"
    matInput
    [formControl]="userManagementFilter" />
  <mat-icon
    matSuffix
    *ngIf="userManagementFilter?.value?.length > 0"
    aria-disabled="true"
    (click)="clearFilter()"
    class="sf-users-clear-icon">
    clear
  </mat-icon>
</mat-form-field>
