import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'reportRoleRelationship' })
export class ReportRoleRelationshipsPipe implements PipeTransform {
  transform(relationship: string): string {
    const relationshipMap: { [key: string]: string } = {
      ONE_TO_ONE: '1:1',
      COURSE: 'Courses',
      COURSES: 'Courses',
      ORGANIZATION: 'Organizations',
      ORG: 'Organizations',
      ALL_STUDENTS: 'All Students',
    };
    return relationshipMap[relationship] || '';
  }
}
