import { Pipe, PipeTransform } from '@angular/core';
import { OrgSelectItem } from '@starfish-access/shared';

@Pipe({
  name: 'serviceToOrg',
})
export class ServiceToOrgPipe implements PipeTransform {
  transform(value: { id: string; name: string }[]): OrgSelectItem[] {
    if (!value) {
      return [];
    }
    return value.map((v) => ({
      id: v.id,
      displayName: `${v.name} [${v.id}]`,
      orgName: v.name,
    }));
  }
}
