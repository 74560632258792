<section
  class="spaced-cards resources-wrapper"
  *ngIf="!(errorMessage | async) && !hasFailed; else error_message">
  <mat-card
    appearance="outlined"
    class="result"
    fxLayout="column">
    <mat-card-header>
      <mat-card-title *ngIf="isFetching || !hasData()">
        <h2>Resource Management and Approval</h2>
      </mat-card-title>
      <mat-card-title *ngIf="!isFetching && hasData()">
        <h2>
          Resource Management and Approval<small>&nbsp;&nbsp;&nbsp;Task #{{ taskId }}</small>
        </h2>
      </mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
      <div *ngIf="!hasData()">
        <p>&nbsp;&nbsp; Task data not found.</p>
      </div>
      <div
        *ngIf="hasData()"
        fxLayout="column">
        <span *ngIf="isFetching && !hasFailed">
          <br />
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </span>
        <span *ngIf="isFetching && hasFailed"> </span>
        <span
          *ngIf="!isFetching"
          fxLayout="column">
          <span
            >Here DUS members can confirm that the Users, Kiosks, and Services below have been added to Starfish.</span
          >

          <mat-list>
            <div
              class="list-header mat-body-2"
              *ngIf="hasUsers()">
              <span fxFlex>Users Requesting Starfish Roles</span>
              <span>Confirm</span>
            </div>
            <mat-divider
              aria-hidden="true"
              *ngIf="hasUsers()"></mat-divider>
            <div *ngFor="let user of resourcesApproval.userRoles">
              <mat-list-item class="service-mat-list-item">
                <mat-icon matListItemIcon>account_circle</mat-icon>
                <h4
                  matListItemTitle
                  *ngIf="getDisplayName(user.user)">
                  <b>{{ getDisplayName(user.user) }}</b>
                </h4>

                <mat-slide-toggle
                  matListItemMeta
                  matTooltip="Confirm"
                  [matTooltipShowDelay]="tooltipDelay"
                  color="primary"
                  data-cy="users-slide-toggle"
                  (change)="toggledUser(user.user, $event)">
                </mat-slide-toggle>

                <div matListItemTitle>
                  <span data-cy="roles-user-info"
                    >User: {{ user.user | searchServiceGetNamePipe | async }} [{{ user.user }}]</span
                  >
                  <br />
                  <span>Requested Roles:</span>
                  <mat-chip-set role="list">
                    <mat-chip
                      role="listitem"
                      *ngFor="let role of user.requestedRoles"
                      >{{ role }}</mat-chip
                    >
                  </mat-chip-set>
                </div>
              </mat-list-item>
            </div>
            <div
              *ngIf="hasKiosks()"
              class="list-header mat-body-2">
              <span fxFlex>Users Requesting Kiosk Access</span>
              <span>Confirm</span>
            </div>
            <mat-divider *ngIf="hasKiosks()"></mat-divider>
            <div *ngFor="let kiosk of resourcesApproval.kioskUpdates">
              <mat-list-item class="service-mat-list-item">
                <mat-icon matListItemIcon>devices</mat-icon>
                <h4 matListItemTitle>
                  <b>{{ kiosk.kioskName }}</b>
                </h4>

                <mat-slide-toggle
                  matListItemMeta
                  matTooltip="Confirm"
                  data-cy="kiosk-slide-toggle"
                  [matTooltipShowDelay]="tooltipDelay"
                  [color]="accent"
                  (change)="toggledKiosk(kiosk)">
                </mat-slide-toggle>

                <div
                  *ngFor="let user of kiosk.userRoles"
                  matListItemTitle>
                  <span data-cy="kiosks-user-info"
                    >User: {{ user.user | searchServiceGetNamePipe | async }} [{{ user.user }}]</span
                  >
                  <br />
                  <span>Requested Roles:</span>
                  <mat-chip-listbox>
                    <mat-chip-option *ngFor="let role of user.requestedRoles">{{ role }}</mat-chip-option>
                  </mat-chip-listbox>
                  <br />
                </div>
              </mat-list-item>
            </div>
            <div
              *ngIf="hasServices()"
              class="list-header mat-body-2">
              <span fxFlex>Users Requesting Service Access</span>
              <span>Confirm</span>
            </div>
            <mat-divider *ngIf="hasServices()"></mat-divider>
            <div *ngFor="let service of resourcesApproval.serviceUpdates">
              <mat-list-item class="service-mat-list-item">
                <mat-icon matListItemIcon>room_service</mat-icon>
                <h4 matListItemTitle>
                  <b>{{ service.serviceName }}</b>
                </h4>

                <mat-slide-toggle
                  matListItemMeta
                  matTooltip="Confirm"
                  [matTooltipShowDelay]="tooltipDelay"
                  [color]="accent"
                  data-cy="service-slide-toggle"
                  (change)="toggledService(service)">
                </mat-slide-toggle>

                <div
                  *ngFor="let user of service.userRoles"
                  matListItemTitle>
                  <span data-cy="services-user-info"
                    >User: {{ user.user | searchServiceGetNamePipe | async }} [{{ user.user }}]</span
                  >
                  <br />
                  <span>Requested Roles:</span>
                  <mat-chip-listbox>
                    <mat-chip-option *ngFor="let role of user.requestedRoles">{{ role }}</mat-chip-option>
                  </mat-chip-listbox>
                </div>
              </mat-list-item>
            </div>
          </mat-list>
        </span>
      </div>
    </mat-card-content>
    <mat-card-actions fxLayoutAlign="end start">
      <span *ngIf="!submitInProgress && hasData()">
        <span
          matTooltip="Submit"
          class="submit-button"
          [matTooltipShowDelay]="tooltipDelay"
          ><a
            [disabled]="submitInProgress"
            mat-mini-fab
            routerLink="."
            (click)="completeRequest()">
            <mat-icon>chevron_right</mat-icon>
          </a></span
        >
      </span>
      <span *ngIf="submitInProgress">
        <mat-spinner
          diameter="40"
          color="primary"></mat-spinner>
      </span>
    </mat-card-actions>
  </mat-card>
</section>

<ng-template #error_message>
  <div class="error_message">
    <span> <strong>ERROR: </strong>{{ getErrors(errorMessage) }} </span>
  </div>
</ng-template>
