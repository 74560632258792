import { Pipe, PipeTransform } from '@angular/core';

interface IdUser {
  id: string;
  displayName: string;
}

@Pipe({ name: 'sortDisplayName' })
export class SortUserNamePipe implements PipeTransform {
  transform(arr: IdUser[] | null): IdUser[] {
    if (!arr) {
      return [];
    }

    return arr.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
}
