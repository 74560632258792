<mat-form-field appearance="outline">
  <mat-label>Select a Group</mat-label>
  <mat-select
    formControlName="group"
    data-cy="edit-existing-role-in-rolegroup">
    <mat-option
      *ngFor="let group of roleGroups | async"
      [value]="group"
      [attr.data-cy]="group.name + '-group-item'">
      {{ group.name }}
    </mat-option>
  </mat-select>
  <mat-error data-cy="select-group-error">You must select a group</mat-error>
</mat-form-field>
