import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { KioskSvc } from '../model/kiosk-services.model';
import { ServiceConfig, ServiceDialogComponent } from './service-dialog.component';
import { TextAreaDialog } from './textarea-dialog.component';

@Injectable()
export class DialogService {
  constructor(public dialog: MatDialog) {}

  serviceDialog(serviceConfig: ServiceConfig): Observable<KioskSvc> {
    const dialogRef: MatDialogRef<ServiceDialogComponent> = this.dialog.open(ServiceDialogComponent, {
      width: '600px',
    });
    dialogRef.componentInstance.serviceConfig = serviceConfig;
    return dialogRef.afterClosed();
  }

  textAreaDialog(title: string): Observable<string> {
    const dialogRef = this.dialog.open(TextAreaDialog, { width: '400px' });
    dialogRef.componentInstance.title = title;
    return dialogRef.afterClosed();
  }
}
