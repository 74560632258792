import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { FailedJob } from './failedjobs.model';

@Injectable()
export class FailedJobsService {
  private static endPoint = 'failedJobs';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getAllFailedJobs(): Observable<FailedJob[]> {
    // build the URL
    FailedJobsService.URL = this.starfishConfig.starfishServices + FailedJobsService.endPoint;

    return this.http
      .get<FailedJob[]>(FailedJobsService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));

    // For Mock Data Only
    // return Observable.from([FAILED_JOBS]);
  }

  retryFailedJob(failedJob: FailedJob): Observable<Response> {
    // build the URL
    FailedJobsService.URL =
      this.starfishConfig.starfishServices + FailedJobsService.endPoint + '/' + failedJob.jobId + '/retry';

    return this.http
      .put<Response>(FailedJobsService.URL, failedJob, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  deleteFailedJob(failedJob: FailedJob): Observable<Response> {
    // build the URL
    FailedJobsService.URL = this.starfishConfig.starfishServices + FailedJobsService.endPoint + '/' + failedJob.jobId;
    // console.log('DELETING FAILED JOB: ' + FailedJobsService.URL);
    return this.http
      .delete<Response>(FailedJobsService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  cancelProcess(failedJob: FailedJob): Observable<Response> {
    // build the URL
    const endPoint = 'processes';
    FailedJobsService.URL =
      this.starfishConfig.starfishServices + endPoint + '/' + failedJob.processInstanceId + '/cancel';
    // console.log('CANCELING PROCESS: ' + FailedJobsService.URL);
    return this.http
      .post<Response>(FailedJobsService.URL, failedJob, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }
}
