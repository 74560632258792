import { Pipe, PipeTransform } from '@angular/core';
import { RoleConfig } from '@starfish-access/models';
import { OrgSelectItem } from '@starfish-access/shared';

@Pipe({ name: 'roleToSelect' })
export class RoleToSelectPipe implements PipeTransform {
  transform(role: RoleConfig[]): OrgSelectItem[] {
    return role.map(this.singleTransform);
  }

  private singleTransform(role: RoleConfig): OrgSelectItem {
    return {
      displayName: role.displayName,
      id: role.roleId ? role.roleId.toString() : '',
      orgName: role.displayName,
    };
  }
}
