import { Component, Input, OnInit } from '@angular/core';
import { ClientRoleGroup } from '@starfish-access/models';
import * as R from 'ramda';
import { Subject } from 'rxjs';
import { ColorGroup } from './color-selector.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent implements OnInit {
  @Input() colorSelection: Subject<string>;
  @Input() roleGroups: ClientRoleGroup[];
  @Input() loadedRoleGroup: ClientRoleGroup;
  cg: ColorGroup;
  // 32
  colorArray: string[] = [
    'blue',
    'green',
    'gray',
    'light-blue',
    'red',
    'navy',
    'orange',
    'gray1',
    'gray2',
    'purps1',
    'purps2',
    'purps3',
    'purps4',
    'purps5',
    'red1',
    'red2',
    'red3',
    'red4',
    'red5',
    'green1',
    'green2',
    'green3',
    'green4',
    'green5',
    'green6',
    'blue1',
    'blue2',
    'blue3',
    'blue4',
    'orange1',
    'orange2',
    'orange3',
  ];

  constructor() {}

  ngOnInit(): void {
    this.cg = this.randomColor();
    if (this.loadedRoleGroup) {
      this.colorSelection.next(this.loadedRoleGroup.iconColor);
    } else {
      this.colorSelection.next(this.cg.color1);
    }
  }

  resetColors(): void {
    this.cg = this.randomColor();
  }

  randomColor(): ColorGroup {
    const usedColors: string[] = [];
    for (const rg of this.roleGroups) {
      usedColors.push(rg.iconColor);
    }

    // R.difference will remove all previously used colors, so we don't allow the user to repeat a color choice
    const reducedArray: string[] = R.difference([...this.colorArray], usedColors);
    const color1: string = this.random(reducedArray);
    const icon1index: number = reducedArray.indexOf(color1);
    const reducedArray2: string[] = R.remove(icon1index, 1, reducedArray);
    const color2: string = this.random(reducedArray2);
    const icon2index = reducedArray2.indexOf(color2);
    const reducedArray3: string[] = R.remove(icon2index, 1, reducedArray2);
    const color3: string = this.random(reducedArray3);

    const ig: ColorGroup = {
      color1,
      color2,
      color3,
    };

    return ig;
  }

  random(array: string[]): string {
    const randomNum = Math.round(Math.random() * (array.length - 1));
    const color: string = array[randomNum];
    return color;
  }
}
