import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BehaviorSubject } from 'rxjs';

export interface SelectedDateRange {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'sf-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Output()
  dateChange: EventEmitter<SelectedDateRange> = new EventEmitter();

  afterTimeValue = ''; // start date
  beforeTimeValue = ''; // end date
  humanReadableDate: BehaviorSubject<string> = new BehaviorSubject('Choose a Date Range');
  minDate: Date = new Date();
  maxDate: Date = new Date();

  constructor() {
    this.minDate.setDate(this.minDate.getDate() - 90); // 3 months ago
    this.maxDate.setDate(this.maxDate.getDate() + 1);
  }

  ngOnInit(): void {
    const now: Date = new Date();
    const then: Date = new Date();
    then.setDate(now.getDate() - 14);
    this.dateUpdatedStart({ value: then } as any);
    this.dateUpdatedEnd({ value: now } as any);
  }

  dateUpdatedStart(event: MatDatepickerInputEvent<Date>): void {
    let d: Date = new Date();
    const eventDate = event.value;
    if (eventDate != null) {
      d = eventDate;
    }
    // isostring returns something like: 2019-10-24T16:53:52.578Z"
    this.afterTimeValue = d.toISOString();

    if (this.beforeTimeValue?.length > 0) {
      this.humanReadableDate.next(
        formatDate(this.afterTimeValue, 'mediumDate', 'en-US') +
          ' - ' +
          formatDate(this.beforeTimeValue, 'mediumDate', 'en-US')
      );
    } else {
      this.humanReadableDate.next(d.toDateString());
    }
  }

  dateUpdatedEnd(event: MatDatepickerInputEvent<Date>): void {
    let d: Date = new Date();
    const eventDate = event.value;
    if (eventDate != null) {
      d = eventDate;
    }

    // isostring returns something like: 2019-10-24T16:53:52.578Z"
    this.beforeTimeValue = d.toISOString();
    if (this.afterTimeValue?.length > 0) {
      this.humanReadableDate.next(
        formatDate(this.afterTimeValue, 'mediumDate', 'en-US') +
          ' - ' +
          formatDate(this.beforeTimeValue, 'mediumDate', 'en-US')
      );
    } else {
      this.humanReadableDate.next(d.toDateString());
    }
    this.emitDateChange();
  }

  private emitDateChange() {
    const dateChange: SelectedDateRange = {
      startDate: new Date(this.beforeTimeValue),
      endDate: new Date(this.afterTimeValue),
    };
    this.dateChange.emit(dateChange);
  }
}
