import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { RequestDUS, RequestStewards } from './roles.actions';
import { User } from './roles.model';

@Injectable()
export class DUSGroupService {
  private static dsPoint = 'dataStewardGroup';
  private static dusPoint = 'dusGroup';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  addDataStewards(newSteward: User): Observable<Action> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dsPoint;
    return this.http
      .post<Response>(DUSGroupService.URL, newSteward, {
        headers: this.headers,
      })
      .pipe(
        map(() => new RequestStewards()), // reload the stewards list with the new guy
        catchError(handleErrorFromRest)
      );
  }

  getDataStewards(): Observable<User[]> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dsPoint;
    return this.http
      .get<User[]>(DUSGroupService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(() => []));
  }

  removeDataSteward(userId: string): Observable<Response> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dsPoint + '/' + userId;
    return this.http
      .delete<Response>(DUSGroupService.URL, {
        headers: this.headers,
      })
      .pipe(
        // .map((response: Response)  => response.json())

        catchError(handleErrorFromRest)
      );
  }

  addDus(newDus: User): Observable<Action> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dusPoint;
    return this.http
      .post<Response>(DUSGroupService.URL, newDus, {
        headers: this.headers,
      })
      .pipe(
        map(() => new RequestDUS()), // reload the dus list with the new guy
        catchError(handleErrorFromRest)
      );
  }

  getDus(): Observable<User[]> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dusPoint;
    return this.http
      .get<User[]>(DUSGroupService.URL, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  removeDus(userId: string): Observable<Response> {
    // build the URL
    DUSGroupService.URL = this.starfishConfig.starfishServices + DUSGroupService.dusPoint + '/' + userId;

    return this.http
      .delete<Response>(DUSGroupService.URL, {
        headers: this.headers,
      })
      .pipe(
        // .map((response: Response)  => response.json())

        catchError(handleErrorFromRest)
      );
  }
}
