import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { DialogModule } from './dialog/dialog.module';
import { FerpaGuard } from './guard/ferpa.guard';
import { StarfishGuard } from './guard/starfish.guard';
import { OkayDialogModule } from './okay-dialog/okay-dialog.module';
import { CourseChannelService } from './services/course-channel.service';
import { DelegateService } from './services/delegate.service';
import { StarfishFerpaService } from './services/directory/directory.service';
import { KioskService } from './services/kiosk/kiosk.service';
import { OrganizationService } from './services/orgs.service';
import { OrientationTrainingHttpService } from './services/orientation/training-http.service';
import { OrientationTrainingService } from './services/orientation/training.service';
import { RolesService } from './services/roles.service';
import { SearchService } from './services/search.service';
import { ServiceService } from './services/service/service.service';
import { UserPreferencesHttpService } from './services/user-preferences/user-preferences-http.service';
import { UserPreferencesService } from './services/user-preferences/user-preferences.service';
import { UserAccountService } from './services/useraccount/useraccount.service';
import { ValidAssignmentSelectedDirective } from './validators/assignment.validator';

@NgModule({
  imports: [CommonModule, DialogModule, ConfirmDialogModule, OkayDialogModule],
  declarations: [ValidAssignmentSelectedDirective],
  providers: [
    FerpaGuard,
    StarfishGuard,
    RolesService,
    DelegateService,
    UserAccountService,
    OrganizationService,
    KioskService,
    ServiceService,
    CourseChannelService,
    StarfishFerpaService,
    UserPreferencesService,
    UserPreferencesHttpService,
    OrientationTrainingService,
    OrientationTrainingHttpService,
    SearchService,
  ],
})
export class CoreModule {}
