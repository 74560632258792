import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../roles.model';

@Component({
  templateUrl: './roledisplay.component.html',
  styleUrls: ['./roledisplay.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-role-display',
})
export class RoleDisplayComponent {
  @Input() canAddUser: Observable<boolean>;
  @Input() canDeleteUser: Observable<boolean>;
  @Input() userList: Observable<User[]>;
  @Input() matTableColumns: string[];
  @Input() displayName: string;
  @Input() isFetching: Observable<boolean>;
  @Input() hasFailed: Observable<boolean>;

  @Output() deleteUserEmitter: EventEmitter<string> = new EventEmitter();
  @Output() addUserEmitter: EventEmitter<User> = new EventEmitter();

  addUser(user: User): void {
    this.addUserEmitter.emit(user);
  }

  deleteUser(userId: string) {
    this.deleteUserEmitter.emit(userId);
  }
}
