import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItem } from '../../model';
import { CourseMenuItem } from '../../model/menu-item.model';
import { Campus, Course, EducationalTrack } from '../../model/server/course-info.model';

export const campusToMenuItem = (campus: Campus): MenuItem => ({
  id: campus.id,
  displayName: campus.name,
});

export const campusesToMenuItems = (campuses: Campus[]): MenuItem[] => campuses.map(campusToMenuItem);

export const campusesToMenuItemsPipe = pipe(map(campusesToMenuItems));

export const educationalTrackToMenuItem = (track: EducationalTrack): MenuItem => ({
  id: track.id,
  displayName: track.name,
});

export const educationalTracksToMenuItems = (tracks: EducationalTrack[]): MenuItem[] =>
  tracks.map(educationalTrackToMenuItem);

export const educationalTracksToMenuItemsPipe = pipe(map(educationalTracksToMenuItems));

export const semesterToMenuItem = (semester: string): MenuItem => ({
  id: semester,
  displayName: semester,
});

export const semestersToMenuItems = (semesters: string[]): MenuItem[] => semesters.map(semesterToMenuItem);

export const semestersToMenuItemsPipe = pipe(map(semestersToMenuItems));

export const createCacheKey = (campus?: string, track?: string, termName?: string, department?: string): string =>
  `${campus}${track}${termName}${department}`;

export const departmentsToMenuItemsPipe = pipe(
  map((departments: string[]) =>
    departments.map((department: string) => ({
      id: department,
      displayName: department,
    }))
  )
);

export const courseToMenuItem = (course: Course): CourseMenuItem => ({
  course: {
    id: course.courseNumber,
    displayName: course.courseNumber,
  },
  sections: course.sectionNumbers?.map((sectionNumber: string) => ({
    id: sectionNumber,
    displayName: sectionNumber,
  })),
});

export const coursesToMenuItems = (courses: Course[]): CourseMenuItem[] => courses?.map(courseToMenuItem);

export const coursesToMenuItemsPipe = pipe(map(coursesToMenuItems));
