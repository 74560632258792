import { Injectable } from '@angular/core';
import { NotificationService } from '@psu/components/notification-ngrx';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, mergeMap, shareReplay, switchMap, tap } from 'rxjs/operators';
import { OrientationTrainingHttpService } from './training-http.service';

@Injectable()
export class OrientationTrainingService {
  // Use this readonly member for anything that needs to check sf orientation training
  readonly getEula$: Observable<boolean | null>;

  private defaultTraining = false;
  private isLoading = new BehaviorSubject<boolean>(false);
  private isUpdating = new BehaviorSubject<boolean>(false);
  private loadTraining = new BehaviorSubject<void>(undefined);
  private loadedTraining = this.loadTraining.pipe(
    tap(() => this.isLoading.next(true)),
    mergeMap(() =>
      this.service.getEula().pipe(
        catchError((e) => {
          // if we can't reach the get service, we should provide a default
          if (e.startsWith('50')) {
            this.notificationService.error({
              message: 'There was a problem checking your Starfish Training.',
            });
            // an unexpected error from the get endpoint.
            // We'll return null, since nothing has been set.
            return of(null);
          }
          // a 4xx series means the user was not found by the get endpoint.
          // so this user has never agreed to the status - we'll return false.
          return of(this.defaultTraining);
        }),
        finalize(() => this.isLoading.next(false))
      )
    )
  );
  private updateEula = new Subject<boolean>();
  private updatedEula = this.updateEula.pipe(
    tap(() => this.isUpdating.next(true)),
    switchMap(() =>
      this.service.updateEula().pipe(
        tap(() => this.loadTraining.next()),
        mergeMap(() => this.loadedTraining),
        catchError((e) => {
          // if we can't reach the post service, we should provide a default
          if (e.startsWith('50')) {
            this.notificationService.error({
              message: 'There was a problem checking your Starfish Training.',
            });
            return of(null);
          }
          // repeated post calls give us a 400 response. Which means the
          // call was successful and the eula agreement value was already set to true.
          return of(true);
        }),
        finalize(() => this.isUpdating.next(false))
      )
    )
  );

  constructor(private notificationService: NotificationService, private service: OrientationTrainingHttpService) {
    this.getEula$ = merge(this.loadedTraining, this.updatedEula).pipe(shareReplay(1));
  }

  updateEulaAgreement(): Observable<boolean | null> {
    this.updateEula.next(true);
    return this.getEula$;
  }
}
