import { Kiosk, KioskSvc, RequestItem, ServerRole } from '@starfish-access/models';

export const sortKiosks = (kiosks: Kiosk[]): Kiosk[] => {
  // build the kiosk list...
  const kioskList: Kiosk[] = [];
  for (const kiosk of kiosks) {
    kioskList.push(kiosk);
  }
  // then sort it...
  kioskList.sort((a: any, b: any) => {
    if (a['name'] < b['name']) {
      return -1;
    } else if (a['name'] > b['name']) {
      return 1;
    } else {
      return 0;
    }
  });
  return kioskList;
};

export const sortServices = (services: KioskSvc[]): KioskSvc[] => {
  // build the services list...
  const servicesList: KioskSvc[] = [];
  for (const service of services) {
    servicesList.push(service);
  }
  // then sort it...
  servicesList.sort((a: any, b: any) => {
    if (a['name'] < b['name']) {
      return -1;
    } else if (a['name'] > b['name']) {
      return 1;
    } else {
      return 0;
    }
  });
  return servicesList;
};

export const buildKioskRequestItem = (kioskFromDropdownValue: any): RequestItem => {
  const kioskRequestItem: RequestItem = {
    type: 'kiosk-request',
    kiosks: kioskFromDropdownValue ? kioskFromDropdownValue : [],
  };
  return kioskRequestItem;
};

export const buildServiceRequestItem = (servicesFromDropdownValue: any): RequestItem => {
  const serviceRequestItem: RequestItem = {
    type: 'service-request',
    services: servicesFromDropdownValue ? servicesFromDropdownValue : [],
  };
  return serviceRequestItem;
};

export const buildRoleRequestItem = (roleid: string): RequestItem => {
  const selectedRoles: ServerRole[] = [];
  const role: ServerRole = buildGeneralRole(+roleid);
  selectedRoles.push(role);

  const roleRequestItem: RequestItem = {
    type: 'role-request',
    roles: selectedRoles,
    calendarRoleUsers: [],
    calendarRoleSemesters: [],
  };
  return roleRequestItem;
};

export const buildGeneralRole = (id: number): ServerRole => {
  // Build Role
  const generalRole: ServerRole = {
    id,
  };
  return generalRole;
};
