import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DelegateService, KioskService, RolesService } from '@starfish-access/core';
import { Delegation } from '@starfish-access/models';
import { OrgSelectItem } from '@starfish-access/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, startWith, tap } from 'rxjs/operators';
import { RoleDeletionDialogComponent } from '../role-deletion-dialog/role-deletion-dialog.component';
import { convertToSelectableRoles, filterDelegatableRoles } from './delegation-create.facade.utils';

@Injectable()
export class DelegationCreateFacade {
  readonly delegatableRoles$ = this.roleService.getAllRoleGroups().pipe(
    startWith([]),
    filterDelegatableRoles,
    convertToSelectableRoles,
    finalize(() => this.isLoading$.next(false))
  );

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  readonly kioskList$: Observable<OrgSelectItem[]> = this.kioskService.getKiosks().pipe(
    map((res) => {
      const modelList: OrgSelectItem[] = [];
      res.forEach((element) => {
        modelList.push({
          id: element.id,
          displayName: element.name,
          orgName: element.name,
        });
      });
      return modelList;
    })
  );

  constructor(
    private delegateService: DelegateService,
    private roleService: RolesService,
    private kioskService: KioskService,
    public dialog: MatDialog
  ) {}

  confirmRoleDeletion(roleName: string): Observable<boolean> {
    return this.dialog
      .open(RoleDeletionDialogComponent, {
        width: '30vw',
        data: {
          numberOfAssignments: 0,
          roleNameToDelete: roleName,
        },
      })
      .afterClosed();
  }

  createDelegation(delegationInfo: Delegation): Observable<Response> {
    this.isUpdating$.next(true);
    return this.delegateService.createNewDelegationGroup(delegationInfo).pipe(tap(() => this.isUpdating$.next(false)));
  }
}
