import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FilterModule } from '@starfish-access/core';
import { ResourcesComponent } from './resources.component';
import { ResourcesApprovalEffects } from './resources.effects';
import { resourcesApprovalReducer } from './resources.reducer';
import { routes } from './resources.routing';
import { ResourcesApprovalService } from './resources.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatChipsModule,
    FlexLayoutModule,
    FilterModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('resourcesApproval', resourcesApprovalReducer),
    EffectsModule.forFeature([ResourcesApprovalEffects]),
  ],
  declarations: [ResourcesComponent],
  exports: [RouterModule],
  providers: [ResourcesApprovalService],
})
export class ResourcesModule {}
