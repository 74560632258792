import { Action } from '@ngrx/store';
import { FailedJob } from './failedjobs.model';

export const REQUEST_JOBS = '[FailedJobs] Request FailedJobs';
export const RECEIVE_JOBS = '[FailedJobs] Receive FailedJobs';
export const RETRY_JOB = '[FailedJobs] RETRY Requested';
export const RETRY_JOB_SUCCESS = '[FailedJobs] RETRY Successful';
export const DELETE_JOB = '[FailedJobs] DELETE Requested';
export const DELETE_JOB_SUCCESS = '[FailedJobs] DELETE Successful';
export const CANCEL_JOB = '[FailedJobs] CANCEL Requested';
export const CANCEL_JOB_SUCCESS = '[FailedJobs] CANCEL Successful';
export const ERROR_JOBS = '[FailedJobs] Error From FailedJobs';

export class RetryJob implements Action {
  readonly type = RETRY_JOB;
  constructor(public payload: FailedJob) {}
}

export class RetryJobSuccess implements Action {
  readonly type = RETRY_JOB_SUCCESS;
  constructor(public payload: FailedJob) {}
}

export class DeleteJob implements Action {
  readonly type = DELETE_JOB;
  constructor(public payload: FailedJob) {}
}

export class DeleteJobSuccess implements Action {
  readonly type = DELETE_JOB_SUCCESS;
  constructor(public payload: FailedJob) {}
}

export class CancelJob implements Action {
  readonly type = CANCEL_JOB;
  constructor(public payload: FailedJob) {}
}

export class CancelJobSuccess implements Action {
  readonly type = CANCEL_JOB_SUCCESS;
  constructor(public payload: FailedJob) {}
}

export class RequestFailedJobs implements Action {
  readonly type = REQUEST_JOBS;
}

export class ReceiveFailedJobs implements Action {
  readonly type = RECEIVE_JOBS;
  constructor(public payload: FailedJob[]) {}
}

export class ErrorFailedJobs implements Action {
  readonly type = ERROR_JOBS;
  constructor(public payload: string) {}
}

export type FailedJobsActions =
  | RetryJob
  | RetryJobSuccess
  | DeleteJob
  | DeleteJobSuccess
  | CancelJob
  | CancelJobSuccess
  | RequestFailedJobs
  | ReceiveFailedJobs
  | ErrorFailedJobs;
