import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Kiosk } from '@starfish-access/models';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../../starfish.config';
import { handleErrorFromRest } from '../../utils/utils.model';
import { ServerKiosk } from '../orgs.models';
import { kioskToServer, serverModelToKiosks } from './kiosk.service.utils';

const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append(NO_CACHE_HEADER, 'true')
  .append(REQUIRE_AUTH_HEADER, 'true');

@Injectable()
export class KioskService {
  private baseUrl = this.starfishConfig.starfishServices;
  private KIOSK_PATH = 'kiosks';
  private kioskBaseUrl = `${this.baseUrl}${this.KIOSK_PATH}`;

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getKiosks(legacy: boolean = false): Observable<Kiosk[]> {
    return this.http
      .get<ServerKiosk[]>(this.kioskBaseUrl, { headers })
      .pipe(catchError(handleErrorFromRest), serverModelToKiosks());
  }

  createKiosk(kiosk: Kiosk): Observable<Kiosk> {
    return this.http.post<Response>(this.kioskBaseUrl, kioskToServer(kiosk), { headers }).pipe(
      catchError(handleErrorFromRest),
      map(() => ({
        id: kiosk.id.toUpperCase(),
        name: kiosk.name,
        description: kiosk.description,
        parentId: kiosk.parentId,
      }))
    );
  }

  updateKiosk(kioskToUpdate: Kiosk): Observable<Kiosk> {
    const url = `${this.kioskBaseUrl}/${kioskToUpdate.id}`;
    return this.http.put<Response>(url, kioskToServer(kioskToUpdate), { headers }).pipe(
      catchError(handleErrorFromRest),
      map((x) => kioskToUpdate)
    );
  }

  deleteKiosk(kioskId: string): Observable<Response> {
    const url = `${this.kioskBaseUrl}/${kioskId}`;
    return this.http.delete<Response>(url, { headers });
  }
}
