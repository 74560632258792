<div
  class="sf-add-svc-kiosk"
  [formGroup]="svcAndKioskFormGroup">
  <h2 class="mat-h2">Services</h2>
  <div *ngIf="selectedRole?.servicesFromDelegation.selectionType !== 'ADMIN_AREA_CHOOSE'">
    <mat-chip-set
      role="list"
      aria-label="Services Selection">
      <mat-chip
        role="listitem"
        [attr.data-cy]="'chip-' + org.displayName"
        *ngFor="let org of svcAndKioskFormGroup?.get('services')?.value"
        >{{ org.displayName }}</mat-chip
      >
    </mat-chip-set>
    <span
      data-cy="no-services-selected"
      *ngIf="svcAndKioskFormGroup?.get('services')?.value?.length === 0"
      >--None Selected--</span
    >
  </div>

  <div *ngIf="selectedRole?.servicesFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE'">
    <sf-org-select-input
      #serviceSelector
      data-cy="services-selection"
      class="services-selector"
      formControlName="services"
      [possibleItems]="convertModel(selectedRole?.servicesFromDelegation?.selectedItems) | sortOrgs"
      [config]="{ placeholder: 'Search for services', showSearchIcon: true, removable: true, keepPanelOpen: true }">
    </sf-org-select-input>
  </div>
  <h2 class="mat-h2">Kiosks</h2>

  <div *ngIf="selectedRole?.kiosksFromDelegation.selectionType !== 'ADMIN_AREA_CHOOSE'">
    <mat-chip-set
      role="list"
      aria-label="Kiosks Selection">
      <mat-chip
        role="listitem"
        *ngFor="let org of svcAndKioskFormGroup?.get('kiosks')?.value"
        >{{ org.displayName }}</mat-chip
      >
    </mat-chip-set>
    <span
      data-cy="no-kiosks-selected"
      *ngIf="svcAndKioskFormGroup?.get('kiosks')?.value?.length === 0"
      >--None Selected--</span
    >
  </div>
  <div *ngIf="selectedRole?.kiosksFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE'">
    <sf-org-select-input
      #kioskSelector
      data-cy="kiosks-selection"
      class="services-selector"
      formControlName="kiosks"
      [possibleItems]="convertModel(selectedRole?.kiosksFromDelegation?.selectedItems) | sortOrgs"
      [config]="{ placeholder: 'Search for kiosks', showSearchIcon: true, removable: true }">
    </sf-org-select-input>
  </div>
</div>
