import { Injectable } from '@angular/core';
import { ConfirmDialogService, OkayDialogService, RolesService } from '@starfish-access/core';
import { ClientRoleGroup } from '@starfish-access/models';
import { flatten } from 'ramda';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { UserDetailsDataSource } from './user-details.datasource';
import {
  DelegationGroupAssignments,
  ManagerDelegations,
  ManagerType,
  Users,
  UsersRequestConfig,
} from './user-details.model';
import { UserDetailsService } from './user-details.service';

@Injectable()
export class UserDetailsFacade {
  readonly roleGroupsWithAdminGroups$: Observable<ClientRoleGroup[]> =
    this.rolesService.getRequestableRoleGroupsWithAdminRoles(true);

  constructor(
    private rolesService: RolesService,
    private okayDialogService: OkayDialogService,
    private userService: UserDetailsService,
    private confirmDialogService: ConfirmDialogService
  ) {}

  getSpecificUserRoles(userId: string): Observable<Users> {
    return this.userService.getSpecificUserRoles(userId);
  }

  getDelegatedRoleAssignments(userId: string): Observable<DelegationGroupAssignments> {
    return this.userService.getDelegatedRoleAssignments(userId).pipe(
      map((assignments) => {
        const delegationGroupAssignmentNames = assignments.map((a) => `${a.delegationName}:${a.roleName}`);
        const kiosks = flatten(
          assignments.map((a) => (a.assignedKiosks ? a.assignedKiosks.map((k) => `${a.delegationName}:${k.name}`) : []))
        );
        const services = flatten(
          assignments.map((a) =>
            a.assignedServices ? a.assignedServices.map((k) => `${a.delegationName}:${k.name}`) : []
          )
        );
        const assignedOrganizations = flatten(
          assignments.map((a) =>
            a.assignedOrganizations
              ? a.assignedOrganizations.map((k) => `${a.delegationName}:${k.organizationName}`)
              : []
          )
        );

        return {
          delegationGroupAssignmentNames,
          kiosksServicesAndOrgMembershipNames: [...kiosks, ...services, ...assignedOrganizations],
        } as DelegationGroupAssignments;
      })
    );
  }

  getManagerDelegations(userId: string, managerType: ManagerType): Observable<ManagerDelegations[]> {
    return this.userService.getManagerDelegations(userId, managerType);
  }

  getUsersTableDataSource(): UserDetailsDataSource {
    return new UserDetailsDataSource(this.okayDialogService, this.userService);
  }

  removeUserRole(userId: string, roleId: number, config: UsersRequestConfig, ds: UserDetailsDataSource): void {
    this.confirmDialogService
      .confirmMessage({
        title: 'Remove Role for User?',
        message: 'Are you sure? Removing this role cannot be undone.',
      })
      .pipe(
        take(1),
        mergeMap((res) => {
          if (res) {
            return this.userService.deleteUserRole(userId, roleId).pipe(map(() => true));
          }
          return of(false);
        }),
        catchError((e) => {
          if (e.status === 403) {
            this.okayDialogService.okay(
              'Delegated Role Error',
              'You cannot remove delegated roles from a user on this page. You can do so from the Role Management section.'
            );
          } else {
            this.okayDialogService.okay('Role Deletion Error', 'We had an unexpected error. Please try again later.');
          }

          return of(false);
        })
      )
      .subscribe((shouldReload) => {
        if (shouldReload) {
          ds.loadUsers(config);
        }
      });
  }
}
