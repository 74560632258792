<div class="service-dialog-comp">
  <h2 mat-dialog-title>{{ serviceConfig.title }}</h2>

  <form
    [formGroup]="serviceFormGroup"
    class="full-width-item"
    mat-dialog-content
    novalidate>
    <mat-form-field
      class="item-margin"
      appearance="outline">
      <mat-label>Name</mat-label>
      <input
        #textArea
        matInput
        required
        placeholder="Name"
        formControlName="name"
        data-cy="kiosk-svc-input"
        maxlength="100" />
      <mat-hint>{{ 100 - textArea.value.length }} characters remaining</mat-hint>
      <mat-error *ngIf="!isNameValid"> This is required </mat-error>
      <mat-error
        data-cy="forbidden-name"
        *ngIf="name?.errors?.forbiddenName">
        Name cannot contain {{ serviceConfig.forbiddenName }}.
      </mat-error>
      <mat-error
        data-cy="missing-required-name"
        *ngIf="name?.errors?.requiredName">
        Name must contain {{ serviceConfig.requiredName }}.
      </mat-error>
    </mat-form-field>
    <mat-form-field
      class="item-margin"
      appearance="outline">
      <mat-label>Unique Organization ID</mat-label>
      <input
        matInput
        required
        placeholder="Unique Organization ID"
        data-cy="kiosk-svc-id"
        class="ksk-svc-input"
        formControlName="description" />
      <mat-error
        *ngIf="!isNameValid"
        data-cy="invalid-id-input">
        This is required. {{ serviceFormGroup.get('description')?.errors?.invalidCharacter }}
      </mat-error>
      <mat-error
        data-cy="invalidCharacter-name"
        *ngIf="serviceFormGroup.get('description')?.errors?.invalidCharacter">
        {{ serviceFormGroup.get('description')?.errors?.invalidCharacter }}
      </mat-error>
    </mat-form-field>
  </form>
</div>

<div
  mat-dialog-actions
  align="end"
  class="button-row-action">
  <button
    mat-dialog-close
    mat-button
    color="warn">
    Cancel
  </button>
  <button
    mat-button
    data-cy="save-service"
    (click)="this.saveChanges()"
    color="primary">
    {{ serviceConfig.saveBtnText ? serviceConfig.saveBtnText : 'Save' }}
  </button>
</div>
