import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../model/roles.model';

@Pipe({ name: 'filterRole' })
export class FilterRolePipe implements PipeTransform {
  transform(usersRole: Role[], filter: any): Role[] {
    if (!usersRole || !filter) {
      return usersRole;
    }

    return usersRole.filter((role) => {
      if (role && role.roleName) {
        return role.roleName.toLowerCase().includes(filter.toLowerCase());
      }
      return '';
    });
  }
}
