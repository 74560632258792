<div class="task-details-display">
  <h2>Request Details</h2>

  <div *ngIf="fetchingDetails | async">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <mat-card
    appearance="outlined"
    *ngIf="!(fetchingDetails | async)">
    <mat-card-content>
      <sf-workflow-header
        (submitEmitter)="completeRequest($event)"
        [processDetailsStatus]="(processDetails | async)?.requestDetails?.requestStatus"
        [taskDetails]="taskDetails"
        [submitInProgress]="submitInProgress"
        [isRequestView]="isRequestView">
      </sf-workflow-header>

      <div class="divider">
        <mat-divider></mat-divider>
      </div>
      <div
        fxLayout="row"
        fxLayoutGap="24px">
        <sf-header-display
          [attr.data-cy]="(processDetails | async | roleName) + '-requested-item'"
          [listToDisplay]="[processDetails | async | roleName]"
          displayTitle="Requested Role"></sf-header-display>
        <sf-header-display
          *ngIf="
            (processDetails | async)?.requestDetails?.requestStatus !== 'COMPLETED' &&
            (processDetails | async)?.requestDetails?.requestStatus !== 'CANCELLED'
          "
          [listToDisplay]="[processDetails | async | roleApprovers]"
          displayTitle="Awaiting Action From"></sf-header-display>
        <sf-header-display
          *ngIf="(processDetails | async)?.requestDetails?.requestStatus === 'AWAITING_MANAGER_OVERRIDE'"
          [isExpanded]="toggleFullViewSubj"
          isExpandable="true"
          [listToDisplay]="[(processDetails | async)?.requestDetails?.approvingManager]"
          displayTitle="Overridden Manager">
        </sf-header-display>
      </div>

      <div class="options-title mat-subtitle-1">Requested Options</div>

      <div
        fxLayout="row"
        fxLayoutGap="24px">
        <sf-header-display
          *ngIf="(processDetails | async | orgName).length > 0"
          [isExpanded]="toggleFullViewSubj"
          isExpandable="true"
          [listToDisplay]="processDetails | async | orgName"
          displayTitle="Requested Organizations"></sf-header-display>
        <sf-header-display
          *ngIf="(processDetails | async | serviceName).length > 0"
          [isExpanded]="toggleFullViewSubj"
          isExpandable="true"
          [listToDisplay]="processDetails | async | serviceName"
          displayTitle="Requested Services"></sf-header-display>
        <sf-header-display
          *ngIf="(processDetails | async | kioskName).length > 0"
          [isExpanded]="toggleFullViewSubj"
          isExpandable="true"
          [listToDisplay]="processDetails | async | kioskName"
          displayTitle="Requested Kiosks"></sf-header-display>
      </div>

      <div
        *ngIf="hasFerpa | async"
        fxLayoutAlign="end center"
        fxLayoutGap="8px"
        class="edit-options-div">
        <button
          *ngIf="isRequestView && ((processDetails | async)?.requestDetails?.requestStatus | canCancelProcess)"
          mat-stroked-button
          data-cy="add-request-comment"
          (click)="addAComment()">
          Add a Comment
        </button>
        <button
          *ngIf="!isRequestView"
          mat-stroked-button
          data-cy="edit-this-request"
          (click)="editThisRequest()">
          Edit
        </button>
        <div
          *ngIf="
            (processDetails | async | orgName).length > 2 ||
            (processDetails | async | serviceName).length > 2 ||
            (processDetails | async | kioskName).length > 2
          ">
          <button
            *ngIf="!(toggleFullViewSubj | async)"
            mat-stroked-button
            color="primary"
            data-cy="view-full-request"
            (click)="toggleFullRequest()">
            View Full Request
          </button>
          <button
            *ngIf="toggleFullViewSubj | async"
            mat-stroked-button
            color="primary"
            data-cy="hide-full-request"
            (click)="toggleFullRequest()">
            Hide Full Request
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
