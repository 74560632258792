import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { PermissionCheckService } from '@psu/apis/fortress';
import { OkayDialogService } from '@starfish-access/core';
import {
  ClientAssignmentResponse,
  ConfiguredAssignments,
  ConfiguredDelegation,
  CourseConfig,
  UserInfo,
} from '@starfish-access/models';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { DirectorsFacade } from '../directors.facade';
import { UserTableComponent } from '../user-table/user-table.component';

@Component({
  selector: 'sf-assignment-form',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['./assignment-form.component.scss'],
})
export class AssignmentFormComponent implements OnDestroy, OnInit, OnChanges {
  @ViewChild('userTableComp') userTableComp: UserTableComponent;
  @Input() configuredDelegation: ConfiguredDelegation;
  formGroup: UntypedFormGroup;
  loadingDelegation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private destroy$ = new Subject<void>();
  private transitionToSemester = '';
  private isAdmin = this.permissionsService
    .hasPermission('starfish-workflow.process.view')
    .pipe(takeUntil(this.destroy$))
    .subscribe((res) => res);

  constructor(
    private fb: UntypedFormBuilder,
    public directorsFacade: DirectorsFacade,
    private dialogService: OkayDialogService,
    private permissionsService: PermissionCheckService
  ) {
    this.formGroup = this.fb.group({
      delegation: [], // Delegation;
      assignedRoles: [[]], // ConfiguredAssignments[];
      calendarManagers: [[]], // UserInfo[];
      studentCalendarManagers: [[]], // UserInfo[]
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.configuredDelegation) {
      const delId: number = this.configuredDelegation?.delegation?.id ? this.configuredDelegation.delegation.id : -1;
      if (delId < 0) {
        return; // TODO error message
      }

      this.directorsFacade
        .getConfiguredAssignments(this.configuredDelegation)
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.loadingDelegation$.next(false))
        )
        .subscribe((res) => {
          this.formGroup.get('assignedRoles')?.patchValue(res);
          this.formGroup.markAsPristine(); // the form is new from the on init. reset to pristine.
        });

      const calMgrs: UserInfo[] = this.configuredDelegation?.delegation?.calendarManagers?.regularCalendarManagers;
      this.formGroup.get('calendarManagers')?.patchValue(calMgrs ? calMgrs : []);

      const stuCalMgrs: UserInfo[] = this.configuredDelegation?.delegation?.calendarManagers?.studentCalendarManagers;
      this.formGroup.get('studentCalendarManagers')?.patchValue(stuCalMgrs ? stuCalMgrs : []);
    }
  }

  ngOnInit(): void {
    this.directorsFacade.transitionSemester$
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => (this.transitionToSemester = res));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveAssignment(): void {
    const delId: number = this.configuredDelegation.delegation.id ? this.configuredDelegation.delegation.id : -1;
    if (delId < 0) {
      return; // TODO error message ?
    }

    // https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/508
    let calMgrs: UserInfo[] = this.formGroup.get('calendarManagers')?.value.managers;
    if (!calMgrs) {
      calMgrs = this.formGroup.get('calendarManagers')?.value;
    }
    const ca: ConfiguredAssignments[] = this.formGroup.get('assignedRoles')?.value.assignments;

    // https://git.psu.edu/ais-swe/starfish-access-ui/-/issues/508
    let studentMgrs: UserInfo[] = this.formGroup.get('studentCalendarManagers')?.value.managers;
    if (!studentMgrs) {
      studentMgrs = this.formGroup.get('studentCalendarManagers')?.value;
    }

    const calMgrExclusions: UserInfo[] = [];
    ca?.forEach((assignment) => {
      if (!assignment.calendarManagementEnabled) {
        calMgrExclusions.push(assignment.user);
      }

      if (assignment.selectedToTransition) {
        // Transition eligible & user has selected to transition
        // we need to update all course info to use the new semester/term
        this.transitionTerms(assignment.options.courseConfig);
        assignment.hasBeenModified = true;
        assignment.transitionState = 'TRANSITIONED';
      }
    });

    this.configuredDelegation.delegation.calendarManagers = {
      regularCalendarManagers: calMgrs,
      studentCalendarManagers: studentMgrs,
      calendarManagementExclusions: calMgrExclusions,
    };

    this.configuredDelegation.assignedRoles = ca;

    this.directorsFacade
      .updateConfiguredDelegation(this.configuredDelegation)
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => {
          this.dialogService.okay('Assignment Not Updated', this.prettyPrintAssignmentExistsMessage(err));
          return of(false);
        })
      )
      .subscribe((res: ClientAssignmentResponse[]) => {
        const car: ClientAssignmentResponse = res[0];
        if (res && car && car.assignmentId > 0) {
          // update form with any new assignment Ids
          this.updateConfiguredAssignments(res);
          this.userTableComp.resetTransitionSelectedMap();
        }

        if (res) {
          this.formGroup.markAsPristine();
          this.dialogService.okay('Success!', 'You have created and saved your delegation assignments.');
        }
      });
  }

  private transitionTerms(cc: CourseConfig | undefined) {
    if (cc && this.transitionToSemester?.length > 0) {
      cc.semester = this.transitionToSemester;
    }
  }

  private updateConfiguredAssignments(caUpdates: ClientAssignmentResponse[]): void {
    const ca: ConfiguredAssignments[] = this.formGroup.get('assignedRoles')?.value?.assignments
      ? this.formGroup.get('assignedRoles')?.value.assignments
      : [];
    if (!ca) {
      return;
    }
    ca.forEach((assignment) => {
      caUpdates.forEach((update) => {
        if (
          assignment.assignmentId === undefined &&
          assignment.user.id === update.userid &&
          assignment.role.roleDelegationId === update.delegatedRoleId &&
          +assignment.role.roleId === update.roleId
        ) {
          // updating the id to the one returned in the post
          assignment.assignmentId = update.assignmentId;
        }
      });
    });

    const caArr: ConfiguredAssignments[] = [];
    ca.forEach((val) => caArr.push(Object.assign({}, val)));
    this.formGroup.get('assignedRoles')?.setValue({ assignments: caArr });
  }

  private prettyPrintAssignmentExistsMessage(err: any): string {
    const errorMessage: string = err?.error?.errorMessage[0];
    if (err?.status === 400 && errorMessage?.indexOf('Assignment already exists') >= 0) {
      const userIndex = errorMessage.indexOf('User:') + 6;
      const delName = this.configuredDelegation?.delegation?.name
        ? this.configuredDelegation?.delegation?.name
        : 'this delegation group';
      const failedUser = userIndex > 6 ? errorMessage.substring(userIndex) : 'the updated user';
      return 'An assignment for ' + failedUser + ' already exists for ' + delName + '.';
    } else if (err?.status === 400 && errorMessage?.indexOf('are not subset of parent courses') >= 0) {
      let delId = '';
      if (err?.url && err.url.length > 0) {
        const urlAsString: string = err?.url ? err.url : '';
        const firstIndex: number = urlAsString.indexOf('delegationGroups/');
        const secondIndex: number = firstIndex + 17;
        const difference: number = urlAsString.substring(secondIndex).indexOf('/');
        delId = ' (' + urlAsString.substring(secondIndex, difference) + ')';
      }

      if (this.isAdmin) {
        return 'You cannot update this delegation group. Please open the Delegation tab from the main menu and either DEFER or choose ALL for this role > then save and try this update again.';
      }

      return (
        'You cannot update this delegation group ' +
        delId +
        '. Please contact a Starfish Manager and request a DEFER or ALL for this role. '
      );
    }
    return 'We could not create your assignment at this time';
  }
}
