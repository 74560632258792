import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationFacade } from '@psu/components/notification-ngrx';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { httpRetryBackoff } from '@psu/utils/rx';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest } from '@starfish-access/core';
import { AccessRequest } from '@starfish-access/models';
import { catchError, map, Observable } from 'rxjs';
import { StarfishConfig, STARFISH_CONFIG } from 'src/app/starfish.config';
import {
  ManagerDelegations,
  ManagerType,
  ServerDelegationGroupAssignments,
  Users,
  UsersDatasourceResponse,
  UsersRequestConfig,
} from './user-details.model';

@Injectable({
  providedIn: 'root',
})
export class UserDetailsService {
  private static userRolesEndPoint = 'starfishUserRoles';
  private static userDelegationAssignmentsEndPoint = 'delegationGroups/assignments';
  private static managerDelegationEndPoint = 'managerDelegations';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(
    private notifications: NotificationFacade,
    private http: HttpClient,
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig
  ) {}

  addUserByAdmin(user: AccessRequest): Observable<Response> {
    const url = this.starfishConfig.starfishServices + 'processes/adhocProcess';
    return this.http
      .post<Response>(url, user, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  getSpecificUserRoles(userId: string): Observable<Users> {
    const url = this.starfishConfig.starfishServices + UserDetailsService.userRolesEndPoint + '?userid=' + userId;
    return this.http
      .get<Users[]>(url, {
        headers: this.headers,
      })
      .pipe(
        map((response: Users[]) => response[0]),
        catchError(handleErrorFromRest)
      );
  }

  getDelegatedRoleAssignments(userId: string): Observable<ServerDelegationGroupAssignments[]> {
    const url =
      this.starfishConfig.starfishServices + UserDetailsService.userDelegationAssignmentsEndPoint + '?userId=' + userId;
    return this.http
      .get<ServerDelegationGroupAssignments[]>(url, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  getManagerDelegations(userId: string, managerType: ManagerType): Observable<ManagerDelegations[]> {
    const url =
      this.starfishConfig.starfishServices +
      UserDetailsService.managerDelegationEndPoint +
      '?userid=' +
      userId +
      '&type=' +
      managerType;
    return this.http
      .get<ManagerDelegations[]>(url, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  getPaginatedUsers(config: UsersRequestConfig): Observable<UsersDatasourceResponse> {
    const customFilters: string = this.parameterizeConfig(config);

    const baseUrl = this.starfishConfig.starfishServices + UserDetailsService.userRolesEndPoint + customFilters;

    return this.http
      .get<Users[]>(baseUrl, {
        headers: this.headers,
        observe: 'response',
        responseType: 'json',
      })
      .pipe(
        httpRetryBackoff(250),
        map((res) => {
          let usersList: Users[] = [];
          if (res.body) {
            usersList = res.body;
          }

          let act = 0;
          const instanceOfHeaderRes = res.headers.get('count');

          if (instanceOfHeaderRes != null) {
            act = +instanceOfHeaderRes;
          }

          const usersResponse: UsersDatasourceResponse = {
            users: usersList,
            totalUsersCount: act,
          };
          return usersResponse;
        }),
        catchError(handleErrorFromRest)
      );
  }

  deleteUser(userId: string): Observable<void> {
    // build the URL
    const url = this.starfishConfig.starfishServices + UserDetailsService.userRolesEndPoint + '/' + userId;
    return this.http
      .delete<Response>(url, {
        headers: this.headers,
      })
      .pipe(
        map((response: Response) => this.logResponse(response)),
        catchError(handleErrorFromRest)
      );
  }

  deleteUserRole(userId: string, roleId: number): Observable<Response> {
    // build the URL
    const URL =
      this.starfishConfig.starfishServices + UserDetailsService.userRolesEndPoint + '/' + userId + '?roleId=' + roleId;

    return this.http.delete<Response>(URL, {
      headers: this.headers,
    });
  }

  notifySuccess(): void {
    this.notifications.success({
      message:
        'The request to add the role has been succesfully received. It is being processed and will appear shortly.',
      closeText: 'OK',
    });
  }

  parameterizeConfig(config: UsersRequestConfig): string {
    if (!config) {
      return '';
    }

    if (config.pageStart && config.pageTotal) {
      // so page three, if 10 items per page, should start at 31
      const startingPage = (config.pageStart - 1) * config.pageTotal + 1;
      const numberOfItems = config.pageTotal; // items per page

      let customParams = '?firstResult=' + startingPage + '&maxResult=' + numberOfItems;

      if (config.filterString && config.filterString.length > 0) {
        customParams = customParams + '&userid=' + config.filterString.toLowerCase();
      }

      if (config.useridSortOrder && config.useridSortOrder.length > 0) {
        customParams = customParams + '&useridSortOrder=' + config.useridSortOrder.toUpperCase();
      }

      return customParams;
    } else {
      return '';
    }
  }

  private logResponse(response: any): void {
    // eslint-disable-next-line no-console
    console.log('RESPONSE: ' + JSON.stringify(response));
  }
}
