<section
  class="user-details-comp"
  *ngIf="canView | async; else perms_error">
  <mat-card appearance="outlined">
    <div class="display-flex">
      <mat-card-content>
        <div class="user-details-header-container">
          <h1 data-cy="starfish-users-header">Starfish Users</h1>
          <button
            mat-flat-button
            matTooltip="Add User"
            [matTooltipShowDelay]="tooltipDelay"
            class="new-user-btn"
            color="primary"
            (click)="openNewUserDialog()">
            Add New User
          </button>
        </div>

        <!-- User Search -->
        <ta-typeahead
          data-cy="search-sf-users-ta"
          [config]="starfishServiceConfig"
          [placeholder]="'Search Starfish user by Access ID (e.g., abc123)'"
          [resultTemplate]="resultTemplate"
          (selected)="selectUser($event)"
          [noResultsTemplate]="noResultsTemplate"
          [autoActiveFirstOption]="false"
          [clearButtonIconTemplate]="clearButtonIconTemplate"
          [showSearchButton]="false"
          [showMatPrefix]="true"
          #ta1>
        </ta-typeahead>
        <ng-template
          #resultTemplate
          let-result>
          <span class="display-flex">
            <span class="result-content">
              <span>{{ result.displayName }} ({{ result.userId }})</span>
            </span>
          </span>
        </ng-template>
        <ng-template #noResultsTemplate>
          There are no results for {{ ta1.currentTerm }} in the Starfish Role Management System.
        </ng-template>
        <ng-template #clearButtonIconTemplate>
          <mat-icon (click)="clearUser()">clear</mat-icon>
        </ng-template>
        <div *ngIf="selectedStarfishUser; then userIsSelected; else userIsNotSelected"></div>
        <ng-template #userIsSelected>
          <div
            data-cy="search-sf-users-results"
            class="display-flex user-search-results">
            <span>
              Search results for: {{ selectedStarfishUser?.displayName }}, {{ selectedStarfishUser?.userId }}
            </span>
            <button
              mat-flat-button
              class="delete-user-btn"
              color="primary"
              matTooltip="Delete User"
              [matTooltipShowDelay]="tooltipDelay"
              (click)="openDeleteUserDialog(selectedStarfishUser?.userId)"
              [disabled]="!canUserBeDeletedFromStarfish">
              Delete User
            </button>
            <span class="info-icon">
              <mat-icon
                svgIcon="help-circle-outline"
                matTooltip="You cannot delete a user that has an assigned role. Please delete the role(s) in the Role Assignment first"
                [matTooltipShowDelay]="tooltipDelay"></mat-icon>
            </span>
          </div>
          <mat-accordion
            multi="true"
            *ngIf="{
              delegationGroupAssignments: delegationGroupAssignments$ | async,
              calendarManagerDelegations: calendarManagerDelegations$ | async,
              roleManagerDelegations: roleManagerDelegations$ | async
            } as asyncValues">
            <!-- Delegated Roles -->
            <mat-expansion-panel class="expansion-panel">
              <mat-expansion-panel-header
                class="expansion-panel-header"
                data-cy="expansion-panel-header">
                <mat-panel-title class="detail-mat-panel-title">
                  <sf-detail-icon iconName="google-circles-communities"></sf-detail-icon>
                  <div class="panel-title-details">
                    <div
                      class="panel-title-details-delegations"
                      data-cy="panel-title-details-delegations">
                      Delegated Roles
                    </div>
                    <div
                      class="panel-title-subdetail"
                      data-cy="panel-title-subdetail-delegations">
                      {{ asyncValues.delegationGroupAssignments?.delegationGroupAssignmentNames.length ?? 0 }} groups
                      currently assigned
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                *ngIf="
                  asyncValues.delegationGroupAssignments?.delegationGroupAssignmentNames.length === 0;
                  else hasDelegationGroupAssignments
                "
                data-cy="panel-content-no-delegations">
                This user has 0 groups to show.
              </div>
              <ng-template #hasDelegationGroupAssignments>
                <mat-chip-listbox>
                  <mat-chip-option
                    class="user-details-chip"
                    color="blue"
                    *ngFor="let assignment of asyncValues.delegationGroupAssignments?.delegationGroupAssignmentNames"
                    [attr.data-cy]="assignment + '-delegation'">
                    <span class="user-details-chip-content">{{ assignment }}</span>
                  </mat-chip-option>
                </mat-chip-listbox>
              </ng-template>
            </mat-expansion-panel>

            <!-- Services and Kiosks / Org Membership (sak-om) -->
            <mat-expansion-panel class="expansion-panel">
              <mat-expansion-panel-header
                class="expansion-panel-header"
                data-cy="expansion-panel-header">
                <mat-panel-title class="detail-mat-panel-title">
                  <sf-detail-icon iconName="video-input-component"></sf-detail-icon>
                  <div class="panel-title-details">
                    <div
                      class="panel-title-details-delegations"
                      data-cy="panel-title-details-sak-om">
                      Services and Kiosks / Org Membership
                    </div>
                    <div
                      class="panel-title-subdetail"
                      data-cy="panel-title-subdetail-sak-om">
                      {{ asyncValues.delegationGroupAssignments?.kiosksServicesAndOrgMembershipNames.length ?? 0 }}
                      groups currently assigned
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                *ngIf="
                  asyncValues.delegationGroupAssignments?.kiosksServicesAndOrgMembershipNames.length === 0;
                  else kiosksServicesAndOrgMembershipNames
                "
                data-cy="panel-content-no-sak-om">
                This user has 0 groups to show.
              </div>
              <ng-template #kiosksServicesAndOrgMembershipNames>
                <mat-chip-listbox>
                  <mat-chip-option
                    class="user-details-chip"
                    color="blue"
                    *ngFor="
                      let assignment of asyncValues.delegationGroupAssignments?.kiosksServicesAndOrgMembershipNames
                    "
                    [attr.data-cy]="assignment + '-sak-om'">
                    <span class="user-details-chip-content">{{ assignment }}</span>
                  </mat-chip-option>
                </mat-chip-listbox>
              </ng-template>
            </mat-expansion-panel>

            <!-- Calendar Managers -->
            <mat-expansion-panel class="expansion-panel">
              <mat-expansion-panel-header
                class="expansion-panel-header"
                data-cy="expansion-panel-header">
                <mat-panel-title class="detail-mat-panel-title">
                  <sf-detail-icon iconName="calendar-blank"></sf-detail-icon>
                  <div class="panel-title-details">
                    <div
                      class="panel-title-details-delegations"
                      data-cy="panel-title-details-cal-manager">
                      Calendar Manager
                    </div>
                    <div
                      class="panel-title-subdetail"
                      data-cy="panel-title-subdetail-cal-manager">
                      {{ asyncValues.calendarManagerDelegations?.length ?? 0 }}
                      calendar managers assigned
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                *ngIf="asyncValues.calendarManagerDelegations?.length === 0; else calendarManagerNames"
                data-cy="panel-content-no-cal-manager">
                This user has 0 calendar managers to show.
              </div>
              <ng-template #calendarManagerNames>
                <mat-chip-listbox>
                  <mat-chip-option
                    class="user-details-chip"
                    color="blue"
                    *ngFor="let calManager of asyncValues.calendarManagerDelegations"
                    [attr.data-cy]="calManager.title + '-cal-manager'">
                    <span class="user-details-chip-content">{{ calManager.title }}</span>
                  </mat-chip-option>
                </mat-chip-listbox>
              </ng-template>
            </mat-expansion-panel>

            <!-- Role Managers -->
            <mat-expansion-panel class="expansion-panel">
              <mat-expansion-panel-header
                class="expansion-panel-header"
                data-cy="expansion-panel-header">
                <mat-panel-title class="detail-mat-panel-title">
                  <sf-detail-icon iconName="account-multiple-plus"></sf-detail-icon>
                  <div class="panel-title-details">
                    <div
                      class="panel-title-details-delegations"
                      data-cy="panel-title-details-role-manager">
                      Delegated Role Manager
                    </div>
                    <div
                      class="panel-title-subdetail"
                      data-cy="panel-title-subdetail-role-manager">
                      {{ asyncValues.roleManagerDelegations?.length ?? 0 }}
                      delegated managers assigned
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div
                *ngIf="asyncValues.roleManagerDelegations?.length === 0; else roleManagerNames"
                data-cy="panel-content-no-role-manager">
                This user has 0 delegated managers to show.
              </div>
              <ng-template #roleManagerNames>
                <mat-chip-listbox>
                  <mat-chip-option
                    class="user-details-chip"
                    color="blue"
                    *ngFor="let roleManager of asyncValues.roleManagerDelegations"
                    [attr.data-cy]="roleManager.title + '-role-manager'">
                    <span class="user-details-chip-content">{{ roleManager.title }}</span>
                  </mat-chip-option>
                </mat-chip-listbox>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-template>
        <ng-template #userIsNotSelected>
          <div data-cy="search-sf-users-no-user-selected">No user has been chosen yet, please search above.</div>
        </ng-template>
      </mat-card-content>
    </div>
  </mat-card>
</section>
<ng-template #perms_error>
  <div class="failed-styling">You do not have access to this content.</div>
</ng-template>
