import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResponsiveModule } from '@psu/utils/responsive';
import { CoreModule } from '@starfish-access/core';
import { TypeaheadModule } from '@starfish-access/shared';
import { DUSGroupService } from './dusGroup.service';
import { RoleDisplayComponent } from './roledisplay/roledisplay.component';
import { RolesComponent } from './roles.component';
import { RolesEffects } from './roles.effects';
import { rolesListReducer } from './roles.reducer';
import { routes } from './roles.routing';
import { RoleTableComponent } from './roletable/roletable.component';
import { UserSearchComponent } from './usersearch/user-search.component';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MatTableModule,
    MatSortModule,
    ResponsiveModule,
    FlexLayoutModule,
    TypeaheadModule,
    StoreModule.forFeature('rolesList', rolesListReducer),
    EffectsModule.forFeature([RolesEffects]),
  ],
  declarations: [RolesComponent, RoleTableComponent, UserSearchComponent, RoleDisplayComponent],
  exports: [RouterModule],
  providers: [DUSGroupService],
})
export class RolesModule {}
