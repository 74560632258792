import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessDetails, TaskDetails } from '@starfish-access/models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  templateUrl: './workflow-details.component.html',
  styleUrls: ['./workflow-details.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-workflow-details',
})
export class WorkflowDetailsComponent {
  @Input() processDetails: Observable<ProcessDetails>;
  @Input() fetchingDetails: Observable<boolean>;
  @Input() hasFerpa: Observable<boolean>;
  @Input() submitInProgress: Observable<boolean>;
  @Input() taskDetails: TaskDetails;
  @Input() isRequestView: boolean;
  @Output() addCommentEmitter: EventEmitter<void> = new EventEmitter();
  @Output() submitEmitter: EventEmitter<string> = new EventEmitter();
  toggleFullViewSubj: Subject<boolean> = new BehaviorSubject<boolean>(false); // for when the user clicks 'yes' on the submit confirmation
  private toggleFullView = false;

  constructor(public router: Router) {}

  addAComment(): void {
    this.addCommentEmitter.emit();
  }

  completeRequest(value: string): void {
    this.submitEmitter.emit(value);
  }

  editThisRequest(): void {
    // we can pass an id to routerLink by using : ['/details', object.id]
    this.router.navigate(['/useraccount', this.taskDetails.processInstanceId, { taskId: this.taskDetails.taskId }]);
  }

  toggleFullRequest(): void {
    this.toggleFullView = !this.toggleFullView;
    this.toggleFullViewSubj.next(this.toggleFullView);
  }
}
