<div
  class="course-info-dialog"
  fxLayout="column">
  <h1 mat-dialog-title>{{ data?.channel?.serviceDto?.code }} Channel Services</h1>
  <div mat-dialog-content>
    <div
      fxLayout="row"
      class="content-row"
      *ngFor="let dept of data?.channel?.assignedDepartments">
      <mat-icon class="list-item-icon">computer</mat-icon>
      <span class="body-bold">{{ dept }}</span>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="primary"
      [mat-dialog-close]="true">
      Close
    </button>
  </mat-dialog-actions>
</div>
