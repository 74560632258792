import { Component, Input } from '@angular/core';
import { ClientRoleGroup } from '@starfish-access/models';
import { Observable } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-select-role-group',
  templateUrl: './select-role-group.component.html',
  styleUrls: ['./select-role-group.component.scss'],
})
export class SelectRoleGroupComponent {
  @Input() roleGroups: Observable<ClientRoleGroup[]>;
}
