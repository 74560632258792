<div
  data-cy="sf-add-org-div"
  [formGroup]="orgFormGroup">
  <h2 class="mat-h2">Organizations</h2>

  <div *ngIf="selectedRole?.orgsFromDelegation.selectionType !== 'ADMIN_AREA_CHOOSE'">
    <mat-chip-listbox aria-label="Organizations Selection">
      <mat-chip-option
        *ngFor="let org of orgFormGroup?.get('orgs')?.value | sortOrgs"
        [attr.data-cy]="'chip-' + org.displayName"
        >{{ org.displayName }}</mat-chip-option
      >
    </mat-chip-listbox>
    <span *ngIf="orgFormGroup?.get('orgs')?.value?.length === 0">--None Selected--</span>
  </div>

  <div *ngIf="selectedRole?.orgsFromDelegation.selectionType === 'ADMIN_AREA_CHOOSE'">
    <sf-org-select-input
      #kioskSelector
      data-cy="orgs-selection"
      class="orgs-selector"
      formControlName="orgs"
      [possibleItems]="orgList | sortOrgs"
      [config]="{
        placeholder: 'Search for organizations',
        showSearchIcon: true,
        removable: true,
        keepPanelOpen: true
      }">
    </sf-org-select-input>
  </div>
</div>
