import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './header-display.component.html',
  styleUrls: ['./header-display.component.scss'],
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'sf-header-display',
})
export class HeaderDisplayComponent {
  @Input() listToDisplay: string[];
  @Input() displayTitle: string;
  @Input() isExpandable: boolean;
  @Input() isExpanded: Observable<boolean>;
}
