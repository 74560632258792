<mat-form-field
  class="autocomplete-form-field"
  appearance="outline"
  fxFlex>
  <mat-icon
    class="search-icon"
    matPrefix
    >search</mat-icon
  >
  <input
    #searchByRole
    data-cy="search-by-role-name"
    type="text"
    [placeholder]="placeHolderText"
    matInput />
</mat-form-field>
