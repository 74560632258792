<div
  fxLayout="row"
  class="edit-row">
  <ng-content fxFlex></ng-content>
  <div
    fxLayoutAlign="end center"
    *ngIf="captionStyleLabels">
    <button
      type="button"
      class="add caption-stroked"
      color="primary"
      mat-stroked-button
      matTooltip="Add {{ rowTitle | titlecase }} #{{ rowCount + 2 }}"
      (click)="onAdd()"
      [ngClass]="{ hidden: !canAdd }"
      aria-label="add an item"
      [attr.data-cy]="rowTitle + '-' + rowCount + '-add-item-btn'">
      <mat-icon>add</mat-icon>
      <span>Add {{ rowTitle }} #{{ rowCount + 2 }}</span>
    </button>
  </div>
  <ng-container *ngTemplateOutlet="captionStyleLabels ? captionStrokedBtns : overlineIconBtns"></ng-container>
</div>

<ng-template #overlineIconBtns>
  <div
    fxLayout="row"
    class="overline-label-container">
    <div class="overline-btn-alignment">
      <button
        type="button"
        class="remove"
        mat-icon-button
        (click)="onDelete()"
        [ngClass]="{ hidden: !canDelete }"
        aria-label="remove this item">
        <mat-icon aria-disabled="true">remove_circle</mat-icon>
      </button>
      <button
        type="button"
        class="add"
        mat-icon-button
        (click)="onAdd()"
        [ngClass]="{ hidden: !canAdd }"
        aria-label="add an item">
        <mat-icon
          aria-disabled="true"
          color="primary"
          >add_circle</mat-icon
        >
      </button>
    </div>
  </div>
</ng-template>

<ng-template #captionStrokedBtns>
  <div
    fxLayout="row"
    class="caption-label-container">
    <div fxFlex></div>
    <div class="caption-label-action-container">
      <button
        type="button"
        class="remove"
        mat-icon-button
        matTooltip="Remove {{ rowTitle | titlecase }} #{{ rowCount + 1 }}"
        (click)="onDelete()"
        [ngClass]="{ hidden: !canDelete }"
        aria-label="remove this item">
        <mat-icon aria-disabled="true">remove_circle</mat-icon>
      </button>
    </div>
  </div>
</ng-template>
