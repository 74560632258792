import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService, CourseChannelService } from '@starfish-access/core';
import { ClientCourseChannelResponse, SubjectAndSection } from '@starfish-access/models';
import { clone } from 'ramda';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { SaveSyncComponent } from '../sync-courses-display/save-sync.component';
import { Selections } from '../unmapped-courses-display/unmapped-courses-display.component';
import { CourseInfoComponent } from './course-info.component';

@Injectable()
export class CourseListFacade {
  isFetching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isFetchingUnmapped$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private service: CourseChannelService,
    private confirmDialog: ConfirmDialogService,
    private dialog: MatDialog
  ) {}

  syncCoursesConfirmDialog(channel: ClientCourseChannelResponse): Observable<boolean> {
    const data: any = { channel };
    const dr: MatDialogRef<SaveSyncComponent> = this.dialog.open(SaveSyncComponent, {
      width: '600px',
      maxHeight: '800px',
      autoFocus: false,
      data,
    });

    return dr.afterClosed();
  }

  mapCoursesDialog(selections?: Selections[]): Observable<boolean> {
    return this.confirmDialog.confirmMessage({
      title: 'Courses to be assigned',
      message:
        'By clicking the OK button you are agreeing to submit the subjects ' +
        'for Assigned status. This cannot be redone. If you are unsure if ' +
        'you want to proceed click the Cancel button and go back. If you are ' +
        'positive you want to assign the subjects, click OK.',
      selections,
    });
  }

  subjectInfoDialog(channel: ClientCourseChannelResponse): void {
    const data: any = { channel };
    this.dialog.open(CourseInfoComponent, {
      width: '400px',
      autoFocus: 'dialog',
      data,
    });
  }

  syncCourses(data: ClientCourseChannelResponse): Observable<ClientCourseChannelResponse> {
    const clonedData = clone(data);

    clonedData.courses.forEach((element: any) => {
      element.synchronized = true;
    });
    return this.service.updateCourse(clonedData);
  }

  updateMappedCourses(data: SubjectAndSection[]): Observable<string[]> {
    const list: ClientCourseChannelResponse[] = [];
    const subjectsAdded: string[] = [];
    data.forEach((element) => {
      if (element.section) {
        const sectionToUpdate: ClientCourseChannelResponse = clone(element.section);
        sectionToUpdate.assignedDepartments.push(element.subject);
        subjectsAdded.push(element.subject);
        list.push(sectionToUpdate);
      }
    });
    return this.service.updateBatchCourses(list).pipe(map(() => subjectsAdded));
  }

  getUnmappedCourses(): Observable<string[]> {
    this.isFetchingUnmapped$.next(true);
    return this.service.getUnmappedCourses().pipe(
      catchError((err) => {
        this.isFetchingUnmapped$.next(false);
        return throwError(err);
      }),
      tap((res) => {
        this.isFetchingUnmapped$.next(false);
      })
    );
  }

  getAllMappedCourses(): Observable<ClientCourseChannelResponse[]> {
    this.isFetching$.next(true);
    return this.service.getAllCourses().pipe(
      catchError((err) => {
        this.isFetching$.next(false);
        return throwError(err);
      }),
      tap((res) => {
        this.isFetching$.next(false);
      })
    );
  }
}
