import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AccessRequest, ClientRoleGroup, OrgModel, RequestItem, Role } from '@starfish-access/models';
import { buildRoleRequestItem } from 'src/app/shared/account-util';
import { Users } from '../usermanagement.model';

@Component({
  selector: 'sf-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss'],
})
export class AddRoleComponent implements OnInit {
  @Input() userToEvaluate = '';
  @Input() allRoles: ClientRoleGroup[] = [];
  @Input() existingUserRoles: Users;
  @Input() disableSaveAction: boolean;
  @Output() saveAction: EventEmitter<MatDialogRef<any>> = new EventEmitter();
  @Output() closeAction: EventEmitter<void> = new EventEmitter();

  addRoleFormGroup: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.addRoleFormGroup = this.fb.group({
      selectedRole: [''],
      singleOrg: [''],
      multiOrg: [''],
    });
  }

  buildAccessRequest(userId: string): AccessRequest {
    const roleFc: AbstractControl | null = this.addRoleFormGroup.get('selectedRole');
    const singleOrgFc: AbstractControl | null = this.addRoleFormGroup.get('singleOrg');
    const multiOrgFc: AbstractControl | null = this.addRoleFormGroup.get('multiOrg');

    let roleRequestItem: RequestItem;

    if (roleFc && roleFc.value) {
      // One Role Selected
      roleRequestItem = buildRoleRequestItem(roleFc.value.id);
    } else {
      // No Role Selected
      roleRequestItem = {
        type: 'role-request',
        roles: [],
        calendarRoleUsers: [],
        calendarRoleSemesters: [],
      };
    }

    const orgs: OrgModel[] = [];
    if (singleOrgFc && singleOrgFc.value) {
      orgs.push(singleOrgFc.value);
    } else if (multiOrgFc && multiOrgFc.value) {
      multiOrgFc.value.forEach((singleOrg: any) => {
        orgs.push(singleOrg);
      });
    }

    const emptyKioskRequestItem: RequestItem = {
      type: 'kiosk-request',
      kiosks: [],
    };
    const emptySvcRequestItem: RequestItem = {
      type: 'service-request',
      services: [],
    };

    const ar: AccessRequest = {
      accessUser: {
        userId,
      },
      college: '',
      department: '',
      jobTitle: '',
      starfishTrainingFlag: true,
      requestItems: [emptyKioskRequestItem, emptySvcRequestItem, roleRequestItem],
      'request-date': '',
      'last-status-date': '',
      requestedOrganizations: orgs,
    };

    return ar;
  }

  ngOnInit(): void {
    const roleCtrl = this.addRoleFormGroup.get('selectedRole');

    if (roleCtrl) {
      roleCtrl.valueChanges.subscribe(() => this.updateValidators());
    }
  }

  private updateValidators(): void {
    const roleCtrl = this.addRoleFormGroup.get('selectedRole');
    const singleOrgCtrl = this.addRoleFormGroup.get('singleOrg');
    const multiOrgCtrl = this.addRoleFormGroup.get('multiOrg');

    if (this.addRoleFormGroup && roleCtrl && singleOrgCtrl && multiOrgCtrl) {
      const role: Role = roleCtrl.value;
      singleOrgCtrl.clearValidators();
      multiOrgCtrl.clearValidators();
      if (role && role.relationships && role.relationships.orgSelectionType === 'SINGLE') {
        singleOrgCtrl.setValidators(Validators.required);
      } else if (role && role.relationships && role.relationships.orgSelectionType === 'MULTIPLE') {
        multiOrgCtrl.setValidators(Validators.required);
      }
      this.addRoleFormGroup.updateValueAndValidity();
    }
  }
}
