import { NewGroupOrgModel } from '../../features/dynamic-role/dynamic-role.model';
import { GroupType } from '../utils/form.utils';
import { OrgModel } from './details.model';
import { Relationships } from './relationships.model';

// 'new' client side only models

export type ControleSelectionType = 'USER_REQUESTABLE' | 'ADMIN_ONLY' | 'DELEGATION_ONLY' | 'READ_ONLY';

export class RoleDetails {
  requestedRole: string;
  requestedDate: string;
  requestStatus: string;
  roleGroupName: string;
  roleGroupIconColor: string;
  roleGroupIconImage: string;
}

export class RoleDetailsExtended extends RoleDetails {
  requestedRoleId: number;
  roleGroupId: number;
}

// Summary of the RoleGroup use only by the Role interface
// A separate 'full' RoleGroup model also exists for editing purposes+
export interface RoleGroupSummary {
  id: number | undefined;
  name: string;
}

export interface ServicesKiosks {
  kioskList: OrgModel[];
  kioskDelegation: boolean;
  serviceList: OrgModel[];
  serviceDelegation: boolean;
}

export class RoleAuthorization {
  managerEnabled: boolean | undefined;
  managerFirst: boolean | undefined;
  specialGroupEnabled: boolean | undefined;
  specialGroupName: string;
  asrEnabled: boolean | undefined;
  dataStewardEnabled: boolean | undefined;
}

export interface Role {
  id: number | undefined;
  roleName: string;
  existingGroup: ClientRoleGroup | undefined;
  newGroup: NewGroupOrgModel | undefined;
  availableToStudent: boolean | undefined;
  availableToAdviser: boolean | undefined;
  availableInElevate: boolean | undefined;
  descriptions: string[];
  relationships: Relationships;
  servicesAndKiosks: ServicesKiosks;
  roleControl: ControleSelectionType | undefined;
  authFlow: RoleAuthorization;
  courseConfig: ClientCourseConfiguration;
}

export interface TypeaheadRoleModel {
  id: number;
  name: string;
  iconColor: string;
  iconImage: string;
}

export interface ClientRoleGroup {
  id: number | undefined;
  name: string;
  description: string;
  roles: Role[];
  iconColor: string;
  iconImage: string;
  priority: number | undefined;
  customUmgGroupName: string | undefined;
  customUmgGroupEnabled: boolean | undefined;
}

export interface ClientRoleGroupAndPriority {
  clientSideRole: Role;
  roleGroupType: GroupType;
  originalRoleGroup?: ClientRoleGroup;
}

export class RoleAuthorizationFlow {
  managerEnabled?: boolean;
  managerFirst?: boolean;
  specialGroupEnabled?: boolean;
  specialGroupName?: string;
  asrEnabled?: boolean;
  dataStewardEnabled?: boolean;
}

export interface RoleGroupPriority {
  priority: number;
  groupId: number;
  groupName: string;
  groupColor: string;
}

export interface ClientCourseConfiguration {
  courseType?: ClientCourseSelectionType;
  deferOptions?: ClientCourseDeferOptions;
  courses?: ClientCourses[];
}

export interface ClientCourseDeferOptions {
  deferCampusSelection: boolean;
  deferTrackSelection: boolean;
  deferSemesterSelection: boolean;
  deferCourseSelection: boolean;
}

export interface ClientCourses {
  campus: string;
  termName: string;
  track: string;
  department: string;
  courseNumber: string;
}

export interface ClientDelegationMetaData {
  delegationName: string;
  delegationId: string;
}

export type ClientCourseSelectionType = 'DEFER_COURSES';
