import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

// This class exists because multiple date formats were coming into starfish. This gives a single place to handle all of them.
@Pipe({ name: 'starfishDatePipe' })
export class StarfishDatePipe implements PipeTransform {
  transform(dateTime: string): string {
    const datePipe = new DatePipe('en-US');
    let newTime: string | null = dateTime;

    try {
      newTime = datePipe.transform(dateTime, 'medium');
    } catch (error) {
      // we couldn't do anything, just return the original value passed in
      return dateTime;
    }
    return newTime !== null ? newTime : dateTime;
  }
}
