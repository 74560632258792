/* eslint-disable @typescript-eslint/member-ordering */
import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ClientRoleGroup, RoleGroupPriority } from '@starfish-access/models';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { DynamicRolesFacade } from '../dynamic-role.facade.service';
import { NewGroupOrgModel, NEW_GROUP_ID, NEW_GROUP_PRIORITY } from '../dynamic-role.model';
import { GroupPriorityComponent } from '../group-priority/group-priority.component';

// Error states will not show on submit until this issue is resolved https://github.com/angular/angular/issues/21823
export class RoleGroupNameErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl, form: FormGroupDirective | NgForm): boolean {
    return (control.parent?.hasError('required') || control.invalid) && (control.touched || form.submitted);
  }
}

@Component({
  selector: 'sf-new-role-group',
  templateUrl: './new-role-group.component.html',
  styleUrls: ['./new-role-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewRoleGroupComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NewRoleGroupComponent),
      multi: true,
    },
  ],
})
export class NewRoleGroupComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() loadedRoleGroup: ClientRoleGroup;
  @Input() set requireNewGroup(req: boolean) {
    // this._required =req;
    this.updateValidators(req);
  }
  @ViewChild('priorityComponent')
  groupPriorityComp: GroupPriorityComponent;
  matcher = new RoleGroupNameErrorMatcher();

  newGroupNameSubj: Subject<string> = new BehaviorSubject<string>('');
  sortedPriorityListObs: Subject<RoleGroupPriority[]> = new BehaviorSubject<RoleGroupPriority[]>([]);
  iconSelectionSubj: Subject<string> = new BehaviorSubject<string>('');
  colorSelectionSubj: Subject<string> = new BehaviorSubject<string>('');
  newRoleGroupForm: UntypedFormGroup;
  private destroy$ = new Subject<void>();
  /////
  // private _onChange: Function;
  // private _onTouched: Function;
  // private _onValidatorChange: Function;
  ///

  constructor(public facade: DynamicRolesFacade, private fb: UntypedFormBuilder) {
    this.newRoleGroupForm = this.fb.group({
      name: ['', [Validators.required], this.asyncRoleNameValidator.bind(this)],
    });
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.newRoleGroupForm.valid) {
      return null;
    } else {
      return this.newRoleGroupForm.errors;
    }
  }
  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  // called when loading existing data from ctrl.patchValue
  // This will be used during editing of existing roles and role groups.
  writeValue(obj: ClientRoleGroup): void {
    if (!obj) {
      return;
    }
    const nameCtrl = this.newRoleGroupForm.get('name');
    if (nameCtrl) {
      nameCtrl.patchValue(obj.name);
    }
    this.colorSelectionSubj.next(obj.iconColor);
    this.iconSelectionSubj.next(obj.iconImage);
    this.newGroupNameSubj.next(obj.name);
    if (this.groupPriorityComp) {
      this.groupPriorityComp.reset();
    }
  }

  // For ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  // For ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    // subscribed so that this will run and call on change without setting variables
    combineLatest([this.colorSelectionSubj, this.iconSelectionSubj, this.newGroupNameSubj, this.sortedPriorityListObs])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([color, icon, name, priorities]) => {
        const updatedRoleGroup: RoleGroupPriority = {
          groupColor: color,
          groupName: name,
          groupId: NEW_GROUP_ID,
          priority: NEW_GROUP_PRIORITY,
        };
        if (this.onChange) {
          this.onChange(this.generateNewGroup(color, icon, name, priorities));
        }
        return updatedRoleGroup;
      });
  }

  generateNewGroup(color: string, icon: string, groupName: string, list: RoleGroupPriority[]): NewGroupOrgModel {
    const newGroup: NewGroupOrgModel = {
      color,
      icon,
      name: groupName,
      priorityList: list,
    };

    return newGroup;
  }

  // this.asyncRoleNameValidator.bind(this)
  private asyncRoleNameValidator(control: AbstractControl): Observable<ValidationErrors | null> {
    return control.valueChanges.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      withLatestFrom(this.facade.roleGroupPriorities$),
      map(([newGroupName, roleGroupList]) => {
        for (const group of roleGroupList) {
          if (group.groupName === newGroupName) {
            return { exists: true } as ValidationErrors;
          }
        }
        this.newGroupNameSubj.next(newGroupName);
        return null;
      }),
      tap((vr: ValidationErrors) =>
        // this.newRoleGroupForm.get('name').setErrors(vr)
        // GIT ISSUE: https://git.psu.edu/ais-swe/starfish-access-ui/issues/363
        console.error('name error')
      ),
      catchError((e) => of(null))
    );
  }
  private updateValidators(req: any): void {
    // this.newRoleGroupForm.get('name').setErrors(null);
    const nameCtrl = this.newRoleGroupForm.get('name');
    if (this.newRoleGroupForm && nameCtrl) {
      if (req) {
        nameCtrl.setValidators(Validators.required);
      } else {
        nameCtrl.clearValidators();
      }
      if (this.onValidatorChange) {
        this.onValidatorChange();
      }
      nameCtrl.updateValueAndValidity();
    }
  }

  private onChange: (_: any) => any = (_: any) => ({});
  private onTouched: (_: any) => any = (_: any) => ({});
  private onValidatorChange: () => any = () => ({});
}
