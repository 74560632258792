import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'error-dialog',
  templateUrl: './error-dialog.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ErrorDialog {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<ErrorDialog>) {}
}
