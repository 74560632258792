import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-detail-icon',
  templateUrl: './detail-icon.component.html',
  styleUrls: ['./detail-icon.component.scss'],
})
export class DetailIconComponent {
  @Input() iconName = 'circle';
}
