import { Routes } from '@angular/router';
import { PermissionGuard } from '@psu/apis/fortress';
import { AuthGuard } from '@psu/utils/security';
import { FerpaGuard, KioskServicePermissions } from '@starfish-access/core';
import { KioskSvcComponent } from './kiosk-services.component';

export const routes: Routes = [
  {
    title: 'services',
    path: 'services',
    canActivate: [AuthGuard, FerpaGuard, PermissionGuard],
    component: KioskSvcComponent,
    data: {
      permissions: KioskServicePermissions.VIEW,
    },
  },
];
