import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OkayDialogService } from '@starfish-access/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DUSGroupService } from './dusGroup.service';
import * as RolesActions from './roles.actions';

@Injectable()
export class RolesEffects {
  requestStewards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.RequestStewards>(RolesActions.REQUEST_DATA_STEWARDS),
      switchMap((id) =>
        this.rolesService.getDataStewards().pipe(
          map((stewardsList) => new RolesActions.ReceiveStewards(stewardsList)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorStewards(errorMsg));
          })
        )
      )
    )
  );

  postStewards$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.AddSteward>(RolesActions.POST_DATA_STEWARDS),
      switchMap((newSteward) =>
        this.rolesService.addDataStewards(newSteward.payload).pipe(
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorStewards(errorMsg));
          })
        )
      )
    )
  );

  deleteSteward$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.DeleteSteward>(RolesActions.DELETE_DATA_STEWARDS),
      switchMap((userId) =>
        this.rolesService.removeDataSteward(userId.payload).pipe(
          map(() => new RolesActions.DeleteStewardSuccess(userId.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorStewards(errorMsg));
          })
        )
      )
    )
  );

  requestDUS$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.RequestDUS>(RolesActions.REQUEST_DUS),
      switchMap((id) =>
        this.rolesService.getDus().pipe(
          map((dusList) => new RolesActions.ReceiveDUS(dusList)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorDUS(errorMsg));
          })
        )
      )
    )
  );

  postDus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.AddDUS>(RolesActions.POST_DUS),
      switchMap((newDus) =>
        this.rolesService.addDus(newDus.payload).pipe(
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorDUS(errorMsg));
          })
        )
      )
    )
  );

  deleteDus$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<RolesActions.DeleteDus>(RolesActions.DELETE_DUS),
      switchMap((userId) =>
        this.rolesService.removeDus(userId.payload).pipe(
          map(() => new RolesActions.DeleteDusSuccess(userId.payload)),
          catchError((errorMsg) => {
            this.showErrorDialog(errorMsg);
            return of(new RolesActions.ErrorDUS(errorMsg));
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private rolesService: DUSGroupService,
    private dialogService: OkayDialogService
  ) {}

  showErrorDialog(err: any) {
    if (err) {
      const message = 'Error retrieving information from a required Starfish service.';
      this.dialogService.okay('Starfish Roles Error', message);
    }
  }
}
