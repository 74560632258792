import { Pipe, PipeTransform } from '@angular/core';
import { ClientRoleGroup, Role } from '../model/roles.model';
import { FilterRolePipe } from './filter-role.pipe';

@Pipe({ name: 'filterGroupRole' })
export class FilterGroupRolePipe implements PipeTransform {
  transform(usersRoles: ClientRoleGroup[] | null, filter: any): number {
    const pipe = new FilterRolePipe();
    if (!usersRoles) {
      return 0;
    }

    let totalRoles = 0;
    for (const group of usersRoles) {
      const roles: Role[] = pipe.transform(group.roles, filter);
      totalRoles += roles.length;
    }

    return totalRoles;
  }
}
