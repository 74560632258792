import { prop, sortBy } from 'ramda';
import { OrgModel } from '../model/details.model';
import { RelationshipType } from '../model/relationships.model';
import { ClientRoleGroup, Role, RoleGroupPriority, ServicesKiosks } from '../model/roles.model';
import {
  ServerConfigurationDetails,
  ServerKioskConfiguration,
  ServerRelationshipType,
  ServerRole,
  ServerRoleGroup,
  ServerServiceConfiguration,
} from '../model/server/server-roles.model';

export const toClientRoleGroup = (rg: ServerRoleGroup): ClientRoleGroup => {
  const clientRoles: Role[] = rg.roles.map(toClientRole);
  return {
    customUmgGroupEnabled: rg.customUmgGroupEnabled ? rg.customUmgGroupEnabled : false,
    customUmgGroupName: rg.customUmgGroupName ? rg.customUmgGroupName : '',
    description: rg.description,
    id: rg.id,
    iconColor: rg.iconColor,
    iconImage: rg.iconImage,
    name: rg.name,
    priority: rg.priority,
    roles: clientRoles,
  };
};

export const toClientRoleGroups = (rgs: ServerRoleGroup[]): ClientRoleGroup[] => {
  const crgs = rgs.map(toClientRoleGroup);
  return crgs;
};

export const toClientRole = (role: ServerRole): Role => ({
  id: role.id,
  roleName: role.name ? role.name : '',
  descriptions: role.descriptions ? role.descriptions : [],
  availableToStudent: role.availableToStudent ?? false,
  availableToAdviser: role.availableToAdviser ?? false,
  availableInElevate: role.availableInElevate ?? false,
  servicesAndKiosks: toServicesAndKiosks(role?.kioskConfiguration, role?.serviceConfiguration),
  relationships: {
    orgSelectionType: role.organizationConfiguration?.allowMultipleOrganizations ? 'MULTIPLE' : 'SINGLE',
    relationshipType: toRelationshipType(role.relationshipType),
    organizations: toOrganizations(role.organizationConfiguration?.organizations),
    deferOrganization: role.organizationConfiguration?.deferOrganization
      ? role.organizationConfiguration.deferOrganization
      : false,
    courseType: 'DEFER_COURSES',
  },
  courseConfig: {
    courseType: role.courseConfiguration?.courseType ? role.courseConfiguration.courseType : undefined,
    deferOptions: role.courseConfiguration?.deferOptions ? role.courseConfiguration?.deferOptions : undefined,
    courses: role.courseConfiguration?.courses ? role.courseConfiguration?.courses : [],
  },
  roleControl: role.assignmentType ? role.assignmentType : 'USER_REQUESTABLE',
  authFlow: {
    managerEnabled: role.approvalPathConfiguration?.managerEnabled,
    managerFirst: role.approvalPathConfiguration?.managerFirst,
    specialGroupEnabled: role.approvalPathConfiguration?.specialGroupEnabled,
    specialGroupName: role.approvalPathConfiguration?.specialGroupName
      ? role.approvalPathConfiguration.specialGroupName
      : '',
    asrEnabled: role.approvalPathConfiguration?.asrEnabled,
    dataStewardEnabled: role.approvalPathConfiguration?.dataStewardEnabled,
  },
  existingGroup: undefined,
  newGroup: undefined,
});

export const toRelationshipType = (srt: ServerRelationshipType | undefined): RelationshipType => {
  if (!srt || srt === 'ORGANIZATION') {
    return 'ORGANIZATION';
  }
  return srt;
};

export const toOrganizations = (relationshipOrgs: ServerConfigurationDetails[] | undefined): OrgModel[] => {
  if (!relationshipOrgs) {
    return [];
  }
  const list: OrgModel[] = [];
  relationshipOrgs.forEach((org) => {
    list.push({
      code: org.code,
      name: org.name,
      id: org.id,
    });
  });

  return list.sort((a, b) => a.name.localeCompare(b.name));
};

export const toServicesAndKiosks = (
  kioskOrgs: ServerKioskConfiguration | null | undefined,
  serviceOrgs: ServerServiceConfiguration | null | undefined
): ServicesKiosks => ({
  kioskList: kioskOrgs ? kioskOrgs.kiosks : [],
  kioskDelegation: kioskOrgs?.deferKioskSelection ? kioskOrgs.deferKioskSelection : false,
  serviceList: serviceOrgs ? serviceOrgs.services : [],
  serviceDelegation: serviceOrgs?.deferServiceSelection ? serviceOrgs.deferServiceSelection : false,
});

export const sortPriorityArray = (rp: RoleGroupPriority[]): RoleGroupPriority[] => {
  const sortByGroupPriority: any = sortBy(prop('priority'));
  return sortByGroupPriority(rp);
};

export const sortGroupPriorityArray = (rg: ServerRoleGroup[]): ServerRoleGroup[] => {
  const sortByGroupPriority: any = sortBy(prop('priority'));
  return sortByGroupPriority(rg);
};
