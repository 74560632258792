import { Pipe, PipeTransform } from '@angular/core';
import { ProcessDetails } from '../model/details.model';

@Pipe({ name: 'roleApprovers' })
export class RoleApproversPipe implements PipeTransform {
  transform(pd: ProcessDetails | null): string {
    if (!pd) {
      return '';
    }

    let assignedToList = '';
    pd.approvalTasks.forEach((task) => {
      if (task.taskAssignedTo?.length > 0 && task.taskAssignedTo[0] !== null) {
        assignedToList = task.taskAssignedTo.toString();
      }
    });

    if (assignedToList.length > 0) {
      return assignedToList;
    }

    // we must gather all tasks that may have approvers
    try {
      for (const obj of pd.approvalTasks) {
        // if (roles.roles) {
        for (const key in obj) {
          if (key === 'taskAssignedTo' && obj.taskApprovedBy === null) {
            const approversList = obj[key];
            const approvers = approversList.toString();
            const newApprovers = approvers.replace(new RegExp(',', 'g'), ', ');
            return newApprovers;
          }
        }
      }
    } catch (e) {
      return '';
    }
    return '';
  }
}
