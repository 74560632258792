<div class="container unmapped-course-display-wrapper">
  <div class="unmapped">
    <div class="unmapped-courses-comp">
      <div>
        <span
          class="body-bold mat-body-1"
          fxFlex
          >Assign the subjects below to the appropriate tutoring section by using the dropdown in the table.</span
        >
      </div>
      <mat-table
        mat-table
        class="mat-elevation-z0 starfish-data-table"
        [dataSource]="dataSource"
        data-cy="dataSourceTable">
        <ng-container matColumnDef="subject">
          <th
            mat-header-cell
            *matHeaderCellDef>
            Unassigned Subject
          </th>
          <td
            mat-cell
            [attr.data-cy]="element.subject + 'umapped-subject'"
            *matCellDef="let element">
            {{ element.subject }}
          </td>
        </ng-container>
        <ng-container matColumnDef="sections">
          <th
            mat-header-cell
            *matHeaderCellDef>
            Service
          </th>
          <td
            mat-cell
            *matCellDef="let element">
            <mat-form-field
              appearance="outline"
              class="section-selection">
              <mat-label [attr.data-cy]="element.subject + 'dropdown-label'">Choose Service</mat-label>
              <mat-select
                [(value)]="element.section"
                [attr.data-cy]="element.subject + 'dropdown'">
                <mat-option
                  *ngFor="let option of sections"
                  [value]="option.value"
                  [attr.data-cy]="option.viewValue + 'dropdownoption'"
                  (click)="onServiceSelection(element.subject, option.viewValue)"
                  >{{ option.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"></tr>
      </mat-table>
    </div>
  </div>

  <div class="mapped">
    <button
      [disabled]="selections.length < 1"
      mat-flat-button
      data-cy="save-mappings"
      (click)="saveMappings()"
      color="primary">
      <mat-icon>save</mat-icon><span class="save-mappings">Save Mappings</span>
    </button>
    <div class="legend-item-wrapper">
      <mat-list *ngFor="let item of sections">
        <mat-list-item class="legend-item">
          <div
            class="green3 icon-dot"
            matListItemIcon>
            <mat-icon>golf_course</mat-icon>
          </div>
          <div matListItemTitle>{{ item.viewValue }}</div>

          <div matListItemLine>{{ item.value.serviceDto.name }}</div>
          <div matListItemMeta>
            <button
              mat-icon-button
              [attr.data-cy]="item.value.serviceDto.name"
              (click)="launchDialog(item.value)">
              <mat-icon
                class="info-icon"
                svgIcon="information-outline"></mat-icon>
            </button>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
