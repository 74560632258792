import { Component, Input } from '@angular/core';
import { RoleDetails } from '@starfish-access/models';

@Component({
  templateUrl: './role-icon.component.html',
  styleUrls: ['./role-icon.component.scss'],
  selector: 'sf-role-icon',
})
export class RoleIconComponent {
  @Input() iconName = 'how_to_reg';
  @Input() iconColor = 'blue';
  @Input() isOpen: boolean;
  @Input() roleName = ''; // This is the specific role this icon is for.
  @Input() myRoles: RoleDetails[]; // This is a list of all roles a user has requested (used for knowing if to disable the image)
}
