import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogMessage } from '@starfish-access/models';

@Component({
  templateUrl: './textarea-dialog.html',
})
export class TextAreaDialogComponent {
  action: string;
  comment: string;
  statusMessageMapping = new Map<string, DialogMessage>();

  constructor(public dialogRef: MatDialogRef<TextAreaDialogComponent>) {
    this.statusMessageMapping.set('AWAITING_ADDITIONAL_INFO', {
      dialogTitle: 'Resubmit This Request',
      dialogMsg: 'Are you sure? This will resubmit this request.',
    });

    this.statusMessageMapping.set('APPROVE', {
      dialogTitle: 'Approve This Request',
      dialogMsg: 'Are you sure? This will approve this request.',
    });

    this.statusMessageMapping.set('REJECT', {
      dialogTitle: 'Reject This Request',
      dialogMsg: 'Are you sure? This will reject this request.',
    });

    this.statusMessageMapping.set('ADDING_USER_TO_STARFISH', {
      dialogTitle: 'Must Add User to Starfish',
      dialogMsg:
        'Are you sure? The user must be added to Starfish. This task will remain active with the same task ID.',
    });
  }
}
