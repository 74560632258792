import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RoleDeletionDialogData {
  numberOfAssignments: number;
  roleNameToDelete: string;
}

@Component({
  selector: 'sf-role-deletion-dialog',
  templateUrl: './role-deletion-dialog.component.html',
  styleUrls: ['./role-deletion-dialog.component.scss'],
})
export class RoleDeletionDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: RoleDeletionDialogData) {}
}
