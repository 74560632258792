import { Pipe, PipeTransform } from '@angular/core';
import { differenceWith, eqBy, innerJoin, prop } from 'ramda';
import { Users } from '../../features/usermanagement/usermanagement.model';
import { Role } from '../model/roles.model';

@Pipe({ name: 'pruneRolesList' })
export class PruneRolesListPipe implements PipeTransform {
  transform(singleRoleList: Role[], existingUserRoles: Users): Role[] {
    if (!existingUserRoles || !existingUserRoles.roles) {
      return singleRoleList;
    }

    // Throw the given user's role Ids in an array of objects to make the ramda functions simpler
    const existingRoleList = existingUserRoles.roles.map(
      (userRole) =>
        ({
          id: userRole.roleId,
        } as Role)
    );

    // since we aren't getting the same object back from the endpoint, we need to get that into a list of Role models
    // once that list is in the same model class as the endpoint provides, we remove everything common between the two lists
    // to eliminate roles the user has already requested.
    const eqById = eqBy<Role>(prop('id'));

    // Get the role ids the given user already has
    const alreadySelected = innerJoin(eqById, singleRoleList, existingRoleList);

    // Return a list of roles from singleRoleList, with any roles the given user already has removed
    return differenceWith(eqById, singleRoleList, alreadySelected);
  }
}
