import { Pipe, PipeTransform } from '@angular/core';
import { ApprovalWorkFlowDisplay } from '../model/approve.model';
import { InitialCapsPipe } from './initial-caps.pipe';
import { StarfishDatePipe } from './starfish-date.pipe';

@Pipe({ name: 'formatTaskStatus' })
export class FormatTaskStatusPipe implements PipeTransform {
  private initialCapsPipe = new InitialCapsPipe();
  private starfishDatePipe = new StarfishDatePipe();

  transform(approvalTask: ApprovalWorkFlowDisplay): string {
    if (!approvalTask) {
      return '';
    }
    if (approvalTask.completedBy && approvalTask.completedBy.length > 0) {
      const formatted: string =
        this.initialCapsPipe.transform(approvalTask.status) +
        ' | ' +
        this.starfishDatePipe.transform(approvalTask.timeCompleted);
      return formatted;
    }
    return this.initialCapsPipe.transform(approvalTask.status);
  }
}
