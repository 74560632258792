import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RoleConfig, UserInfo } from '@starfish-access/models';
import { SEARCH_IMPL, TypeaheadSelectConfig } from '@starfish-access/shared';
import { SearchService } from '../../../core/services/search.service';

@Component({
  selector: 'sf-connected-student',
  templateUrl: './connected-student.component.html',
  styleUrls: ['./connected-student.component.scss'],
  providers: [
    { provide: SEARCH_IMPL, useExisting: SearchService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConnectedStudentComponent),
      multi: true,
    },
  ],
})
export class ConnectedStudentComponent implements OnInit, ControlValueAccessor {
  @Input() selectedRole: RoleConfig;
  delegationAdminConfig: TypeaheadSelectConfig = {
    inputAppearance: 'outline',
    inputPlaceholder: 'Search by User ID or Name',
    removableItems: true,
    showSummary: true,
  };
  connectedUserFormGroup: UntypedFormGroup;

  constructor(fb: UntypedFormBuilder) {
    this.connectedUserFormGroup = fb.group({
      users: [],
      bulkusers: [],
    });
  }
  writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    this.connectedUserFormGroup.patchValue(obj);
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.connectedUserFormGroup.valueChanges.subscribe((value: UserInfo[]) => {
      this.propagateChange(value);
    });
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
