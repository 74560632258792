import { Pipe, PipeTransform } from '@angular/core';
import { ClientRoleGroup } from '../model/roles.model';

@Pipe({ name: 'filterAdminGroups' })
export class FilterAdminGroupsPipe implements PipeTransform {
  transform(groups: ClientRoleGroup[] | null): ClientRoleGroup[] {
    const rgArr: ClientRoleGroup[] = [];

    if (!groups) {
      return rgArr;
    }

    groups.forEach((group) => {
      if (group.roles && group.roles.length > 0) {
        rgArr.push(group);
      }
    });
    return rgArr;
  }
}
