import { Pipe, PipeTransform } from '@angular/core';
import { UserInfo } from '../model/delegation.model';

@Pipe({ name: 'toIdList' })
export class ToIdListPipe implements PipeTransform {
  transform(userInfo: UserInfo[] | null): string {
    if (!userInfo) {
      return '';
    }

    return userInfo.map((user) => user.id).join(',');
  }
}
