import { Injectable } from '@angular/core';
import { OkayDialogService, TaskService } from '@starfish-access/core';
import { Tasks } from '@starfish-access/models';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Injectable()
export class TaskListFacade {
  isFetching$ = new BehaviorSubject<boolean>(false);
  hasFailed$ = new BehaviorSubject<boolean>(false);
  errorMsg$ = new BehaviorSubject<string>('');

  constructor(private service: TaskService, private dialogService: OkayDialogService) {}

  getTasks(): Observable<Tasks[]> {
    this.isFetching$.next(true);
    this.hasFailed$.next(false);
    return this.service.getTasks().pipe(
      catchError((err) => {
        this.hasFailed$.next(true);
        this.errorMsg$.next(err);
        this.dialogService.okay('Starfish Error', 'Error retrieving information from a required Starfish service.');
        return [];
      }),
      finalize(() => {
        this.isFetching$.next(false);
      })
    );
  }

  getAuditTasks(): Observable<Tasks[]> {
    this.isFetching$.next(true);
    this.hasFailed$.next(false);
    return this.service.getAuditTasks().pipe(
      catchError((err) => {
        this.isFetching$.next(false);
        this.hasFailed$.next(true);
        this.errorMsg$.next(err);
        return [];
      }),
      finalize(() => {
        this.isFetching$.next(false);
      })
    );
  }

  requestIfUserIsDataStewardFromService(id: string, hasPerms: boolean): Observable<boolean> {
    if (hasPerms) {
      return of(true);
    }

    return this.service.isUserDataSteward(id);
  }
}
