<span *ngIf="(isFetching | async) && !(hasFailed | async)">
  <br /><br />
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</span>
<span *ngIf="(isFetching | async) && (hasFailed | async)">
  <br /><br />
  We couldn't find the user roles. Please try again later, or contact an admin for help.
</span>
<div *ngIf="!(isFetching | async)">
  <sf-user-search
    [canAdd]="canAddUser"
    [users]="userList"
    [userType]="displayName"
    (addNewUserToSource)="addUser($event)"></sf-user-search>
  <sf-role-table
    [users]="userList"
    [userRoleColumns]="matTableColumns"
    [canDelete]="canDeleteUser"
    [tableType]="displayName"
    (deleteUser)="deleteUser($event)">
  </sf-role-table>
</div>
