import { Pipe, PipeTransform } from '@angular/core';
import { ClientCourseChannelResponse } from '@starfish-access/models';

@Pipe({ name: 'hasUnsyncedCourses' })
export class UnsyncedCoursesPipe implements PipeTransform {
  transform(channel: ClientCourseChannelResponse | null): boolean {
    if (!channel) {
      return false;
    }

    let hasUnsynchronized = false;
    channel.courses?.forEach((element) => {
      if (!element.synchronized) {
        hasUnsynchronized = true;
      }
    });
    return hasUnsynchronized;
  }
}
