import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CancelProcessPipe } from './cancel-process.pipe';
import { CommentSizePipe } from './comment-size.pipe';
import { FormatTaskCommentPipe } from './comment-task.pipe';
import { DisplayNamePipe } from './display-name.pipe';
import { FilterAdminGroupsPipe } from './filter-admin-groups.pipe';
import { FilterConfiguredDelegationPipe } from './filter-configured-delegation.pipe';
import { FilterDelegationPipe } from './filter-delegation.pipe';
import { FilterGroupRolePipe } from './filter-group-role.pipe';
import { FilterRolePipe } from './filter-role.pipe';
import { FilterPipe } from './filter.pipe';
import { ConfiguredDelegationByIdPipe } from './get-configured-delegation-by-id.pipe';
import { DelegationByIdPipe } from './get-delegation-by-id.pipe';
import { HasCartItemsPipe } from './has-cart-items.pipe';
import { HasRolePipe } from './has-role.pipe';
import { InitialCapsPipe } from './initial-caps.pipe';
import { IsStewardPipe } from './is-steward.pipe';
import { FilterSyncedCoursesPipe } from './is-unsynced-courses.pipe';
import { KioskNamePipe } from './kiosk-name.pipe';
import { OrgNamePipe } from './org-name.pipe';
import { PruneRolesListPipe } from './prune-roles.pipe';
import { RoleRelationshipsPipe } from './relationships.pipe';
import { ReportRoleRelationshipsPipe } from './report-relationships.pipe';
import { ReverseListPipe } from './reverse.pipe';
import { RoleApproversPipe } from './role-approvers.pipe';
import { RoleNamePipe } from './role-name.pipe';
import { RoleToSelectPipe } from './role-to-select.pipe';
import { ServiceNamePipe } from './service-name.pipe';
import { SortCampusPipe } from './sort-campuses.pipe';
import { SortCoursesPipe } from './sort-courses.pipe';
import { SortArrayOfOrgModelsPipe } from './sort-orgs-model.pipe';
import { SortArrayOfOrgSelectItemsPipe } from './sort-orgs.pipe';
import { SortArrayOfServicesItemsPipe } from './sort-services.pipe';
import { SortUserNamePipe } from './sort-user-name.pipe';
import { StarfishDatePipe } from './starfish-date.pipe';
import { TargetTooltipPipe } from './target-tooltip.pipe';
import { FormatTaskStatusPipe } from './task-approval.pipe';
import { ToIdListPipe } from './to-id-list.pipe';
import { UnsyncedCourseCountPipe } from './unsynced-course-count.pipe';
import { UnsyncedCoursesPipe } from './unsynced-courses.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    StarfishDatePipe,
    HasRolePipe,
    HasCartItemsPipe,
    FilterRolePipe,
    InitialCapsPipe,
    CommentSizePipe,
    FormatTaskCommentPipe,
    FormatTaskStatusPipe,
    ReverseListPipe,
    PruneRolesListPipe,
    RoleNamePipe,
    RoleToSelectPipe,
    KioskNamePipe,
    ServiceNamePipe,
    RoleApproversPipe,
    CancelProcessPipe,
    OrgNamePipe,
    FilterGroupRolePipe,
    IsStewardPipe,
    FilterAdminGroupsPipe,
    FilterDelegationPipe,
    FilterConfiguredDelegationPipe,
    DisplayNamePipe,
    UnsyncedCoursesPipe,
    RoleRelationshipsPipe,
    SortArrayOfOrgSelectItemsPipe,
    SortArrayOfOrgModelsPipe,
    UnsyncedCourseCountPipe,
    ReportRoleRelationshipsPipe,
    SortCoursesPipe,
    FilterSyncedCoursesPipe,
    SortCampusPipe,
    TargetTooltipPipe,
    DelegationByIdPipe,
    SortUserNamePipe,
    ConfiguredDelegationByIdPipe,
    ToIdListPipe,
    SortArrayOfServicesItemsPipe,
  ],
  exports: [
    FilterPipe,
    StarfishDatePipe,
    HasRolePipe,
    HasCartItemsPipe,
    FilterRolePipe,
    InitialCapsPipe,
    CommentSizePipe,
    FormatTaskCommentPipe,
    FormatTaskStatusPipe,
    ReverseListPipe,
    PruneRolesListPipe,
    RoleNamePipe,
    RoleToSelectPipe,
    KioskNamePipe,
    ServiceNamePipe,
    RoleApproversPipe,
    CancelProcessPipe,
    OrgNamePipe,
    FilterGroupRolePipe,
    IsStewardPipe,
    FilterAdminGroupsPipe,
    FilterDelegationPipe,
    FilterConfiguredDelegationPipe,
    DisplayNamePipe,
    UnsyncedCoursesPipe,
    RoleRelationshipsPipe,
    SortArrayOfOrgSelectItemsPipe,
    SortArrayOfOrgModelsPipe,
    UnsyncedCourseCountPipe,
    ReportRoleRelationshipsPipe,
    SortCoursesPipe,
    FilterSyncedCoursesPipe,
    SortCampusPipe,
    TargetTooltipPipe,
    DelegationByIdPipe,
    SortUserNamePipe,
    ConfiguredDelegationByIdPipe,
    ToIdListPipe,
    SortArrayOfServicesItemsPipe,
  ],
})
export class FilterModule {}
