import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '@starfish-access/models';

@Pipe({ name: 'sortCampusName' })
export class SortCampusPipe implements PipeTransform {
  transform(arr: MenuItem[] | null): MenuItem[] {
    if (!arr) {
      return [];
    }

    return arr.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }
}
