import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as R from 'ramda';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { EditedRoleGroupModel } from '../../features/dynamic-role/dynamic-role.model';
import { STARFISH_CONFIG, StarfishConfig } from '../../starfish.config';
import {
  ClientDelegationMetaData,
  ClientRoleGroup,
  ClientRoleGroupAndPriority,
  RoleGroupPriority,
} from '../model/roles.model';
import {
  RoleGroupUpdateInfo,
  ServerDelegationMetaData,
  ServerRoleGroup,
  ServerRoleGroupAndPriority,
} from '../model/server/server-roles.model';
import { addToRoleGroup } from '../utils/form.utils';
import { handleErrorFromRest } from '../utils/utils.model';
import { sortGroupPriorityArray, toClientRoleGroup, toClientRoleGroups } from './roles.service.utils';
import { headers } from './service.utils';

@Injectable()
export class RolesService {
  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getRequestableGroups(): Observable<ClientRoleGroup[]> {
    const groupsUrl =
      this.starfishConfig.starfishServices + 'roleGroups?includeAdminRoles=false&includeDelegationRoles=false';
    return this.http.get<ServerRoleGroup[]>(groupsUrl, { headers }).pipe(
      map((result) => {
        const rg: ServerRoleGroup[] = result;
        const sortedGroups: ServerRoleGroup[] = this.sortGroupPriorityArray(rg);

        const clientRgList: ClientRoleGroup[] = [];
        sortedGroups.forEach((roleGroup) => {
          clientRgList.push(toClientRoleGroup(roleGroup));
        });
        return clientRgList;
      }),
      catchError(handleErrorFromRest)
    );
  }

  getRequestableRoleGroupsWithAdminRoles(includeDelegationRoles: boolean): Observable<ClientRoleGroup[]> {
    const groupsUrl =
      this.starfishConfig.starfishServices +
      'roleGroups?includeAdminRoles=true&includeDelegationRoles=' +
      includeDelegationRoles +
      '&includeReadOnlyRoles=' +
      includeDelegationRoles; // if we include del roles, also include read only roles
    return this.http.get<ClientRoleGroup[]>(groupsUrl, { headers }).pipe(
      map((result) => {
        const rg: ServerRoleGroup[] = result;
        const sortedGroups: ServerRoleGroup[] = sortGroupPriorityArray(rg);

        const clientRgList: ClientRoleGroup[] = [];
        sortedGroups.forEach((roleGroup) => {
          clientRgList.push(toClientRoleGroup(roleGroup));
        });
        return clientRgList;

        // return sortedGroups;
      }),
      catchError(handleErrorFromRest)
    );
  }

  getRoleGroupPriorities(): Observable<RoleGroupPriority[]> {
    const url = this.starfishConfig.starfishServices + 'roleGroups';
    return this.http.get<ServerRoleGroup[]>(url, { headers }).pipe(
      map((roleGroupsList) => {
        const rgp: RoleGroupPriority[] = [];
        for (const rg of roleGroupsList) {
          const rgpEntry: RoleGroupPriority = {
            groupColor: rg.iconColor,
            groupId: rg.id !== undefined ? rg.id : -1,
            groupName: rg.name,
            priority: rg.priority !== undefined ? rg.priority : -1,
          };
          rgp.push(rgpEntry);
        }
        return rgp;
      }),
      catchError(handleErrorFromRest)
    );
  }

  sortPriorityArray(rp: RoleGroupPriority[]): RoleGroupPriority[] {
    const sortByGroupPriority: any = R.sortBy(R.prop('priority'));
    return sortByGroupPriority(rp);
  }

  sortGroupPriorityArray(rg: ServerRoleGroup[]): ServerRoleGroup[] {
    const sortByGroupPriority: any = R.sortBy(R.prop('priority'));
    return sortByGroupPriority(rg);
  }

  createRoleAndValidateUmg(gap: ClientRoleGroupAndPriority): Observable<Response> {
    const serverGap: ServerRoleGroupAndPriority = addToRoleGroup(gap);
    const rg: ServerRoleGroup = serverGap.roleGroup;
    if (rg.customUmgGroupEnabled) {
      return this.createRoleAndValidateUmgService(rg);
    }
    const rgWithoutUmgName: ServerRoleGroup = R.omit(['customUmgGroupName', 'customUmgGroupEnabled'], rg);
    return this.createNewRole(rgWithoutUmgName);
  }

  updateRoleAndValidateUmg(clientSideRole: ClientRoleGroupAndPriority): Observable<Response> {
    const gap: ServerRoleGroupAndPriority = addToRoleGroup(clientSideRole);
    const rg: RoleGroupUpdateInfo = gap.roleGroupInfo;
    if (rg.customUmgGroupEnabled) {
      return this.updateRoleAndValidateUmgService(rg);
    }
    return this.updateRoleGroup(rg);
  }

  createNewGroupAndSetPriority(clientGap: ClientRoleGroupAndPriority): Observable<Response> {
    const url = this.starfishConfig.starfishServices + 'roleGroups';
    const serverGap: ServerRoleGroupAndPriority = addToRoleGroup(clientGap);
    const priorityList: string[] = this.generatePriorityHeaderValue(serverGap.roleGroupPriority);

    return this.http
      .post<Response>(url, serverGap.roleGroup, {
        headers: headers.append('PRIORITY-LIST', priorityList),
      })
      .pipe(catchError(handleErrorFromRest));
  }

  updateExistingRoleGroup(rg: EditedRoleGroupModel): Observable<Response> {
    const priorityList: string[] = this.generatePriorityHeaderValue(rg.updatedGroupDetails.priorityList);

    const updatedRoleGroup: ServerRoleGroup = {
      name: rg.updatedGroupDetails.name,
      description: rg.updatedGroupDetails.description || '',
      roles: rg.originalRoleGroup.roles,
      iconColor: rg.updatedGroupDetails.color,
      iconImage: rg.updatedGroupDetails.icon,
      id: rg.originalRoleGroup.id,
    };

    const getUrl =
      this.starfishConfig.starfishServices +
      'roleGroups/' +
      rg.originalRoleGroup.id +
      '/roles?includeAdminRoles=true&includeDelegationRoles=true&includeReadOnlyRoles=true';

    const putUrl = this.starfishConfig.starfishServices + 'roleGroups/' + rg.originalRoleGroup.id;

    return this.http.get<any[]>(getUrl, { headers }).pipe(
      mergeMap((result) => {
        updatedRoleGroup.roles = result;
        return this.http.put<Response>(putUrl, updatedRoleGroup, {
          headers: headers.append('PRIORITY-LIST', priorityList),
        });
      }),
      catchError(handleErrorFromRest)
    );
  }

  getAssociatedDelegationGroups(roleId: string): Observable<ClientDelegationMetaData[]> {
    const url = this.starfishConfig.starfishServices + 'delegationGroups/role/' + roleId;
    return this.http.get<ServerDelegationMetaData[]>(url, { headers }).pipe(
      map(
        (result) => result.map(this.toClientDelegationMetaData),
        catchError(() => of([]))
      )
    );
  }

  generatePriorityHeaderValue(rgp: RoleGroupPriority[]): string[] {
    const priorityIdList: string[] = [];
    for (const priority of rgp) {
      const id = priority.groupId;
      if (id === 1234) {
        priorityIdList.push('new');
      } else {
        priorityIdList.push(id.toString());
      }
    }
    return priorityIdList;
  }

  getAllRoleGroups(): Observable<ClientRoleGroup[]> {
    const params = {
      includeAdminRoles: 'true',
      includeDelegationRoles: 'true',
      includeReadOnlyRoles: 'true',
    };
    const url = this.starfishConfig.starfishServices + 'roleGroups';
    return this.http
      .get<ServerRoleGroup[]>(url, { headers, params })
      .pipe(catchError(handleErrorFromRest), map(toClientRoleGroups));
  }

  private updateRoleGroup(rgInfo: RoleGroupUpdateInfo): Observable<Response> {
    const url =
      this.starfishConfig.starfishServices +
      'roleGroups/' +
      rgInfo.originalRoleGroup.id +
      '/roles/' +
      rgInfo.updatedRole.id;

    const customHeaders = headers.append(
      'NEW-GROUP-ID',
      rgInfo.destinationRoleGroup.id ? rgInfo.destinationRoleGroup.id.toString() : ''
    );

    return this.http
      .put<Response>(url, rgInfo.updatedRole, {
        headers: customHeaders,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  private updateRoleAndValidateUmgService(rg: RoleGroupUpdateInfo): Observable<Response | any> {
    const umgGroupName = rg.customUmgGroupName;
    if (umgGroupName) {
      return this.isUmgGroupValid(umgGroupName).pipe(
        switchMap((isValidUmgGroup) => {
          if (umgGroupName && umgGroupName.length > 0 && !isValidUmgGroup) {
            // invalid group name, do something
            return throwError({
              message: 'The Custom UMG Group name you supplied could not be found.',
            });
          } else {
            // const rgWithoutUmgName: RoleGroup = R.dissoc('customUmgGroupName', rg);
            return this.updateRoleGroup(rg);
          }
        })
      );
    }
    return of(handleErrorFromRest);
  }

  private createRoleAndValidateUmgService(rg: ServerRoleGroup): Observable<Response | any> {
    const umgGroupName = rg.customUmgGroupName;
    if (umgGroupName) {
      return this.isUmgGroupValid(umgGroupName).pipe(
        switchMap((isValidUmgGroup) => {
          if (umgGroupName && umgGroupName.length > 0 && !isValidUmgGroup) {
            // invalid group name, do something
            return throwError({
              message: 'The Custom UMG Group name you supplied could not be found.',
            });
          } else {
            const rgWithoutUmgName: ServerRoleGroup = R.omit(['customUmgGroupName', 'customUmgGroupEnabled'], rg);
            return this.createNewRole(rgWithoutUmgName);
          }
        })
      );
    }
    return of(handleErrorFromRest);
  }

  private isUmgGroupValid(umgGroupName: string): Observable<boolean> {
    const url = this.starfishConfig.starfishServices + 'isUmgGroupValid?umgGroup=' + umgGroupName;

    return this.http.get<boolean>(url, { headers }).pipe(catchError(handleErrorFromRest));
  }

  // creates a new role in an existing role group /roleGroups/{roleGroupId}
  private createNewRole(rg: ServerRoleGroup): Observable<Response> {
    const url = this.starfishConfig.starfishServices + 'roleGroups/' + rg.id;
    return this.http.put<Response>(url, rg, { headers }).pipe(catchError(handleErrorFromRest));
  }

  private toClientDelegationMetaData(server: ServerDelegationMetaData): ClientDelegationMetaData {
    return {
      delegationId: server.id?.toString(),
      delegationName: server.name,
    };
  }
}
