import { Component, Input } from '@angular/core';

@Component({
  selector: 'sf-generate-csv',
  templateUrl: './generate-csv.component.html',
  styleUrls: ['./generate-csv.component.scss'],
})
export class GenerateCsvComponent {
  @Input() dataFile = '';
  @Input() colorType = 'accent';
  @Input() buttonLabel = 'Download';

  setting: any = {
    element: {
      dynamicDownload: HTMLElement,
    },
  };

  constructor() {
    this.setting.element.dynamicDownload = document.createElement('a');
  }

  dynamicDownloadCsv(): void {
    this.dynamicDownloadByHtmlTag({
      fileName: 'user_request.csv',
      text: this.dataFile,
    });
  }

  private dynamicDownloadByHtmlTag(arg: { fileName: string; text: string }) {
    const element = this.setting.element.dynamicDownload;

    const fileType = 'text/csv;charset=utf-8;';

    element.setAttribute('href', `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`);

    element.setAttribute('download', arg.fileName);

    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }
}
