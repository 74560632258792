import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserInfo } from '@starfish-access/models';
import { OrgSelectItem, SEARCH_IMPL } from '@starfish-access/shared';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SearchService } from '../../../core/services/search.service';
import { AccountAutoCompleteComponent } from '../../../shared/autocomplete/autocomplete.component';
import { buildStudentList } from '../directors.facade';
import { ConnectedStudentForm } from '../multi-student/multi-student.component';

@Component({
  selector: 'sf-connected-student-summary',
  templateUrl: './connected-student-summary.component.html',
  styleUrls: ['./connected-student-summary.component.scss'],
  providers: [
    { provide: SEARCH_IMPL, useExisting: SearchService },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConnectedStudentSummaryComponent,
      multi: true,
    },
  ],
})
export class ConnectedStudentSummaryComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('searchInput') searchInput: AccountAutoCompleteComponent;
  connectedUserFormGroup: UntypedFormGroup;
  filterSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  filteredChips$: Observable<UserInfo[]>;
  deletionCount = 0;
  private destroy$ = new Subject<void>();

  constructor(fb: UntypedFormBuilder) {
    this.connectedUserFormGroup = fb.group({
      users: [],
      bulkusers: {
        connectedUsers: '',
        studentUpdateType: 'MERGE',
      },
    });
  }
  writeValue(obj: ConnectedStudentForm): void {
    if (!obj) {
      return;
    }

    const allthethings: UserInfo[] = buildStudentList(obj);

    const connectedUserForm: ConnectedStudentForm = {
      users: allthethings,
      bulkusers: {
        connectedUsers: '',
        studentUpdateType: 'MERGE',
      },
    };

    this.connectedUserFormGroup.patchValue(connectedUserForm);

    this.filteredChips$ = this.filterSubject$.pipe(
      takeUntil(this.destroy$),
      map((filter) => {
        if (!filter) {
          return this.connectedUserFormGroup?.get('users')?.value;
        }

        const orgList: OrgSelectItem[] = this.connectedUserFormGroup?.get('users')?.value
          ? this.connectedUserFormGroup?.get('users')?.value
          : [];

        return orgList.filter((u) => {
          if (u?.id) {
            return u.id.toLowerCase().includes(filter.toLowerCase());
          }
          return '';
        });
      })
    );
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  ngOnInit(): void {
    this.connectedUserFormGroup.valueChanges.subscribe((value: UserInfo[]) => {
      this.propagateChange(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  removeSelectedItem(item: OrgSelectItem): void {
    const orgList: OrgSelectItem[] = this.connectedUserFormGroup?.get('users')?.value
      ? this.connectedUserFormGroup?.get('users')?.value
      : [];
    const index = orgList.indexOf(item);
    const patchThis: ConnectedStudentForm = {
      users: orgList,
      bulkusers: this.connectedUserFormGroup?.get('bulkusers')?.value as any,
    };

    if (index > -1) {
      this.deletionCount++;
      orgList.splice(index, 1);
      if (this.connectedUserFormGroup?.get('users')) {
        this.connectedUserFormGroup?.get('users')?.patchValue(orgList);
      }
      this.propagateChange(patchThis);

      this.filterSubject$.next('');
      if (this.searchInput?.input?.nativeElement?.value) {
        this.searchInput.input.nativeElement.value = ' ';
      }
    }
  }

  private propagateChange: (_: any) => any = (_: any) => ({});
  private propagateTouch: (_: any) => any = (_: any) => ({});
}
