import { Pipe, PipeTransform } from '@angular/core';
import { AccessRequest, ProcessDetails, RequestItem } from '../model/details.model';

@Pipe({ name: 'serviceName' })
export class ServiceNamePipe implements PipeTransform {
  transform(pd: ProcessDetails | null): string[] {
    if (!pd) {
      return ['None'];
    }

    const request: AccessRequest = pd.requestDetails;
    const requestItemDetails: RequestItem[] = request.requestItems;

    try {
      const serviceList: string[] = [];
      for (const services of requestItemDetails) {
        if (services?.services && services.services.length > 0) {
          for (const service of services.services) {
            serviceList.push(service.name);
          }
        }
      }
      if (serviceList.length === 0) {
        return ['--'];
      }
      return serviceList;
    } catch (e) {}
    return ['None'];
  }
}
