export class Tasks {
  taskId: string;
  taskName: string;
  requester: string;
  processInstanceId: string;
  assignee: string;
  taskCreatedTime: string;
  taskStatus: string;
}

export interface ClientAuditTask {
  userDisplayName: string;
  userId: string;
  roleName: string;
  taskName: string;
}
