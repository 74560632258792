import { Pipe, PipeTransform } from '@angular/core';
import { DelegationMetaData } from '@starfish-access/models';

@Pipe({ name: 'filterDelegation' })
export class FilterDelegationPipe implements PipeTransform {
  transform(delegationList: DelegationMetaData[], filter: string): DelegationMetaData[] {
    if (!delegationList || !filter) {
      return delegationList;
    }

    return delegationList.filter((d) => {
      if (d && d.name) {
        return d.name.toLowerCase().includes(filter.toLowerCase());
      }
      return '';
    });
  }
}
