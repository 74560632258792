import { InjectionToken } from '@angular/core';

export interface StarfishConfig {
  environment: string;
  cprService: string;
  securityService: string;
  fortressService: string;
  starfishServices: string;
  directoryServices: string;
  searchService: string;
  psuHomePage: string;
  psuHelpDesk: string;
  psuDirectoryEntry: string;
  starfishInterface: string;
  orientationLink: string;
  turnOffUserDisplayNameTag?: boolean;
}

export const STARFISH_CONFIG = new InjectionToken<StarfishConfig>('starfish.config');
