<div class="sf-nav-wrapper">
  <mat-nav-list class="starfish-navigation-menu">
    <div *ngFor="let section of navSections | async">
      <!-- navigation sections that cannot collapse -->
      <div *ngIf="!section.heading">
        <ng-template
          ngFor
          let-view
          [ngForOf]="section.views"
          let-even="even"
          let-odd="odd"
          let-rowIndex="index">
          <ng-container
            [ngTemplateOutlet]="subitemdisplay"
            [ngTemplateOutletContext]="{ view: view, cartNumber: cartNumber }">
          </ng-container>
        </ng-template>
      </div>

      <!-- navigation sections that can collapse -->
      <mat-expansion-panel
        expanded
        *ngIf="section.heading">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span>{{ section.heading }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template
          ngFor
          let-view
          [ngForOf]="section.views"
          let-even="even"
          let-odd="odd"
          let-rowIndex="index">
          <ng-container
            [ngTemplateOutlet]="subitemdisplay"
            [ngTemplateOutletContext]="{ view: view }">
          </ng-container>
        </ng-template>
      </mat-expansion-panel>
    </div>

    <a
      data-cy="keep-menu-open-nav-item"
      mat-list-item>
      <mat-slide-toggle
        matListItemTitle
        (change)="saveUserPrefs($event)"
        [checked]="menuSubject | async"
        class="navigation-label-text"
        >Keep Menu Open</mat-slide-toggle
      >
    </a>
  </mat-nav-list>

  <!-- common navigation items. This displays the icon and the name, along with the link-->
  <ng-template
    #subitemdisplay
    let-view="view">
    <a
      [attr.data-cy]="view.routerLink + '-nav-item'"
      mat-list-item
      [routerLink]="view.routerLink"
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active-link"
      (click)="onSelect()">
      <mat-icon
        aria-disabled="true"
        matListItemIcon
        class="navigation-label-icon"
        >{{ view.icon }}</mat-icon
      >
      <span
        matListItemTitle
        class="navigation-label-text"
        >{{ view.name }}</span
      >
      <div
        matListItemMeta
        fxLayoutAlign="row"
        class="submitted-request-cart"
        data-cy="submitted-request-cart"
        *ngIf="view?.hasCart && (cartNumber | async | hasCartItems)"
        aria-disabled="true">
        <mat-icon class="cart-label">label</mat-icon>
        <span
          class="cart-font"
          data-cy="submitted-request-cart-count"
          >{{ (cartNumber | async)?.length }}</span
        >
      </div>
      <div
        matListItemMeta
        fxLayoutAlign="row"
        class="submitted-request-cart"
        data-cy="course-mapping-request-cart"
        *ngIf="authService.getCurrentUser() !== null && view?.hasCourses && (courseService.unsyncedCounter$ | async)"
        aria-disabled="true">
        <div>
          <mat-icon
            color="primary"
            class="cart-label"
            >label</mat-icon
          >
          <span
            class="cart-font"
            data-cy="course-mapping-cart-count"
            >{{ courseService.unsyncedCounter$ | async | courseCountPipe }}</span
          >
        </div>
      </div>
    </a>
  </ng-template>
</div>
