import { Action } from '@ngrx/store';
import { AccessRequest, NewComment, ProcessDetails, UpdateProcess } from '@starfish-access/models';

export const PUT_COMMENT_COMPLETED = '[DetailsList] PUT COMMENT completed - resetting';
export const PUT_SUCCESS_COMMENT = '[DetailsList] PUT COMMENT and Created';
export const PUT_COMMENT = '[DetailsList] PUT COMMENT';
export const PUT_SUCCESS_DETAILS = '[DetailsList] Details PUT and Created';
export const PUT_DETAILS = '[DetailsList] PUT Details';
export const POST_COMPLETED = '[DetailsList] Post completed - resetting';
export const POST_SUCCESS_DETAILS = '[DetailsList] Details Posted and Created';
export const POST_DETAILS = '[DetailsList] Post Details';
export const REQUEST_DETAILS = '[DetailsList] Request Details';
export const RECEIVE_DETAILS = '[DetailsList] Receive Details';
export const ERROR_DETAILS = '[DetailsList] Error From Details';
export const CLEAR_REQUEST_CART = '[DetailsList] Clear Request Card';

export class ClearRequestCart implements Action {
  readonly type = CLEAR_REQUEST_CART;
  constructor() {}
}

export class AddComment implements Action {
  readonly type = PUT_COMMENT;
  constructor(public payload: NewComment) {}
}

export class AddCommentSuccess implements Action {
  readonly type = PUT_SUCCESS_COMMENT;
  constructor(public payload: NewComment) {}
}

export class CompleteAddComment implements Action {
  readonly type = PUT_COMMENT_COMPLETED;
}

export class AddProcess implements Action {
  readonly type = POST_DETAILS;
  constructor(public payload: AccessRequest) {}
}

export class AddProcessSuccess implements Action {
  readonly type = POST_SUCCESS_DETAILS;
}

export class CompleteAddProcess implements Action {
  readonly type = POST_COMPLETED;
}

export class PutProcess implements Action {
  readonly type = PUT_DETAILS;
  constructor(public payload: UpdateProcess) {}
}

export class PutProcessSuccess implements Action {
  readonly type = PUT_SUCCESS_DETAILS;
}

export class RequestDetails implements Action {
  readonly type = REQUEST_DETAILS;
  constructor(public payload: string) {}
}

export class ReceiveDetails implements Action {
  readonly type = RECEIVE_DETAILS;
  constructor(public payload: ProcessDetails) {}
}

export class ErrorDetails implements Action {
  readonly type = ERROR_DETAILS;
  constructor(public payload: string) {}
}

export type DetailsActions =
  | AddComment
  | AddCommentSuccess
  | CompleteAddComment
  | AddProcess
  | AddProcessSuccess
  | CompleteAddProcess
  | PutProcess
  | PutProcessSuccess
  | RequestDetails
  | ReceiveDetails
  | ClearRequestCart
  | ErrorDetails;
