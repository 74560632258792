import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { handleErrorFromRest, OkayDialogService } from '@starfish-access/core';
import {
  AccessRequest,
  ApprovalTask,
  GenericService,
  NewComment,
  OrgModel,
  ProcessDetails,
  RequestItem,
  UpdateProcess,
} from '@starfish-access/models';
import * as R from 'ramda';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ServerApprovalTask,
  ServerGetProcessModel,
  ServerPostProcessModel,
} from '../../core/model/server/server-roles.model';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';

@Injectable()
export class DetailsService {
  private static processPoint = 'processes';
  private static endPoint = '/details';
  private static updatePoint = '/update';
  private static commentPoint = '/comments';
  private static URL = '';
  private readonly headers = new HttpHeaders()
    .append('Content-Type', 'application/json')
    .append(NO_CACHE_HEADER, 'true')
    .append(REQUIRE_AUTH_HEADER, 'true');

  constructor(
    private http: HttpClient,
    @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig,
    private dialogService: OkayDialogService
  ) {}

  // a new user request
  addNewProcess(newProcess: AccessRequest): Observable<Response | boolean> {
    const updatedModel: ServerPostProcessModel = this.toServerPostProcess(newProcess);

    // build the URL
    DetailsService.URL = this.starfishConfig.starfishServices + DetailsService.processPoint;
    return this.http
      .post<Response>(DetailsService.URL, updatedModel, {
        headers: this.headers,
      })
      .pipe(
        catchError(() =>
          this.dialogService.okay(
            'Role Addition Failure',
            'We could not complete your request.  Have you already requested the role? A role with a submission in progress can not be re-requested.'
          )
        )
      );
  }

  // requesting user or admin adding a comment to an existing request
  addNewComment(newComment: NewComment): Observable<Response> {
    // build the URL
    DetailsService.URL =
      this.starfishConfig.starfishServices +
      DetailsService.processPoint +
      '/' +
      newComment.processInstanceId +
      DetailsService.commentPoint;

    return this.http
      .put<Response>(DetailsService.URL, newComment.comment, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  // we are getting details from a specific existing process
  getDetails(id: string): Observable<ProcessDetails> {
    // build the URL
    DetailsService.URL =
      this.starfishConfig.starfishServices + DetailsService.processPoint + '/' + id + DetailsService.endPoint;
    return this.http
      .get<ServerGetProcessModel>(DetailsService.URL, {
        headers: this.headers,
      })
      .pipe(
        map((res) => this.convertToProcessDetails(res)),
        catchError(handleErrorFromRest)
      );
  }

  // an admin is editing an existing request
  updateProcess(processDetails: UpdateProcess): Observable<Response> {
    const serverModel: ServerPostProcessModel = this.toServerPostProcess(processDetails.requestToUpdate);
    // build the URL
    DetailsService.URL =
      this.starfishConfig.starfishServices +
      DetailsService.processPoint +
      '/' +
      processDetails.processInstanceId +
      DetailsService.updatePoint;

    return this.http
      .put<Response>(DetailsService.URL, serverModel, {
        headers: this.headers,
      })
      .pipe(catchError(handleErrorFromRest));
  }

  private convertToProcessDetails(details: ServerGetProcessModel): ProcessDetails {
    const convertedDetails: ProcessDetails = {
      approvalTasks: this.toClientApprovalTasks(details.approvalTasks),
      processComments: details.processComments ? details.processComments : [],
      requestDetails: this.toAccessRequest(details.requestDetails),
    };

    if (details.requestDetails.requestedOrganizations && details.requestDetails.requestedOrganizations.length > 0) {
      const orgList: OrgModel[] = [];
      for (const org of details.requestDetails.requestedOrganizations) {
        orgList.push(org);
      }
      convertedDetails.organizations = orgList;
    }
    return convertedDetails;
  }

  private toClientApprovalTasks(satList: ServerApprovalTask[] | undefined): ApprovalTask[] {
    if (!satList || satList.length === 0) {
      return [];
    }

    const approvalTaskList: ApprovalTask[] = [];
    satList.forEach((sat) => {
      approvalTaskList.push({
        taskApprovalTime: sat.taskApprovalTime ? sat.taskApprovalTime : '',
        taskApprovedBy: sat.taskApprovedBy ? sat.taskApprovedBy : '',
        taskAssignedTo: sat.taskAssignedTo,
        taskComment: sat.taskComment,
        taskId: sat.taskId,
        taskName: sat.taskName,
        taskStatus: sat.taskStatus,
      });
    });
    return approvalTaskList;
  }

  private buildOrgParam(process: AccessRequest | undefined): OrgModel[] {
    const endpointOrgArray: OrgModel[] = [];
    if (!process || !process.organizations || R.isEmpty(process.organizations[0])) {
      return [];
    }
    if (process.organizations.length > 0) {
      for (const org of process.organizations) {
        endpointOrgArray.push({
          code: org.code,
          name: org.name,
        });
      }
    } else if (JSON.stringify(process.organizations).length > 2) {
      endpointOrgArray.push(process.organizations as any);
    }
    return endpointOrgArray;
  }

  private toAccessRequest(serverModel: ServerPostProcessModel): AccessRequest {
    const ar: AccessRequest = {
      accessUser: serverModel.accessUser,
      college: serverModel.college ? serverModel.college : '',
      department: serverModel.department,
      jobTitle: serverModel.jobTitle,
      starfishTrainingFlag: serverModel.starfishTrainingFlag,
      requestStatus: serverModel.requestStatus,
      'request-date': serverModel['request-date'],
      'last-status-date': serverModel['last-status-date'],
      approvingManager: serverModel.approvingManager,
      organizations: serverModel.requestedOrganizations,
      requestItems: serverModel.requestItems, // this will only copy over the 'role request'
    };

    ar.requestItems.push({
      type: 'kiosk-request',
      kiosks: serverModel.requestedKiosks ? serverModel.requestedKiosks.map(this.toGenericService) : [],
    });
    ar.requestItems.push({
      type: 'service-request',
      services: serverModel.requestedKiosks ? serverModel.requestedServices.map(this.toGenericService) : [],
    });

    return ar;
  }
  private toServerPostProcess(process: AccessRequest): ServerPostProcessModel {
    const roleId: number = this.getRoleIdFromRequest(process.requestItems);
    const modelToPost: ServerPostProcessModel = {
      accessUser: process.accessUser,
      college: process.college,
      department: process.department,
      starfishTrainingFlag: process.starfishTrainingFlag,
      requestStatus: process.requestStatus === '' ? null : process.requestStatus, // the endpoint will return a 400 if the request status is an empty string
      requestItems: [
        {
          type: 'role-request',
          roles: [{ id: roleId }], // TODO
        },
      ],
      'request-date': process['request-date'],
      requestedOrganizations: this.buildOrgParam(process),
      requestedKiosks: this.getKiosksFromRequest(process.requestItems),
      requestedServices: this.getServicesFromRequest(process.requestItems),
      approvingManager: process.approvingManager,
      'last-status-date': process['last-status-date'],
      jobTitle: process.jobTitle,
    };
    return modelToPost;
  }

  private getKiosksFromRequest(requestItems: RequestItem[]): OrgModel[] {
    if (!requestItems || requestItems.length === 0) {
      return [];
    }
    let kioskList: OrgModel[] = [];

    requestItems.forEach((element) => {
      if (element?.type === 'kiosk-request' && element?.kiosks) {
        kioskList = element.kiosks.map(this.toOrgModel);
      }
    });
    return kioskList;
  }

  private getServicesFromRequest(requestItems: RequestItem[]): OrgModel[] {
    if (!requestItems || requestItems.length === 0) {
      return [];
    }
    const svcList: OrgModel[] = [];
    requestItems.forEach((element) => {
      if (element?.type === 'service-request' && element?.services) {
        element.services.forEach((svc) => svcList.push(this.toOrgModel(svc)));
      }
    });
    return svcList;
  }

  private toOrgModel(requestItem: any): OrgModel {
    return {
      code: requestItem.id,
      name: requestItem.name,
    };
  }

  private toGenericService(orgModel: OrgModel): GenericService {
    return {
      description: orgModel.code,
      id: orgModel.code,
      name: orgModel.name,
      parentId: orgModel.code,
    };
  }

  private getRoleIdFromRequest(requestItems: RequestItem[]): number {
    if (!requestItems || requestItems.length === 0) {
      return -1;
    }

    let retVal = -1;
    requestItems.forEach((element) => {
      if (element?.type === 'role-request' && element?.roles) {
        retVal = element.roles[0].id ? element.roles[0].id : -1;
      }
    });

    return retVal;
  }
}
