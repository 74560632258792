import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NO_CACHE_HEADER } from '@psu/utils/browser';
import { REQUIRE_AUTH_HEADER } from '@psu/utils/security';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StarfishConfig, STARFISH_CONFIG } from '../../starfish.config';
import { OrganizationFile, OrgType, RelationshipOrgModel } from '../model/starfish-organizations.model';
import { handleErrorFromRest } from '../utils/utils.model';
import { convertToRelationshipOrg } from './orgs.service.utils';

const headers = new HttpHeaders()
  .append('Content-Type', 'application/json')
  .append(NO_CACHE_HEADER, 'true')
  .append(REQUIRE_AUTH_HEADER, 'true');

@Injectable()
export class OrganizationService {
  private baseUrl = this.starfishConfig.starfishServices;

  private ORG_PATH = 'organizations';

  private orgBaseUrl = `${this.baseUrl}${this.ORG_PATH}`;

  private orgCache: Observable<RelationshipOrgModel[]> | undefined = undefined;

  constructor(private http: HttpClient, @Inject(STARFISH_CONFIG) private starfishConfig: StarfishConfig) {}

  getOrganizations(subType: OrgType): Observable<OrganizationFile[]> {
    const url = `${this.orgBaseUrl}`;
    return this.http.get<OrganizationFile[]>(url, { headers }).pipe(catchError(handleErrorFromRest));
  }

  getRelationshipOrgs(): Observable<RelationshipOrgModel[]> {
    if (!this.orgCache) {
      this.orgCache = this.getOrganizations('NONE').pipe(convertToRelationshipOrg());
    }
    return this.orgCache;
  }
}
