<div class="sf-date-range-picker">
  <mat-date-range-input
    [rangePicker]="picker2"
    [min]="minDate"
    [max]="maxDate"
    class="date-picker-input">
    <input
      matStartDate
      [matDatepicker]="picker2"
      (dateChange)="dateUpdatedStart($event)" />
    <input
      matEndDate
      [matDatepicker]="picker2"
      (dateChange)="dateUpdatedEnd($event)" />
  </mat-date-range-input>

  <button
    mat-flat-button
    class="date-filter calendar-button"
    (click)="picker2.open()">
    <mat-icon>calendar_today</mat-icon><span class="btn-filter-date">{{ humanReadableDate | async }}</span>
    <mat-date-range-picker #picker2></mat-date-range-picker>
  </button>
</div>
